import { putResolve, call, spawn, take, select } from 'redux-saga/effects';
import firebase from 'firebase';

import { usersPageActions, usersPageSelector } from './store';
import { api } from '../../app/api';

function* getUsersSaga() {
  while (true) {
    try {
      const { payload: filter } = yield take(usersPageActions.getUsers().type);

      yield putResolve(usersPageActions.setLoading(true));
      yield putResolve(usersPageActions.setError(''));

      const pageToken = yield select(usersPageSelector((state) => state.pageToken));
      const apiData = yield call(
        api,
        `/.netlify/functions/get-users?${filter ? `filter=${filter}` : pageToken ? `pageToken=${pageToken}` : ''}`,
      );

      yield putResolve(usersPageActions.setLoading(false));

      if (apiData.error) {
        yield putResolve(usersPageActions.setError(apiData.error));
      } else {
        yield putResolve(usersPageActions.loadUsers(apiData));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(usersPageActions.setLoading(false));
      yield putResolve(usersPageActions.setError('Something went wrong'));
    }
  }
}

function* getUserSaga() {
  while (true) {
    try {
      const { payload } = yield take(usersPageActions.getUser().type);

      yield putResolve(usersPageActions.setLoading(true));
      yield putResolve(usersPageActions.setError(''));

      const apiData = yield call(api, `/.netlify/functions/get-user?uid=${payload}`);

      yield putResolve(usersPageActions.setLoading(false));

      if (apiData.error) {
        yield putResolve(usersPageActions.setError(apiData.error));
      } else {
        yield putResolve(usersPageActions.loadUser(apiData));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(usersPageActions.setLoading(false));
      yield putResolve(usersPageActions.setError('Something went wrong'));
    }
  }
}

function* createUserSaga() {
  while (true) {
    try {
      const { payload, meta } = yield take(usersPageActions.createUser().type);

      yield putResolve(usersPageActions.setLoading(true));
      yield putResolve(usersPageActions.setError(''));

      const apiData = yield call(api, `/.netlify/functions/create-user`, 'POST', payload);

      yield putResolve(usersPageActions.setLoading(false));

      if (apiData.error) {
        yield putResolve(usersPageActions.setError(apiData.error));
      } else {
        meta.redirect();
      }
    } catch (err) {
      console.log(err);
      yield putResolve(usersPageActions.setLoading(false));
      yield putResolve(usersPageActions.setError('Something went wrong'));
    }
  }
}

function* updateUserSaga() {
  while (true) {
    try {
      const { payload } = yield take(usersPageActions.updateUser().type);

      yield putResolve(usersPageActions.setLoading(true));
      yield putResolve(usersPageActions.setError(''));

      const apiData = yield call(api, `/.netlify/functions/update-user?uid=${payload.uid}`, 'PUT', payload);

      yield putResolve(usersPageActions.setLoading(false));

      if (apiData.error) {
        yield putResolve(usersPageActions.setError(apiData.error));
      } else {
        yield putResolve(usersPageActions.loadUser(apiData));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(usersPageActions.setLoading(false));
      yield putResolve(usersPageActions.setError('Something went wrong'));
    }
  }
}

function* sendPasswordResetEmailSaga() {
  while (true) {
    try {
      const { payload } = yield take(usersPageActions.sendPasswordResetEmail().type);

      yield putResolve(usersPageActions.setLoading(true));
      yield putResolve(usersPageActions.setError(''));

      yield call((email) => firebase.auth().sendPasswordResetEmail(email), payload.email);

      yield putResolve(usersPageActions.setLoading(false));
    } catch (err) {
      console.log(err);
      yield putResolve(usersPageActions.setLoading(false));
      yield putResolve(usersPageActions.setError('Something went wrong'));
    }
  }
}

function* sendVerificationEmailSaga() {
  while (true) {
    try {
      const { payload } = yield take(usersPageActions.sendVerificationEmail().type);

      yield putResolve(usersPageActions.setLoading(true));
      yield putResolve(usersPageActions.setError(''));

      const apiData = yield call(api, `/.netlify/functions/send-verification-email?uid=${payload.uid}`, 'POST');

      yield putResolve(usersPageActions.setLoading(false));

      if (apiData && apiData.error) {
        yield putResolve(usersPageActions.setError(apiData.error));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(usersPageActions.setLoading(false));
      yield putResolve(usersPageActions.setError('Something went wrong'));
    }
  }
}

function* setEmailToVerifiedSaga() {
  while (true) {
    try {
      const { payload } = yield take(usersPageActions.setEmailToVerified().type);

      yield putResolve(usersPageActions.setLoading(true));
      yield putResolve(usersPageActions.setError(''));

      const apiData = yield call(api, `/.netlify/functions/set-email-to-verified?uid=${payload.uid}`, 'POST');

      yield putResolve(usersPageActions.setLoading(false));

      if (apiData.error) {
        yield putResolve(usersPageActions.setError(apiData.error));
      } else {
        yield putResolve(usersPageActions.loadUser(apiData));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(usersPageActions.setLoading(false));
      yield putResolve(usersPageActions.setError('Something went wrong'));
    }
  }
}

function* disableUserSaga() {
  while (true) {
    try {
      const { payload } = yield take(usersPageActions.disableUser().type);

      yield putResolve(usersPageActions.setLoading(true));
      yield putResolve(usersPageActions.setError(''));

      const apiData = yield call(api, `/.netlify/functions/disable-user?uid=${payload.uid}`, 'POST');

      yield putResolve(usersPageActions.setLoading(false));

      if (apiData.error) {
        yield putResolve(usersPageActions.setError(apiData.error));
      } else {
        yield putResolve(usersPageActions.loadUser(apiData));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(usersPageActions.setLoading(false));
      yield putResolve(usersPageActions.setError('Something went wrong'));
    }
  }
}

function* enableUserSaga() {
  while (true) {
    try {
      const { payload } = yield take(usersPageActions.enableUser().type);

      yield putResolve(usersPageActions.setLoading(true));
      yield putResolve(usersPageActions.setError(''));

      const apiData = yield call(api, `/.netlify/functions/enable-user?uid=${payload.uid}`, 'POST');

      yield putResolve(usersPageActions.setLoading(false));

      if (apiData.error) {
        yield putResolve(usersPageActions.setError(apiData.error));
      } else {
        yield putResolve(usersPageActions.loadUser(apiData));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(usersPageActions.setLoading(false));
      yield putResolve(usersPageActions.setError('Something went wrong'));
    }
  }
}

export default function* usersPageSagaRootSaga() {
  yield spawn(getUsersSaga);
  yield spawn(getUserSaga);
  yield spawn(createUserSaga);
  yield spawn(updateUserSaga);
  yield spawn(sendPasswordResetEmailSaga);
  yield spawn(sendVerificationEmailSaga);
  yield spawn(setEmailToVerifiedSaga);
  yield spawn(disableUserSaga);
  yield spawn(enableUserSaga);
}

import React, { Component } from 'react';
import { state } from '../../store';
import { connect } from 'react-redux';
import { sellerProfileActions, sellerProfileSelector } from './store';
import { Dropdown } from '../../components-library/dropDown';
import Input from 'react-phone-number-input/input';
import { appSelector } from '../../app/store';
import ErrorPage from '../../components-library/error-page';
import { withRouter } from 'react-router-dom';
const incotermsOption = [
  { value: 'EXW', label: 'EXW' },
  { value: 'FCA', label: 'FCA' },
  { value: 'CPT', label: 'CPT' },
  { value: 'CIP', label: 'CIP' },
  { value: 'DAP', label: 'DAP' },
  { value: 'DPU', label: 'DPU' },
  { value: 'DDP', label: 'DDP' },
  { value: 'FAS', label: 'FAS' },
  { value: 'FOB', label: 'FOB' },
  { value: 'CFR', label: 'CFR' },
  { value: 'CIF', label: 'CIF' },
  { value: "Don't know", label: "Don't know" },
];
const incotermType = incotermsOption.map((incoterm) => ({
  ...incoterm,
  label: incoterm.value,
  value: incoterm.value,
}));

export const SellerProfile = withRouter(
  connect(
    state.select({
      data: sellerProfileSelector((state) => state.data, []),
      loading: sellerProfileSelector((state) => state.loading, null),
      error: appSelector((state) => state.error, ''),
      editMode: sellerProfileSelector((state) => state.editMode, false),
    }),
    state.actions(sellerProfileActions, [
      'sellerProfilePageData',
      'setEditMode',
      'setOriginalData',
      'handleInputChange',
      'updateSellerProfile',
    ]),
  )(
    class SellerProfile extends Component {
      state = {
        buttonClicked: false,
      };
      componentWillMount() {
        const { query } = this.props;
        let id = query.get('id');
        if (id) {
          this.props.sellerProfilePageData(id);
        } else {
          this.props.history.push('/404');
        }
      }
      saveButtonHandler = (event) => {
        const { data, updateSellerProfile } = this.props;

        event.preventDefault();
        this.setState({ buttonClicked: true });
        let payload = {
          id: data['Supplier Record ID'],
          'Supplier Phone': data['Supplier Phone'],
          'Company (legal and DBA name)': data['Company (legal and DBA name)'],
          'DUNS Number': data['DUNS Number'],
          'VAT Id Number': data['VAT Id Number'],
          'Do you have an export license?': data['Do you have an export license?'],
          'Supplier Incoterms': data['Supplier Incoterms'],
          'Referred by?': data['Referred by?'],
          'Supplier Address 1': data['Supplier Address 1'],
          'Supplier Address 2': data['Supplier Address 2'],
          'Supplier City': data['Supplier City'],
          'Supplier State': data['Supplier State'],
          'Supplier Country': data['Supplier Country'],
          'Other State/Country': data['Other State/Country'],
          'Zip or Postal code': data['Zip or Postal code'],
        };

        updateSellerProfile(payload);
      };
      render() {
        const { data, loading, error, editMode } = this.props;

        //to edit profile
        let status = false;
        if (editMode === true) {
          status = true;
        }
        const { setEditMode, setOriginalData, handleInputChange } = this.props;
        let inocterms = data['Supplier Incoterms'];
        let value = data['Supplier Phone'];

        if (loading) {
          return (
            <div className="main-content">
              <section className="container page-content pb-6 market-content text-center mt-4">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </section>
            </div>
          );
        }
        if (error) {
          return <ErrorPage error={error} />;
        }

        return (
          <div className="main-content">
            <div className="pt-3 pb-7 bg-dark bg-ellipses">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-md-10 col-lg-8 col-xl-6">
                    <h1 className="display-4 text-center text-white">Your Seller Profile</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row mt-n7">
                <div className="col-12 col-lg-8 ml-auto mr-auto">
                  <div className="card">
                    <div className="card-body">
                      <div className="text-uppercase text-center text-muted my-2">Profile Information</div>

                      {status === true ? (
                        <form onSubmit={this.saveButtonHandler}>
                          <div className="pt-3">
                            <h2 className="header-title">Supplier Details</h2>
                          </div>
                          <hr />

                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label>
                                  <strong>
                                    Phone Number
                                    <span className="form-asterik">*</span>
                                  </strong>
                                </label>
                                <span className="d-flex">
                                  <div className="phone-number">+1&nbsp;(US)&nbsp;</div>
                                  <Input
                                    country="US"
                                    value={value}
                                    required
                                    maxLength="14"
                                    pattern=".{14,14}"
                                    onInvalid={(e) => {
                                      e.target.setCustomValidity('Enter valid phone');
                                    }}
                                    onInput={(e) => {
                                      e.target.setCustomValidity('');
                                    }}
                                    className="form-control"
                                    onChange={(e) => {
                                      let payload = {
                                        fieldName: 'Supplier Phone',
                                        value: e,
                                      };
                                      handleInputChange(payload);
                                    }}
                                  />
                                </span>
                              </div>
                            </div>

                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label>
                                  <strong>
                                    Company (legal and DBA name)
                                    <span className="form-asterik">*</span>
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={data['Company (legal and DBA name)']}
                                  required
                                  onChange={(e) => {
                                    let payload = {
                                      fieldName: 'Company (legal and DBA name)',
                                      value: e.target.value,
                                    };
                                    handleInputChange(payload);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label>
                                  <strong>DUNS Number</strong>
                                </label>
                                <p className="form-text text-muted">
                                  Providing the Data Universal Numbering System number helps to expedite due diligence
                                  and ensure transparency with potential partners.
                                </p>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={data['DUNS Number'] && data['DUNS Number']}
                                  onChange={(e) => {
                                    let payload = {
                                      fieldName: 'DUNS Number',
                                      value: e.target.value,
                                    };
                                    handleInputChange(payload);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label>
                                  <strong>Value Added Tax Id Number (VATIN)</strong>
                                </label>
                                <p className="form-text text-muted">
                                  Please provide your Value Added Tax Identification Number, if you have.
                                </p>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={data['VAT Id Number']}
                                  onChange={(e) => {
                                    let payload = {
                                      fieldName: 'VAT Id Number',
                                      value: e.target.value,
                                    };
                                    handleInputChange(payload);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label>
                                  <strong>
                                    Do you have an export license?
                                    <span className="form-asterik">*</span>
                                  </strong>
                                </label>
                                <select
                                  className="form-control"
                                  id="exportLicense"
                                  required
                                  defaultValue={data['Do you have an export license?']}
                                  onChange={(e) => {
                                    let payload = {
                                      fieldName: 'Do you have an export license?',
                                      value: e.target.value,
                                    };
                                    handleInputChange(payload);
                                  }}
                                >
                                  <option></option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                  <option value="N/A (not importing)">N/A (not importing)</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label>
                                  <strong>
                                    Incoterms
                                    <span className="form-asterik">*</span>
                                  </strong>
                                </label>
                                <Dropdown
                                  isMulti
                                  options={incotermsOption}
                                  value={inocterms && incotermType.filter((item) => inocterms.includes(item.value))}
                                  onChange={(e) => {
                                    let payload = {
                                      fieldName: 'Supplier Incoterms',
                                      value: e,
                                    };

                                    handleInputChange(payload);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label>
                                  <strong>Referred by?</strong>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={data['Referred by?'] && data['Referred by?']}
                                  onChange={(e) => {
                                    let payload = {
                                      fieldName: 'Referred by?',
                                      value: e.target.value,
                                    };
                                    handleInputChange(payload);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <hr />
                          <div className="pt-3 pb-3">
                            <h2 className="header-title">Location</h2>
                          </div>

                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label>
                                  <strong>
                                    Street Address
                                    <span className="form-asterik">*</span>
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  defaultValue={data['Supplier Address 1']}
                                  onChange={(e) => {
                                    let payload = {
                                      fieldName: 'Supplier Address 1',
                                      value: e.target.value,
                                    };

                                    handleInputChange(payload);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label>
                                  <strong>Street Address Line 2</strong>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={data['Supplier Address 2'] && data['Supplier Address 2']}
                                  onChange={(e) => {
                                    let payload = {
                                      fieldName: 'Supplier Address 2',
                                      value: e.target.value,
                                    };

                                    handleInputChange(payload);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label>
                                  <strong>
                                    City
                                    <span className="form-asterik">*</span>
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  defaultValue={data['Supplier City']}
                                  onChange={(e) => {
                                    let payload = {
                                      fieldName: 'Supplier City',
                                      value: e.target.value,
                                    };

                                    handleInputChange(payload);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label>
                                  <strong>
                                    State
                                    <span className="form-asterik">*</span>
                                  </strong>
                                </label>
                                <select
                                  className="form-control"
                                  id="state"
                                  required
                                  defaultValue={data['Supplier State']}
                                  onChange={(e) => {
                                    let payload = {
                                      fieldName: 'Supplier State',
                                      value: e.target.value,
                                    };

                                    handleInputChange(payload);
                                  }}
                                >
                                  <option></option>
                                  <option>AK</option>
                                  <option>AL</option>
                                  <option>AR</option>
                                  <option>AZ</option>
                                  <option>CA</option>
                                  <option>CO</option>
                                  <option>CT</option>
                                  <option>DE</option>
                                  <option>FL</option>
                                  <option>GA</option>
                                  <option>HI</option>
                                  <option>IA</option>
                                  <option>ID</option>
                                  <option>IL</option>
                                  <option>IN</option>
                                  <option>KS</option>
                                  <option>KY</option>
                                  <option>LA</option>
                                  <option>MA</option>
                                  <option>MD</option>
                                  <option>ME</option>
                                  <option>MI</option>
                                  <option>MN</option>
                                  <option>MO</option>
                                  <option>MS</option>
                                  <option>MT</option>
                                  <option>NC</option>
                                  <option>NE</option>
                                  <option>NH</option>
                                  <option>NJ</option>
                                  <option>NV</option>
                                  <option>NY</option>
                                  <option>OH</option>
                                  <option>OK</option>
                                  <option>OR</option>
                                  <option>Other US Territory</option>
                                  <option>Outside of USA</option>
                                  <option>PA</option>
                                  <option>RI</option>
                                  <option>SC</option>
                                  <option>SD</option>
                                  <option>TN</option>
                                  <option>TX</option>
                                  <option>UT</option>
                                  <option>VA</option>
                                  <option>VT</option>
                                  <option>WA</option>
                                  <option>WI</option>
                                  <option>WV</option>
                                  <option>WY</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label>
                                  <strong>
                                    Zip or Postal code
                                    <span className="form-asterik">*</span>
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  defaultValue={data['Zip or Postal code']}
                                  onInvalid={(e) => e.target.setCustomValidity('Enter valid zip code')}
                                  onInput={(e) => e.target.setCustomValidity('')}
                                  maxLength="5"
                                  pattern="^[0-9]{5}"
                                  onChange={(e) => {
                                    let payload = {
                                      fieldName: 'Zip or Postal code',
                                      value: e.target.value,
                                    };
                                    handleInputChange(payload);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label>
                                  <strong>
                                    Country Select
                                    <span className="form-asterik">*</span>
                                  </strong>
                                </label>
                                <select
                                  className="form-control"
                                  id="country"
                                  required
                                  defaultValue={data['Supplier Country']}
                                  onChange={(e) => {
                                    let payload = {
                                      fieldName: 'Supplier Country',
                                      value: e.target.value,
                                    };

                                    handleInputChange(payload);
                                  }}
                                >
                                  <option></option>
                                  <option>USA</option>
                                  <option>China</option>
                                  <option>Other</option>
                                  <option>Pakistan </option>
                                  <option>Mexico</option>
                                  <option>Canada</option>
                                  <option>South Korea</option>
                                  <option>United Kingdom</option>
                                  <option>Singapore</option>
                                  <option>Malta</option>
                                  <option>India</option>
                                  <option>Vietnam</option>
                                  <option>United Arab Emirates</option>
                                  <option>Hong Kong</option>
                                  <option>South Africa</option>
                                  <option>Japan</option>
                                  <option>The Philippines</option>
                                  <option>Australia</option>
                                  <option>Italy</option>
                                  <option>Germany</option>
                                  <option>Taiwan</option>
                                  <option>The Netherlands</option>
                                  <option>Sri Lanka</option>
                                  <option>Oman</option>
                                  <option>Cambodia</option>
                                  <option>Switzerland</option>
                                  <option>Belarus</option>
                                  <option>Belgium</option>
                                  <option>Bangladesh</option>
                                  <option>Greece</option>
                                  <option>Turkey</option>
                                  <option>Bulgaria</option>
                                  <option>Thailand</option>
                                  <option>Czech Republic</option>
                                  <option>Serbia</option>
                                  <option>Poland</option>
                                  <option>Georgia</option>
                                  <option>Malaysia</option>
                                  <option>Latvia</option>
                                  <option>Estonia</option>
                                  <option>Hungary</option>
                                  <option>New Zealand</option>
                                  <option>Indonesia</option>
                                  <option>Puerto Rico</option>
                                  <option>Slovenia</option>
                                  <option>Ireland</option>
                                  <option>France</option>
                                  <option>Colombia</option>
                                  <option>Spain</option>
                                  <option>Jordan</option>
                                  <option>Russia</option>
                                  <option>Norway</option>
                                  <option>Denmark</option>
                                  <option>Lithuania</option>
                                  <option>Finland</option>
                                  <option>Israel</option>
                                  <option>Portugal</option>
                                  <option>Panama</option>
                                  <option>Croati</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          {data['Supplier Country'] === 'Other' && (
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <label>
                                    <strong>
                                      Other State/Country
                                      <span className="form-asterik">*</span>
                                    </strong>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={data['Other State/Country']}
                                    required
                                    onChange={(e) => {
                                      let payload = {
                                        fieldName: 'Other State/Country',
                                        value: e.target.value,
                                      };
                                      handleInputChange(payload);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="row justify-content-center mt-4 mb-4">
                            {status && (
                              <div className="d-flex">
                                <button
                                  className="mr-3 btn btn-primary"
                                  onClick={(_e) => {
                                    setEditMode(false);
                                    setOriginalData();
                                  }}
                                >
                                  <strong>Cancel</strong>
                                </button>

                                <button className="btn btn-primary form-button" type="submit" id="submitBtn">
                                  <strong>Save</strong>
                                </button>
                              </div>
                            )}
                          </div>
                        </form>
                      ) : (
                        <div className="mb-1">
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                              <div>Contact Name:</div>
                              <div> {data['Supplier Full Name']}</div>
                            </li>
                            <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                              <div> Email Address:</div>
                              <div>{data['Supplier Email']}</div>
                            </li>
                            <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                              <div>Phone Number:</div>
                              <div>{data['Supplier Phone']}</div>
                            </li>
                            <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                              <div>Incoterms:</div>
                              <div>
                                {data['Supplier Incoterms'] && data['Supplier Incoterms']
                                  ? data['Supplier Incoterms'].join(', ')
                                  : '-'}
                              </div>
                            </li>
                            <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                              <div>Address:</div>
                              <div>
                                {data['Supplier Address 1']},&nbsp;
                                {data['Supplier City']},&nbsp;
                                {data['Supplier State']}&nbsp;
                                {data['Zip or Postal code']}
                              </div>
                            </li>
                          </ul>

                          <div className="row justify-content-center">
                            <button
                              className="col-8 btn btn-primary my-4"
                              onClick={(e) => {
                                setEditMode(true);
                              }}
                            >
                              <strong>Update Your Profile</strong>
                              <br />
                            </button>
                          </div>
                        </div>
                      )}
                      <p className="text-center">
                        WARNING: <strong>DO NOT</strong> share this website page/url/link with anyone outside YOUR
                        company. This page contains personal identifiable information (PII) about YOU, YOUR company, and
                        YOUR requests/offers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    },
  ),
);

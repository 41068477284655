import { state } from '../store';

export const [appActions, appSelector, AppState] = state('App', {
  initial: {
    authLoaded: false,
    user: null,
    decodedToken: null,
    posts: [],
    paramId: '',
    userType: '',
    hideNav: false,
    error: '',
  },
  on: {
    authenticate: (_, { user, decodedToken }) => {
      return {
        ..._,
        authLoaded: true,
        user,
        decodedToken,
      };
    },
    loadPosts: (_, posts) => {
      return { ..._, posts };
    },
    setUserType: (_, userType) => {
      return { ..._, userType };
    },
    setId: (_, paramId) => {
      return { ..._, paramId };
    },
    hideNav: (_) => {
      return { ..._, hideNav: true };
    },
    showNav: (_) => {
      return { ..._, hideNav: false };
    },
    setError: (_, data) => {
      return { ..._, error: data };
    },
  },
  events: ['buyerCreateRFQ'],
});

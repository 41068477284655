import React from 'react';
import { connect } from 'react-redux';
import { state } from '../store/index';
import avatar from '../app/assets/img/user.svg';
import Logo from '../app/assets/img/p95-lvrg-logo.png';

import RFQIcon from '../app/assets/img/notepad.png';
import OfferIcon from '../app/assets/img/statement.png';
import GroupOrdersIcon from '../app/assets/img/users.png';
import DashboardIcon from '../app/assets/img/home.png';
import ProductIcon from '../app/assets/img/products.png';
import { NavLink, withRouter } from 'react-router-dom';

import { appActions, appSelector } from '../app/store';
import { buyerProfileSelector, buyerProfileActions } from '../buyer/buyer-profile/store';
import styled from 'styled-components';
import firebase from 'firebase';

const Img = styled.img`
  max-height: 5rem !important;
`;
const sellerPages = [
  'seller',
  'seller-products',
  'seller-product',
  'seller-offers',
  'seller-rfqs',
  'rfq',
  'offer-details',
  'rfq-details',
  'stripe-connect-oauth',
  'stripe-connect-success',
  'offer-submission',
  'edit-offer',
  'add-product',
  'edit-product',
  'product-details',
  'seller-dashboard',
];

const buyerPages = [
  'group-orders',
  'buyer',
  // 'buyer-dashboard',
  'buyer-rfqs',
  'deal',
  'offers',
  'agg',
  'offer',
  'edit-rfq',
  'create-rfq',
];
const noNavPages = ['500', '404'];

export const Navbar = withRouter(
  connect(
    state.select({
      userType: appSelector((state) => state.userType, ''),
      paramId: appSelector((state) => state.paramId, ''),
      hideNav: appSelector((state) => state.hideNav, false),
      user: appSelector((state) => state.user),
      decodedToken: appSelector((state) => state.decodedToken),
      notVerified: buyerProfileSelector((state) => state.notVerified),
      qualified: buyerProfileSelector((state) => state.qualified),
    }),
    state.actions(appActions, ['setUserType', 'setId'], buyerProfileActions, [
      'setShowNotQualifiedModal',
      'setShowNotVerifiedModal',
    ]),
  )(
    class Navbar extends React.PureComponent {
      state = {
        navOpen: false,
        profileDivOpen: false,
        noNavPage: null,
      };
      expandNav = () => {
        this.setState({ navOpen: !this.state.navOpen });
      };
      profileDivExpand = () => {
        this.setState({ profileDivOpen: !this.state.profileDivOpen });
      };

      updateUserType = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let pathArray = window.location.pathname.split('/');
        let id = urlParams.get('id');

        if (sellerPages.some((v) => pathArray[1] === v)) {
          this.props.setUserType('seller');
          if (
            pathArray[1] !== 'rfq' &&
            pathArray[1] !== 'ama-agg' &&
            pathArray[1] !== 'agg' &&
            pathArray[1] !== 'edit-rfq' &&
            pathArray[1] !== 'edit-product' &&
            pathArray[1] !== 'product-details'
          ) {
            this.props.setId(id);
          }
        }
        if (buyerPages.some((v) => pathArray[1] === v)) {
          //  this.setState({ userType: "buyer" });
          this.props.setUserType('buyer');
          this.props.setId(id);
        }
        if (noNavPages.some((v) => pathArray[1] === v)) {
          this.setState({ noNavPage: pathArray[1] });
        }
      };

      componentDidMount() {
        this.updateUserType();

        this.unhandleRouteChange = this.props.history.listen(() => {
          this.updateUserType();
        });
      }

      componentWillUnmount() {
        this.unhandleRouteChange();
      }

      Offers = () => {
        let result = null;
        const { paramId } = this.props;

        if (this.props.userType === 'seller') {
          result = (
            <li
              className="nav-item"
              onClick={(e) => {
                this.setState({ navOpen: !this.state.navOpen });
              }}
            >
              <NavLink className="nav-link navFont cursorDefault nav-icon-hover " to={'/seller-offers?id=' + paramId}>
                <span className="navbarMenu">
                  <img src={OfferIcon} alt="offer-icon" className="align-image" />
                  &nbsp; Offers
                </span>
              </NavLink>
            </li>
          );
        }

        return result;
      };

      Products = () => {
        const { paramId } = this.props;
        let result = null;

        if (this.props.userType === 'seller') {
          result = (
            <li
              className="nav-item"
              onClick={(e) => {
                this.setState({ navOpen: !this.state.navOpen });
              }}
            >
              <NavLink className="nav-link  navFont cursorDefault nav-icon-hover" to={'/seller-products?id=' + paramId}>
                <span className="navbarMenu">
                  <img src={ProductIcon} alt="product-icon" className="align-image" />
                  &nbsp; Products
                </span>
              </NavLink>
            </li>
          );
        }

        return result;
      };
      RFQs = () => {
        const { paramId } = this.props;
        let result = null;

        if (this.props.userType === 'seller') {
          result = (
            <li
              className="nav-item"
              onClick={(e) => {
                this.setState({ navOpen: !this.state.navOpen });
              }}
            >
              <NavLink className="nav-link  navFont cursorDefault nav-icon-hover" to={'/seller-rfqs?id=' + paramId}>
                <span className="navbarMenu ">
                  <img src={RFQIcon} alt="RFQ-icon" className="align-image" />
                  &nbsp; Request for Quotes
                </span>
              </NavLink>
            </li>
          );
        }
        if (this.props.userType === 'buyer') {
          result = (
            <li
              className="nav-item "
              onClick={(e) => {
                this.setState({ navOpen: !this.state.navOpen });
              }}
            >
              <NavLink className="nav-link navFont cursorDefault nav-icon-hover" to={'/buyer-rfqs?id=' + paramId}>
                <span className="navbarMenu ">
                  <img src={RFQIcon} alt="RFQ-icon" className="align-image" />
                  <span style={{ paddingLeft: '0.3em' }}>Request for Quotes</span>
                </span>
              </NavLink>
            </li>
          );
        }

        return result;
      };

      GroupOrders = () => {
        const { paramId } = this.props;
        let result = null;

        if (this.props.userType === 'buyer') {
          result = (
            <li
              className="nav-item"
              onClick={(e) => {
                this.setState({ navOpen: !this.state.navOpen });
              }}
            >
              <NavLink className="nav-link navFont cursorDefault nav-icon-hover" to={'/group-orders?id=' + paramId}>
                <span className="navbarMenu ">
                  <img src={GroupOrdersIcon} style={{ width: '22px' }} alt="Group-order-icon" className="align-image" />
                  <span style={{ paddingLeft: '0.44em' }}>Group Orders</span>
                </span>
              </NavLink>
            </li>
          );
        }

        return result;
      };

      Dashborad = () => {
        const { paramId } = this.props;
        let result = null;

        // if (this.props.userType === 'buyer') {
        //   result = (
        //     <li
        //       className="nav-item"
        //       onClick={(e) => {
        //         this.setState({ navOpen: !this.state.navOpen });
        //       }}
        //     >
        //       <NavLink className="nav-link navFont cursorDefault nav-icon-hover" to={'/buyer-dashboard?id=' + paramId}>
        //         <span className="navbarMenu ">
        //           <img
        //             src={DashboardIcon}
        //             style={{ width: '20px', marginLeft: '4px' }}
        //             alt="dashboard-icon"
        //             className="align-image"
        //           />
        //           &nbsp; Dashboard
        //         </span>
        //       </NavLink>
        //     </li>
        //   );
        // }
        if (this.props.userType === 'seller') {
          result = (
            <li
              className="nav-item"
              onClick={(e) => {
                this.setState({ navOpen: !this.state.navOpen });
              }}
            >
              <NavLink className="nav-link navFont cursorDefault nav-icon-hover" to={'/seller-dashboard?id=' + paramId}>
                <span className="navbarMenu ">
                  <img
                    src={DashboardIcon}
                    style={{ width: '20px', marginLeft: '4px' }}
                    alt="dashboard-icon"
                    className="align-image"
                  />
                  &nbsp; Dashboard
                </span>
              </NavLink>
            </li>
          );
        }

        return result;
      };
      Profile = () => {
        const { paramId, userType, user } = this.props;
        return (
          <>
            {userType === 'buyer' && (
              <NavLink className="dropdown-item" to={'buyer?id=' + paramId}>
                Profile
              </NavLink>
            )}
            {userType === 'seller' && (
              <NavLink className="dropdown-item" to={'seller?id=' + paramId}>
                Profile
              </NavLink>
            )}
            {user && (
              <span
                style={{
                  color: '#12263f',
                  cursor: 'pointer',
                }}
                className="dropdown-item"
                onClick={async () => {
                  await firebase.auth().signOut();

                  window.location.href = '/login';
                }}
              >
                Logout
              </span>
            )}
          </>
        );
      };
      render() {
        const {
          hideNav,
          paramId,
          userType,
          notVerified,
          setShowNotVerifiedModal,
          setShowNotQualifiedModal,
          qualified,
        } = this.props;
        let navClassName = '';

        let profileClassName = '';
        let profileExpandClassName = '';
        if (this.state.profileDivOpen) {
          profileClassName = 'dropdown show';
          profileExpandClassName = 'dropdown-menu dropdown-menu-right show';
        } else {
          profileClassName = 'dropdown';
          profileExpandClassName = 'dropdown-menu dropdown-menu-right';
        }

        if (this.state.navOpen) {
          navClassName = 'collapse navbar-collapse show';
        } else {
          navClassName = 'collapse navbar-collapse ';
        }

        if (hideNav === true) {
          return null;
        }

        const { noNavPage } = this.state;
        if (noNavPage === '500' || noNavPage === 500 || noNavPage === '404' || noNavPage === 404) {
          return null;
        }

        return (
          <nav className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" id="sidebar">
            <div className="container-fluid">
              <button className="navbar-toggler" type="button" onClick={this.expandNav}>
                <span className="navbar-toggler-icon"></span>
              </button>
              {/* eslint-disable-next-line */}
              <a className="navbar-brand" href="">
                <Img src={Logo} className="navbar-brand-img mx-auto" style={{ width: '140px' }} alt="PN95-LVRG-LOGO" />
              </a>
              {userType === 'buyer' && (
                <button
                  type="button"
                  className="btn btn-primary text-uppercase d-none d-sm-none d-md-block mb-4 "
                  onClick={() => {
                    if (notVerified) {
                      setShowNotVerifiedModal(true);
                    } else if (!qualified) {
                      setShowNotQualifiedModal(true);
                    } else {
                      this.props.history.push('/create-rfq?id=' + paramId);
                    }
                  }}
                >
                  <strong>+ Request for Quote </strong>
                </button>
              )}

              <div className="navbar-user d-md-none">
                <div className={profileClassName} onClick={this.profileDivExpand}>
                  <div id="sidebarIcon" className="dropdown-toggle">
                    <div className="avatar avatar-xs">
                      <img src={avatar} className="avatar-img rounded-circle pointerClass" alt="..." />
                    </div>
                  </div>

                  <div className={profileExpandClassName} aria-labelledby="sidebarIcon">
                    {this.Profile()}
                  </div>
                </div>
              </div>

              <div className={navClassName} id="sidebarCollapse">
                {userType === 'buyer' && (
                  <button
                    type="button"
                    className="btn btn-primary mt-sm-4 mb-sm-0 text-uppercase d-sm-block d-md-none "
                    onClick={(e) => {
                      if (notVerified) {
                        setShowNotVerifiedModal(true);
                      } else if (!qualified) {
                        setShowNotQualifiedModal(true);
                      } else {
                        this.props.history.push('/create-rfq?id=' + paramId);
                      }
                    }}
                  >
                    <strong>+ Request for Quote </strong>
                  </button>
                )}
                <ul className="navbar-nav mt-3">
                  <>
                    {this.Dashborad()}
                    {this.Offers()} {this.RFQs()} {this.GroupOrders()}
                    {this.Products()}
                  </>
                </ul>

                {/* <!-- Push content down --> */}
                <div className="mt-auto"></div>

                {/* <!-- User (md) --> */}
                <div className="navbar-user d-none d-md-flex" id="sidebarUser">
                  {/* <!-- Dropup --> */}
                  <div className="dropup">
                    <div id="sidebarIconCopy" className="dropdown-toggle">
                      <div className="avatar avatar-xs">
                        <img src={avatar} className="avatar-img rounded-circle pointerClass" alt="..." />
                      </div>
                    </div>

                    <div className="dropdown-menu " aria-labelledby="sidebarIconCopy">
                      {this.Profile()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        );
      }
    },
  ),
);

import { state } from '../../store';
import { sortAscDesc } from '../../components-library/helper';

const rfqFilter = (text) => (item) => {
  if (Array.isArray(item['Product Type']) && item['Product Type'][0].toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['Product Specification'] && item['Product Specification'].toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['Status'] && item['Status'].toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['Quantity'] && item['Quantity'].toString().toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['Timing'] && item['Timing'].toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['Total Cost'] && item['Total Cost'].toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['Ask Price'] && item['Ask Price'].toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item.offerCount && item.offerCount.toString().indexOf(parseInt(text)) !== -1) {
    return item;
  }
};
const initialRfqsFilterFields = {
  active: { productName: '' },
  won: { productName: '' },
  noOffers: { productName: '' },
  expired: { productName: '' },
};
export const [rfqPageActions, rfqPageSelector, rfqPageState] = state('rfqPage', {
  initial: {
    rfqPageDetails: [],
    data: { active: [], won: [], noOffers: [], expired: [] },
    filteredRfq: { active: [], won: [], noOffers: [], expired: [] },
    loading: true,
    createRFQLoading: false,
    sortRfqType: {
      active: {
        productName: { ascending: true },
        totalCost: { ascending: true },
        status: { ascending: true },
        quantity: { ascending: true },
        askPrice: { ascending: true },
        productSpecification: { ascending: true },
        timing: { ascending: true },
        bids: { ascending: true },
      },
      won: {
        productName: { ascending: true },
        totalCost: { ascending: true },
        status: { ascending: true },
        quantity: { ascending: true },
        askPrice: { ascending: true },
        productSpecification: { ascending: true },
        timing: { ascending: true },
        bids: { ascending: true },
      },
      noOffers: {
        productName: { ascending: true },
        totalCost: { ascending: true },
        status: { ascending: true },
        quantity: { ascending: true },
        askPrice: { ascending: true },
        productSpecification: { ascending: true },
        timing: { ascending: true },
        bids: { ascending: true },
      },
      expired: {
        productName: { ascending: true },
        totalCost: { ascending: true },
        status: { ascending: true },
        quantity: { ascending: true },
        askPrice: { ascending: true },
        productSpecification: { ascending: true },
        timing: { ascending: true },
        bids: { ascending: true },
      },
    },
    rfqsFilterFields: { ...initialRfqsFilterFields },
    searchText: { active: '', won: '', noOffers: '', expired: '' },
    selectProduct: [],
  },
  on: {
    loadRfqDetails: (_, data) => {
      let newStoreObj = { ..._ };
      newStoreObj.rfqPageDetails = data; //buyer record id
      if (data.rfqs) {
        //sort based on status
        let active = data.rfqs.filter((item) => item['Status'] === 'Live RFQ' || item['Status'] === 'Live Offer');
        let pending = data.rfqs.filter((item) => item['Status'] === 'Live RFQ Pending');

        active = [...active, ...pending];

        let won = data.rfqs.filter((item) => item['Status'] === 'Purchased' || item['Status'] === 'Unpaid');

        let noOffers = data.rfqs.filter((item) => item['Status'] === 'No Offers RFQ');

        let expired = data.rfqs.filter((item) => item['Status'] === 'Expired');

        newStoreObj.data = { active, noOffers, won, expired };
        newStoreObj.filteredRfq = { active, noOffers, won, expired };
      }
      return newStoreObj;
    },
    setLoading: (_, data) => {
      let newStoreObj = { ..._, loading: data };
      return newStoreObj;
    },
    setCreateRFQLoading: (_, data) => {
      return { ..._, createRFQLoading: data };
    },
    searchRfqs: (_, data) => {
      const { event, value } = data;
      let newStoreObj = { ..._ };
      let searchText = { ...newStoreObj.searchText };

      searchText[value] = event.target.value.toLowerCase();

      return { ...newStoreObj, searchText: { ...searchText } };
    },
    sortRfq: (_, data) => {
      const { currentTab, type } = data;
      let newStoreObj = {
        ..._,
      };

      let allRfqs = [];
      if (type === 'productName') {
        newStoreObj.sortRfqType[currentTab].productName.ascending = !newStoreObj.sortRfqType[currentTab].productName
          .ascending;

        allRfqs = newStoreObj.filteredRfq[currentTab].sort((a, b) => {
          let aProduct = a['Product Type'][0].toLowerCase();
          let bProduct = b['Product Type'][0].toLowerCase();

          return sortAscDesc(aProduct, bProduct, newStoreObj.sortRfqType[currentTab].productName.ascending);
        });
      }
      //status
      if (type === 'status') {
        newStoreObj.sortRfqType[currentTab].status.ascending = !newStoreObj.sortRfqType[currentTab].status.ascending;

        allRfqs = newStoreObj.filteredRfq[currentTab].sort((a, b) => {
          let statusa = a.Status === 'Live RFQ' || a.Status === 'Live Offer' ? 'live' : a.Status;

          statusa = a.Status === 'Purchased' ? 'purchased' : statusa;
          statusa = a.Status === 'Expired' ? 'expired' : statusa;
          statusa = a.Status === 'No Offers RFQ' ? 'no offers' : statusa;
          let statusb = b.Status === 'Live RFQ' || b.Status === 'Live Offer' ? 'live' : b.Status;

          statusb = b.Status === 'Purchased' ? 'purchased' : statusb;
          statusb = b.Status === 'Expired' ? 'expired' : statusb;
          statusb = b.Status === 'No Offers RFQ' ? 'no offers' : statusb;

          return sortAscDesc(statusa, statusb, newStoreObj.sortRfqType[currentTab].status.ascending);
        });
      }

      //total-cost
      if (type === 'totalCost') {
        newStoreObj.sortRfqType[currentTab].totalCost.ascending = !newStoreObj.sortRfqType[currentTab].totalCost
          .ascending;
        allRfqs = newStoreObj.filteredRfq[currentTab].sort((a, b) => {
          let aCost = parseFloat(a['Total Cost'].split('$')[1]);
          let bCost = parseFloat(b['Total Cost'].split('$')[1]);
          return sortAscDesc(aCost, bCost, newStoreObj.sortRfqType[currentTab].totalCost.ascending);
        });
      }
      //quantity
      if (type === 'quantity') {
        newStoreObj.sortRfqType[currentTab].quantity.ascending = !newStoreObj.sortRfqType[currentTab].quantity
          .ascending;
        allRfqs = newStoreObj.filteredRfq[currentTab].sort((a, b) => {
          let aquantity = parseInt(a.Quantity);
          let bquantity = parseInt(b.Quantity);
          return sortAscDesc(aquantity, bquantity, newStoreObj.sortRfqType[currentTab].quantity.ascending);
        });
      }
      //ask price
      if (type === 'askPrice') {
        newStoreObj.sortRfqType[currentTab].askPrice.ascending = !newStoreObj.sortRfqType[currentTab].askPrice
          .ascending;
        allRfqs = newStoreObj.filteredRfq[currentTab].sort((a, b) => {
          let aAskPrice = parseFloat(a['Ask Price'].split('$')[1]);
          let bAskPrice = parseFloat(b['Ask Price'].split('$')[1]);
          return sortAscDesc(aAskPrice, bAskPrice, newStoreObj.sortRfqType[currentTab].askPrice.ascending);
        });
      }
      //product specification
      if (type === 'productSpecification') {
        newStoreObj.sortRfqType[currentTab].productSpecification.ascending = !newStoreObj.sortRfqType[currentTab]
          .productSpecification.ascending;

        allRfqs = newStoreObj.filteredRfq[currentTab].sort((a, b) => {
          let aProductSpec = a['Product Specification'].toLowerCase();
          let bProductSpec = b['Product Specification'].toLowerCase();
          return sortAscDesc(
            aProductSpec,
            bProductSpec,
            newStoreObj.sortRfqType[currentTab].productSpecification.ascending,
          );
        });
      }
      //timing days
      if (type === 'timing') {
        newStoreObj.sortRfqType[currentTab].timing.ascending = !newStoreObj.sortRfqType[currentTab].timing.ascending;

        allRfqs = newStoreObj.filteredRfq[currentTab].sort((a, b) => {
          let atiming = a['Timing'].toLowerCase();
          let btiming = b['Timing'].toLowerCase();
          return sortAscDesc(atiming, btiming, newStoreObj.sortRfqType[currentTab].timing.ascending);
        });
      }
      //bids Count
      if (type === 'bids') {
        newStoreObj.sortRfqType[currentTab].bids.ascending = !newStoreObj.sortRfqType[currentTab].bids.ascending;
        allRfqs = newStoreObj.filteredRfq[currentTab].sort((a, b) => {
          let aBids = a.offerCount ? parseInt(a.offerCount) : 0;
          let bBids = b.offerCount ? parseInt(b.offerCount) : 0;

          return sortAscDesc(aBids, bBids, newStoreObj.sortRfqType[currentTab].bids.ascending);
        });
      }

      let filteredRfq = { ...newStoreObj.filteredRfq };
      filteredRfq[currentTab] = [...allRfqs];
      return {
        ...newStoreObj,
        filteredRfq: { ...filteredRfq },
      };
    },
    filterChangeHandler: (_, result) => {
      const newStoreObj = {
        ..._,
      };
      const { fieldName, value, currentTab } = result;

      let rfqsFilterFields = { ...newStoreObj.rfqsFilterFields };
      rfqsFilterFields[currentTab][fieldName] = value;
      return {
        ..._,
        rfqsFilterFields: { ...rfqsFilterFields },
      };
    },
    selectProductHandler: (_, data) => {
      let newStoreObj = { ..._, selectProduct: data };
      return newStoreObj;
    },
    resetFilter: (_, currentTab) => {
      let newStoreObj = { ..._ };
      let originalData = newStoreObj.data;
      let filteredRfq = newStoreObj.filteredRfq;
      filteredRfq[currentTab] = originalData[currentTab];

      let rfqsFilterFields = { ...newStoreObj.rfqsFilterFields };
      rfqsFilterFields[currentTab] = { productName: '' };

      return {
        ...newStoreObj,
        filteredRfq: { ...filteredRfq },
        rfqsFilterFields: {
          ...rfqsFilterFields,
        },
      };
    },
    applyFilter: (_, currentTab) => {
      let newStoreObj = {
        ..._,
      };
      let searchText = newStoreObj.searchText[currentTab];
      let originalData = newStoreObj.data[currentTab];

      let currentFilters = newStoreObj.rfqsFilterFields[currentTab];

      let filteredValues = originalData;

      //search
      if (searchText.length) {
        filteredValues = originalData.filter(rfqFilter(searchText.trim().toLowerCase()));
      }

      //productType
      if (currentFilters.productName) {
        filteredValues = filteredValues.filter((item) => item['Product Type'][0] === currentFilters.productName);
      }
      let filteredRfq = { ...newStoreObj.filteredRfq };
      filteredRfq[currentTab] = [...filteredValues];
      return { ...newStoreObj, filteredRfq: { ...filteredRfq } };
    },
  },
  events: ['rfqPageData', 'buyerCreateRFQ'],
});

import React from 'react';
import { Carousel } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { rfqSelector, rfqActions } from './store';
import { state } from '../../store';
import { Countdown } from '../../components-library/countDown';
import RatingComponent from '../../components-library/ratingComponent';
import { RFQPagePopup } from './rfq-popup';
import { formatMoney, getFlagAndCountry } from '../../components-library/helper';
import { appSelector } from '../../app/store';
import ErrorPage from '../../components-library/error-page';

export const SellerSingleRFQPage = withRouter(
  connect(
    state.select({
      allData: rfqSelector((state) => state.data, []),
      loading: rfqSelector((state) => state.loading, null),
      price: rfqSelector((state) => state.price, ''),
      qty: rfqSelector((state) => state.qty, ''),
      shippingCost: rfqSelector((state) => state.shippingCost, ''),
      taxes: rfqSelector((state) => state.taxes, ''),
      priceClass: rfqSelector((state) => state.priceClass, []),
      qtyClass: rfqSelector((state) => state.qtyClass, []),
      paymentTerms: rfqSelector((state) => state.paymentTerms, ''),
      deliveryWithin: rfqSelector((state) => state.deliveryWithin, ''),
      iAgreeCheck: rfqSelector((state) => state.iAgreeCheck, false),
      error: appSelector((state) => state.error, ''),
      fieldError: rfqSelector((state) => state.fieldError, ''),
      modalShow: rfqSelector((state) => state.modalShow, null),
      additionalInformation: rfqSelector((state) => state.additionalInformation, ''),
      taxSpinner: rfqSelector((state) => state.taxSpinner, false),
      showTaxDisclaimer: rfqSelector((state) => state.showTaxDisclaimer, false),
    }),

    state.actions(rfqActions, [
      'loadRfqData',
      'handleInputChange',
      'iAgreeCheckChangeHandler',
      'setModalShow',
      'offerDraft',
      'loadOfferData',
      'offerUpdate',
      'getSubTotalTaxes',
    ]),
  )(
    class SellerSingleRFQPage extends React.PureComponent {
      constructor(props) {
        super(props);

        this.state = {
          buttonClicked: false,
        };
      }

      componentWillMount() {
        const { query, editMode } = this.props;
        let id = query.get('id');

        if (id) {
          if (editMode === true) {
            let rfqId = query.get('rfqId');
            this.props.loadOfferData({ id, rfqId });
          } else {
            let draft_offer = query.get('draft_offer');
            this.props.loadRfqData({ id, draft_offer });
          }
        } else {
          this.props.history.push('/404');
        }
      }

      validateNumber(e, type) {
        const value = e.target.value;
        this.props.handleInputChange({ fieldName: type, value: value });
      }

      calculateSubtotalTaxes = () => {
        const { allData, price, qty, fieldError } = this.props;

        if (
          price &&
          price.length &&
          fieldError.price.length === 0 &&
          qty &&
          qty.length &&
          fieldError.qty.length === 0
        ) {
          let subtotal = parseFloat(price) * parseFloat(qty);
          if (!isNaN(subtotal)) {
            let payload = {
              buyerId: allData['Buyer Record ID'],
              subtotal,
            };

            this.props.getSubTotalTaxes(payload);
          }
        }
      };

      submitOffer = (event) => {
        const {
          price,
          qty,
          shippingCost,
          taxes,
          iAgreeCheck,
          additionalInformation,
          // paymentTerms,
          deliveryWithin,
          allData,
          editMode,
        } = this.props;
        event.preventDefault();
        this.setState({ buttonClicked: true });

        if (editMode === true) {
          let payload = {
            id: allData['Offers'],
            price: price,
            quantity: qty,
            taxes: allData['Tax Exempt?'] === true ? '0' : taxes,
            shipping: shippingCost.length ? shippingCost : 0,
            delivery: deliveryWithin,
            additionalInfo: additionalInformation,
            history: this.props.history,
          };
          this.props.offerUpdate({ ...payload });
        }
        if (editMode === false) {
          let payload = {
            TOS: iAgreeCheck,
            additionalInfo: additionalInformation,
            delivery: deliveryWithin,
            hotlineId: allData['Hotline ID'],
            paymentTerms: 'Payment in Advance (100%)', //paymentTerms,
            price: price,
            productId: allData['Product ID'],
            productType: allData['Product Type'],
            quantity: qty,
            requestId: allData['ID'],
            rfqId: allData['Record ID'],
            shipping: shippingCost.length ? shippingCost : 0,
            supplierRecordID: allData['Supplier Record ID'],
            taxes: allData['Tax Exempt?'] === true ? '0' : taxes,
            history: this.props.history,
          };
          this.props.offerDraft({ ...payload });
        }
      };

      breadcrumb = () => {
        let detailsUrl = `rfq-details?id=${this.props.allData['Supplier Record ID']}&hotlineId=${this.props.allData['Hotline Record ID']}&status=${this.props.allData['RFQ Status']}`;
        if (this.props.editMode === true) {
          detailsUrl = `offer-details?id=${this.props.allData['Supplier Record ID']}&rfqId=${this.props.allData['Record ID']}`;
        }
        return (
          <div className="header pl-4">
            <div className="pt-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href={`/seller-offers?id=${this.props.allData['Supplier Record ID']}`}>Request For RFQ</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href={detailsUrl}>Details</a>
                  </li>
                  <li className="breadcrumb-item active">
                    {this.props.editMode === true ? 'Edit Offer' : 'Create Offer'}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        );
      };
      render() {
        const {
          allData,
          loading,
          priceClass,
          qtyClass,
          price = 0,
          qty = 0,
          shippingCost = 0,
          taxes = 0,
          deliveryWithin = 0,
          additionalInformation,
          iAgreeCheck,
          error,
          modalShow,
          editMode,
          showTaxDisclaimer,
          taxSpinner,
        } = this.props;

        let data = allData ? allData : [];

        let QuantityFormatter = null;
        if (data['Quantity']) {
          QuantityFormatter = data['Quantity'].toLocaleString();
        }

        let getTimeline = null;
        if (data['Timeline'] && data['Timeline'].length) {
          let timeline = data['Timeline'];

          if (parseInt(timeline) > 0) {
            getTimeline = `${timeline} days`;
          } else {
            getTimeline = 'ASAP';
          }
        }
        let flagDetails = null;
        if (data['Country of Manufacture']) {
          flagDetails = getFlagAndCountry(data['Country of Manufacture']);
        }

        let supplierMOQFormatted = null;
        if (data['Supplier MOQ']) {
          supplierMOQFormatted = formatMoney(data['Supplier MOQ'], 0, '.');
        }

        let minQuoteFromOffers = null;
        if (editMode === false) {
          if (data['Min Quote from Offers']) {
            minQuoteFromOffers = formatMoney(data['Min Quote from Offers'], 2, '.', ',');
          }
        } else {
          minQuoteFromOffers = data['Min Quote from Offers'] ? data['Min Quote from Offers'].replace('$', '') : '';
        }

        const keys = Object.keys(data);

        keys.forEach((e) => {
          if (Array.isArray(data[e]) && data[e].length === 1) {
            if (e !== 'Supplier Product Pictures') {
              let v = data[e][0];
              data[e] = v;
            }
          }
        });

        let incoterms = allData['Supplier Incoterms'] ? allData['Supplier Incoterms'] : [];
        if (Array.isArray(allData['Supplier Incoterms'])) {
          incoterms = allData['Supplier Incoterms'].join(',');
        } else {
          incoterms = allData['Supplier Incoterms'];
        }

        let offerTotal = 0;
        if (parseFloat(price) > 0 && parseFloat(qty) > 0) {
          offerTotal = parseFloat(price) * parseFloat(qty);

          if (parseFloat(shippingCost) >= 0) {
            offerTotal = offerTotal + parseFloat(shippingCost);
          }
          if (parseFloat(taxes) >= 0) {
            offerTotal = offerTotal + parseFloat(taxes);
          }
        }

        //api loading
        if (loading) {
          return (
            <section className="container page-content pb-6 market-content text-center">
              {this.breadcrumb()}

              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </section>
          );
        }
        //api fieldError
        if (error.length) {
          return <ErrorPage error={error} />;
        }
        let rating = data['Product Ratings CSS'] / 10;

        return (
          <section className="container">
            {this.breadcrumb()}
            <div className="row mb-5">
              <div className="col-md-6 container-fluid">
                {/* <h1 className="mb-2 pull-webfont-left">Request for Quote</h1> */}
                <p>
                  A buyer on the Marketplace is need of the following product and able to purchase. Please submit an
                  offer if you're able to meet their requirements.
                </p>
                <p>
                  <strong>Organization Type:</strong>
                  <br />- {data['Org Type'] ? data['Org Type'] : null}
                  <br />
                </p>
                <h5 className="mb-4">
                  <mark>OPPORTUNITY: {data['Revenue Opportunity']}</mark>
                </h5>

                <p className="mb-5">
                  <strong>Product Type:</strong> {data['Product Type']}
                  <br />
                  <strong>Max Price:</strong> {data['Max Price']}
                  <br />
                  <strong>Quantity (each/ounce/wipe):</strong> {QuantityFormatter}
                  <br />
                  <strong>Needed by:</strong> {getTimeline}
                  <br />
                  <strong>Ship-to Zipcode:</strong> {data['Zipcode']}
                  {/* <br />
                  <strong>Payment Terms:</strong> {data["Payment Terms"]} */}
                  <br />
                  <strong>Product Specfiications:</strong> {data['Product Spec']}
                  <br />
                  <strong>Additional Notes:</strong> {data['Notes']}
                  <br />
                </p>
                <p className="mb-5">
                  The Marketplace team will review your offer and notify the buyer once approved. The buyer will have{' '}
                  <strong>48 hours</strong> to respond and select an offer.
                </p>

                <div className="card">
                  <div className="card-header">
                    <h3 className="mt-3 mb-2">{data['Supplier Name']} Profile</h3>
                  </div>
                  <div className="card-body">
                    <p className="mt-3">
                      <strong>Email:</strong> {data['Supplier Email']}
                      <br />
                      <strong>Phone:</strong> {data['Supplier Phone']}
                      <br />
                      <strong>Address:</strong> {data['Supplier Address 1']}
                      {data['Supplier Address 2'] ? ` ${data['Supplier Address 2']}` : null}, {data['Supplier City']},{' '}
                      {data['Supplier State']}, {data['Supplier Country']}
                      <br />
                      <strong>Incoterms:</strong> {incoterms}
                      <br />
                      <br />
                      <strong>Dispute Resolution:</strong> You agree, upon Buyer request, to refund Buyer payments in
                      full (less shipping) in case of product defect, failed delivery or delay of more than 5 business
                      days over original delivery estimate. No refunds will be required if request is made more than 7
                      business days following Buyer receipt of the goods, or for open or used products.
                      <br />
                    </p>
                  </div>
                </div>

                <p></p>
                <h4 className="mt-5 mb-2">Vetted Product Information</h4>
                <p>This card is what will be displayed to the buyer.</p>
                <div className="card mb-3">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-12">
                        <h3 className="mt-0 mb-0">
                          {data['Product Type']}
                          {flagDetails && (
                            <span data-toggle="tooltip" className="pl-1">
                              <img
                                src={flagDetails.flagSrc}
                                alt={flagDetails.flagAlt}
                                data-toggle="tooltip"
                                title={flagDetails.flagTitle}
                                className="flag-image"
                              ></img>
                            </span>
                          )}
                        </h3>
                        {data['showRating'] ? (
                          <div className="d-flex flex-row " style={{ height: '20px' }}>
                            <RatingComponent rating={rating} />
                            <span
                              className="market-stars"
                              style={{
                                padding: 'unset',
                                paddingLeft: '10px',
                                paddingTop: '3px',
                              }}
                            >
                              {data['Product Ratings Count']}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <Carousel className="w-100" key={Math.random()} indicators={false}>
                          {data['Supplier Product Pictures'] && Array.isArray(data['Supplier Product Pictures'])
                            ? data['Supplier Product Pictures'].map((item) => (
                                <Carousel.Item key={item.id}>
                                  <img
                                    key={item}
                                    alt={item.filename}
                                    src={item.thumbnails.large.url}
                                    className="d-inline-block w-100 h-50"
                                    onClick={(e) => window.open(item.url)}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </Carousel.Item>
                              ))
                            : null}
                        </Carousel>
                      </div>
                      <div className="col-md-8">
                        {data.Badges &&
                          Array.isArray(data.Badges) &&
                          data.Badges.map((badge, idx) => (
                            <div
                              key={idx}
                              className={`supply-label ${badge.name} d-inline`}
                              data-toggle="tooltip"
                              title={`${badge.title}`}
                            ></div>
                          ))}
                        <p className="mt-1">
                          {data['Manufacturer']} {data['Product Model']}
                        </p>
                        <strong>Price:</strong> ${data['Supplier Price']} per unit
                        <br />
                        <strong>MOQ (each/ounce):</strong> {supplierMOQFormatted}
                        {/* <br />
                        <strong>Payment Terms:</strong>{" "}
                        {data["Supplier Payment Terms"]
                          ? data["Supplier Payment Terms"]
                          : null} */}
                        <br />
                        <strong>Additional Information:</strong>{' '}
                        {data['Additional Information'] ? data['Additional Information'] : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body pt-5">
                    {editMode === false && (
                      <h3>
                        Quote expires in:{' '}
                        <span className="d-md-none">
                          <br />
                        </span>
                        <strong>
                          <mark>
                            {data['Start Time'] && (
                              <Countdown
                                start_time={data['Start Time']}
                                interval={48}
                                callback={(e) => {
                                  window.location.reload();
                                }}
                              />
                            )}{' '}
                          </mark>
                        </strong>
                      </h3>
                    )}
                    <form onSubmit={this.submitOffer}>
                      <h3 className="mb-3">{editMode === true ? 'Update Your Offer' : 'Submit Your Offer'}</h3>

                      <div className="form-group">
                        <label>
                          <strong>
                            Price per Unit (in USD)
                            <span className="form-asterik">*</span>
                          </strong>
                          <br />
                          Best Quote / Market Price: ${minQuoteFromOffers}
                        </label>
                        <input
                          type="number"
                          step=".01"
                          defaultValue={price}
                          onChange={(e) => {
                            this.validateNumber(e, 'price');
                          }}
                          className={priceClass.join(' ')}
                          placeholder={minQuoteFromOffers}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          <strong>
                            Quantity (each/ounce/wipe)
                            <span className="form-asterik">*</span>
                          </strong>
                        </label>
                        <input
                          type="number"
                          defaultValue={qty}
                          className={qtyClass.join(' ')}
                          onChange={(e) => {
                            this.validateNumber(e, 'qty');
                          }}
                          id="quantity"
                          placeholder={data['Quantity']}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          <strong>
                            Shipping Costs (in USD)
                            <span className="form-asterik">*</span>
                          </strong>
                        </label>
                        <input
                          type="number"
                          step=".01"
                          min="0"
                          defaultValue={shippingCost}
                          className="form-control"
                          onChange={(e) => {
                            this.validateNumber(e, 'shippingCost');
                          }}
                          id="shipping"
                          placeholder="5.00"
                          required
                        />
                      </div>
                      {allData['Tax Exempt?'] === null ? (
                        <>
                          <div className="form-group">
                            <label>
                              <strong>
                                Taxes (in USD)
                                <span className="form-asterik">*</span>
                              </strong>
                            </label>
                            <div className="d-flex align-items-baseline justify-content-space-between">
                              <input
                                type="number"
                                step=".01"
                                min="0"
                                value={taxes}
                                className="form-control w-75"
                                onChange={(e) => {
                                  this.validateNumber(e, 'taxes');
                                }}
                                id="taxes"
                                placeholder="5.00"
                                required
                              />
                              <span
                                className="ml-3"
                                style={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  this.calculateSubtotalTaxes();
                                }}
                              >
                                calculate{' '}
                              </span>
                              &nbsp;
                              {taxSpinner && (
                                <span
                                  className="spinner-border spinner-border-sm align-self-center"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}{' '}
                            </div>
                          </div>

                          {showTaxDisclaimer && (
                            <div className="form-group">
                              <label>
                                <strong>Tax Disclaimer:</strong> The tax calculator uses destination taxes and based the
                                buyer’s zip code and your subtotal. You are responsible for charging and reporting
                                appropriate taxes.
                              </label>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="form-group">
                          <label>
                            <strong>Taxes: </strong> TAX EXEMPT
                          </label>
                        </div>
                      )}
                      <h4 className="mt-4 mb-4 total-cost">
                        Total:
                        <mark>
                          <span>{offerTotal > 0 ? `$${formatMoney(offerTotal, 2, '.')}` : null}</span>
                        </mark>
                      </h4>
                      <hr />

                      <div className="form-group">
                        <label>
                          <strong>
                            Delivery within (in days)
                            <span className="form-asterik">*</span>
                          </strong>
                        </label>
                        <input
                          type="number"
                          min="0"
                          className="form-control"
                          defaultValue={deliveryWithin}
                          id="delivery"
                          max="365"
                          placeholder="0"
                          onChange={(e) => {
                            this.validateNumber(e, 'deliveryWithin');
                          }}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          <strong>Additional Seller Information:</strong>
                        </label>
                        <textarea
                          className="form-control"
                          id="additionalInfo"
                          rows="3"
                          defaultValue={additionalInformation}
                          onChange={(e) => {
                            let payload = {
                              value: e.target.value,
                              fieldName: 'additionalInformation',
                            };
                            this.props.handleInputChange(payload);
                          }}
                        ></textarea>
                        <p>
                          Do not include email, phone, or other contact (e.g. website) information. If you have
                          additional documentation or images, please upload it to your profile. All contact information
                          will be removed.
                        </p>
                      </div>

                      <div className="form-group">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="termsAndConditions"
                            checked={editMode === true ? true : iAgreeCheck}
                            onChange={(e) => this.props.iAgreeCheckChangeHandler()}
                            required
                          />
                          <label className="form-check-label">
                            I agree to the{' '}
                            <a href="https://www.projectn95.org/market-terms">Marketplace terms and conditions</a> to
                            include a <strong>5% platform fee</strong>.
                          </label>
                        </div>
                      </div>

                      {this.state.buttonClicked === false ? (
                        <button type="submit" id="offerSubmitBtn" className="btn btn-primary w-100">
                          {editMode === false ? 'Submit Offer' : 'Update Offer'}
                        </button>
                      ) : (
                        <button className="btn btn-primary w-100" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{' '}
                          Processing...
                        </button>
                      )}
                    </form>
                    <p className="mt-4 product-needs">
                      <strong>NOTICE:</strong> To protect your purchase from fraud and theft, payment is collected
                      upfront. We directly coordinate any dispute resolutions and refunds on your behalf.
                    </p>
                    <p className="mt-4 product-needs">
                      <strong>Have a question about this RFQ or need assistance?</strong> Email us at{' '}
                      <a href="mailto:marketplace@projectn95.org" target="_blank" rel="noopener noreferrer">
                        marketplace@projectn95.org
                      </a>
                      .
                    </p>
                    {/* <a href={returnTosellerUrl}>
                        Return to your Seller Profile
                      </a> */}
                    <p className="mt-4">
                      <strong>WARNING: DO NOT</strong> share this website page/url/link with anyone outside YOUR
                      company. This page contains personal identifiable information (PII) about YOU, YOUR company, YOUR
                      product(s), and YOUR competitive offer. The links on this page provide direct access to edit your
                      information represented to the public.
                    </p>
                  </div>
                </div>
                {data['draftOfferData'] ? (
                  <RFQPagePopup
                    show={modalShow}
                    onHide={() => this.props.setModalShow(false)}
                    data={data['draftOfferData']}
                  />
                ) : null}
              </div>
            </div>
          </section>
        );
      }
    },
  ),
);

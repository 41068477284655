import React from 'react';

import { Modal } from 'react-bootstrap';
require('react-bootstrap/ModalHeader');

export class ManualShippingModal extends React.PureComponent {
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={(e) => {
          this.props.onHide();
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton className="card-header">
          <Modal.Title id="contained-modal-title-vcenter">
            <h3 className="card-header-title pt-3">Information</h3>
          </Modal.Title>
        </Modal.Header>
        <div className="modal-body mx-3 market-content" id="stateBody">
          <p className="mt-3 mb-3 product-needs">
            <strong>Shipments to Puerto Rico require manual calculation of shipping.</strong>
            <br />
            Please contact us at{' '}
            <a href="mailto:grouporder@projectn95.org" target="_blank" rel="noopener noreferrer">
              GroupOrders@projectn95.org
            </a>{' '}
            or <br /> call us at <a href="te:202-849-9858">202-849-9858</a> for assistance.
          </p>
        </div>
      </Modal>
    );
  }
}

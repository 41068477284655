import React from 'react';
import styled, { css } from 'styled-components';
import { state } from '../store';
import { connect } from 'react-redux';
export const [fileInputActions, fileInputSelector] = state('FileInput', {
  initial: {
    selected: null,
    error: '',
  },
  on: {
    setSelected: (currentState, file) => ({
      ...currentState,
      selected: file,
    }),
    setErrorMsg: (currentState, err) => {
      return {
        ...currentState,
        error: err,
      };
    },
    clearFiles: (_, result) => {
      return { ..._, selected: null, error: '' };
    },
  },
});

const InputWrapper = styled.div`
  ${(props) =>
    css`
      display: ${props.display || `flex`};
      flex-direction: ${props.flexDirection || `row`};
      background-color: ${props.background || 'transparant'};
    `}
`;

const Input = styled.input`
  display: none !important;
`;

const InputLabel = styled.label`
  display: inline-block;
  padding: 6px 12px;
  font-weight: 500;
  color: white !important;
  background-color: #2c7be5;
  border-color: #2c7be5;
  align-self: center;
  cursor: pointer;
  border-radius: 5px;
`;

export const FileInput = connect(
  state.select({
    selected: fileInputSelector((state) => state.selected, null),
    error: fileInputSelector((state) => state.error, ''),
  }),
  state.actions(fileInputActions, ['setSelected', 'setErrorMsg', 'clearFiles']),
)(
  class FileInput extends React.PureComponent {
    validateFile(fileList) {
      const errors = {
        none: '',
        fileSize: `File Size must be below ${this.props.MaxLength} MB.`,
        invalidFileType: 'File Type is not valid',
      };

      let error = errors.none;
      this.props.setErrorMsg(error);

      let selected = [];
      if (fileList.length !== 0) {
        for (let i = 0; i < fileList.length; i++) {
          let file = fileList[i];
          if (file.size > this.props.MaxLength * 1024 * 1024) {
            error = `${file.name} ${errors.fileSize}`;
            if (this.props.showNotification === true) {
              let payload = {};
              payload[Math.random()] = error;
              //this.props.setError(payload);
            }
            continue;
          }

          selected.push(file);
        }
      }

      if (error !== errors.none) {
        this.props.setErrorMsg(error);
      } else {
        this.props.setSelected(selected);
      }
      this.props.uploadOnChangeHandler({ selected, error });
    }

    componentWillUnmount = () => {
      //remove files from memory so they arent hanging around
      this.props.clearFiles();
    };

    render() {
      const { label, multiple, required, name } = this.props;

      return (
        <InputWrapper>
          <InputLabel>
            <Input
              type="file"
              name={name}
              required={required}
              multiple={multiple}
              onChange={(e) => {
                this.validateFile(e.target.files);
                // without the following line we are not able to
                // upload the same file more than once in a render
                // since its in memory there is technically no change
                e.target.value = '';
              }}
            />
            {label}
          </InputLabel>
        </InputWrapper>
      );
    }
  },
);

import { putResolve, take, call, spawn } from 'redux-saga/effects';
import { sellerRfqDetailsPageActions } from './store';
import { api } from '../../app/api';
import { appActions } from '../../app/store';

export function* sellerRfqDetailsPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(sellerRfqDetailsPageActions.loadRfqDetailsData().type);
      yield putResolve(sellerRfqDetailsPageActions.loadData([]));
      yield putResolve(sellerRfqDetailsPageActions.setLoading(true));
      yield putResolve(appActions.setError(''));
      yield putResolve(appActions.showNav());

      let apiData = yield call(
        api,
        `/.netlify/functions/seller-rfq-details?id=${data.id}&hotlineId=${data.hotlineId}&status=${data.status}`,
      );
      yield putResolve(sellerRfqDetailsPageActions.setLoading(false));

      if (apiData.error) {
        yield putResolve(appActions.hideNav());
        yield putResolve(appActions.setError(apiData.error));
        return;
      }

      if (apiData.data && apiData.error === null) {
        apiData.data['NoOfOffers'] = parseInt(parseInt(apiData.data['NoOfOffers']) * 2.5);
        yield putResolve(sellerRfqDetailsPageActions.loadData(apiData.data));
      }
    } catch (err) {
      console.log('error in SellerPageSaga', err);
      yield putResolve(appActions.hideNav());
      yield putResolve(sellerRfqDetailsPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}
function* viewRfqSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(sellerRfqDetailsPageActions.viewRfq().type);
      const result = yield call(api, `/.netlify/functions/rfq-submission`, 'POST', {
        productRecordId: data.productRecordId,
        hotlineId: data.hotlineId,
        sellerId: data.sellerId,
        hotlineRecordId: data.hotlineRecordId,
      });
      if (result && result.data && result.data.type === 'redirect') {
        data.history.push(result.data.value);
      }
    } catch (error) {
      console.log('error in SellerPage viewRfqSaga', error);
      yield putResolve(appActions.hideNav());
      yield putResolve(sellerRfqDetailsPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}
export default function* sellerRfqDetailsRootSaga() {
  yield spawn(sellerRfqDetailsPageSaga);
  yield spawn(viewRfqSaga);
}

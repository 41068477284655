import React from 'react';

import { Redirect } from 'react-router-dom';
import firebase from 'firebase';
import { useSelector } from 'react-redux';

import { appSelector } from '../../app/store';
import { AuthPage, AuthBody } from '../auth-components';
import useCheckEmailVerification from './use-check-email-verification';

const LoginPage = () => {
  const user = useSelector(appSelector((state) => state.user));
  const emailVerified = useCheckEmailVerification(user);

  if (emailVerified) {
    return <Redirect to="/" />;
  }

  return (
    <AuthPage maxWidth={800}>
      <AuthBody>
        {/* eslint-disable-next-line */}
        <p>A verification email has been sent to <strong>{user.email}</strong>. If you don't see it in your inbox, make sure you entered the correct address. If it is incorrect, please return to the login page and create a new account with the correct address. Otherwise, check your spam or junk folder. If you still don't see an email within 10 minutes, <a href="#" onClick={() => {
              user.sendEmailVerification({
                url: `${window.location.origin}/login`,
              });
            }}
          >
            click here
          </a>{' '}
          to send a new one.
        </p>
        {/* eslint-disable-next-line */}
        <p>
          <a
            href="#"
            onClick={async () => {
              await firebase.auth().signOut();

              window.location.href = '/login';
            }}
          >
            Return to login page
          </a>
        </p>
      </AuthBody>
    </AuthPage>
  );
};

export default LoginPage;

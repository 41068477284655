import React from 'react';
import { connect } from 'react-redux';
import { state } from '../../store';
import { stripeConnectOauthPageSelector, stripeConnectOauthPageActions } from './store';
import ErrorPage from '../../components-library/error-page';
import { appSelector } from '../../app/store';
import { withRouter } from 'react-router-dom';

export const StripeConnectOauthPage = withRouter(
  connect(
    state.select({
      data: stripeConnectOauthPageSelector((state) => state.data, []),
      loading: stripeConnectOauthPageSelector((state) => state.loading),
      error: appSelector((state) => state.error, ''),
    }),
    state.actions(stripeConnectOauthPageActions, ['stripeConnectOauthPageData']),
  )(
    class StripeConnectOauthPage extends React.PureComponent {
      componentWillMount() {
        const { query } = this.props;
        let id = query.get('id');
        if (id) {
          this.props.stripeConnectOauthPageData(id);
        } else {
          this.props.history.push('/404');
        }
      }

      render() {
        const { error } = this.props;

        if (error) {
          return <ErrorPage error={error} />;
        }
        return (
          <div className="main-content">
            <section className="container page-content pb-6 market-content text-center mt-4">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </section>
          </div>
        );
      }
    },
  ),
);

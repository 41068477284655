import { putResolve, call, spawn, take, select } from 'redux-saga/effects';
import { dealPageActions, dealPageSelector } from './store';
import { api } from '../../app/api';
import { appActions } from '../../app/store';

function* dealPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(dealPageActions.dealPageData().type);
      let apiData = yield call(api, `/.netlify/functions/deal?id=${data.id}&transact_id=${data.transact_id}`);
      yield putResolve(dealPageActions.setLoading(false));
      if (apiData.error) {
        yield putResolve(appActions.setError(apiData.error));
        yield putResolve(appActions.hideNav());
        return;
      }

      if (apiData.data && apiData.error === null) {
        //product rating check
        let ratingCSS = apiData.data['Product Ratings CSS'];
        let ratingCount = apiData.data['Product Ratings Count'];

        let showRating = true;
        if (
          !(
            Array.isArray(ratingCount) &&
            ratingCount.length &&
            ratingCount.some(function (it) {
              return it !== 0;
            })
          )
        ) {
          showRating = false;
        }
        if (
          !(
            Array.isArray(ratingCSS) &&
            ratingCSS.length &&
            ratingCSS.some(function (it) {
              return it !== 0;
            })
          )
        ) {
          showRating = false;
        }

        apiData.data.showRating = showRating;
        yield putResolve(appActions.setId(apiData.data['Buyer Record ID']));
        yield putResolve(dealPageActions.loadBuyerDetails(apiData.data));
      }
    } catch (err) {
      console.log('error dealPageActions', err);
      yield putResolve(appActions.hideNav());
      yield putResolve(dealPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export function* submitInquirySaga() {
  try {
    const { payload: data } = yield take(dealPageActions.submitInquiry().type);

    const result = yield call(api, `.netlify/functions/inquiry`, 'POST', data);

    if (result && result.data && result.error === null) {
      yield putResolve(dealPageActions.setShowModal(false));
    } else {
      yield putResolve(dealPageActions.setShowModal(false));
      yield putResolve(appActions.setError(result.error));
    }
  } catch (err) {
    console.log(err);
    yield putResolve(dealPageActions.setLoading(false));
    yield putResolve(appActions.setError('Something went wrong'));
  }
}

export function* purchaseProductSaga() {
  try {
    const { payload: data } = yield take(dealPageActions.purchaseProduct().type);

    const result = yield call(api, `/.netlify/functions/invoice-buyer`, 'POST', data);

    if (result && result.data && result.data.type === 'redirect' && result.error === null) {
      window.location = result.data.value;
    } else {
      yield putResolve(appActions.setError(result.error));
    }
  } catch (err) {
    console.log(err);
    yield putResolve(dealPageActions.setLoading(false));
    yield putResolve(appActions.setError('Something went wrong'));
  }
}

export function* purchaseProductCCSaga() {
  try {
    const { payload: data } = yield take(dealPageActions.purchaseProductCC().type);
    let stripe = yield select(dealPageSelector((state) => state.stripe));

    const result = yield call(api, `/.netlify/functions/stripe-buyer`, 'POST', data);

    if (result && result.data && result.data.type === 'redirect' && result.error === null) {
      window.location = result.data.value;
    } else if (result && result.data && result.data.type === 'session' && result.error === null) {
      stripe
        .redirectToCheckout({
          sessionId: result.data.value.id,
        })
        .then(function (result) {
          console.log('Something here', result.error.message);
        });
    } else {
      yield putResolve(appActions.setError(result.error));
    }
  } catch (err) {
    console.log(err);
    yield putResolve(dealPageActions.setLoading(false));
    yield putResolve(appActions.setError('Something went wrong'));
  }
}

export function* purchaseProductACHDebitSaga() {
  try {
    const { payload: data } = yield take(dealPageActions.purchaseProductACHDebit().type);

    const result = yield call(api, `/.netlify/functions/ach-buyer`, 'POST', data);

    if (result && result.data && result.data.type === 'redirect' && result.error === null) {
      window.location = result.data.value;
    } else {
      yield putResolve(appActions.setError(result.error));
    }
  } catch (err) {
    console.log(err);
    yield putResolve(dealPageActions.setLoading(false));
    yield putResolve(appActions.setError('Something went wrong'));
  }
}
export default function* dealPageRootSaga() {
  yield spawn(dealPageSaga);
  yield spawn(submitInquirySaga);
  yield spawn(purchaseProductSaga);
  yield spawn(purchaseProductCCSaga);
  yield spawn(purchaseProductACHDebitSaga);
}

import React from 'react';

import Container from 'react-bootstrap/Container';

const NotFound = () => {
  return (
    <Container fluid>
      <div className="row justify-content-center pt-5">
        <div className="col-12 col-md-5 col-xl-4 my-5 pt-5">
          <div className="text-center pt-5">
            <h6 className="text-uppercase text-muted mb-4">404 error</h6>
            <h1 className="display-4 mb-3">
              Page not found{' '}
              <span role="img" aria-label="img">
                😭
              </span>
            </h1>
            <p className="text-muted mb-4">The requested page could not be found.</p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default NotFound;

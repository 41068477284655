import React, { Component } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';

import { connect } from 'react-redux';
import { sellerOffersPageActions, sellerOffersPageSelector } from './store';
import { state } from '../../store';
import { appSelector } from '../../app/store';
import ErrorPage from '../../components-library/error-page';
import { sellerProfileSelector, sellerProfileActions } from '../seller-profile/store';

export const CommanTab = withRouter(
  connect(
    state.select({
      filteredOffer: sellerOffersPageSelector((state) => state.filteredOffer, {
        won: [],
        quoted: [],
        lost: [],
      }),
      loading: sellerOffersPageSelector((state) => state.loading),
      error: appSelector((state) => state.error, ''),
      offersFilterFields: sellerOffersPageSelector((state) => state.offersFilterFields),
      uniqueProducts: sellerOffersPageSelector((state) => state.uniqueProducts),
    }),
    state.actions(sellerOffersPageActions, [
      'sortOffers',
      'searchoffers',
      'filterChangeHandler',
      'applyFilter',
      'resetFilter',
    ]),
  )(
    class CommanTab extends Component {
      state = {
        filterOpen: false,
      };
      searchHandler = (event) => {
        this.props.searchoffers({ event, value: this.props.currentTab });
        this.props.applyFilter(this.props.currentTab);
      };
      resetHandler = (_e) => {
        this.setState({ filterOpen: false });
        this.props.resetFilter(this.props.currentTab);
        this.props.applyFilter(this.props.currentTab);
      };
      render() {
        const { filteredOffer, history, currentTab = 'won', loading } = this.props;

        let filterDDClassName = '';
        let filterDivClassName = '';
        if (this.state.filterOpen) {
          filterDDClassName = 'dropdown show';
          filterDivClassName = 'dropdown-menu dropdown-menu-right dropdown-menu-card show dropdownOpen';
        } else {
          filterDDClassName = 'dropdown';
          filterDivClassName = 'dropdown-menu dropdown-menu-right dropdown-menu-card ';
        }

        if (loading) {
          return (
            <section className="container page-content pt-6 market-content text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </section>
          );
        }
        return (
          <>
            <div className="card" id="contactsList">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col">
                    {/* <!-- Form --> */}
                    <form>
                      <div className="input-group input-group-flush">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fe fe-search"></i>
                          </span>
                        </div>
                        <input
                          className="list-search form-control"
                          type="search"
                          placeholder="Search"
                          onChange={this.searchHandler}
                        />
                      </div>
                    </form>
                  </div>
                  <div className="col-auto mr-n3"></div>

                  <OutsideClickHandler
                    onOutsideClick={(_e) => {
                      this.setState({
                        filterOpen: false,
                      });
                    }}
                  >
                    <div className="col-auto">
                      {/* <!-- Dropdown --> */}
                      <div className={filterDDClassName}>
                        {/* <!-- Toggle --> */}
                        <button
                          className="btn btn-sm btn-white"
                          type="button"
                          onClick={(_e) => {
                            this.setState({
                              filterOpen: !this.state.filterOpen,
                            });
                          }}
                        >
                          <i className="fe fe-sliders mr-1"></i> Filter{' '}
                          <span className="badge badge-primary ml-1 d-none">0</span>
                        </button>

                        {/* <!-- Menu --> */}
                        <div className={filterDivClassName}>
                          <div className="card-header">
                            {/* <!-- Title --> */}
                            <h4 className="card-header-title">Filters</h4>

                            {/* <!-- Link --> */}
                            <button
                              className="btn btn-sm btn-link text-reset"
                              onClick={(_e) => {
                                this.resetHandler();
                              }}
                            >
                              Clear filters
                            </button>
                          </div>
                          <div className="card-body">
                            {/* <!-- List group --> */}
                            <div className="list-group list-group-flush mt-n4 mb-4">
                              <div className="list-group-item">
                                <div className="row">
                                  <div className="col">
                                    {/* <!-- Text --> */}
                                    <small>Product</small>
                                  </div>
                                  <div className="col-6">
                                    {/* <!-- Select --> */}
                                    <select
                                      className="custom-select custom-select-sm"
                                      onChange={(e) => {
                                        let payload = {
                                          fieldName: 'product',
                                          value: e.target.value,
                                          currentTab: currentTab,
                                        };
                                        this.props.filterChangeHandler(payload);
                                      }}
                                      value={
                                        this.props.offersFilterFields[currentTab].product === ''
                                          ? '*'
                                          : this.props.offersFilterFields[currentTab].product
                                      }
                                    >
                                      {this.props.offersFilterFields[currentTab].product === '' && (
                                        <option value="*" disabled>
                                          Select
                                        </option>
                                      )}
                                      {Array.isArray(this.props.uniqueProducts) &&
                                        this.props.uniqueProducts.map((item, idx) => {
                                          return (
                                            <option key={idx} value={item}>
                                              {item}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                                {/* <!-- / .row --> */}
                              </div>
                            </div>

                            {/* <!-- Button --> */}
                            <button
                              className="btn btn-block btn-primary"
                              onClick={(_e) => {
                                this.setState({ filterOpen: false });
                                this.props.applyFilter(currentTab);
                              }}
                            >
                              Apply filter
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OutsideClickHandler>
                </div>
                {/* <!-- / .row --> */}
              </div>
              <div className="table-responsive">
                <table className="table table-sm table-hover table-nowrap card-table">
                  <thead>
                    <tr>
                      <th>
                        <span className="list-sort text-muted ">Record ID</span>
                      </th>
                      <th>
                        <span
                          onClick={(_e) =>
                            this.props.sortOffers({
                              currentTab: currentTab,
                              type: 'product',
                            })
                          }
                          className="list-sort text-muted cursorTospan"
                          data-sort="item-name"
                        >
                          Product
                        </span>
                      </th>
                      <th>
                        <span
                          onClick={(_e) =>
                            this.props.sortOffers({
                              currentTab: currentTab,
                              type: 'status',
                            })
                          }
                          className="list-sort text-muted cursorTospan"
                          data-sort="item-title "
                        >
                          status
                        </span>
                      </th>

                      <th>
                        <span
                          onClick={(_e) =>
                            this.props.sortOffers({
                              currentTab: currentTab,
                              type: 'quantity',
                            })
                          }
                          className="list-sort text-muted cursorTospan"
                          data-sort="item-phone"
                        >
                          Quantity
                        </span>
                      </th>
                      <th>
                        <span
                          onClick={(_e) =>
                            this.props.sortOffers({
                              currentTab: currentTab,
                              type: 'taxes',
                            })
                          }
                          className="list-sort text-muted cursorTospan"
                          data-sort="item-company"
                        >
                          Taxes
                        </span>
                      </th>
                      <th>
                        <span
                          onClick={(_e) =>
                            this.props.sortOffers({
                              currentTab: currentTab,
                              type: 'shipping',
                            })
                          }
                          className="list-sort text-muted cursorTospan"
                          data-sort="item-company"
                        >
                          Shipping
                        </span>
                      </th>
                      <th>
                        <span
                          onClick={(_e) =>
                            this.props.sortOffers({
                              currentTab: currentTab,
                              type: 'deliveredBy',
                            })
                          }
                          className="list-sort text-muted cursorTospan"
                          data-sort="item-company"
                        >
                          Delivery In
                        </span>
                      </th>
                    </tr>
                  </thead>
                  {filteredOffer[currentTab].length ? (
                    <tbody className="list font-size-base">
                      {filteredOffer[currentTab].map((offer, index) => {
                        return (
                          <tr
                            key={index}
                            className="cursorTospan"
                            onClick={(_e) => {
                              history.push(
                                `offer-details?id=${offer['Supplier Record ID'][0]}&rfqId=${offer['Record ID']}`,
                              );
                            }}
                          >
                            <td>
                              <span className="item-title ">{offer['Hotline Record ID']}</span>
                            </td>
                            <td>
                              <span className="item-title ">{offer['Product Type']}</span>
                            </td>
                            <td>
                              <span className="item-title toolTip">
                                {offer['RFQ Offer Status'] === 'Won' && (
                                  <>
                                    <span className="text-success">● </span>
                                    Won{' '}
                                    {!offer['Winning Offer Shipping Information'] ? (
                                      <span
                                        data-tooltip="Missing shipping details"
                                        className="badge badge-pill badge-danger badge-circle "
                                      >
                                        <strong>!</strong>
                                      </span>
                                    ) : (
                                      !offer['Paid Out?'] ||
                                      (offer['Paid Out?'] && offer['Paid Out?'][0] !== true && (
                                        <span
                                          data-tooltip="Payment pending"
                                          className="badge badge-pill badge-warning badge-circle"
                                        >
                                          <strong>!</strong>
                                        </span>
                                      ))
                                    )}
                                  </>
                                )}
                                {offer['RFQ Offer Status'] === 'Refunded' && (
                                  <>
                                    <span className="text-success">● </span>
                                    Won
                                  </>
                                )}
                                {offer['RFQ Offer Status'] === 'Quoted' && (
                                  <>
                                    <span className="text-warning">● </span>
                                    Quoted
                                  </>
                                )}
                                {offer['RFQ Offer Status'] === 'Lost' && (
                                  <>
                                    <span className="text-danger">● </span>
                                    Lost
                                  </>
                                )}
                              </span>
                            </td>
                            <td>
                              <span className="item-title ">{offer['Quoted Quantity']}</span>
                            </td>
                            <td>
                              <span className="item-title ">{offer['Quoted Taxes']}</span>
                            </td>

                            <td>
                              <span className="item-title ">{offer['Quoted Shipping Cost']}</span>
                            </td>
                            <td>
                              <span className="item-title ">{offer['Quoted Delivery By']}</span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : null}
                </table>
                {filteredOffer[currentTab].length === 0 && (
                  <div className="card mb-0">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center justify-content-center">
                        <h6 className="text-uppercase text-center text-muted my-4">No offers Found</h6>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        );
      }
    },
  ),
);

export const SellerOffersPage = withRouter(
  connect(
    state.select({
      data: sellerOffersPageSelector((state) => state.originalData, {
        won: [],
        quoted: [],
        lost: [],
      }),
      loading: sellerOffersPageSelector((state) => state.loading),
      error: appSelector((state) => state.error),
      sellerInfo: sellerProfileSelector((state) => state.originalData),
    }),
    state.actions(sellerOffersPageActions, ['loadOffersData'], sellerProfileActions, ['sellerProfilePageData']),
  )(
    class SellerOffersPage extends Component {
      // eslint-disable-next-line react/no-deprecated
      componentWillMount() {
        const { query } = this.props;
        let id = query.get('id');
        let status = query.get('status') ? query.get('status') : 'Won';
        this.props.loadOffersData({ id, status });
        this.props.sellerProfilePageData(id);
      }

      render() {
        const { error, sellerInfo } = this.props;

        if (error && error.length > 0) {
          return <ErrorPage error={error} />;
        }
        return (
          <div className="main-content">
            <div className="header">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-end">
                    <div className="col">
                      <h1 className="header-title">Offers</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row bg-white no-gutters ">
                <div className="col-6 py-4 text-center">
                  <h5 className="text-uppercase text-muted">Won</h5>

                  <h1 className="mb-0">${sellerInfo.MarketWon}</h1>
                </div>
                <div className="col-6 py-4 text-center border-left">
                  <h5 className="text-uppercase text-muted">Quoted</h5>

                  <h1 className="mb-0">${sellerInfo.MarketQuoted}</h1>
                </div>
              </div>

              <Tabs
                defaultActiveKey="Won"
                className="nav mb-3 ml-2"
                activeKey={this.props.query.get('status') ? this.props.query.get('status') : 'Won'}
                onSelect={(currentTab) => {
                  const { query, history } = this.props;
                  let id = query.get('id');
                  const preUrl = `seller-offers?id=${id}`;
                  history.push(`${preUrl}&status=${currentTab}`);
                  this.props.loadOffersData({ id, status: currentTab });
                }}
              >
                <Tab eventKey="Won" title={`Won`}>
                  <CommanTab currentTab="won" />
                </Tab>
                <Tab eventKey="Quoted" title={`Quoted`}>
                  <CommanTab currentTab="quoted" />
                </Tab>

                <Tab eventKey="Lost" title={`Lost`}>
                  <CommanTab currentTab="lost" />
                </Tab>
              </Tabs>
            </div>
          </div>
        );
      }
    },
  ),
);

import { state } from '../../store';
export const [sellerProfileActions, sellerProfileSelector, sellerProfileState] = state('SellerProfile', {
  initial: {
    data: [],
    originalData: [],
    loading: true,
    editMode: false,
  },
  on: {
    loadSellerProfileDetails: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      newStoreObj.data = { ...data };
      newStoreObj.originalData = { ...data };
      return newStoreObj;
    },
    setOriginalData: (_) => {
      let newStoreObj = { ..._ };

      let data = newStoreObj.originalData;
      return {
        ..._,
        data: data,
      };
    },
    setLoading: (_, data) => {
      let newStoreObj = { ..._, loading: data };
      return newStoreObj;
    },
    setEditMode: (_, state) => {
      let newStoreObj = { ..._, editMode: state };
      return newStoreObj;
    },
    handleInputChange: (_, result) => {
      let newStoreObj = { ..._ };

      const { fieldName, value } = result;
      let data = { ...newStoreObj.data };
      data[fieldName] = value;

      if (fieldName === 'Supplier Incoterms') {
        let incoterms = value && value.map((item) => item.value);
        data[fieldName] = incoterms;
      } else {
        //storing value in variable
        data[fieldName] = value;
      }

      return { ...newStoreObj, data: { ...data } };
    },
  },
  events: ['sellerProfilePageData', 'updateSellerProfile'],
});

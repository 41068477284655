import { state } from '../../store';
export const [sellerDashboardActions, sellerDashboardSelector, sellerDashboardState] = state('SellerDashboard', {
  initial: {
    data: {},
    loading: true,
  },
  on: {
    setDashboardData: (_, data) => {
      const newStoreObj = {
        ..._,
      };

      newStoreObj.data = { ...data };
      return newStoreObj;
    },
    setLoading: (_, data) => {
      let newStoreObj = { ..._, loading: data };
      return newStoreObj;
    },
  },
  events: ['loadDashboardData'],
});

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { sellerProductPageSelector, sellerProductPageActions } from './store';
import { state } from '../../store';
import DefaultImage from '../../app/assets/img/image-placeholder.jpg';
import { appSelector } from '../../app/store';
import ErrorPage from '../../components-library/error-page';

export const SellerProductPage = withRouter(
  connect(
    state.select({
      sellerId: appSelector((state) => state.paramId, ''),
      data: sellerProductPageSelector((state) => state.data, []),
      filteredProducts: sellerProductPageSelector((state) => state.filteredProducts, []),
      loading: sellerProductPageSelector((state) => state.loading),
      error: appSelector((state) => state.error, ''),
    }),
    state.actions(sellerProductPageActions, ['sellerProductPageData', 'searchProducts']),
  )(
    class SellerProductPage extends React.PureComponent {
      componentWillMount() {
        const { query } = this.props;
        let id = query.get('id');
        console.log(id);
        if (id) {
          this.props.sellerProductPageData(id);
        } else {
          this.props.history.push('/404');
        }
      }
      searchChangeHandler = (e) => {
        this.props.searchProducts(e);
      };

      render() {
        const { sellerId, data, filteredProducts, loading, error, history } = this.props;

        if (loading) {
          return (
            <>
              <div className="header">
                <div className="container">
                  <div className="header-body">
                    <div className="row align-items-end">
                      <div className="col mt-5">
                        <h1 className="header-title">Products</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section className="container page-content pb-6 market-content text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </section>
            </>
          );
        }
        if (error) {
          return <ErrorPage error={error} />;
        }

        //for the empty state
        let noProductDetails = (
          <div className="container">
            <div className="row no-gutters align-items-center justify-content-center">
              <h4 className=" text-center text-muted my-4">
                No Products Found
                <p className="pt-2">Click + Add New to add new product.</p>
              </h4>
            </div>
          </div>
        );
        //products details
        let productDetails = (
          <>
            {filteredProducts && filteredProducts.length
              ? filteredProducts.map((product, index) => (
                  <div
                    className="col-12 col-lg-4 col-md-6 col-sm-6 cursorTospan"
                    key={index}
                    onClick={(e) => history.push(`product-details?id=${product.id}`)}
                  >
                    <div className="card border">
                      {product['Public Pictures'] && product['Public Pictures'].length > 0 ? (
                        product['Public Pictures'][0].type === 'image/png' ||
                        product['Public Pictures'][0].type === 'image/jpeg' ||
                        product['Public Pictures'][0].type === 'image/jpg' ? (
                          <img
                            src={product['Public Pictures'][0].url}
                            alt={product['Public Pictures'][0].alt}
                            className="card-img-top product-image border-bottom"
                          />
                        ) : (
                          <img
                            src={DefaultImage}
                            alt="defaultImage"
                            className="card-img-top product-default-image border-bottom"
                          />
                        )
                      ) : product['Product Pictures and Videos'] &&
                        product['Product Pictures and Videos'].length > 0 ? (
                        product['Product Pictures and Videos'][0].type === 'image/png' ||
                        product['Product Pictures and Videos'][0].type === 'image/jpeg' ||
                        product['Product Pictures and Videos'][0].type === 'image/jpg' ? (
                          <img
                            src={product['Product Pictures and Videos'][0].url}
                            alt={product['Product Pictures and Videos'][0].alt}
                            className="card-img-top product-image border-bottom"
                          />
                        ) : (
                          <img
                            src={DefaultImage}
                            alt="defaultImage"
                            className="card-img-top product-default-image border-bottom"
                          />
                        )
                      ) : (
                        <img
                          src={DefaultImage}
                          alt="defaultImage"
                          className="card-img-top product-default-image border-bottom"
                        />
                      )}

                      <div className="card-body">
                        <div className="row align-items-center">
                          <div className="col">
                            <h2 className="mb-2">
                              {product['Actual Product Model'] ? product['Actual Product Model'] : '-'}
                            </h2>
                            <h3 className="text-muted">
                              {product['Actual Manufacturer'] ? product['Actual Manufacturer'] : '-'}
                            </h3>
                          </div>
                        </div>

                        <div className="list-group list-group-flush mb-4">
                          <div className="list-group-item">
                            <div className="row align-items-center">
                              <div className="col">
                                <div>Vetting Status</div>
                              </div>
                              <div className="col-auto">
                                <div className="text-uppercase text-muted">
                                  {product['Vetting status'] ? product['Vetting status'] : '-'}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="list-group-item">
                            <div className="row align-items-center">
                              <div className="col">
                                <div>Product Type</div>
                              </div>
                              <div className="col-auto">
                                <div className="text-muted">{product['Product Type']}</div>
                              </div>
                            </div>
                          </div>
                          <div className="list-group-item">
                            <div className="row align-items-center">
                              <div className="col">
                                <div>Record ID</div>
                              </div>
                              <div className="col-auto">
                                <div className="text-muted">{product.id}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
            {filteredProducts && filteredProducts.length === 0 && (
              <div className="container">
                <div className="row no-gutters align-items-center justify-content-center">
                  <h4 className=" text-center text-muted my-4">No Products Found According To Search Criteria</h4>
                </div>
              </div>
            )}
          </>
        );

        return (
          <>
            <div className="container">
              <div className="header">
                <div className="header-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <h1 className="header-title">Products</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row mb-4">
                <div className="col pr-0">
                  <form>
                    <div className="input-group input-group-md w-25 float-right input-group-merge">
                      <input
                        type="text"
                        className="form-control form-control-prepended list-search"
                        placeholder="Search"
                        onChange={this.searchChangeHandler}
                      />

                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <span className="fe fe-search"></span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-auto">
                  <button
                    className="btn btn-primary btn-md text-uppercase mb-4 "
                    onClick={(e) => {
                      history.push(`add-product?id=${sellerId}`);
                    }}
                  >
                    <strong>+ Add New</strong>
                  </button>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">{data && data.length === 0 ? noProductDetails : productDetails}</div>
            </div>
          </>
        );
      }
    },
  ),
);

import React from 'react';

export class StripeConnectSuccessPage extends React.PureComponent {
  componentWillMount() {
    const { query } = this.props;
    let id = query.get('id');
    setTimeout(function () {
      window.location.href = '/seller?id=' + id;
    }, 3000);
  }
  render() {
    return (
      <>
        <div className="header">
          <div className="container">
            <div className="header-body">
              <div className="row align-items-end">
                <div className="col">
                  <h1 className="header-title">Congratulations!</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="container page-content pb-6 market-content ">
          <p>You've successfully created stripe connect account.</p>
          <p>
            <strong>Need assistance!</strong> Reach out to{' '}
            <a href="mailto:marketplace@projectn95.org" rel="noopener noreferrer" target="_blank">
              marketplace@projectn95.org
            </a>
          </p>
        </section>
      </>
    );
  }
}

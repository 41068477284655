import { state } from '../../store';

export const [rfqActions, rfqSelector, rfqState] = state('RFQpage', {
  initial: {
    data: [],
    loading: true,
    total: '',
    price: '',
    qty: '',
    shippingCost: '',
    taxes: '',
    paymentTerms: 'Net 30',
    deliveryWithin: '',
    additionalInformation: '',
    iAgreeCheck: false,
    taxSpinner: false,
    showTaxDisclaimer: false,
    fieldError: {
      price: '',
      qty: '',
      shippingCost: '',
      paymentTerms: '',
      taxes: '',
      deliveryWithin: '',
    },
    priceClass: ['form-control'],
    qtyClass: ['form-control'],
    modalShow: true,
    popupIAgreeCheck: false,
  },
  on: {
    load: (_, data) => {
      return { ..._, data: data };
    },
    setData: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      newStoreObj.price = data['Quoted Price'] ? data['Quoted Price'].split('$')[1] : 0;
      newStoreObj.qty = data['Quoted Quantity'];
      newStoreObj.shippingCost = data['Quoted Shipping Cost'] ? data['Quoted Shipping Cost'].split('$')[1] : 0;

      newStoreObj.taxes = data['Quoted Taxes'] ? data['Quoted Taxes'].split('$')[1] : 0;
      newStoreObj.deliveryWithin = data['Quoted Delivery Within'];
      newStoreObj.additionalInformation = data['Quoted Quantity'];

      return { ...newStoreObj, loading: false };
    },
    setLoadingStatus: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      newStoreObj.loading = data;
      return { ...newStoreObj, loading: false };
    },
    handleInputChange: (_, result) => {
      let newStoreObj = { ..._ };

      const { fieldName, value } = result;

      let fieldError = { ...newStoreObj.fieldError };

      //storing value in variable
      newStoreObj[fieldName] = value;

      const regexp = /^([0-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/;
      let validNumber = regexp.test(value);

      if ((fieldName === 'shippingCost' || fieldName === 'taxes' || fieldName === 'deliveryWithin') && !validNumber) {
        fieldError[fieldName] = 'fieldError';
      } else if (fieldName === 'shippingCost' || fieldName === 'taxes' || fieldName === 'deliveryWithin') {
        fieldError[fieldName] = '';
      }
      //setting the className AND fieldError for price input
      if (fieldName === 'price' && validNumber) {
        if (parseFloat(value) < 0.01) {
          newStoreObj.priceClass = ['form-control', 'is-invalid'];
          fieldError.price = 'fieldError';
        } else {
          newStoreObj.priceClass = ['form-control', 'is-valid'];
          fieldError.price = '';
        }
      } else if (fieldName === 'price') {
        newStoreObj.priceClass = ['form-control', 'is-invalid'];
        fieldError.price = 'fieldError';
      }

      //setting the className AND fieldError for qty input
      if (fieldName === 'qty' && validNumber) {
        if (parseInt(value) === 0) {
          newStoreObj.qtyClass = ['form-control', 'is-invalid'];
          fieldError.qty = 'fieldError';
        } else {
          newStoreObj.qtyClass = ['form-control', 'is-valid'];
          fieldError.qty = '';
        }
      } else if (fieldName === 'qty') {
        newStoreObj.qtyClass = ['form-control', 'is-invalid'];
        fieldError.qty = 'fieldError';
      }

      return { ...newStoreObj, fieldError: fieldError };
    },
    iAgreeCheckChangeHandler: (_) => {
      let newStoreObj = { ..._ };

      newStoreObj.iAgreeCheck = !newStoreObj.iAgreeCheck;

      return {
        ...newStoreObj,
      };
    },
    popupIAgreeCheckChangeHandler: (_) => {
      let newStoreObj = { ..._ };

      newStoreObj.popupIAgreeCheck = !newStoreObj.popupIAgreeCheck;

      return {
        ...newStoreObj,
      };
    },
    setModalShow: (_, status) => {
      let newStoreObj = { ..._ };

      newStoreObj.modalShow = status;

      return {
        ...newStoreObj,
      };
    },
    setTaxes: (_, taxes) => {
      let newStoreObj = { ..._ };

      newStoreObj.taxes = taxes;

      return {
        ...newStoreObj,
      };
    },
    setTaxSpinner: (_, status) => {
      let newStoreObj = { ..._ };

      newStoreObj.taxSpinner = status;

      return {
        ...newStoreObj,
      };
    },
    showTaxDisclaimer: (_) => {
      let newStoreObj = { ..._ };

      newStoreObj.showTaxDisclaimer = true;

      return {
        ...newStoreObj,
      };
    },
  },

  events: ['loadRfqData', 'offerSubmit', 'offerDraft', 'loadOfferData', 'offerUpdate', 'getSubTotalTaxes'],
});

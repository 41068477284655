import { call, select } from 'redux-saga/effects';
// import { state } from '../store';
// import { historySelector, historyActions } from '../router/store';
import { appSelector } from './store';
// import { createUploadFileChannel } from './create-file-upload-channel';

export function* api(uri, method = 'GET', body = null) {
  const user = yield select(appSelector((state) => state.user));
  const headers = {};

  if (user) {
    headers['Authorization'] = `Bearer ${yield call(() => user.getIdToken())}`;
  }

  let response;

  if (body) {
    headers['Content-Type'] = 'application/json';

    response = yield call(fetch, uri, {
      method: method,
      body: JSON.stringify(body),
      headers,
    });
  } else {
    response = yield call(fetch, uri, { method: method || 'GET', headers });
  }

  const json = yield call(retrieveJSON, response);

  // if (response.status > 299 || json.success === false) {
  //   yield call(apiErrorHandler, json, response);
  // }

  return json;
}

export function retrieveJSON(response) {
  return response.json();
}

// export function* apiErrorHandler(json, response) {
//   //list of intended api errors
//   const knownErrors = {
//     existingTeamMemberError: 'User is already part of the team.',
//     noContactEmailError: 'There is no contact with email',
//     permissionError: `You don't have permission to access this service`,
//     authenticationError: `You cannot be authenticated`
//   };

//   //create object
//   let errorAlertObj = {};
//   let setError = false;

//   for (let error in knownErrors) {
//     if (json.message.includes(knownErrors[error])) {
//       errorAlertObj[error] = json.message;
//       setError = true;
//     }
//   }

//   //if we did not identify the api error
//   if (!setError) {
//     errorAlertObj.unknownApiError =
//       'Woops! Something went wrong. Please refresh and try again.';
//   }

//   //I left these in because im not sure of the implications for no longer throwing these
//   //types of errors. Needs more research into the codebase
//   if (response.status === 401) {
//     const accessForbiddenError = new Error(
//       `You don't have permission to access this service`
//     );

//     throw accessForbiddenError;
//   }
//   if (response.status === 402) {
//     const err = new Error(
//       `You don't have permission to access this part of the application.`
//     );
//     err.shouldGoBack = true;
//     throw err;
//   }
//   if (response.status === 403) {
//     return yield call(redirectToSignInPage);
//   }
//   if (response.status === 500 || json.success === false) {
//     throw new Error(json.message);
//   }

//   // yield put.resolve(alertsActions.setError(errorAlertObj));
// }

// export function redirectToSignInPage() {
//   // eslint-disable-next-line no-restricted-globals
//   location.href = '/signin?expired';
// }

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { buyerProfileSelector } from '../buyer-profile/store';
import { aggregationSelector, aggregationActions } from './store';
import { state } from '../../store';
import { StartCountDownTimerWithEndDate } from '../../components-library/countDown';
import { PurchaseConfirmationModal } from './purchase-confirmation-popup';
import { ManualShippingModal } from './manual-shipping-popup';
import { AddressVerificationModal } from './address-verification-popup';
import Input from 'react-phone-number-input/input';
import { appSelector } from '../../app/store';
import ErrorPage from '../../components-library/error-page';

export const AggregationPage = withRouter(
  connect(
    state.select({
      data: aggregationSelector((state) => state.data, []),
      error: appSelector((state) => state.error, null),
      loading: aggregationSelector((state) => state.loading, ''),
      formIAgreeCheck: aggregationSelector((state) => state.formIAgreeCheck, ''),
      noOfWorkers: aggregationSelector((state) => state.noOfWorkers, ''),
      daysOfCoverage: aggregationSelector((state) => state.daysOfCoverage, ''),
      quantity: aggregationSelector((state) => state.quantity, ''),
      addressLine1: aggregationSelector((state) => state.addressLine1, ''),
      addressLine2: aggregationSelector((state) => state.addressLine2, ''),
      city: aggregationSelector((state) => state.city, ''),
      zipCode: aggregationSelector((state) => state.zipCode, ''),
      state: aggregationSelector((state) => state.state, ''),
      pcModalShow: aggregationSelector((state) => state.pcModalShow, false),
      avModalShow: aggregationSelector((state) => state.avModalShow, false),
      isExpired: aggregationSelector((state) => state.isExpired, false),
      phone: aggregationSelector((state) => state.phone, ''),
      puertoRicoModalShow: aggregationSelector((state) => state.puertoRicoModalShow, false),
      qualified: buyerProfileSelector((state) => state.qualified),
    }),
    state.actions(aggregationActions, [
      'handleInputChange',
      'loadAggData',
      'formIAgreeCheckChangeHandler',
      'setPCModalShow',
      'purchaseHandler',
      'setStripe',
      'setPuertoRicoModalShow',
      'setAVModalShow',
      'addressVerification',
      'setExpiredTime',
    ]),
  )(
    class AggregationPage extends React.PureComponent {
      constructor(props) {
        super(props);
        this.zipcodeRef = React.createRef();

        this.state = {
          buttonClicked: false,
        };
      }

      UNSAFE_componentWillMount() {
        const { query } = this.props;
        let id = query.get('id');
        let transactId = query.get('transact_id');
        this.props.loadAggData({ id, transactId });

        let stripElement = document.createElement('script');

        stripElement.setAttribute('src', 'https://js.stripe.com/v3/');

        document.head.appendChild(stripElement);

        stripElement.onload = () => {
          let base_url = window.location.origin;
          const stripe_pk_test_key = `pk_test_lEB9lUuCtWLZqEz2tc5NjHef00coK4jX91`;
          const stripe_pk_live_key = `pk_live_Hh9wXjxOfGl47hbUrWHl7bc900LeLjyaYs`;
          let stripe = window.Stripe(base_url.includes('projectn95.lvrg.ai') ? stripe_pk_live_key : stripe_pk_test_key);

          this.props.setStripe(stripe);
        };
      }

      purchaseButtonHandler = (event) => {
        event.preventDefault();
        const { zipCode, addressLine1, addressLine2, city, state } = this.props;
        this.setState({ buttonClicked: true });

        let payload = {
          line1: addressLine1,
          line2: addressLine2,
          city: city,
          zipcode: zipCode,
          state: state,
        };

        this.props.addressVerification(payload);
      };
      breadcrumb = () => {
        return (
          <div className="header">
            <div className="container-fluid pt-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href={`/group-orders?id=${this.props.data['MER ID']}`}>Group Orders</a>
                  </li>
                  <li className="breadcrumb-item active">Offer Details</li>
                </ol>
              </nav>
            </div>
          </div>
        );
      };
      render() {
        const {
          data,
          error,
          loading,
          formIAgreeCheck,
          zipCode,
          state,
          pcModalShow,
          puertoRicoModalShow,
          avModalShow,
          isExpired,
          phone,
          qualified,
        } = this.props;

        //actions
        const { handleInputChange, formIAgreeCheckChangeHandler, setExpiredTime } = this.props;

        if (zipCode.length === 5 && state === '') {
          this.zipcodeRef.current.setCustomValidity('Please enter valid zipcode.');
        } else if (this.zipcodeRef.current && zipCode.length === 5 && state !== '') {
          this.zipcodeRef.current.setCustomValidity('');
        }

        //if status is success then redirect to group orders
        if (data.transactionData && data.transactionData['Status'] && data.transactionData['Status'] === 'success') {
          setTimeout(function () {
            window.location = `/group-orders?id=` + data['MER ID'];
          }, 3000);
        }

        if (loading) {
          return (
            <>
              {this.breadcrumb()}
              <section className="container page-content pb-6 market-content text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </section>
            </>
          );
        }
        if (error) {
          return <ErrorPage error={error} />;
        }
        return (
          <>
            {this.breadcrumb()}

            <div className="container page-content pb-6 market-content acp-content mt-3 mt-md-0">
              <div className="row mb-5">
                <div className="col-md-6">
                  <h1 className="mt-0 mb-3">{data['Aggregation Name']}</h1>
                  <h3 className="text-uppercase">
                    <strong>Estimated Ship by:</strong> {data['Ship by']}
                  </h3>
                  <p className="mt-4">
                    <strong>Product Type:</strong> {data['Product Type']}
                    <br />
                    <strong>Price:</strong> ${data['Price']} per {data['UoM']} ({data['Base UoM Quantity']} units, $
                    {data['Base UoM Price']}/{data['Base UoM']})
                    <br />
                    <br />
                    <strong>Manufacturer / Model:</strong> {data['Manufacturer']} / {data['Product Model']}
                    <br />
                    <strong>Specifications:</strong> {data['Specifications'] && data['Specifications'].join(',')}
                    <br />
                    <br />
                    <strong>Additional Details:</strong> {data['Additional Details']}
                    <br />
                  </p>
                  <ul className="n95 mt-5">
                    {data['Product Pictures'] &&
                      data['Product Pictures'].map((img, index) => {
                        return (
                          <span key={index}>
                            <li>
                              <img className="rounded" src={img.thumbnails.large.url} alt={img.filename} />
                            </li>{' '}
                          </span>
                        );
                      })}
                  </ul>
                  <p className="mt-3">
                    <strong>Return Policy.</strong> We can only accept returns of defective merchandise. No refunds will
                    be required if request is made more than 5 business days following Buyer receipt of the goods, or
                    for open or used products.
                  </p>
                  <p className="mt-3 product-needs">
                    <strong>Need help with this transaction or have a question about the offer?</strong>
                    <br />
                    Email us at{' '}
                    <a href="mailto:grouporder@projectn95.org" target="_blank" rel="noopener noreferrer">
                      GroupOrders@projectn95.org
                    </a>{' '}
                    or call us at <a href="te:202-849-9858">202-849-9858</a>.
                  </p>
                </div>
                <div className="col-md-6 pl-5">
                  <div className="card market-agg-card">
                    <div className="info-box  mt-5 mt-md-0 card-body">
                      {data['showPurchaseButton'] === true ? (
                        <>
                          {isExpired === false ? (
                            <h1 id="countdown-timer">
                              CLOSES IN:{' '}
                              <span className="d-md-none">
                                <br />
                                <br />
                              </span>
                              <strong>
                                <mark>
                                  <StartCountDownTimerWithEndDate
                                    end_time={data['End Date']}
                                    callBackFun={(e) => {
                                      setExpiredTime(true);
                                    }}
                                  />
                                </mark>
                              </strong>
                            </h1>
                          ) : null}
                          {data['showCreditAvailable'] && (
                            <h2 className="text-uppercase">
                              <strong>Available Credit:</strong> <mark>${data['Total Credit Amount']}</mark>
                            </h2>
                          )}
                          <hr />
                          <h2 className="mt-4 text-uppercase">Order Form</h2>
                          <form onSubmit={this.purchaseButtonHandler}>
                            <div className="form-group mt-3">
                              <label>
                                <strong>
                                  Number of individuals protected
                                  <span className="form-asterik">*</span>
                                </strong>
                              </label>
                              <input
                                type="number"
                                min="1"
                                onChange={(e) => {
                                  let payload = {
                                    fieldName: 'noOfWorkers',
                                    value: e.target.value,
                                  };

                                  handleInputChange(payload);
                                }}
                                className="form-control"
                                id="headcount"
                                required
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <strong>
                                  Days of coverage
                                  <span className="form-asterik">*</span>
                                </strong>
                                <br />
                                How many days of coverage will the PPE you’re purchasing provide?
                              </label>
                              <input
                                type="number"
                                min="1"
                                className="form-control"
                                id="coverage"
                                onChange={(e) => {
                                  let payload = {
                                    fieldName: 'daysOfCoverage',
                                    value: e.target.value,
                                  };

                                  handleInputChange(payload);
                                }}
                                required
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <strong>
                                  Quantity ({data['UoM']})<span className="form-asterik">*</span>
                                </strong>
                              </label>
                              <input
                                type="number"
                                min="1"
                                className="form-control"
                                id="qty"
                                onChange={(e) => {
                                  let payload = {
                                    fieldName: 'quantity',
                                    value: e.target.value,
                                  };

                                  handleInputChange(payload);
                                }}
                                required
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <strong>
                                  Contact Phone
                                  <span className="form-asterik">*</span>
                                </strong>
                              </label>
                              <span className="d-flex">
                                <div className="phone-number">+1&nbsp;(US)&nbsp;</div>
                                <Input
                                  country="US"
                                  value={phone}
                                  required
                                  maxLength="14"
                                  pattern=".{14,14}"
                                  onInvalid={(e) => {
                                    e.target.setCustomValidity('Enter valid phone');
                                  }}
                                  onInput={(e) => {
                                    e.target.setCustomValidity('');
                                  }}
                                  className="form-control"
                                  onChange={(e) => {
                                    let payload = {
                                      fieldName: 'phone',
                                      value: e,
                                    };
                                    handleInputChange(payload);
                                  }}
                                />
                              </span>
                            </div>
                            <h3 className="mt-4 mb-2 text-uppercase">Shipping Address</h3>
                            <strong>NOTE:</strong> We can NOT deliver to PO Boxes. Expect longer delivery times for
                            Hawaii, Alaska, and Puerto Rico.
                            <br />
                            <div className="form-group mt-3">
                              <label>
                                <strong>
                                  Address Line 1<span className="form-asterik">*</span>
                                </strong>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="address1"
                                pattern="[^:]*$"
                                onInvalid={(e) => {
                                  if (e.target.value && e.target.value.includes(':')) {
                                    e.target.setCustomValidity('Colon ":" is not allowed');
                                  } else {
                                    e.target.setCustomValidity('Please fill in the field');
                                  }
                                }}
                                onInput={(e) => {
                                  e.target.setCustomValidity('');
                                  e.target.checkValidity();
                                }}
                                onChange={(e) => {
                                  let payload = {
                                    fieldName: 'addressLine1',
                                    value: e.target.value,
                                  };

                                  handleInputChange(payload);
                                }}
                                required
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <strong>Address Line 2</strong>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                pattern="[^:]*$"
                                onInvalid={(e) => e.target.setCustomValidity('Colon ":" is not allowed')}
                                onInput={(e) => {
                                  e.target.setCustomValidity('');
                                  e.target.checkValidity();
                                }}
                                id="address2"
                                onChange={(e) => {
                                  let payload = {
                                    fieldName: 'addressLine2',
                                    value: e.target.value,
                                  };

                                  handleInputChange(payload);
                                }}
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <strong>
                                  City<span className="form-asterik">*</span>
                                </strong>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="city"
                                onChange={(e) => {
                                  let payload = {
                                    fieldName: 'city',
                                    value: e.target.value,
                                  };

                                  handleInputChange(payload);
                                }}
                                required
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <strong>
                                  Zip Code<span className="form-asterik">*</span>
                                </strong>
                              </label>
                              <input
                                ref={this.zipcodeRef}
                                type="text"
                                pattern="^[0-9]{5}"
                                className="form-control"
                                id="zipcode"
                                onChange={(e) => {
                                  let payload = {
                                    fieldName: 'zipCode',
                                    value: e.target.value,
                                  };

                                  handleInputChange(payload);
                                }}
                                required
                                title="Please enter valid zipcode."
                              ></input>
                            </div>
                            <div className="form-group">
                              <label>
                                <strong>
                                  State<span className="form-asterik">*</span>
                                </strong>
                              </label>
                              <select
                                className="form-control text-muted"
                                id="state"
                                required
                                onChange={(e) => {
                                  let payload = {
                                    fieldName: 'state',
                                    value: e.target.value,
                                  };

                                  handleInputChange(payload);
                                }}
                                value={this.props.state}
                                disabled={true}
                              >
                                <option value="" defaultValue>
                                  State
                                </option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AS">American Samoa</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="AA">Armed Forces (AA)</option>
                                <option value="AE">Armed Forces (AE)</option>
                                <option value="AP">Armed Forces (AP)</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="GU">Guam</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MH">Marshall Islands</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="FM">Micronesia</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="MP">Northern Mariana Islands</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PW">Palau</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="PR">Puerto Rico</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VI">Virgin Islands</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="termsAndConditions"
                                  checked={formIAgreeCheck}
                                  onChange={(e) => formIAgreeCheckChangeHandler()}
                                  required
                                />
                                <label className="form-check-label">
                                  I agree to the{' '}
                                  <a href="https://www.projectn95.org/market-terms">Marketplace terms and conditions</a>
                                </label>
                              </div>
                            </div>
                            <button
                              id="purchaseSubmitBtn"
                              type="submit"
                              className="btn btn-block btn-primary"
                              title={!qualified ? 'Your account is under review.' : undefined}
                              disabled={(this.state.buttonClicked === true ? true : false) || !qualified}
                            >
                              {this.state.buttonClicked === false ? (
                                'Purchase'
                              ) : (
                                <>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>{' '}
                                  Processing...
                                </>
                              )}
                            </button>
                          </form>
                        </>
                      ) : null}

                      {data['showPurchaseButton'] === false && data['transactionData'] && (
                        <>
                          <strong>Quantity:</strong> {data.transactionData['Quantity']}
                          <br />
                          <br />
                          {data['showAppliedCredit'] && (
                            <>
                              <strong>Applied Credit Amount:</strong> ${data['Applied Credit Amount']}
                              <br />
                            </>
                          )}
                          <strong>Subtotal:</strong> ${data.transactionData['Subtotal']}
                          <br />
                          <strong>Shipping:</strong> ${data.transactionData['Shipping']}
                          <br />
                          {data['showTaxes'] && (
                            <>
                              <strong>Taxes:</strong> ${data.transactionData['Taxes']}
                              <br />
                            </>
                          )}
                          <h4 className="my-4">
                            <mark>Total amount charged: ${data.transactionData['Amount']}</mark>
                          </h4>
                          <h4 className="mt-0 mb-4">Transaction Status: {data.transactionData['Status']}</h4>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <ManualShippingModal
                  show={puertoRicoModalShow}
                  onHide={() => {
                    this.props.setPuertoRicoModalShow(false);
                    this.setState({ buttonClicked: false });
                  }}
                />
                <PurchaseConfirmationModal
                  show={pcModalShow}
                  onHide={() => {
                    this.props.setPCModalShow(false);
                    this.props.setAVModalShow(false);

                    this.setState({ buttonClicked: false });
                  }}
                />
                {avModalShow && (
                  <AddressVerificationModal
                    show={avModalShow}
                    onHide={() => {
                      this.props.setAVModalShow(false);
                      this.setState({ buttonClicked: false });
                    }}
                  />
                )}
              </div>
            </div>
          </>
        );
      }
    },
  ),
);

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import { usersPageActions, usersPageSelector } from './store';
import { appSelector } from '../../app/store';

const CreateUserPage = () => {
  const decodedToken = useSelector(appSelector((state) => state.decodedToken));
  const { error, loading } = useSelector(usersPageSelector((state) => state));
  const dispatch = useDispatch();
  const history = useHistory();
  const [userType, setUserType] = useState();
  const [tenantId, setTenantId] = useState(2);
  const [buyerId, setBuyerId] = useState();
  const [sellerId, setSellerId] = useState();
  const [roles, setRoles] = useState([]);
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();

  return (
    <>
      <div className="header">
        <div className="container-fluid">
          <div className="header-body">
            <div className="row align-items-end">
              <div className="col">
                <h1 className="header-title">Create User</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Alert variant="danger" show={Boolean(error)}>
              {error}
            </Alert>
            <div className="card">
              <div className="card-body">
                <Form
                  onSubmit={async (e) => {
                    const form = e.currentTarget;

                    e.preventDefault();

                    if (form.checkValidity() === false) {
                      e.stopPropagation();
                    }

                    dispatch(
                      usersPageActions.createUser(
                        {
                          email,
                          firstName,
                          lastName,
                          userType,
                          buyerId,
                          sellerId,
                          tenantId,
                          roles,
                        },
                        {
                          redirect: () => history.push('/internal/users'),
                        },
                      ),
                    );
                  }}
                >
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicFirstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      placeholder="Enter first name"
                      required
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicLastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      placeholder="Enter last name"
                      required
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicUserType">
                    <Form.Label>User Type</Form.Label>
                    <Form.Control as="select" required onChange={(e) => setUserType(e.target.value)}>
                      <option></option>
                      <option>buyer</option>
                      <option>seller</option>
                      {decodedToken.claims.roles && decodedToken.claims.roles.includes('admin') && (
                        <option>internal</option>
                      )}
                    </Form.Control>
                  </Form.Group>

                  {userType && userType !== 'internal' && (
                    <Form.Group controlId="formBasicTenant">
                      <Form.Label>Is this a Project N95 user?</Form.Label>
                      <Form.Check
                        type="radio"
                        label="No"
                        name="formBasicTenant"
                        id="formBasicTenant1"
                        checked={tenantId === 1}
                        onChange={() => setTenantId(1)}
                      />
                      <Form.Check
                        type="radio"
                        label="Yes"
                        name="formBasicTenant"
                        id="formBasicTenant2"
                        checked={tenantId === 2}
                        onChange={() => setTenantId(2)}
                      />
                    </Form.Group>
                  )}

                  {userType && userType === 'buyer' && (
                    <Form.Group controlId="formBasicBuyer">
                      <Form.Label>Buyer ID</Form.Label>
                      <Form.Control
                        placeholder="Enter buyer ID"
                        required
                        onChange={(e) => setBuyerId(e.target.value)}
                      />
                    </Form.Group>
                  )}

                  {userType && userType === 'seller' && (
                    <Form.Group controlId="formBasicSeller">
                      <Form.Label>Seller ID</Form.Label>
                      <Form.Control
                        placeholder="Enter seller ID"
                        required
                        onChange={(e) => setSellerId(e.target.value)}
                      />
                    </Form.Group>
                  )}

                  {userType && userType === 'internal' && (
                    <Form.Group controlId="formBasicRole">
                      <Form.Label>Role</Form.Label>
                      <Form.Control as="select" required onChange={(e) => setRoles([e.target.value])}>
                        <option></option>
                        <option>support</option>
                        <option>admin</option>
                      </Form.Control>
                    </Form.Group>
                  )}

                  <Button variant="primary" type="submit" style={{ width: '100%' }} disabled={loading}>
                    Submit
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateUserPage;

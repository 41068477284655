import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './app';
import * as serviceWorker from './serviceWorker';
import createSagaMiddleware from 'redux-saga';
import { Provider } from 'react-redux';
import firebase from 'firebase';

//store
import bootstrap from './store/bootstrap';
//saga
import RootSaga from './app/saga';
//router
import { BrowserRouter as Router } from 'react-router-dom';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

firebase.initializeApp(firebaseConfig);
firebase.auth().useDeviceLanguage();

const sagaMiddleware = createSagaMiddleware();
const store = bootstrap({
  initialState: {},
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(RootSaga);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { state } from '../../store';

export const [sellerOfferPageActions, sellerOfferPageSelector, sellerOfferPageState] = state('sellerOfferPage', {
  initial: {
    data: [],
    loading: true,
    shippingLoading: false,
  },
  on: {
    loadOfferDetails: (_, data) => {
      let newStoreObj = { ..._, data: data };

      return newStoreObj;
    },
    setLoading: (_, data) => {
      let newStoreObj = { ..._, loading: data };
      return newStoreObj;
    },
    setShippingLoading: (_, status) => {
      let newStoreObj = { ..._, shippingLoading: status };
      return newStoreObj;
    },
    setRemoveLoading: (_, payload) => {
      let data = { ..._.data };
      data['Shipping Information'] = data['Shipping Information'].map((item) => {
        if (item['Record ID'] === payload.id) {
          item.loading = true;
        }
        return { ...item };
      });
      let newStoreObj = { ..._, data };
      return newStoreObj;
    },
    setShippingInfo: (_, info) => {
      let data = { ..._.data };
      data['Shipping Information'] = info;
      let newStoreObj = { ..._, data };
      return newStoreObj;
    },
  },
  events: ['getOfferData', 'shippingSubmissionOffer', 'deleteShippingInfo'],
});

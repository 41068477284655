import { state } from '../store';

export const [landingPageActions, landingPageSelector, landingPageState] = state('LandingPage', {
  initial: {
    userLoaded: false,
    user: null,
    loading: false,
    error: '',
  },
  on: {
    loadUser: (_, data) => {
      const newStoreObj = { ..._, user: data, userLoaded: true };

      return newStoreObj;
    },
    setLoading: (_, data) => {
      const newStoreObj = { ..._, loading: data };

      return newStoreObj;
    },
    setError: (_, data) => {
      const newStoreObj = { ..._, error: data };

      return newStoreObj;
    },
  },
  events: ['getUserByEmail'],
});

import chinaFlag from '../app/assets/img/flags/China-flag.png';
import japanFlag from '../app/assets/img/flags/Japan-flag.png';
import koreaFlag from '../app/assets/img/flags/Korea-flag.png';
import mexicoFlag from '../app/assets/img/flags/Mexico-flag.png';
import taiwanFlag from '../app/assets/img/flags/Taiwan-flag.png';
import thailandFlag from '../app/assets/img/flags/Thailand-flag.png';
import usaFlag from '../app/assets/img/flags/USA-flag.png';
import vietnamFlag from '../app/assets/img/flags/Vietnam-flag.png';

export const sortAscDesc = (a, b, typeCheck) => {
  if (typeCheck) {
    if (a < b) {
      return -1;
    }
    if (b > a) {
      return 1;
    }
    return 0;
  } else {
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  }
};

export function formatMoney(amount, decimalCount, decimal, thousands = ',') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
}

export const getFlagAndCountry = (countryCode) => {
  //flag
  let result = { flagAlt: '', flagTitle: '', flagSrc: null };
  let country = null;
  if (countryCode) {
    const flagMap = {
      JP: 'Japan',
      CN: 'China',
      MX: 'Mexico',
      US: 'USA',
      TW: 'Taiwan',
      TY: 'Thailand',
      KR: 'Korea',
      VN: 'Vietnam',
    };

    country = flagMap[countryCode];
    if (country === undefined) {
      return null;
    }
    let flagAlt = `${country} Flag`;
    let flagTitle = `Made in ${country}`;
    let flagSrc = '';

    switch (country) {
      case 'Japan':
        flagSrc = japanFlag;
        break;
      case 'China':
        flagSrc = chinaFlag;
        break;
      case 'Mexico':
        flagSrc = mexicoFlag;
        break;
      case 'USA':
        flagSrc = usaFlag;
        break;
      case 'Taiwan':
        flagSrc = taiwanFlag;
        break;
      case 'Thailand':
        flagSrc = thailandFlag;
        break;
      case 'Korea':
        flagSrc = koreaFlag;
        break;
      case 'Vietnam':
        flagSrc = vietnamFlag;
        break;
      default:
        flagSrc = '/#';
    }

    result = { flagAlt, flagSrc, flagTitle };
  }
  return result;
};

export const isValidEmail = (string) => {
  if (string === '') {
    return true;
  }
  const isEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return isEmail.test(string.toLowerCase().trim());
};

import React from 'react';

import { Switch, Redirect } from 'react-router-dom';

const SwitchWithNotFound = (props) => {
  return (
    <Switch>
      {props.children}
      <Redirect to="/404" />
    </Switch>
  );
};

export default SwitchWithNotFound;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Countdown } from '../../components-library/countDown';
import { connect } from 'react-redux';
import { sellerRfqDetailsPageActions, sellerRfqDetailsPageSelector } from './store';
import { state } from '../../store';
import { appSelector } from '../../app/store';
import ErrorPage from '../../components-library/error-page';

export const SellerRfqDetailsPage = withRouter(
  connect(
    state.select({
      data: sellerRfqDetailsPageSelector((state) => state.data, []),
      loading: sellerRfqDetailsPageSelector((state) => state.loading),
      error: appSelector((state) => state.error, ''),
    }),
    state.actions(sellerRfqDetailsPageActions, ['loadRfqDetailsData', 'viewRfq']),
  )(
    class SellerRfqDetailsPage extends Component {
      componentWillMount() {
        const { query } = this.props;
        let id = query.get('id');
        let hotlineId = query.get('hotlineId');
        let status = query.get('status');
        if (id && hotlineId) {
          this.props.loadRfqDetailsData({ id, hotlineId, status });
        } else {
          this.props.history.push('/404');
        }
      }
      getTimeline = (timeline) => {
        let fixedTimeline = null;
        if (parseInt(timeline) > 0) {
          fixedTimeline = `${timeline} days`;
        } else {
          fixedTimeline = 'ASAP';
        }
        return fixedTimeline;
      };
      viewRfqButtonHandler = (event) => {
        this.setState({
          disable: true,
        });
        const { data } = this.props;
        event.preventDefault();

        let payload = {
          productRecordId: event.target[0].value,
          hotlineId: data['Hotline ID'],
          sellerId: data['Supplier Record ID'],
          hotlineRecordId: data['Hotline Record ID'],
          history: this.props.history,
        };
        this.props.viewRfq(payload);
      };
      breadcrumb = () => {
        return (
          <div className="header">
            <div className="pt-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href={`/seller-rfqs?id=${this.props.data['Supplier Record ID']}`}>Request For Quotes</a>
                  </li>
                  <li className="breadcrumb-item active">Details</li>
                </ol>
              </nav>
            </div>
          </div>
        );
      };
      render() {
        const { data, loading = false, error, history } = this.props;
        if (loading) {
          return (
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-10 col-xl-8">
                  {this.breadcrumb()}
                  <section className="container page-content pb-6 market-content text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          );
        }
        if (error && error.length > 0) {
          return <ErrorPage error={error} />;
        }

        return (
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-8">
                {this.breadcrumb()}
                <div className="card card-body p-5">
                  <div className="row pt-2 bg-light">
                    <div className="col text-left">
                      <strong>Record ID: </strong>
                      {data['Hotline Record ID']}
                    </div>
                    <div className="col text-right">
                      {data['RFQ Status'] === 'Active' && (
                        <>
                          <span className="text-success">● </span>
                          Active
                        </>
                      )}
                      {data['RFQ Status'] === 'Quoted' && (
                        <>
                          <span className="text-success">● </span>
                          Quoted
                        </>
                      )}
                      {data['RFQ Status'] === 'Unqualified' && (
                        <>
                          <span className="text-warning">● </span>
                          Unqualified
                        </>
                      )}
                      {data['RFQ Status'] === 'Expired' && (
                        <>
                          <span className="text-danger">● </span>
                          Expired
                        </>
                      )}
                    </div>
                  </div>

                  <div className="row bg-light pt-4">
                    <div className="col text-center">
                      <h2 className="mb-2">Request For {data['Product Type']}</h2>
                      <strong className="text-uppercase">
                        Opportunity: <span>{data['Revenue Opportunity']}</span>
                      </strong>
                      {data['RFQ Status'] === 'Active' ||
                      (data['RFQ Status'] === 'Quoted' && data['All Quoted'] === false) ? (
                        <p className="mt-0 mb-0">
                          Expires in:{' '}
                          <span className="">
                            <Countdown
                              start_time={new Date(data['Start Time'][0])}
                              interval={48}
                              callback={() => {
                                history.push(`/seller-rfqs?id=${this.props.data['Supplier Record ID']}`);
                              }}
                            />
                          </span>
                        </p>
                      ) : (
                        <p className="text-muted mb-0"></p>
                      )}
                      <p className="mt-0 mb-0">
                        <span className="text-uppercase ">Best Quote: {data['Min Quote from Offers']}</span>
                      </p>
                      <p className="mt-0 mb-6">
                        # of offers : <span className="">{data['NoOfOffers']}</span>
                      </p>
                    </div>
                  </div>
                  <hr />
                  {(data['RFQ Status'] === 'Active' ||
                    (data['RFQ Status'] === 'Quoted' && data['All Quoted'] === false)) && (
                    <>
                      <div className="row mb-5 mt-3">
                        <form onSubmit={this.viewRfqButtonHandler} style={{ display: 'contents' }}>
                          <div className="col-12 ">
                            <strong className="text-body">
                              Manufacturer/Product Model
                              <span className="text-danger">*</span>:
                            </strong>
                            <br />
                            <select className="browser-default custom-select">
                              {Array.isArray(data['qualifiedVettedProducts']) &&
                                data['qualifiedVettedProducts'].map((p, index) => {
                                  return (
                                    <option value={p['Product Record ID']} key={index}>
                                      {p['Manufacturer']} / {p['Product Model']}
                                    </option>
                                  );
                                })}
                            </select>
                            <button className="btn btn-md btn-primary mt-4">Create Offer</button>
                          </div>
                        </form>
                        {/* <div className="col-12 col-md-6 text-md-right">
                            <div className="card mt-3">
                              <div className="card-body">
                                <div className="row justify-content-between">
                                  <div className="col-12 text-center "></div>
                                </div>
                                <div className="row mt-2 justify-content-between">
                                  <div className="col-12 text-center">
                                    <span className="h4 text-uppercase mb-2">
                                      # of offers :{" "}
                                      <span className="h2 mb-0 ">
                                        {data["NoOfOffers"]}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                      </div>
                      <hr />
                    </>
                  )}

                  {data['RFQ Status'] === 'Quoted' && data['yourOffers'] && data['yourOffers'].length && (
                    <>
                      <div className="row justify-content-between align-items-center mb-2">
                        <div className="col-12 col-md-9 col-xl-7">
                          <h2 className="mb-2">Offers</h2>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          {/* <!-- List group --> */}
                          <div className="list-group list-group-flush my-n3">
                            {data['yourOffers'].map((offer, index) => {
                              return (
                                <div key={index} className="list-group-item">
                                  <div className="row align-items-center">
                                    <div className="col-auto"></div>
                                    <div className="col ml-n2">
                                      <span className="mb-1">
                                        <span className="h4">${offer['Quoted Total']}</span> at ${offer['Quoted Price']}
                                        /unit (quantity of {offer['Quoted Quantity'][0]}
                                        ), delivered within{' '}
                                        {offer['Quoted Delivery By'][0] === '0'
                                          ? 'ASAP'
                                          : offer['Quoted Delivery By'][0] === '1'
                                          ? `${offer['Quoted Delivery By'][0]} day`
                                          : `${offer['Quoted Delivery By'][0]} days`}
                                      </span>
                                    </div>
                                    <div className="col-auto">
                                      {/* <!-- Button --> */}
                                      <a
                                        href={`/offer-details?id=${offer['Supplier Record ID'][0]}&rfqId=${offer['Record ID']}`}
                                      >
                                        View Offer
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          {/* <!-- List group --> */}
                        </div>
                      </div>
                      <hr />
                    </>
                  )}
                  <div className="row mt-5">
                    <div className="col-12 col-md-6">
                      <strong className="text-body">Organization Type:</strong>
                    </div>
                    <div className="col-12 col-md-6 text-md-right">
                      <p className="text-body"> {data['Org Type']}</p>
                    </div>
                    <div className="col-12 col-md-6">
                      <strong className="text-body">Max Price Per Unit:</strong>
                    </div>
                    <div className="col-12 col-md-6 text-md-right">
                      <p className="text-body"> {data['Max Price']}</p>
                    </div>
                    <div className="col-12 col-md-6">
                      <strong className="text-body">Quantity:</strong>
                    </div>
                    <div className="col-12 col-md-6 text-md-right">
                      <p className="text-body">{data['Quantity']} </p>
                    </div>
                    <div className="col-12 col-md-6">
                      <strong className="text-body">Needed by:</strong>
                    </div>
                    <div className="col-12 col-md-6 text-md-right">
                      <p className="text-body">{this.getTimeline(data['Timeline'])}</p>
                    </div>
                    <div className="col-12 col-md-6">
                      <strong className="text-body">Ship to Zipcode:</strong>
                    </div>
                    <div className="col-12 col-md-6 text-md-right">
                      <p className="text-body"> {data['Zipcode']}</p>
                    </div>
                    <div className="col-12 col-md-6">
                      <strong className="text-body">Product Specification:</strong>
                    </div>
                    <div className="col-12 col-md-6 text-md-right">
                      <p className="text-body"> {data['Product Spec']}</p>
                    </div>
                    <div className="col-12 col-md-6">
                      <strong className="text-body">Size:</strong>
                    </div>
                    <div className="col-12 col-md-6 text-md-right">
                      <p className="text-body"> {data['Size'] ? data['Size'] : '-'}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    },
  ),
);

import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { state } from '../../store';
import { dealPageSelector, dealPageActions } from './store';
require('react-bootstrap/ModalHeader');
export const InquiryPopup = connect(
  state.select({
    data: dealPageSelector((state) => state.data, []),
    notes: dealPageSelector((state) => state.notes, ''),
    tos: dealPageSelector((state) => state.tos, ''),
    fieldError: dealPageSelector((state) => state.fieldError, ''),
  }),
  state.actions(dealPageActions, [
    'dealPageData',
    'setShowModal',
    'submitInquiry',
    'inquiryFieldsChangeHandler',
    'tosCheckChangeHandler',
    'resetInquiryPopup',
  ]),
)(
  class InquiryPopup extends React.PureComponent {
    //click check -->processing
    constructor(props) {
      super(props);
      this.state = {
        submitClick: false,
      };
    }
    submit = (event) => {
      event.preventDefault();
      const { submitInquiry } = this.props;
      const { notes, tos, data } = this.props;
      let ID = data.ID.toString();
      let payload = {
        actionType: 'Inquiry',
        offerId: ID,
        notes: notes,
        TOS: tos,
      };
      this.setState({ submitClick: true });
      submitInquiry(payload);
    };
    validation = (fieldLabel) => (e) => {
      let payload = {
        fieldLabel: fieldLabel,
        value: e.target.value,
      };
      this.props.inquiryFieldsChangeHandler(payload);
    };
    render() {
      const { fieldError, tos } = this.props;
      return (
        <div className="card">
          <Modal
            show={this.props.show}
            onHide={() => {
              this.setState({ submitClick: false });
              this.props.resetInquiryPopup();
              this.props.onHide();
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <form onSubmit={this.submit}>
              <Modal.Header closeButton className="card-header ">
                <Modal.Title id="contained-modal-title-vcenter">
                  <h3 className="card-header-title pt-3">PRODUCT INQUIRY</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label className="col-form-label">
                    We're here to support your purchasing questions. To help us better understand your needs, what
                    additional information are you looking for prior to making a purchasing decision?
                  </label>
                  <textarea
                    required
                    id="modalNotes"
                    className={`form-control ${fieldError.notes}`}
                    onChange={this.validation('notes')}
                  ></textarea>
                </div>
                <div className="form-group">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="modalTermsAndConditions"
                      required
                      checked={tos}
                      onChange={(e) => this.props.tosCheckChangeHandler()}
                    />
                    <label className="form-check-label">
                      I agree to the Marketplace&nbsp;
                      <a href="https://www.projectn95.org/market-terms">terms and conditions</a>
                    </label>
                  </div>
                </div>
                <hr />
                <div>
                  <button className="btn btn-primary float-right donate mb-3">
                    {this.state.submitClick === false ? (
                      `Submit`
                    ) : (
                      <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{' '}
                        Processing...
                      </>
                    )}
                  </button>
                </div>
              </Modal.Body>
            </form>
          </Modal>
        </div>
      );
    }
  },
);

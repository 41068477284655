import { putResolve, call, spawn, take } from 'redux-saga/effects';
import { api } from '../app/api';
import { landingPageActions } from './store';

function* getUserByEmailSaga() {
  while (true) {
    try {
      const { payload: email } = yield take(landingPageActions.getUserByEmail().type);

      yield putResolve(landingPageActions.setLoading(true));

      const apiData = yield call(api, `/.netlify/functions/get-user-by-email?email=${email}`);

      yield putResolve(landingPageActions.setLoading(false));

      if (apiData.error) {
        yield putResolve(landingPageActions.setError(apiData.error));
      }

      if (apiData.error == null) {
        yield putResolve(landingPageActions.loadUser(apiData.data));
      }
    } catch (err) {
      console.log(err);

      yield putResolve(landingPageActions.setLoading(false));
      yield putResolve(landingPageActions.setError('Something went wrong'));
    }
  }
}

export default function* landingPageRootSaga() {
  yield spawn(getUserByEmailSaga);
}

import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import { state } from '../../store';
import { rfqPageSelector, rfqPageActions } from './store';
import { Countdown } from '../../components-library/countDown';
import OutsideClickHandler from 'react-outside-click-handler';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import { appSelector } from '../../app/store';
import { buyerProfileSelector, buyerProfileActions } from '../buyer-profile/store';
import ErrorPage from '../../components-library/error-page';

export const CommanTab = withRouter(
  connect(
    state.select({
      filteredRfq: rfqPageSelector((state) => state.filteredRfq, {
        active: [],
        won: [],
        noOffers: [],
        expired: [],
      }),
      data: rfqPageSelector((state) => state.data, {
        active: [],
        won: [],
        noOffers: [],
        expired: [],
      }),
      rfqPageDetails: rfqPageSelector((state) => state.rfqPageDetails, []),
      rfqsFilterFields: rfqPageSelector((state) => state.rfqsFilterFields),
      selectProduct: rfqPageSelector((state) => state.selectProduct),
      paramId: appSelector((state) => state.paramId, ''),
      createRFQLoading: rfqPageSelector((state) => state.createRFQLoading, false),
      notVerified: buyerProfileSelector((state) => state.notVerified),
      qualified: buyerProfileSelector((state) => state.qualified),
    }),
    state.actions(
      rfqPageActions,
      ['searchRfqs', 'sortRfq', 'filterChangeHandler', 'applyFilter', 'resetFilter', 'setCreateRFQLoading'],
      buyerProfileActions,
      ['setShowNotVerifiedModal', 'setShowNotQualifiedModal'],
    ),
  )(
    class CommanTab extends PureComponent {
      state = {
        filterOpen: false,
      };
      resetHandler = (event) => {
        this.setState({ filterOpen: false });
        this.props.resetFilter(this.props.currentTab);
        this.props.applyFilter(this.props.currentTab);
      };
      searchFilteredRfq = (event) => {
        this.props.searchRfqs({ event, value: this.props.currentTab });
        this.props.applyFilter(this.props.currentTab);
      };
      render() {
        const {
          data,
          filteredRfq,
          rfqsFilterFields,
          history,
          currentTab = 'active',
          paramId,
          createRFQLoading,
          notVerified,
          qualified,
        } = this.props;

        //actions
        const { sortRfq, setShowNotVerifiedModal, setShowNotQualifiedModal } = this.props;

        let filterDDClassName = '';
        let filterDivClassName = '';
        if (this.state.filterOpen) {
          filterDDClassName = 'dropdown show';
          filterDivClassName = 'dropdown-menu dropdown-menu-right dropdown-menu-card show dropdownOpen';
        } else {
          filterDDClassName = 'dropdown';
          filterDivClassName = 'dropdown-menu dropdown-menu-right dropdown-menu-card ';
        }
        let rfqTable = (
          <div className="table-responsive">
            <table className="table table-sm table-hover table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <span className="list-sort text-muted">Record ID</span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted cursorTospan"
                      data-sort="item-name"
                      onClick={() => {
                        sortRfq({
                          currentTab: currentTab,
                          type: 'productName',
                        });
                      }}
                    >
                      Product
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted cursorTospan"
                      data-sort={currentTab === 'won' || currentTab === 'active' ? 'item-name' : undefined}
                      onClick={
                        currentTab === 'won' || currentTab === 'active'
                          ? () => {
                              sortRfq({
                                currentTab: currentTab,
                                type: 'status',
                              });
                            }
                          : undefined
                      }
                    >
                      Status
                    </span>
                  </th>

                  <th>
                    <span
                      className="list-sort text-muted cursorTospan"
                      data-sort="item-email"
                      onClick={() => {
                        sortRfq({ currentTab: currentTab, type: 'askPrice' });
                      }}
                    >
                      Ask Price
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted cursorTospan"
                      data-sort="item-phone"
                      onClick={() => {
                        sortRfq({ currentTab: currentTab, type: 'quantity' });
                      }}
                    >
                      Quantity
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted cursorTospan"
                      data-sort="item-score"
                      onClick={() => {
                        sortRfq({ currentTab: currentTab, type: 'timing' });
                      }}
                    >
                      Timeline
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted cursorTospan"
                      data-sort="item-company"
                      onClick={() => {
                        sortRfq({ currentTab: currentTab, type: 'bids' });
                      }}
                    >
                      Offers
                    </span>
                  </th>
                  <th>
                    <span className="text-muted">Close In</span>
                  </th>
                  <th></th>
                </tr>
              </thead>

              {filteredRfq[currentTab].length ? (
                <tbody className="list font-size-base">
                  {filteredRfq[currentTab].map((rfq, index) => {
                    return (
                      <tr
                        className="cursorTospan"
                        key={index}
                        onClick={(e) => {
                          history.push(`/offer?id=${rfq['Record ID']}`);
                        }}
                      >
                        <td className="tables-row">{rfq['Record ID']}</td>
                        <td className="tables-row">{rfq['Product Type']}</td>

                        {rfq['Status'] === 'Live RFQ' && rfq['Call Completed at'] ? (
                          <td className="goal-status">
                            <span className="text-success">●&nbsp;</span>
                            Live
                          </td>
                        ) : null}

                        {rfq['Status'] === 'Live RFQ Pending' && (
                          <td className="goal-status">
                            <span className="text-secondary">●&nbsp;</span>
                            Pending
                          </td>
                        )}

                        {rfq['Status'] === 'No Offers RFQ' ? (
                          <td className="goal-status">
                            <span className="text-danger">●&nbsp;</span>
                            No Offers
                          </td>
                        ) : null}

                        {rfq['Status'] === 'Live Offer' ? (
                          <td className="goal-status">
                            <span className="text-success">●&nbsp;</span>
                            Live
                          </td>
                        ) : null}

                        {rfq['Status'] === 'Purchased' ? (
                          <td className="goal-status">
                            <span className="text-primary">●&nbsp;</span>
                            {rfq['Status']}
                          </td>
                        ) : null}

                        {rfq['Status'] === 'Unpaid' ? (
                          <td className="goal-status">
                            <span className="text-warning">●&nbsp;</span>
                            {rfq['Status']}
                          </td>
                        ) : null}

                        {rfq['Status'] === 'Expired' ? (
                          <td className="goal-status">
                            <span className="text-danger">●&nbsp;</span>
                            {rfq['Status']}
                          </td>
                        ) : null}
                        <td className="tables-last">{rfq['Ask Price']}</td>
                        <td className="tables-handle">{rfq['Quantity'].toLocaleString()}</td>
                        <td className="tables-handle">{rfq['Timing']}</td>

                        <td className="tables-handle">
                          {rfq['Status'] === 'Live Offer' || rfq['Status'] === 'Expired' ? rfq.offerCount : '-'}
                        </td>

                        <td className="tables-handle">
                          {rfq['Status'] === 'Live RFQ' && rfq['Call Completed at'] ? (
                            <span className="badge mark-span span-font">
                              <Countdown
                                start_time={rfq['Call Completed at']}
                                interval={48}
                                callback={() => {
                                  console.log('timer expired');
                                }}
                              />
                            </span>
                          ) : null}

                          {rfq['Status'] === 'Live Offer' && rfq['Offer Start Time'] ? (
                            <span className="badge mark-span span-font">
                              <Countdown start_time={rfq['Offer Start Time']} interval={48} />
                            </span>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : null}
            </table>
            {filteredRfq[currentTab].length === 0 && (
              <div className="card mb-0">
                <div className="card-body">
                  <div className="row no-gutters align-items-center justify-content-center">
                    <h6 className="text-uppercase text-center text-muted my-4">No RFQs Found</h6>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
        //for the empty state (create rfq button)
        let rfqTableWithRfqButton = (
          <div className="table-responsive">
            <table className="table table-sm table-hover table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <span className="list-sort text-muted">Record ID</span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted cursorTospan"
                      data-sort="item-name"
                      onClick={() => {
                        sortRfq({
                          currentTab: currentTab,
                          type: 'productName',
                        });
                      }}
                    >
                      Product
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted cursorTospan"
                      data-sort={currentTab === 'won' ? 'item-name' : undefined}
                      onClick={
                        currentTab === 'won'
                          ? () => {
                              sortRfq({
                                currentTab: currentTab,
                                type: 'status',
                              });
                            }
                          : undefined
                      }
                    >
                      Status
                    </span>
                  </th>

                  <th>
                    <span
                      className="list-sort text-muted cursorTospan"
                      data-sort="item-email"
                      onClick={() => {
                        sortRfq({ currentTab: currentTab, type: 'askPrice' });
                      }}
                    >
                      Ask Price
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted cursorTospan"
                      data-sort="item-phone"
                      onClick={() => {
                        sortRfq({ currentTab: currentTab, type: 'quantity' });
                      }}
                    >
                      Quantity
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted cursorTospan"
                      data-sort="item-score"
                      onClick={() => {
                        sortRfq({ currentTab: currentTab, type: 'timing' });
                      }}
                    >
                      Timeline
                    </span>
                  </th>
                  <th>
                    <span
                      className="list-sort text-muted cursorTospan"
                      data-sort="item-company"
                      onClick={() => {
                        sortRfq({ currentTab: currentTab, type: 'bids' });
                      }}
                    >
                      Offers
                    </span>
                  </th>
                  <th>
                    <span className="text-muted">Close In</span>
                  </th>
                  <th></th>
                </tr>
              </thead>
            </table>
            {data[currentTab].length === 0 && (
              <div className="card mb-0">
                <div className="card-body">
                  <div className="row no-gutters align-items-center justify-content-center">
                    <h6 className="text-uppercase text-center text-muted my-4">No RFQs Found</h6>
                  </div>
                  <div className="row no-gutters align-items-center justify-content-center">
                    <button
                      type="button"
                      className="btn btn-primary btn-lg text-uppercase mb-4 "
                      onClick={(e) => {
                        if (notVerified) {
                          setShowNotVerifiedModal(true);
                        } else if (!qualified) {
                          setShowNotQualifiedModal(true);
                        } else {
                          this.props.history.push('/create-rfq?id=' + paramId);
                        }
                      }}
                    >
                      {createRFQLoading === false ? (
                        <strong>+ REQUEST FOR QUOTE</strong>
                      ) : (
                        <>
                          <strong>+ REQUEST FOR QUOTE</strong>&nbsp;
                          <div className="spinner-border spinner-border-sm align-middle" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
        return (
          <div className="card">
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col">
                  {/* <!-- Form --> */}
                  <form>
                    <div className="input-group input-group-flush">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fe fe-search"></i>
                        </span>
                      </div>
                      <input
                        className="list-search form-control"
                        type="search"
                        placeholder="Search"
                        onChange={this.searchFilteredRfq}
                      />
                    </div>
                  </form>
                </div>
                <div className="col-auto mr-n3"></div>

                <OutsideClickHandler
                  onOutsideClick={(e) => {
                    this.setState({
                      filterOpen: false,
                    });
                  }}
                >
                  <div className="col-auto">
                    {/* <!-- Dropdown --> */}
                    <div className={filterDDClassName}>
                      {/* <!-- Toggle --> */}
                      <button
                        className="btn btn-sm btn-white"
                        type="button"
                        onClick={(e) => {
                          this.setState({
                            filterOpen: !this.state.filterOpen,
                          });
                        }}
                      >
                        <i className="fe fe-sliders mr-1"></i> Filter{' '}
                        <span className="badge badge-primary ml-1 d-none">0</span>
                      </button>

                      {/* <!-- Menu --> */}

                      <div className={filterDivClassName}>
                        <div className="card-header">
                          {/* <!-- Title --> */}
                          <h4 className="card-header-title">Filters</h4>

                          {/* <!-- Link --> */}
                          <button
                            className="btn btn-sm btn-link text-reset"
                            onClick={(e) => {
                              this.resetHandler();
                            }}
                          >
                            Clear filters
                          </button>
                        </div>
                        <div className="card-body">
                          {/* <!-- List group --> */}
                          <div className="list-group list-group-flush mt-n4 mb-4">
                            <div className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  {/* <!-- Text --> */}
                                  <small>Product Type</small>
                                </div>
                                <div className="col-6">
                                  {/* <!-- Select --> */}
                                  <select
                                    className="custom-select custom-select-sm"
                                    onChange={(e) => {
                                      let payload = {
                                        fieldName: 'productName',
                                        value: e.target.value,
                                        currentTab: currentTab,
                                      };
                                      this.props.filterChangeHandler(payload);
                                    }}
                                    value={
                                      rfqsFilterFields[currentTab].productName === ''
                                        ? '*'
                                        : rfqsFilterFields[currentTab].productName
                                    }
                                  >
                                    {rfqsFilterFields[currentTab].productName === '' && (
                                      <option value="*" disabled>
                                        Select
                                      </option>
                                    )}
                                    {Array.isArray(this.props.selectProduct) &&
                                      this.props.selectProduct.map((item) => {
                                        return (
                                          <option value={item} key={item}>
                                            {item}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                              </div>
                              {/* <!-- / .row --> */}
                            </div>
                          </div>

                          {/* <!-- Button --> */}
                          <button
                            className="btn btn-block btn-primary"
                            onClick={(e) => {
                              this.setState({ filterOpen: false });
                              this.props.applyFilter(currentTab);
                            }}
                          >
                            Apply filter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </OutsideClickHandler>
              </div>

              {/* <!-- / .row --> */}
            </div>
            {data[currentTab].length === 0 ? rfqTableWithRfqButton : rfqTable}
          </div>
        );
      }
    },
  ),
);

export const BuyerRfqPage = connect(
  state.select({
    rfqPageDetails: rfqPageSelector((state) => state.rfqPageDetails, []),
    data: rfqPageSelector((state) => state.data, []),
    loading: rfqPageSelector((state) => state.loading),
    error: appSelector((state) => state.error),
  }),
  state.actions(rfqPageActions, ['rfqPageData']),
)(
  class BuyerRfqPage extends Component {
    componentWillMount() {
      const { query } = this.props;
      let id = query.get('id');
      this.props.rfqPageData(id);
    }

    render() {
      const { loading, error, data, rfqPageDetails } = this.props;
      if (loading) {
        return (
          <>
            <div className="header">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-end">
                    <div className="col">
                      <h1 className="header-title">Request For Quotes</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className="container page-content pb-6 market-content text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </section>
          </>
        );
      }

      if (error) {
        return <ErrorPage error={error} callUsAt={true} />;
      }

      return (
        <>
          <div className="header">
            <div className="container-fluid">
              <div className="header-body">
                <div className="row align-items-end">
                  <div className="col">
                    <h1 className="header-title">Request For Quotes</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row bg-white no-gutters ">
              <div className="col-6 py-4 text-center">
                <h5 className="text-uppercase text-muted">Planned</h5>

                <h1 className="mb-0">${rfqPageDetails.MarketPlanned}</h1>
              </div>
              <div className="col-6 py-4 text-center border-left">
                <h5 className="text-uppercase text-muted">Spend</h5>

                <h1 className="mb-0">${rfqPageDetails.MarketPurchased}</h1>
              </div>
            </div>
            <Tabs defaultActiveKey="Active" className="nav mb-3 ml-2">
              <Tab eventKey="Active" title={`Active (${data.active.length})`}>
                <CommanTab currentTab="active" />
              </Tab>
              <Tab eventKey="Won" title={`Purchased (${data.won.length})`}>
                <CommanTab currentTab="won" />
              </Tab>
              <Tab eventKey="No Offers" title={`No Offers (${data.noOffers.length})`}>
                <CommanTab currentTab="noOffers" />
              </Tab>
              <Tab eventKey="Expired" title={`Expired (${data.expired.length})`}>
                <CommanTab currentTab="expired" />
              </Tab>
            </Tabs>
          </div>
        </>
      );
    }
  },
);

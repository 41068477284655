import React, { Component } from 'react';
export default class Page500 extends Component {
  render() {
    return (
      <div className="container pt-5">
        <div className="row justify-content-center pt-5">
          <div className="col-12 col-md-5 col-xl-4 my-5 pt-5">
            <div className="text-center pt-5">
              <h6 className="text-uppercase text-muted mb-4">500 error</h6>
              <h1 className="display-4 mb-3">
                Something went wrong{' '}
                <span role="img" aria-label="img">
                  😭
                </span>
              </h1>
              <p className="text-muted mb-4">
                Need help with something? Reach out to{' '}
                <a href="mailto:hotline@projectn95.org" target="_blank" rel="noopener noreferrer">
                  hotline@projectn95.org
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

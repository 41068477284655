import { state } from '../../store';

export const [signupPageActions, signupPageSelector, signupPageState] = state('SignupPage', {
  initial: {
    user: null,
    loading: false,
    error: '',
  },
  on: {
    setUser: (_, data) => {
      let newStoreObj = { ..._, user: data.user };

      return newStoreObj;
    },
    setLoading: (_, data) => {
      let newStoreObj = { ..._, loading: data };

      return newStoreObj;
    },
    setError: (_, data) => {
      let newStoreObj = { ..._, error: data };

      return newStoreObj;
    },
  },
  events: ['signupBuyer'],
});

import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export class PieChart extends React.PureComponent {
  prepareData = (data) => {
    return [
      {
        name: 'Sold Count',
        colorByPoint: true,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return Math.round(this.percentage * 100) / 100 + ' %';
          },
          distance: -30,
          color: 'black',
          style: '{fill: transparent;stroke: transparent;}',
        },
        data:
          data && data.length
            ? data.map((prodctType) => {
                return {
                  name: prodctType.name,
                  y: prodctType.count,
                };
              })
            : [],
      },
    ];
  };

  render() {
    const { data, width } = this.props;
    let seriesData = this.prepareData(data);
    const options = {
      colors: ['#2c7be5', '#ff7a4d', '#974EFF', '#00A8B5', '#FFC231', '#E55A5A'],
      title: {
        text: '',
        style: {
          fontWeight: 'bold',
          color: 'black',
          fontFamily: '"Cerebri Sans",sans-serif',
        },
      },
      credits: {
        enabled: false,
      },
      series: seriesData,
      chart: {
        backgroundColor: '#63709900',
        type: 'pie',
        style: {
          fontFamily: 'Cerebri Sans',
        },
        width: width,
        height: 250,
        plotShadow: false,
      },

      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
    };
    return <HighchartsReact immutable={true} highcharts={Highcharts} options={options} />;
  }
}

import { putResolve, call, spawn, take } from 'redux-saga/effects';
import { api } from '../../app/api';
import { stripeConnectOauthPageActions } from './store';
import { appActions } from '../../app/store';

function* stripeConnectOauthPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(stripeConnectOauthPageActions.stripeConnectOauthPageData().type);

      let result = yield call(api, `/.netlify/functions/stripe-connect-oauth?id=${data}`);

      if (result && result.data) {
        setTimeout(function () {
          window.location.href = result.data;
        }, 3000);
      }
      yield putResolve(stripeConnectOauthPageActions.setLoading(false));
      if (result.error) {
        yield putResolve(appActions.setError(result.error));
        yield putResolve(appActions.hideNav());
      }
    } catch (err) {
      console.log(err);
      yield putResolve(appActions.hideNav());
      yield putResolve(stripeConnectOauthPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}
export default function* StripeConnectOauthPageRootSaga() {
  yield spawn(stripeConnectOauthPageSaga);
}

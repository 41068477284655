import { putResolve, call, spawn, take } from 'redux-saga/effects';
import { api } from '../../app/api';
import { sellerDashboardActions } from './store';
import { appActions } from '../../app/store';

function* sellerDashboardPageSaga() {
  while (true) {
    try {
      const { payload: id } = yield take(sellerDashboardActions.loadDashboardData().type);

      let apiData = yield call(api, `/.netlify/functions/seller-dashboard?id=${id}`);

      if (apiData.error) {
        yield putResolve(appActions.setError(apiData.error));
        yield putResolve(sellerDashboardActions.setLoading(false));
        yield putResolve(appActions.hideNav());
        return;
      }

      if (apiData.data && apiData.error === null) {
        yield putResolve(sellerDashboardActions.setDashboardData(apiData.data));
        yield putResolve(sellerDashboardActions.setLoading(false));
        return;
      }
    } catch (err) {
      console.log(err);
      yield putResolve(appActions.hideNav());
      yield putResolve(sellerDashboardActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export default function* sellerDashboardPageRootSaga() {
  yield spawn(sellerDashboardPageSaga);
}

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { state } from '../../store';
import { withRouter } from 'react-router-dom';
import { PieChart } from './pie-chart';
// import { SpendPatternChart } from './timeseries-chart';
import { buyerDashboardSelector, buyerDashboardActions } from './store';
// import { auth } from 'firebase-admin';
import ErrorPage from '../../components-library/error-page';
import { appSelector } from '../../app/store';

export const BuyerDashboardPage = withRouter(
  connect(
    state.select({
      data: buyerDashboardSelector((state) => state.data, {}),
      loading: buyerDashboardSelector((state) => state.loading),
      error: appSelector((state) => state.error),
    }),
    state.actions(buyerDashboardActions, ['loadDashboardData']),
  )(
    class BuyerDashboardPage extends PureComponent {
      // eslint-disable-next-line camelcase
      UNSAFE_componentWillMount() {
        const { query } = this.props;
        let id = query.get('id');
        this.props.loadDashboardData(id);
      }
      render() {
        const { loading, error, data } = this.props;
        if (loading) {
          return (
            <>
              <div className="header">
                <div className="container-fluid">
                  <div className="header-body" style={{ border: '0px' }}>
                    <div className="row align-items-end">
                      <div className="col">
                        <h1 className="header-title">Dashboard</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section className="container page-content pb-6 market-content text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </section>
            </>
          );
        }
        if (error) {
          return <ErrorPage error={error} />;
        }
        return (
          <>
            <div className="header mb-1">
              <div className="container-fluid">
                <div className="header-body" style={{ border: '0px' }}>
                  <div className="row align-items-end">
                    <div className="col">
                      <h1 className="header-title">Dashboard</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid d-flex flex-column">
              <div className="row">
                <div className="col-12 col-md-6 col-xl-6 d-flex flex-column">
                  <div className="card  h-50">
                    <div className="card-body p-md-1 d-flex align-items-center justify-content-center">
                      <div className="row align-items-center text-center">
                        <div className="col">
                          <h6 className="text-uppercase h4 text-muted mb-2">Total Spend</h6>
                          <span className="h1 mb-0">${data.totalSpend}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card h-50">
                    <div className="card-body p-md-1  d-flex align-items-center justify-content-center">
                      <div className="row align-items-center text-center">
                        <div className="col">
                          <h6 className="text-uppercase h4 text-muted mb-2">Total Planned</h6>
                          <span className="h1 mb-0">${data.totalPlanned}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 h-50 col-md-6 col-xl-6">
                  <div className="card h-75">
                    <div className="card-header p-0 pl-3">
                      <h4 className="card-header-title">Product Types Purchased</h4>
                    </div>
                    <div className="card-body p-1">
                      <PieChart data={data.productTypesPurchased} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-12 col-md-6 col-xl-6">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-header-title">Spent Pattern</h4>
                    </div>
                    <div className="card-body">
                      <SpendPatternChart seriesData={data.timeline} />
                    </div>
                  </div>
                </div> */}
                <div className="col-12">
                  <div className="card">
                    <div className="card-header border-bottom-0">
                      <h4 className="card-header-title">Most Recent Purchases</h4>
                    </div>
                    <div className="card-body pt-0 pr-0">
                      <div className="table-responsive table-dashboard">
                        <table className="table  table-sm table-nowrap card-table table-data">
                          <thead>
                            <tr>
                              <th>
                                <span className="text-muted">Product</span>
                              </th>
                              <th>
                                <span className="text-muted">Quantity</span>
                              </th>
                              <th>
                                <span className="text-muted">Total Cost</span>
                              </th>
                              <th>
                                <span className="text-muted">Purchased On</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list font-size-base">
                            {data.recentPurchases && data.recentPurchases.length ? (
                              data.recentPurchases.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="tables-row">{item.productType}</td>
                                    <td className="tables-row">{item.quantity}</td>
                                    <td className="tables-row">${item.totalAmount}</td>
                                    <td className="tables-row">{item.date}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td className="tables-row" colSpan="4">
                                  <div className="col ml-n2">
                                    <h4 className="mb-1 text-center">No Recent Purchases Found</h4>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
    },
  ),
);

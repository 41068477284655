import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { state } from '../../store';
import { addProductPageActions, addProductPageSelector } from './store';
import { appSelector } from '../../app/store';
import { Dropdown } from '../../components-library/dropDown';
import { FileInput } from '../../components-library/fileInput';
import firebase from 'firebase';
import styled from 'styled-components';
import ErrorPage from '../../components-library/error-page';

const RemoveFile = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  font-size: 10px;
  margin-left: 5px;
  svg {
    font-size: 8px;
    font-weight: 500;
    align-items: center;
    justify-self: center;
    height: 1em;
    width: 1em;
    fill: #6e7d90;
    :hover {
      fill: black;
    }
  }
`;

const countryOfManufactureOption = [
  { value: 'CA', label: 'CA' },
  { value: 'CN', label: 'CN' },
  { value: 'DE', label: 'DE' },
  { value: 'JP', label: 'JP' },
  { value: 'KR', label: 'KR' },
  { value: 'MX', label: 'MX' },
  { value: 'Other', label: 'Other' },
  { value: 'PK', label: 'PK' },
  { value: 'TW', label: 'TW' },
  { value: 'TY', label: 'TY' },
  { value: 'VN', label: 'VN' },
  { value: 'US', label: 'US' },
];

const countryType = countryOfManufactureOption.map((country) => ({
  ...country,
  label: country.value,
  value: country.value,
}));

const productModelOptions = [
  {
    label: '',
    value: '',
  },
  {
    label: '1860',
    value: '1860',
  },
  {
    label: 'ACM812',
    value: 'ACM812',
  },
  {
    label: 'AEON A200 IR THERMOMETER',
    value: 'AEON A200 IR THERMOMETER',
  },
  {
    label: 'AFIAS COVID-19 Ab (with AFIAS-1 analyzer)',
    value: 'AFIAS COVID-19 Ab (with AFIAS-1 analyzer)',
  },
  {
    label: 'AKF2002',
    value: 'AKF2002',
  },
  {
    label: 'AMC812',
    value: 'AMC812',
  },
  {
    label: 'AMV-2000',
    value: 'AMV-2000',
  },
  {
    label: 'Astral 100/150',
    value: 'Astral 100/150',
  },
  {
    label: 'Avea ',
    value: 'Avea ',
  },
  {
    label: 'B702, B702V, B704, B704',
    value: 'B702, B702V, B704, B704',
  },
  {
    label: 'BMC',
    value: 'BMC',
  },
  {
    label: 'BiPAP ST30',
    value: 'BiPAP ST30',
  },
  {
    label: 'CAL1000ISO',
    value: 'CAL1000ISO',
  },
  {
    label: 'CD950',
    value: 'CD950',
  },
  {
    label: 'CD9501',
    value: 'CD9501',
  },
  {
    label: 'CLARITY COVID-19 IgG/IgM Antibody Test',
    value: 'CLARITY COVID-19 IgG/IgM Antibody Test',
  },
  {
    label: 'COVID-19 (SARS-CoV-2) Nucleic Acid Test Kit',
    value: 'COVID-19 (SARS-CoV-2) Nucleic Acid Test Kit',
  },
  {
    label: 'COVID-19 IgG and IgM Antibody ',
    value: 'COVID-19 IgG and IgM Antibody ',
  },
  {
    label: 'COVID-19 IgG/IgM Rapid Test Casette (Whole Blood/Serum/Plasma)',
    value: 'COVID-19 IgG/IgM Rapid Test Casette (Whole Blood/Serum/Plasma)',
  },
  {
    label: 'COVID-19 Ten Minute IgG/IgM test',
    value: 'COVID-19 Ten Minute IgG/IgM test',
  },
  {
    label: 'CPFM-100, CPFM-101, LH-KN95',
    value: 'CPFM-100, CPFM-101, LH-KN95',
  },
  {
    label: 'Carescape R860',
    value: 'Carescape R860',
  },
  {
    label: 'Cleanmo',
    value: 'Cleanmo',
  },
  {
    label: 'DAAN Gene Co., Ltd. Of Sun Yat-sen University',
    value: 'DAAN Gene Co., Ltd. Of Sun Yat-sen University',
  },
  {
    label: 'DFFilter KN95',
    value: 'DFFilter KN95',
  },
  {
    label: 'DFLFS',
    value: 'DFLFS',
  },
  {
    label: 'DTC3B',
    value: 'DTC3B',
  },
  {
    label: 'DTC3X',
    value: 'DTC3X',
  },
  {
    label: 'DTC3X-1, DTC3X-2, DTC3X-3, DTC3B-1',
    value: 'DTC3X-1, DTC3X-2, DTC3X-3, DTC3B-1',
  },
  {
    label: 'DiaPlexQ Novel Coronavirus Detection Kit',
    value: 'DiaPlexQ Novel Coronavirus Detection Kit',
  },
  {
    label: 'DiaPlexQ Novel Coronavirus test kit',
    value: 'DiaPlexQ Novel Coronavirus test kit',
  },
  {
    label: 'Expia COVID-19 IgM/IgG Rapid Test',
    value: 'Expia COVID-19 IgM/IgG Rapid Test',
  },
  {
    label: 'FFP2 NR E-300, FFP2 NR E-680, FFP2 NR 952, FFP2 NR F-820, KN95 958, KN95 951',
    value: 'FFP2 NR E-300, FFP2 NR E-680, FFP2 NR 952, FFP2 NR F-820, KN95 958, KN95 951',
  },
  {
    label: 'FS-01',
    value: 'FS-01',
  },
  {
    label: 'GB 2626-2006',
    value: 'GB 2626-2006',
  },
  {
    label: 'GYD-002',
    value: 'GYD-002',
  },
  {
    label: 'GenBody COVID-19 IgM/IgG ',
    value: 'GenBody COVID-19 IgM/IgG ',
  },
  {
    label: 'GeneFinder™ COVID-19 Plus RealAmp Kit',
    value: 'GeneFinder™ COVID-19 Plus RealAmp Kit',
  },
  {
    label: 'KN95 FFP2',
    value: 'KN95 FFP2',
  },
  {
    label: 'KN95 Mask series',
    value: 'KN95 Mask series',
  },
  {
    label: 'KN95 N9',
    value: 'KN95 N9',
  },
  {
    label: 'KN95',
    value: 'KN95',
  },
  {
    label: 'L288',
    value: 'L288',
  },
  {
    label: 'LifeMask Niosh N95 Respirator',
    value: 'LifeMask Niosh N95 Respirator',
  },
  {
    label: 'Lyra SARS-CoV-2 Assay ',
    value: 'Lyra SARS-CoV-2 Assay ',
  },
  {
    label: 'MASK-104',
    value: 'MASK-104',
  },
  {
    label: 'MS6155',
    value: 'MS6155',
  },
  {
    label: 'MS8225',
    value: 'MS8225',
  },
  {
    label: 'MSH ',
    value: 'MSH ',
  },
  {
    label: 'Mether M-9501 EN149:2001 FFP2',
    value: 'Mether M-9501 EN149:2001 FFP2',
  },
  {
    label: 'Model No FS2020',
    value: 'Model No FS2020',
  },
  {
    label: 'N95',
    value: 'N95',
  },
  {
    label: 'N9500-N95',
    value: 'N9500-N95',
  },
  {
    label: 'NS-V1',
    value: 'NS-V1',
  },
  {
    label: 'Other',
    value: 'Other',
  },
  {
    label: 'PB560',
    value: 'PB560',
  },
  {
    label: 'PPE-FS100',
    value: 'PPE-FS100',
  },
  {
    label: 'Patient Vinyl Exam Glove, Non Sterile',
    value: 'Patient Vinyl Exam Glove, Non Sterile',
  },
  {
    label: 'Philips Respironics V60 ',
    value: 'Philips Respironics V60 ',
  },
  {
    label: 'QuantiVirus SARS-CoV-2 Test kit',
    value: 'QuantiVirus SARS-CoV-2 Test kit',
  },
  {
    label: 'R-T Fluorescent RT-PR Kit for detecting SARS-COV-2',
    value: 'R-T Fluorescent RT-PR Kit for detecting SARS-COV-2',
  },
  {
    label: 'RAPID-COV',
    value: 'RAPID-COV',
  },
  {
    label: 'RIZSQ100sb',
    value: 'RIZSQ100sb',
  },
  {
    label: 'S1100',
    value: 'S1100',
  },
  {
    label: 'S9030',
    value: 'S9030',
  },
  {
    label: 'SARS-CoV-2 Fluorescent PCR Kit',
    value: 'SARS-CoV-2 Fluorescent PCR Kit',
  },
  {
    label: 'SARS-CoV-2 IgG/IgM Rapid Qualitative ',
    value: 'SARS-CoV-2 IgG/IgM Rapid Qualitative ',
  },
  {
    label: 'SARS-CoV-2 IgM/IgG test kit （Colloidal gold Assay）',
    value: 'SARS-CoV-2 IgM/IgG test kit （Colloidal gold Assay）',
  },
  {
    label: 'SAS™ COVID-19 IgG and IgM Antibody ',
    value: 'SAS™ COVID-19 IgG and IgM Antibody ',
  },
  {
    label: 'SDKN series',
    value: 'SDKN series',
  },
  {
    label: 'SFS-1 face shield and KIT',
    value: 'SFS-1 face shield and KIT',
  },
  {
    label: 'SH300',
    value: 'SH300',
  },
  {
    label: 'SH9550 series',
    value: 'SH9550 series',
  },
  {
    label: 'SQ100Sb',
    value: 'SQ100Sb',
  },
  {
    label: 'SQ100sb',
    value: 'SQ100sb',
  },
  {
    label: 'SV300',
    value: 'SV300',
  },
  {
    label: 'Shangrila 510s',
    value: 'Shangrila 510s',
  },
  {
    label: 'Show-Me Face Shields DL79',
    value: 'Show-Me Face Shields DL79',
  },
  {
    label: 'Smart DetectTM SARS-CoV-2 rRT-PCR Kit (EUA)',
    value: 'Smart DetectTM SARS-CoV-2 rRT-PCR Kit (EUA)',
  },
  {
    label: 'TC-84A-5411',
    value: 'TC-84A-5411',
  },
  {
    label: 'Tell Me Fast',
    value: 'Tell Me Fast',
  },
  {
    label: 'Trilogy Series',
    value: 'Trilogy Series',
  },
  {
    label: 'V60',
    value: 'V60',
  },
  {
    label: 'VERI-Q PCR 316',
    value: 'VERI-Q PCR 316',
  },
  {
    label: 'VG70',
    value: 'VG70',
  },
  {
    label: 'VPAP ST',
    value: 'VPAP ST',
  },
  {
    label: 'Veri-Q',
    value: 'Veri-Q',
  },
  {
    label: 'Y-30T',
    value: 'Y-30T',
  },
  {
    label: 'YH-MFK-B95, YH-MFK-Z95',
    value: 'YH-MFK-B95, YH-MFK-Z95',
  },
  {
    label: 'YH830/YH730',
    value: 'YH830/YH730',
  },
  {
    label: 'YNOT000350, 51',
    value: 'YNOT000350, 51',
  },
  {
    label: 'ZS-A950',
    value: 'ZS-A950',
  },
  {
    label: 'ZYB-11',
    value: 'ZYB-11',
  },
  {
    label: 'microVent',
    value: 'microVent',
  },
  {
    label: 'qSARS-CoV-2 IgG/IgM Rapid Test',
    value: 'qSARS-CoV-2 IgG/IgM Rapid Test',
  },
  {
    label: '9500-N95',
    value: '9500-N95',
  },
];

export const AddProductPage = withRouter(
  connect(
    state.select({
      data: addProductPageSelector((state) => state.data, []),
      loading: addProductPageSelector((state) => state.loading),
      error: appSelector((state) => state.error, ''),
      sellerId: appSelector((state) => state.paramId, ''),
      productTypesList: addProductPageSelector((state) => state.productTypesList, []),
      productId: addProductPageSelector((state) => state.productId, ''),
      otherProductType: addProductPageSelector((state) => state.otherProductType, ''),
      productOutofStock: addProductPageSelector((state) => state.productOutofStock, ''),
      productModel: addProductPageSelector((state) => state.productModel, ''),
      productModelOther: addProductPageSelector((state) => state.productModelOther, ''),
      productPictures: addProductPageSelector((state) => state.productPictures, []),
      photoPublicationPermission: addProductPageSelector((state) => state.photoPublicationPermission, false),
      documentsAndCertification: addProductPageSelector((state) => state.documentsAndCertification, []),
      pricePerUnit: addProductPageSelector((state) => state.pricePerUnit, ''),
      baseUnit: addProductPageSelector((state) => state.baseUnit, ''),
      minOrderQuantity: addProductPageSelector((state) => state.minOrderQuantity, ''),
      quantityCurrentlyOnHand: addProductPageSelector((state) => state.quantityCurrentlyOnHand, ''),
      daysToDeliver: addProductPageSelector((state) => state.daysToDeliver, ''),
      upcCode: addProductPageSelector((state) => state.upcCode, ''),
      manufacturer: addProductPageSelector((state) => state.manufacturer, ''),
      otherManufacturer: addProductPageSelector((state) => state.otherManufacturer, ''),
      fdaRegistrationInfo: addProductPageSelector((state) => state.fdaRegistrationInfo, ''),
      countryOfManufacture: addProductPageSelector((state) => state.countryOfManufacture, []),
      manufacturerDocumentation: addProductPageSelector((state) => state.manufacturerDocumentation, []),
      supplierRequestedReviewCheck: addProductPageSelector((state) => state.supplierRequestedReviewCheck, false),
      vettingDecisionAppeal: addProductPageSelector((state) => state.vettingDecisionAppeal, ''),
      productDetailsData: addProductPageSelector((state) => state.productDetailsData, []),
      unlinkProductPictures: addProductPageSelector((state) => state.unlinkProductPictures, []),
      unlinkDocumentsAndCertification: addProductPageSelector((state) => state.unlinkDocumentsAndCertification, []),
      unlinkManufacturerDocumentation: addProductPageSelector((state) => state.unlinkManufacturerDocumentation, []),
    }),
    state.actions(addProductPageActions, [
      'handleInputChange',
      'addNewProduct',
      'photoPublicationPermissionChangeHandler',
      'supplierRequestedReviewCheckChangeHandler',
      'uploadFileChangeHandler',
      'removeProductPicture',
      'removeDocumentsAndCertification',
      'removeManufacturerDocumentation',
      'loadProductDetails',
      'updateProduct',
      'resetState',
      'loadProductTypes',
    ]),
  )(
    class AddProductPage extends Component {
      state = {
        buttonClicked: false,
      };
      componentWillMount() {
        const { query, editMode = true } = this.props;
        this.props.resetState();
        let id = query.get('id');
        if (id) {
          if (editMode === true) {
            this.props.loadProductDetails(id);
          } else {
            this.props.loadProductTypes();
          }
        } else {
          this.props.history.push('/404');
        }
      }
      breadcrumb = () => {
        const { editMode, sellerId, data } = this.props;
        return (
          <div className="header">
            <div className="container-fluid pt-5">
              {editMode === true && (
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href={`seller-products?id=${sellerId}`}>Products</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href={`product-details?id=${data.id}`}>Details</a>
                    </li>
                    <li className="breadcrumb-item active">Edit Product</li>
                  </ol>
                </nav>
              )}
              {editMode === false && (
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href={`seller-products?id=${sellerId}`}>Products</a>
                    </li>
                    <li className="breadcrumb-item active">Add Product</li>
                  </ol>
                </nav>
              )}
            </div>
          </div>
        );
      };
      addNewProduct = async (e) => {
        const {
          sellerId,
          productId,
          otherProductType,
          productOutofStock,
          productModel,
          productModelOther,
          productPictures,
          photoPublicationPermission,
          documentsAndCertification,
          pricePerUnit,
          baseUnit,
          minOrderQuantity,
          quantityCurrentlyOnHand,
          daysToDeliver,
          upcCode,
          manufacturer,
          otherManufacturer,
          fdaRegistrationInfo,
          countryOfManufacture,
          manufacturerDocumentation,
          addNewProduct,
          editMode,
        } = this.props;
        e.stopPropagation();
        e.preventDefault();
        this.setState({ buttonClicked: true });

        //upload product pictures
        if (editMode === false) {
          let productPicturesUrls = [];
          if (productPictures) {
            for (let i = 0; i < productPictures.length; i++) {
              let element = productPictures[i];
              const storageRef = firebase.storage().ref();

              const fileRef = storageRef.child(`product-images/${sellerId}/${element.name}`);

              const snapshot = await fileRef.put(element);

              let url = await snapshot.ref.getDownloadURL();

              productPicturesUrls.push({ url });
            }
          }

          //upload Documents And Certification
          let documentsAndCertificationUrls = [];
          if (documentsAndCertification) {
            for (let i = 0; i < documentsAndCertification.length; i++) {
              let element = documentsAndCertification[i];
              const storageRef = firebase.storage().ref();

              const fileRef = storageRef.child(`product-documents/${sellerId}/${element.name}`);

              const snapshot = await fileRef.put(element);

              let url = await snapshot.ref.getDownloadURL();

              documentsAndCertificationUrls.push({ url });
            }
          }

          //upload Manufacturer Documentation
          let manufacturerDocumentationUrls = [];
          if (manufacturerDocumentation) {
            for (let i = 0; i < manufacturerDocumentation.length; i++) {
              let element = manufacturerDocumentation[i];
              const storageRef = firebase.storage().ref();

              const fileRef = storageRef.child(`product-documents/${sellerId}/${element.name}`);

              const snapshot = await fileRef.put(element);

              let url = await snapshot.ref.getDownloadURL();

              manufacturerDocumentationUrls.push({ url });
            }
          }
          let payload = {
            id: sellerId,
            supplierID: sellerId,
            productId: productId,
            otherProductType: otherProductType,
            productOutofStock: productOutofStock,
            productModel: productModel,
            productModelOther: productModelOther,
            productPictures: productPicturesUrls,
            photoPublicationPermission: photoPublicationPermission,
            documentsAndCertification: documentsAndCertificationUrls,
            pricePerUnit: pricePerUnit,
            baseUnit: baseUnit,
            minOrderQuantity: minOrderQuantity,
            quantityCurrentlyOnHand: quantityCurrentlyOnHand,
            daysToDeliver: daysToDeliver,
            upcCode: upcCode,
            manufacturer: manufacturer,
            otherManufacturer: otherManufacturer,
            fdaRegistrationInfo: fdaRegistrationInfo,
            countryOfManufacture: countryOfManufacture,
            manufacturerDocumentation: manufacturerDocumentationUrls,
          };

          addNewProduct({ ...payload });
        }
      };
      updateProduct = async (e) => {
        const {
          sellerId,
          productId,
          otherProductType,
          productOutofStock,
          productModel,
          productModelOther,
          productPictures,
          photoPublicationPermission,
          documentsAndCertification,
          pricePerUnit,
          baseUnit,
          minOrderQuantity,
          quantityCurrentlyOnHand,
          daysToDeliver,
          upcCode,
          manufacturer,
          otherManufacturer,
          fdaRegistrationInfo,
          countryOfManufacture,
          manufacturerDocumentation,
          editMode = true,
          updateProduct,
          unlinkProductPictures,
          unlinkDocumentsAndCertification,
          unlinkManufacturerDocumentation,
          data,
        } = this.props;

        e.stopPropagation();
        e.preventDefault();
        this.setState({ buttonClicked: true });
        //upload product pictures
        if (editMode === true) {
          let productPicturesUrls = productPictures.filter((item) => item.newUpload !== true);

          const storageRef = firebase.storage().ref();

          let newProductPictures = productPictures.filter((item) => item.newUpload === true);

          if (newProductPictures && newProductPictures.length) {
            for (let i = 0; i < newProductPictures.length; i++) {
              let element = newProductPictures[i];
              const storageRef = firebase.storage().ref();

              const fileRef = storageRef.child(`product-images/${sellerId}/${element.name}`);

              const snapshot = await fileRef.put(element);

              let url = await snapshot.ref.getDownloadURL();

              productPicturesUrls.push({ url });
            }
          }

          //delete product pictures

          let deletedFiles = unlinkProductPictures.filter((item) => item.url && item.url.length > 0);

          if (deletedFiles && deletedFiles.length) {
            for (let i = 0; i < deletedFiles.length; i++) {
              let element = deletedFiles[i];

              let deleteRef = storageRef.child(`product-images/${sellerId}/${element.name}`);

              deleteRef
                .delete()
                .then(function () {
                  console.log('FILE DELETED');
                })
                .catch(function (error) {
                  console.log('Uh-oh, an error occurred!');
                });
            }
          }

          //upload Documents And Certification
          let documentsAndCertificationUrls = documentsAndCertification.filter((item) => item.newUpload !== true);
          let newDocumentsAndCertification = documentsAndCertification.filter((item) => item.newUpload === true);
          if (newDocumentsAndCertification && newDocumentsAndCertification.length) {
            for (let i = 0; i < newDocumentsAndCertification.length; i++) {
              let element = newDocumentsAndCertification[i];
              const storageRef = firebase.storage().ref();

              const fileRef = storageRef.child(`product-documents/${sellerId}/${element.name}`);

              const snapshot = await fileRef.put(element);

              let url = await snapshot.ref.getDownloadURL();

              documentsAndCertificationUrls.push({ url });
            }
          }
          //delete the documentation and certification
          let deletedDocumentsAndCertification = unlinkDocumentsAndCertification.filter(
            (item) => item.url && item.url.length > 0,
          );

          if (deletedDocumentsAndCertification && deletedDocumentsAndCertification.length) {
            for (let i = 0; i < deletedFiles.length; i++) {
              let element = deletedDocumentsAndCertification[i];

              let deletedeletedDocumentsAndCertificationRef = storageRef.child(
                `product-documents/${sellerId}/${element.name}`,
              );

              deletedeletedDocumentsAndCertificationRef
                .delete()
                .then(function () {
                  console.log('FILE DELETED');
                })
                .catch(function (error) {
                  console.log('Uh-oh, an error occurred!');
                });
            }
          }
          //upload Manufacturer Documentation
          let manufacturerDocumentationUrls = manufacturerDocumentation.filter((item) => item.newUpload !== true);
          let newManufacturerDocumentation = manufacturerDocumentation.filter((item) => item.newUpload === true);
          if (newManufacturerDocumentation && newManufacturerDocumentation.length) {
            for (let i = 0; i < newManufacturerDocumentation.length; i++) {
              let element = newManufacturerDocumentation[i];
              const storageRef = firebase.storage().ref();

              const fileRef = storageRef.child(`product-documents/${sellerId}/${element.name}`);

              const snapshot = await fileRef.put(element);

              let url = await snapshot.ref.getDownloadURL();

              manufacturerDocumentationUrls.push({ url });
            }
          }
          //delete Manufacturer Documentation
          let deletedManufacturerDocumentation = unlinkManufacturerDocumentation.filter(
            (item) => item.url && item.url.length > 0,
          );

          if (deletedManufacturerDocumentation && deletedManufacturerDocumentation.length) {
            for (let i = 0; i < deletedFiles.length; i++) {
              let element = deletedDocumentsAndCertification[i];

              let deletedManufacturerDocumentationRef = storageRef.child(
                `product-documents/${sellerId}/${element.name}`,
              );

              deletedManufacturerDocumentationRef
                .delete()
                .then(function () {
                  console.log('FILE DELETED');
                })
                .catch(function (error) {
                  console.log('Uh-oh, an error occurred!');
                });
            }
          }
          let payload = {
            id: data.id,
            supplierID: sellerId,
            productId: [productId],
            otherProductType: otherProductType,
            productOutofStock: productOutofStock,
            productModel: productModel,
            productModelOther: productModelOther,
            productPictures: productPicturesUrls,
            photoPublicationPermission: photoPublicationPermission,
            documentsAndCertification: documentsAndCertificationUrls,
            pricePerUnit: pricePerUnit,
            baseUnit: baseUnit,
            minOrderQuantity: minOrderQuantity,
            quantityCurrentlyOnHand: quantityCurrentlyOnHand,
            daysToDeliver: daysToDeliver,
            upcCode: upcCode,
            manufacturer: manufacturer,
            otherManufacturer: otherManufacturer,
            fdaRegistrationInfo: fdaRegistrationInfo,
            countryOfManufacture: countryOfManufacture,
            manufacturerDocumentation: manufacturerDocumentationUrls,
            history: this.props.history,
          };

          updateProduct({ ...payload });
        }
      };
      render() {
        const {
          loading,
          error,
          sellerId,
          productTypesList,
          productOutofStock,
          productModel,
          productModelOther,
          pricePerUnit,
          productId,
          otherProductType,
          productPictures = [],
          photoPublicationPermission,
          minOrderQuantity,
          quantityCurrentlyOnHand,
          daysToDeliver,
          baseUnit,
          upcCode,
          manufacturer,
          otherManufacturer,
          fdaRegistrationInfo,
          countryOfManufacture = [],
          manufacturerDocumentation,
          documentsAndCertification = [],
          editMode = true,
          history,
        } = this.props;

        //action
        const {
          handleInputChange,
          photoPublicationPermissionChangeHandler,
          removeProductPicture,
          removeDocumentsAndCertification,
          removeManufacturerDocumentation,
        } = this.props;

        if (loading) {
          return (
            <>
              {this.breadcrumb()}

              <section className="container page-content pb-6 market-content text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </section>
            </>
          );
        }
        if (error) {
          return <ErrorPage error={error} />;
        }
        let allProductTypes = productTypesList.map((item) => {
          return { ...item, label: item.name, value: item.id };
        });

        let selectedType = { value: '', label: 'Select One' };

        if (productId && productId.length) {
          selectedType = allProductTypes.filter((item) => {
            return productId === item.id;
          })[0];
        }
        let selectProductName;
        productTypesList.forEach((a) => {
          if (productId === a.id) {
            selectProductName = a.name;
          }
        });

        //products pictures and videos
        const productPicturefilesList = () => {
          let result = null;

          if (productPictures.length) {
            result = productPictures.map((file, index) => {
              if (file.url) {
                let name = decodeURIComponent(file.filename).split('/');

                if (name && name[2]) {
                  file.name = name[2];
                }
                file.src = file.url;
              } else {
                file.src = URL.createObjectURL(productPictures[index]);
              }

              file.onload = function () {
                URL.revokeObjectURL(this.src);
              };

              return (
                <div key={Math.random()} className="pb-2">
                  {file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' ? (
                    <img
                      className="productImages cursorTospan"
                      src={file.src}
                      alt="productPicture"
                      onClick={(e) => window.open(file.src)}
                    />
                  ) : (
                    <span className="fe fe-file-text"></span>
                  )}
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  {file.name || file.file_name || file.filename}
                  <RemoveFile
                    onClick={() => {
                      let payload = {
                        id: file.id || file.itemImageId,
                      };
                      removeProductPicture(payload);
                    }}
                  >
                    X
                  </RemoveFile>
                </div>
              );
            });

            return result;
          }
        };
        //Supporting Documentation and Certifications
        const documentsAndCertificationList = () => {
          let result = null;
          if (documentsAndCertification.length) {
            result = documentsAndCertification.map((file, index) => {
              if (file.url) {
                let name = decodeURIComponent(file.filename).split('/');

                if (name && name[2]) {
                  file.name = name[2];
                }
                file.src = file.url;
              } else {
                file.src = URL.createObjectURL(documentsAndCertification[index]);
              }
              file.onload = function () {
                URL.revokeObjectURL(this.src);
              };

              return (
                <div key={Math.random()} className="pb-2">
                  {file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' ? (
                    <img
                      className="productImages cursorTospan"
                      src={file.src}
                      alt="documentsAndCertification"
                      onClick={(e) => window.open(file.src)}
                    />
                  ) : (
                    <span className="fe fe-file-text"></span>
                  )}
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  {file.name || file.file_name || file.filename}
                  <RemoveFile
                    onClick={() => {
                      let payload = {
                        id: file.id || file.itemImageId,
                      };
                      removeDocumentsAndCertification(payload);
                    }}
                  >
                    X
                  </RemoveFile>
                </div>
              );
            });
          }

          return result;
        };
        //Manufacturer Documentation
        const manufacturerDocumentationList = () => {
          let result = null;
          if (manufacturerDocumentation.length) {
            result = manufacturerDocumentation.map((file, index) => {
              if (file.url) {
                let name = decodeURIComponent(file.filename).split('/');

                if (name && name[2]) {
                  file.name = name[2];
                }
                file.src = file.url;
              } else {
                file.src = URL.createObjectURL(manufacturerDocumentation[index]);
              }

              file.onload = function () {
                URL.revokeObjectURL(this.src);
              };
              return (
                <div key={Math.random()} className="pb-2">
                  {file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' ? (
                    <img
                      className="productImages cursorTospan"
                      src={file.src}
                      alt="manufacturerDocumentation"
                      onClick={(e) => window.open(file.src)}
                    />
                  ) : (
                    <span className="fe fe-file-text"></span>
                  )}
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  {file.name || file.file_name || file.filename}
                  <RemoveFile
                    onClick={() => {
                      let payload = {
                        id: file.id || file.itemImageId,
                      };
                      removeManufacturerDocumentation(payload);
                    }}
                  >
                    X
                  </RemoveFile>
                </div>
              );
            });
          }

          return result;
        };

        return (
          <>
            {this.breadcrumb()}
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-10 col-xl-8">
                  <div className="header">
                    <div className="header-body pt-0">
                      <div className="row align-items-center">
                        <div className="col">
                          {editMode === true ? (
                            <h1 className="header-title">Edit Product</h1>
                          ) : (
                            <h1 className="header-title">Add New Product</h1>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-10 col-xl-8">
                  <form
                    className="mb-4"
                    onSubmit={(e) => (editMode === true ? this.updateProduct(e) : this.addNewProduct(e))}
                  >
                    <div className="form-group">
                      <label>Product Type</label>
                      <span className="form-asterik">*</span>
                      <select
                        className="form-control"
                        value={selectedType ? selectedType.value : ''}
                        onChange={(e) => {
                          let payload = {
                            fieldName: 'productId',
                            value: e.target.value,
                          };
                          handleInputChange(payload);
                        }}
                        required
                      >
                        {editMode === false && (
                          <option value="" disabled>
                            Select One
                          </option>
                        )}

                        {allProductTypes.map((item, index) => {
                          return (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    {selectProductName === 'Other' && (
                      <div className="form-group">
                        <label>Product Type = "Other"</label>
                        <span className="form-asterik">*</span>
                        <p className="form-text text-muted">Please enter the Product Type if selected "Other" above</p>
                        <input
                          type="text"
                          className="form-control"
                          required
                          onChange={(e) => {
                            let payload = {
                              fieldName: 'otherProductType',
                              value: e.target.value,
                            };
                            handleInputChange(payload);
                          }}
                          defaultValue={otherProductType}
                        />
                      </div>
                    )}

                    <div className="form-group">
                      <label>Product Out of Stock</label>

                      <p className="form-text text-muted">
                        If this product is out of stock, please let us know if this is a temporary or permanent status.
                      </p>
                      <select
                        className="form-control"
                        data-toggle="select"
                        value={productOutofStock}
                        onChange={(e) => {
                          let payload = {
                            fieldName: 'productOutofStock',
                            value: e.target.value,
                          };
                          handleInputChange(payload);
                        }}
                      >
                        <option></option>
                        <option value="Temporary Out of Stock">Temporary Out of Stock</option>
                        <option value="Permanently Out of Stock">Permanently Out of Stock</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label>Product Model</label>
                      <span className="form-asterik">*</span>
                      <p className="form-text text-muted">Type to search or select "Other"</p>
                      <select
                        className="form-control"
                        data-toggle="select"
                        required
                        value={productModel}
                        onChange={(e) => {
                          let payload = {
                            fieldName: 'productModel',
                            value: e.target.value,
                          };
                          handleInputChange(payload);
                        }}
                      >
                        {productModelOptions.map((item, index) => {
                          return (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    {productModel === 'Other' && (
                      <div className="form-group">
                        <label>Product Model - Other</label>
                        <span className="form-asterik">*</span>
                        <p className="form-text text-muted">
                          By selecting "Other" above, please specify the Product Model
                        </p>
                        <input
                          type="text"
                          className="form-control"
                          required
                          defaultValue={productModelOther}
                          onChange={(e) => {
                            let payload = {
                              fieldName: 'productModelOther',
                              value: e.target.value,
                            };
                            handleInputChange(payload);
                          }}
                        />
                      </div>
                    )}

                    <div className="form-group">
                      <label className="mb-1">Product Pictures and Videos</label>
                      <span className="form-asterik">*</span>

                      <FileInput
                        className="form-control"
                        MaxLength="100"
                        type="file"
                        name="productPictures"
                        //required
                        multiple={true}
                        showNotification={true}
                        label={'+ Add'}
                        uploadOnChangeHandler={({ selected }) => {
                          this.props.uploadFileChangeHandler({
                            selected,
                            key: 'productPictures',
                          });
                        }}
                      />
                      {productPictures.length > 0 && (
                        <div className="card pt-2">
                          <ul
                            style={{
                              //overflowY: "scroll",
                              paddingLeft: '2em',
                              marginBlockStart: '0px',
                            }}
                          >
                            {productPicturefilesList()}
                          </ul>
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label>Photo Publication Permission</label>
                      <span className="form-asterik">*</span>
                      <p className="form-text text-muted">
                        By clicking here, I confirm that Project N95 has permission to use any images I have submitted
                        to represent my product offerings for commercial purposes.
                      </p>

                      <input
                        className="form-check-input is-invalid ml-0 permission-check "
                        type="checkbox"
                        value=""
                        id="invalidCheck3"
                        required
                        checked={photoPublicationPermission}
                        onChange={(e) => photoPublicationPermissionChangeHandler()}
                      />
                    </div>

                    <div className="row pt-5 pb-3 ml-0">
                      <h1>Product Information</h1>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Price per unit delivered to the US</label>
                          <span className="form-asterik">*</span>
                          <p className="form-text text-muted">
                            Please indicate price for each i.e. one gown, one respirator, etc.
                          </p>

                          <input
                            type="number"
                            className="form-control"
                            min="0"
                            step="0.01"
                            required
                            defaultValue={pricePerUnit}
                            onChange={(e) => {
                              let payload = {
                                fieldName: 'pricePerUnit',
                                value: e.target.value,
                              };
                              handleInputChange(payload);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Base Unit of Measure</label>
                          <span className="form-asterik">*</span>
                          <p className="form-text text-muted">Base Unit of Measure (e.g. each, ounce)</p>
                          <select
                            className="form-control"
                            data-toggle="select"
                            required
                            value={baseUnit}
                            onChange={(e) => {
                              let payload = {
                                fieldName: 'baseUnit',
                                value: e.target.value,
                              };
                              handleInputChange(payload);
                            }}
                          >
                            <option></option>
                            <option value="cubic inches">cubic inches</option>
                            <option value="each">each</option>
                            <option value="gram">gram</option>
                            <option value="ounce">ounce</option>
                            <option value="second">second</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Minimum Order Quantity</label>
                          <span className="form-asterik">*</span>
                          <p className="form-text text-muted">in base unit of measure.</p>
                          <input
                            type="number"
                            className="form-control"
                            min="0"
                            required
                            defaultValue={minOrderQuantity}
                            onChange={(e) => {
                              let payload = {
                                fieldName: 'minOrderQuantity',
                                value: e.target.value,
                              };
                              handleInputChange(payload);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Quantity Currently on Hand</label>

                          <p className="form-text text-muted">in base unit of measure</p>
                          <input
                            type="number"
                            className="form-control"
                            min="0"
                            pattern="[0-9]*"
                            defaultValue={quantityCurrentlyOnHand}
                            onChange={(e) => {
                              let payload = {
                                fieldName: 'quantityCurrentlyOnHand',
                                value: e.target.value,
                              };
                              handleInputChange(payload);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Lead time in days to deliver to USA</label>

                          <input
                            type="number"
                            className="form-control"
                            defaultValue={daysToDeliver}
                            onChange={(e) => {
                              let payload = {
                                fieldName: 'daysToDeliver',
                                value: e.target.value,
                              };
                              handleInputChange(payload);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>UPC code</label>

                          <input
                            type="text"
                            className="form-control"
                            defaultValue={upcCode}
                            onChange={(e) => {
                              let payload = {
                                fieldName: 'upcCode',
                                value: e.target.value,
                              };
                              handleInputChange(payload);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="mb-1">Supporting Documentation and Certifications</label>
                      <p className="form-text text-muted">
                        Acceptable documents include: product tests results, CE certificate, recent purchase order with
                        product details etc.
                      </p>
                      {/* <Form.File.Input id="formBasicTaxExemptFormInput" /> */}
                      <FileInput
                        MaxLength="100"
                        multiple={true}
                        showNotification={true}
                        label={'+ Add'}
                        uploadOnChangeHandler={({ selected }) => {
                          this.props.uploadFileChangeHandler({
                            selected,
                            key: 'documentsAndCertification',
                          });
                        }}
                      />
                      {documentsAndCertification.length > 0 && (
                        <div className="card pt-2">
                          <ul
                            style={{
                              //overflowY: "scroll",
                              paddingLeft: '2em',
                              marginBlockStart: '0px',
                            }}
                          >
                            {documentsAndCertificationList()}
                          </ul>
                        </div>
                      )}
                    </div>

                    <div className="row pt-5 pb-3 ml-0">
                      <h1>Manufacturer</h1>
                    </div>
                    <div className="form-group">
                      <label>Manufacturer</label>
                      <span className="form-asterik">*</span>
                      <p className="form-text text-muted">Type to search or select "Other"</p>
                      <select
                        className="form-control"
                        data-toggle="select"
                        required
                        value={manufacturer}
                        onChange={(e) => {
                          let payload = {
                            fieldName: 'manufacturer',
                            value: e.target.value,
                          };
                          handleInputChange(payload);
                        }}
                      >
                        <option></option>
                        <option value="3M">3M</option>
                        <option value="AAB">AAB</option>
                        <option value="Adaptive Energy">Adaptive Energy</option>
                        <option value="Aeonmed">Aeonmed</option>
                        <option value="ALFA SCIENTIFIC DESIGNS/Clarity Diagnostics LLC">
                          ALFA SCIENTIFIC DESIGNS/Clarity Diagnostics LLC
                        </option>
                        <option value="Anhui Deepblue Med Tech">Anhui Deepblue Med Tech</option>
                        <option value="Anhui Hongai Industrial Co.">Anhui Hongai Industrial Co.</option>
                        <option value="Anhui Zhongke ">Anhui Zhongke </option>
                        <option value="Anshun Health and Medical Technology">
                          Anshun Health and Medical Technology
                        </option>
                        <option value="Bei Bei Safety">Bei Bei Safety</option>
                        <option value="Beijing Aeonmed">Beijing Aeonmed</option>
                        <option value="Beijing Aerospace">Beijing Aerospace</option>
                        <option value="Beijing Eternity">Beijing Eternity</option>
                        <option value="Beijing Topnew Import & Export Co., Ltd.">
                          Beijing Topnew Import & Export Co., Ltd.
                        </option>
                        <option value="BGI Genomics">BGI Genomics</option>
                        <option value="BioCan Diagnostics">BioCan Diagnostics</option>
                        <option value="Biosana Health">Biosana Health</option>
                        <option value="BMC">BMC</option>
                        <option value="BNOS Meditech">BNOS Meditech</option>
                        <option value="Bocci Laboratories">Bocci Laboratories</option>
                        <option value="Boditech Med">Boditech Med</option>
                        <option value="BYD">BYD</option>
                        <option value="CareAline">CareAline</option>
                        <option value="CC Face Shield">CC Face Shield</option>
                        <option value="Cellex">Cellex</option>
                        <option value="Chengde Technology Co.">Chengde Technology Co.</option>
                        <option value="Chongquing Zaisheng Tech Corp">Chongquing Zaisheng Tech Corp</option>
                        <option value="Christmas By Krebs">Christmas By Krebs</option>
                        <option value="Clongene Biotech">Clongene Biotech</option>
                        <option value="CTT Co. Ltd">CTT Co. Ltd</option>
                        <option value="Da an Gene Co">Da an Gene Co</option>
                        <option value="Daddybaby Co Ltd.">Daddybaby Co Ltd.</option>
                        <option value="Daniels Wood Land">Daniels Wood Land</option>
                        <option value="DiaCarta">DiaCarta</option>
                        <option value="DiaPlexQ corona virus detection kit">DiaPlexQ corona virus detection kit</option>
                        <option value="Dongguan Arun Industrial Co Ltd">Dongguan Arun Industrial Co Ltd</option>
                        <option value="Dongguan Arun">Dongguan Arun</option>
                        <option value="Dongguan Elephant Electronic Technology Co., Ltd.">
                          Dongguan Elephant Electronic Technology Co., Ltd.
                        </option>
                        <option value="Dongguan Huagang">Dongguan Huagang</option>
                        <option value="Dongguan Leihuo Medical Device Co">Dongguan Leihuo Medical Device Co</option>
                        <option value="Dongguan Premium Fashion">Dongguan Premium Fashion</option>
                        <option value="Dongguan RuiZhuo Plastic Industrial Co., Ltd.">
                          Dongguan RuiZhuo Plastic Industrial Co., Ltd.
                        </option>
                        <option value="Foshan Flying Medical">Foshan Flying Medical</option>
                        <option value="Foshan Nanhai Plus Medical">Foshan Nanhai Plus Medical</option>
                        <option value="Fujian Greatland Garments Co., Ltd.">Fujian Greatland Garments Co., Ltd.</option>
                        <option value="Fujian Kang Chen Daily Necessities Co Ltd">
                          Fujian Kang Chen Daily Necessities Co Ltd
                        </option>
                        <option value="Fujian Pageone Garment">Fujian Pageone Garment</option>
                        <option value="GarryGalaxy">GarryGalaxy</option>
                        <option value="GE Healthcare">GE Healthcare</option>
                        <option value="GenBody">GenBody</option>
                        <option value="Gencurix">Gencurix</option>
                        <option value="GENOMMA LAB USA, INC.">GENOMMA LAB USA, INC.</option>
                        <option value="Guangdong Fei Fan MStar">Guangdong Fei Fan MStar</option>
                        <option value="Guangdong Senda">Guangdong Senda</option>
                        <option value="Guangdong ZhiZhen Biologica">Guangdong ZhiZhen Biologica</option>
                        <option value="Guangzhou Harley Commodity">Guangzhou Harley Commodity</option>
                        <option value=" Guangzhou Powecom Labor Insurance Supplies">
                          Guangzhou Powecom Labor Insurance Supplies
                        </option>
                        <option value="Guangzhou Wondfo Biotech">Guangzhou Wondfo Biotech</option>
                        <option value="Guangzhou Yihere">Guangzhou Yihere</option>
                        <option value="Habitata Building Products">Habitata Building Products</option>
                        <option value="Habitata Building Products, LLC">Habitata Building Products, LLC</option>
                        <option value="Halcyon Shades">Halcyon Shades</option>
                        <option value="Hangzhou Universal Electronic Co.">Hangzhou Universal Electronic Co.</option>
                        <option value="Hardwire">Hardwire</option>
                        <option value="Healgen Scientific LLC">Healgen Scientific LLC</option>
                        <option value="Henan DiFei">Henan DiFei</option>
                        <option value="Henan Huibo">Henan Huibo</option>
                        <option value="Hypnus">Hypnus</option>
                        <option value="InBios">InBios</option>
                        <option value="Indusaver S.A">Indusaver S.A.</option>
                        <option value="Indusaver S.A.">Indusaver S.A.</option>
                        <option value="JIANGSU HANHENG MEDICAL TECHNOLOGY">JIANGSU HANHENG MEDICAL TECHNOLOGY</option>
                        <option value="Jiangsu Macro & Micro Inst">Jiangsu Macro & Micro Inst</option>
                        <option value="Jiangyin Jinke">Jiangyin Jinke</option>
                        <option value="Jilin Provincial Medical Supplies">Jilin Provincial Medical Supplies</option>
                        <option value="KEK Associates, Inc.">KEK Associates, Inc.</option>
                        <option value="Kushan Jiehong">Kushan Jiehong</option>
                        <option value="Lanshan Shendun">Lanshan Shendun</option>
                        <option value="Liaoning Shenjingtang">Liaoning Shenjingtang</option>
                        <option value="Maccura Biotechnology">Maccura Biotechnology</option>
                        <option value="Makrite">Makrite</option>
                        <option value="MiCo BioMed Co.,Ltd.">MiCo BioMed Co.,Ltd.</option>
                        <option value="MiCo BioMed">MiCo BioMed</option>
                        <option value="MiCoBioMed">MiCoBioMed</option>
                        <option value="Micomme">Micomme</option>
                        <option value="Minnetronix">Minnetronix</option>
                        <option value="Nanjing Superstar Medical">Nanjing Superstar Medical</option>
                        <option value="Nations Photo Lab">Nations Photo Lab</option>
                        <option value="NeoTech">NeoTech</option>
                        <option value="New Derun Garment Co., Ltd.">New Derun Garment Co., Ltd.</option>
                        <option value=" Ningbo You King Costume Industry and Trade Co.">
                          Ningbo You King Costume Industry and Trade Co.
                        </option>
                        <option value="Ningbo You King Costume Industry and Trade Co">
                          {' '}
                          Ningbo You King Costume Industry and Trade Co.
                        </option>
                        <option value="Other">Other</option>
                        <option value="PaxGenBio Inc">PaxGenBio Inc</option>
                        <option value="Philips Respironics">Philips Respironics</option>
                        <option value="Philips">Philips</option>
                        <option value="Port Plastics, Inc.">Port Plastics, Inc.</option>
                        <option value="PowerFilm">PowerFilm</option>
                        <option value="PUTIAN JINLI.LAISI CLOTHING & ACCESSORIES CO.,LTD">
                          PUTIAN JINLI.LAISI CLOTHING & ACCESSORIES CO.,LTD
                        </option>
                        <option value="Qinghai Guocao Biotechnology">Qinghai Guocao Biotechnology</option>
                        <option value="Quidel">Quidel</option>
                        <option value="Ray Biotech Life">Ray Biotech Life</option>
                        <option value="ResMed">ResMed</option>
                        <option value="Rizhao Sanqi Medical and Health Articles">
                          Rizhao Sanqi Medical and Health Articles
                        </option>
                        <option value="Rizhao Sanqi">Rizhao Sanqi</option>
                        <option value="Rizhao Sanqui Medical and Health Articles">
                          Rizhao Sanqui Medical and Health Articles
                        </option>
                        <option value="Rizhoa Sanqi">Rizhoa Sanqi</option>
                        <option value="SA Scientific">SA Scientific</option>
                        <option value="San Huei United Company, Ltd.external icon">
                          San Huei United Company, Ltd.external icon
                        </option>
                        <option value="Sanjiao Oral">Sanjiao Oral</option>
                        <option value="Shandong Liaocheng">Shandong Liaocheng</option>
                        <option value="Shandong ThinkLab Biotechnology Co., Ltd">
                          Shandong ThinkLab Biotechnology Co., Ltd
                        </option>
                        <option value="Shanghai Dasheng Health Products Manufacture Co">
                          Shanghai Dasheng Health Products Manufacture Co
                        </option>
                        <option value="Shenzen Medico">Shenzen Medico</option>
                        <option value="Shenzen Mindray">Shenzen Mindray</option>
                        <option value="Shenzhen Aeon Technology Co., Ltd.">Shenzhen Aeon Technology Co., Ltd.</option>
                        <option value="Shenzhen Tianze">Shenzhen Tianze</option>
                        <option value="Shenzhen Yihujia Printing Material">Shenzhen Yihujia Printing Material</option>
                        <option value="Solgent Co">Solgent Co</option>
                        <option value="Solgent">Solgent</option>
                        <option value="Suzhou Fangtian Industries Co., Ltd.">
                          Suzhou Fangtian Industries Co., Ltd.
                        </option>
                        <option value="Suzhou Sanical">Suzhou Sanical </option>
                        <option value="Timberlane">Timberlane</option>
                        <option value="Vazyme">Tongcheng Dongtai Plastic Industry</option>
                        <option value="">Vazyme</option>
                        <option value="Viasys">Viasys</option>
                        <option value="Voodoo Manufacturing">Voodoo Manufacturing</option>
                        <option value="Weini Technology Development Co., Ltd.">
                          Weini Technology Development Co., Ltd.
                        </option>
                        <option value="Weini">Weini</option>
                        <option value="Winner Medical Co., Ltd.">Winner Medical Co., Ltd.</option>
                        <option value="Woodchuck">Woodchuck</option>
                        <option value="Wuhan EasyDiagnosis Biomedicine">Wuhan EasyDiagnosis Biomedicine</option>
                        <option value="Wuhan Mingde Biomedicine Co. Ltd.">Wuhan Mingde Biomedicine Co. Ltd.</option>
                        <option value="Xiamen Biotime Biotechnology">Xiamen Biotime Biotechnology</option>
                        <option value="XIANTAO LIJUN NON-WOVEN PRODUCTS CO.,LTD">
                          XIANTAO LIJUN NON-WOVEN PRODUCTS CO.,LTD{' '}
                        </option>
                        <option value="Xiantao Ruiyang Protective Products Co.">
                          Xiantao Ruiyang Protective Products Co.
                        </option>
                        <option value="Xiantao Zhongyi Safety Protection">Xiantao Zhongyi Safety Protection</option>
                        <option value="Yibin Wuyuan Technology Co">Yibin Wuyuan Technology Co</option>
                        <option value="Yiwu Sunrise Import & Export">Yiwu Sunrise Import & Export</option>
                        <option value="Yuwell">Yuwell</option>
                        <option value="Yuyao Ruhu Medical Instruments">Yuyao Ruhu Medical Instruments</option>
                        <option value="Zhangzhou Easepal">Zhangzhou Easepal</option>
                        <option value="Zhejiang Orient Gene Biotech Co., Ltd">
                          Zhejiang Orient Gene Biotech Co., Ltd
                        </option>
                        <option value="Zhejiang Stage Leather Apparel Co.">Zhejiang Stage Leather Apparel Co.</option>
                        <option value="Zhejiang Xingji Technology">Zhejiang Xingji Technology</option>
                        <option value="Zhengzhou Ruipu Medical Tech Co">Zhengzhou Ruipu Medical Tech Co</option>
                        <option value="Zhengzhou Ruipu">Zhengzhou Ruipu</option>
                        <option value="Zhuhai Livzon Diagnostics">Zhuhai Livzon Diagnostics</option>
                        <option value="Zibo Intco Medical Products">Zibo Intco Medical Products</option>
                        <option value="Suzhou Sanical Protective Product Mfg Co">
                          Suzhou Sanical Protective Product Mfg Co
                        </option>
                      </select>
                    </div>

                    {manufacturer === 'Other' && (
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label>Manufacturer - Other</label>
                            <span className="form-asterik">*</span>
                            <p className="form-text text-muted">
                              By selecting "Other" above, please specify the Manufacturer
                            </p>
                            <input
                              type="text"
                              className="form-control"
                              required
                              defaultValue={otherManufacturer}
                              onChange={(e) => {
                                let payload = {
                                  fieldName: 'otherManufacturer',
                                  value: e.target.value,
                                };
                                handleInputChange(payload);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row pt-5 pb-3 ml-0">
                      <h1>Manufacturer Information</h1>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>FDA registration information for manufacturing facility</label>
                          <p className="form-text text-muted">
                            Please submit the manufacturer's FDA Registration/FEI Number or Owner/Operator Number. Enter
                            NA if not FDA registered.
                          </p>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={fdaRegistrationInfo}
                            onChange={(e) => {
                              let payload = {
                                fieldName: 'fdaRegistrationInfo',
                                value: e.target.value,
                              };
                              handleInputChange(payload);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Country of Manufacture</label>
                          <Dropdown
                            isMulti
                            options={countryOfManufactureOption}
                            value={countryType.filter((item) => countryOfManufacture.includes(item.value))}
                            onChange={(e) => {
                              let payload = {
                                fieldName: 'countryOfManufacture',
                                value: e,
                              };

                              handleInputChange(payload);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="mb-1">Manufacturer Documentation</label>
                      <p className="form-text text-muted">
                        Documents could include: Manufacturer's FDA establishment registration certificate, FDA 510K
                        clearance, NIOSH-approved letter, etc."
                      </p>

                      <FileInput
                        MaxLength="100"
                        multiple={true}
                        showNotification={true}
                        label={'+ Add'}
                        uploadOnChangeHandler={({ selected }) => {
                          this.props.uploadFileChangeHandler({
                            selected,
                            key: 'manufacturerDocumentation',
                          });
                        }}
                      />
                      {manufacturerDocumentation.length > 0 && (
                        <div className="card pt-2">
                          <ul
                            style={{
                              //overflowY: "scroll",
                              paddingLeft: '2em',
                              marginBlockStart: '0px',
                            }}
                          >
                            {manufacturerDocumentationList()}
                          </ul>
                        </div>
                      )}
                    </div>

                    {/* <!-- Divider --> */}
                    <hr className="mt-5 mb-5" />

                    {this.state.buttonClicked === false ? (
                      <button type="submit" className="btn btn-block btn-primary">
                        {editMode === true ? <strong>UPDATE</strong> : <strong>SAVE</strong>}
                      </button>
                    ) : (
                      <button className="btn btn-block btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{' '}
                        Saving...
                      </button>
                    )}
                    {editMode === true ? (
                      <>
                        <button
                          onClick={(e) => {
                            this.props.resetState();
                            history.push(`/product-details?id=${this.props.data.id}`);
                          }}
                          className="btn btn-block btn-link text-muted"
                        >
                          <strong>Cancel</strong>
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={(e) => {
                            this.props.resetState();
                            history.push(`/seller-products?id=${sellerId}`);
                          }}
                          className="btn btn-block btn-link text-muted"
                        >
                          <strong>Cancel</strong>
                        </button>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </>
        );
      }
    },
  ),
);

import { state } from '../../store';
export const productFilter = (text) => (item) => {
  if (item.SupplierProductID.toString().indexOf(parseInt(text)) !== -1) {
    return item;
  } else if (Array.isArray(item['Product Type']) && item['Product Type'][0].toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['Vetting status'] && item['Vetting status'].toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['Actual Manufacturer'] && item['Actual Manufacturer'].toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['Actual Product Model'] && item['Actual Product Model'].toLowerCase().indexOf(text) !== -1) {
    return item;
  }
};

export const [sellerProductPageActions, sellerProductPageSelector, sellerProductPageState] = state(
  'SellerProductPage',
  {
    initial: {
      data: [],
      filteredProducts: [],
      loading: true,
    },
    on: {
      loadProductsList: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        newStoreObj.data = data;
        newStoreObj.filteredProducts = data;

        return newStoreObj;
      },
      setLoading: (_, data) => {
        let newStoreObj = { ..._, loading: data };
        return newStoreObj;
      },
      searchProducts: (_, event) => {
        let newStoreObj = { ..._ };
        let searchText = event.target.value;

        let originalData = newStoreObj.data;

        if (searchText.length) {
          originalData = originalData.filter(productFilter(searchText.toLowerCase()));
        }
        return {
          ...newStoreObj,
          filteredProducts: originalData,
        };
      },
    },
    events: ['sellerProductPageData'],
  },
);

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getLoginRedirectParam } from '../utils';

const ROUTE_VERIFY_EMAIL = '/signup/verify';

const ProtectedRoute = ({ component: Component, requireVerifiedEmail = true, internalUsersOnly = false, ...rest }) => {
  const { user, decodedToken } = useSelector((state) => state.App);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                search: `?${getLoginRedirectParam(props.location)}`,
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }

        if (requireVerifiedEmail && !user.emailVerified) {
          return <Redirect to={ROUTE_VERIFY_EMAIL} />;
        }

        if (
          internalUsersOnly &&
          !(decodedToken && decodedToken.claims && decodedToken.claims.userType === 'internal')
        ) {
          return <Redirect to="/404" />;
        }

        return <Component query={rest.query} {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;

import React from 'react';
import { connect } from 'react-redux';
import { rfqSelector, rfqActions } from './store';
import { state } from '../../store';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
require('react-bootstrap/ModalHeader');
const ProductModel = styled.p`
  font-size: 16px !important;
`;
export const RFQPagePopup = withRouter(
  connect(
    state.select({
      allData: rfqSelector((state) => state.data, []),
      popupIAgreeCheck: rfqSelector((state) => state.popupIAgreeCheck, false),
    }),

    state.actions(rfqActions, ['offerSubmit', 'popupIAgreeCheckChangeHandler']),
  )(
    class RFQPagePopup extends React.PureComponent {
      constructor(props) {
        super(props);

        this.state = {
          buttonClicked: false,
        };
      }
      buttonHandler = (event) => {
        event.preventDefault();

        const { data } = this.props;
        this.setState({ buttonClicked: true });
        let payload = {
          TOS: data.TOS,
          draftOfferId: data.draftOfferId,
          supplierRecordID: this.props.allData['Supplier Record ID'],
          history: this.props.history,
        };

        this.props.offerSubmit({ ...payload });
      };
      render() {
        const { data, popupIAgreeCheck, allData } = this.props;

        return (
          <div className="card">
            <Modal
              show={this.props.show}
              onHide={this.props.onHide}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <Modal.Header closeButton className="card-header">
                <Modal.Title id="contained-modal-title-vcenter">
                  <h3 className="card-header-title pt-3">Offer Summary</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="mx-4 mb-4">
                  <h3 className="mb-0">{allData['Manufacturer']}</h3>
                  <ProductModel>{allData['Product Model']}</ProductModel>
                  <p className="mb-4" style={{ fontSize: '16px' }}>
                    <strong>Product Type: </strong> {data.productType} <br />
                    <br />
                    <strong>Price per Unit (in USD):</strong> ${data.price}
                    <br />
                    <strong>Quantity (each/ounce):</strong> {data.quantity}
                    <br />
                    <strong>Shipping Costs (in USD):</strong> ${data.shipping}
                    <br />
                    {allData['Tax Exempt?'] === null ? (
                      <>
                        <strong>Taxes (in USD):</strong> ${data.taxes}
                      </>
                    ) : (
                      <>
                        <strong>Taxes:</strong> TAX EXEMPT
                      </>
                    )}
                    {/* <br />
                <strong>Payment Terms: </strong> {data.paymentTerms} */}
                    <br />
                    <strong>Delivery within (in days): </strong>
                    {data.delivery}
                    <br />
                    <strong>Additional Seller Information:</strong> {data.additionalInfo}
                    <br />
                  </p>
                  <form onSubmit={this.buttonHandler}>
                    <div className="form-group">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="termsAndConditions"
                          checked={popupIAgreeCheck}
                          onChange={(e) => this.props.popupIAgreeCheckChangeHandler()}
                          required
                        />
                        <input type="hidden" value={data.draftOfferId} id="draftOfferId" />
                        <label className="form-check-label">
                          I agree to the{' '}
                          <a href="https://www.projectn95.org/market-terms">Marketplace terms and conditions</a> to
                          include the <strong>5% platform fee</strong>.
                        </label>
                      </div>
                    </div>
                    <button
                      id="acceptBtn"
                      type="submit"
                      className="btn btn-block btn-primary donate"
                      // onClick={this.buttonHandler}
                      //  disabled={disabled}
                    >
                      {this.state.buttonClicked === false ? (
                        `Confirm Offer ($${data.total})`
                      ) : (
                        <>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{' '}
                          Processing...
                        </>
                      )}
                    </button>
                  </form>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        );
      }
    },
  ),
);

import { putResolve, take, call, spawn } from 'redux-saga/effects';
import { sellerRfqPageActions } from './store';
import { api } from '../../app/api';
import { appActions } from '../../app/store';

export function* sellerRfqsPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(sellerRfqPageActions.loadRfqsData().type);
      yield putResolve(sellerRfqPageActions.loadData([]));
      yield putResolve(sellerRfqPageActions.setLoading(true));

      let apiData = yield call(api, `/.netlify/functions/seller-rfqs?id=${data}`);
      yield putResolve(sellerRfqPageActions.setLoading(false));

      if (apiData.error) {
        yield putResolve(appActions.hideNav());
        yield putResolve(appActions.setError(apiData.error));
        return;
      }

      if (apiData.data && apiData.error === null) {
        let requestForArray = [];

        let arr = apiData.data.rfqs.slice().sort(function (a, b) {
          return new Date(b['Created at']) - new Date(a['Created at']);
        });

        let active = [];

        let activeRFQ = [];
        activeRFQ = arr
          .filter(function (item) {
            return item['RFQ Status'] === 'Active';
          })
          .sort((a, b) => new Date(a['Start Time'][0]) - new Date(b['Start Time'][0]));

        let quotedRFQ = [];
        quotedRFQ = arr.filter(function (item) {
          return item['RFQ Status'] === 'Quoted';
        });

        //acombined array for active + quoted RFQS
        active = [...activeRFQ, ...quotedRFQ];
        let unqualified = [];
        unqualified = arr.filter(function (item) {
          return item['RFQ Status'] === 'Unqualified';
        });

        let rfqExpired = [];
        rfqExpired = arr.filter(function (item) {
          return item['RFQ Status'] === 'RFQExpired';
        });

        let allData = {
          active: [],
          unqualified: [],
          rfqExpired: [],
        };
        allData = {
          active: active,
          unqualified: unqualified,
          rfqExpired: rfqExpired,
          MarketWon: apiData.data['Market Won'],
          MarketQuoted: apiData.data['Market Quoted'],
        };

        apiData.data.rfqs.forEach((rfq) => {
          requestForArray.push(rfq['Product Type'][0]);
        });

        let uniqueRequestForArray = requestForArray.filter((v, i, a) => a.indexOf(v) === i);
        yield putResolve(sellerRfqPageActions.loadData(allData));
        yield putResolve(sellerRfqPageActions.setUniqueRequestFor(uniqueRequestForArray));
      }
    } catch (err) {
      console.log('error in SellerPageSaga', err);
      yield putResolve(appActions.hideNav());
      yield putResolve(sellerRfqPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export default function* sellerRfqsRootSaga() {
  yield spawn(sellerRfqsPageSaga);
}

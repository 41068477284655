import React, { Component } from 'react';
import { state } from '../../store';
import { buyerProfileActions, buyerProfileSelector } from './store';
import { appSelector } from '../../app/store';
import firebase from 'firebase';

import { connect } from 'react-redux';
import Input from 'react-phone-number-input/input';
import { withRouter } from 'react-router-dom';
import ErrorPage from '../../components-library/error-page';
export const BuyerProfile = withRouter(
  connect(
    state.select({
      data: buyerProfileSelector((state) => state.data, []),
      originalData: buyerProfileSelector((state) => state.data, []),
      loading: buyerProfileSelector((state) => state.loading, null),
      error: appSelector((state) => state.error, ''),
      dataError: buyerProfileSelector((state) => state.error, {}),
      editMode: buyerProfileSelector((state) => state.editMode, false),
      saveButtonClicked: buyerProfileSelector((state) => state.saveButtonClicked, false),
      user: appSelector((state) => state.user),
    }),
    state.actions(buyerProfileActions, [
      'buyerProfilePageData',
      'setOriginalData',
      'handleInputChange',
      'updateBuyerProfile',
      'setEditMode',
      'setSaveButtonState',
    ]),
  )(
    class BuyerProfile extends Component {
      componentWillMount() {
        const { query, history } = this.props;
        let id = query.get('id');
        if (id === null) {
          history.push('/404');
        } else {
          this.props.buyerProfilePageData(id);
        }
      }
      saveButtonHandler = async (event) => {
        const { data, updateBuyerProfile, user, setSaveButtonState } = this.props;
        event.preventDefault();
        setSaveButtonState(true);
        let taxExemptFormUrl;

        if (data.deleteTaxExemptFile) {
          const storageRef = firebase.storage().ref();

          // Create a reference to the file to delete
          const desertRef = storageRef.child(`tax-exempt-forms/${user.uid}`);

          // Delete the file
          desertRef
            .delete()
            .then(function () {
              console.log('Deleted Image From Firebase Succesfully  ');
            })
            .catch(function (error) {
              console.log('Error From Firebase File Delete Function', error);
            });
        }

        if (data.addedTaxExemptFile) {
          const storageRef = firebase.storage().ref();
          const fileRef = storageRef.child(`tax-exempt-forms/${user.uid}`);
          const snapshot = await fileRef.put(data.addedTaxExemptFile);

          taxExemptFormUrl = await snapshot.ref.getDownloadURL();
        }
        let taxExemptChanged = true;
        if (data.addedTaxExemptFile === undefined && data.deleteTaxExemptFile === undefined) {
          taxExemptChanged = false;
        }

        let payload = {
          id: data.BuyerRecordID,
          Buyer: data.Buyer,
          'First Name': data.FirstName,
          'Last Name': data.LastName,
          'Buyer Email': data.BuyerEmail,
          'Buyer Phone': data.BuyerPhone,
          City: data.City,
          State: data.State,
          'Buyer Org Type': data.BuyerOrgType,
          'Buyer Other Org Name': data.BuyerOtherOrgName,
          'Organization Address': data.OrganizationAddress,
          ZipCode: data.ZipCode,
          taxExemptFormUrl: taxExemptFormUrl,
          taxExemptChanged: taxExemptChanged,
        };
        // resetTaxExemptVariable();
        updateBuyerProfile(payload);
      };
      render() {
        const { data, loading, error, editMode, saveButtonClicked } = this.props;
        let status = false;
        if (editMode === true) {
          status = true;
        }
        //actions
        const { handleInputChange, setOriginalData, setEditMode } = this.props;
        if (loading) {
          return (
            <section className="container page-content pb-6 market-content text-center mt-4">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </section>
          );
        }
        if (error) {
          return <ErrorPage error={error} />;
        }

        return (
          <>
            <div className="pt-3 pb-7 bg-dark bg-ellipses">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-md-10 col-lg-8 col-xl-6">
                    <h1 className="display-4 text-center text-white">Your Buyer Profile</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row mt-n7">
                <div className="col-12 col-lg-8 ml-auto mr-auto">
                  <div className="card">
                    <div className="card-body">
                      <div className="text-uppercase text-center text-muted my-2">Profile Information</div>
                      <div>
                        {!status && <h2 className="text-center my-2">{data.Buyer}</h2>}
                        {status ? (
                          <form onSubmit={this.saveButtonHandler}>
                            {/* <div className="form-group mt-3 ">
                            <label>
                              <strong>
                                First Name
                                <span className="form-asterik">*</span>
                              </strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              value={data.FirstName}
                              onChange={(e) => {
                                let payload = {
                                  fieldName: "FirstName",
                                  value: e.target.value
                                };

                                handleInputChange(payload);
                              }}
                            />
                          </div>
                          <div className="form-group mt-3 ">
                            <label>
                              <strong>
                                Last Name
                                <span className="form-asterik">*</span>
                              </strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              value={data.LastName}
                              onChange={(e) => {
                                let payload = {
                                  fieldName: "LastName",
                                  value: e.target.value
                                };

                                handleInputChange(payload);
                              }}
                            />
                          </div>
                          <div className="form-group mt-3 ">
                            <label>
                              <strong>
                                Email
                                <span className="form-asterik">*</span>
                              </strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              value={data.BuyerEmail}
                              onChange={(e) => {
                                let payload = {
                                  fieldName: "BuyerEmail",
                                  value: e.target.value
                                };

                                handleInputChange(payload);
                              }}
                            />
                          </div> */}
                            <div className="form-group mt-3 ">
                              <label>
                                <strong>
                                  Organization Name
                                  <span className="form-asterik">*</span>
                                </strong>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                required
                                value={data.Buyer}
                                onChange={(e) => {
                                  let payload = {
                                    fieldName: 'Buyer',
                                    value: e.target.value,
                                  };

                                  handleInputChange(payload);
                                }}
                              />
                            </div>
                            <div className="form-group mt-3 ">
                              <label>
                                <strong>
                                  Phone Number
                                  <span className="form-asterik">*</span>
                                </strong>
                              </label>
                              <span className="d-flex">
                                <div className="phone-number">+1&nbsp;(US)&nbsp;</div>
                                <Input
                                  country="US"
                                  value={data.BuyerPhone}
                                  required
                                  maxLength="14"
                                  pattern=".{14,14}"
                                  onInvalid={(e) => {
                                    e.target.setCustomValidity('Enter valid phone');
                                  }}
                                  onInput={(e) => {
                                    e.target.setCustomValidity('');
                                  }}
                                  className="form-control"
                                  onChange={(e) => {
                                    let payload = {
                                      fieldName: 'BuyerPhone',
                                      value: e,
                                    };
                                    handleInputChange(payload);
                                  }}
                                />{' '}
                              </span>
                            </div>

                            <div className="form-group mt-3 ">
                              <label>
                                <strong>
                                  Type of Organization
                                  <span className="form-asterik">*</span>
                                </strong>
                              </label>
                              <select
                                className="form-control"
                                id="orgType"
                                required
                                value={
                                  Array.isArray(data.BuyerOrgType) && data.BuyerOrgType.length
                                    ? data.BuyerOrgType[0]
                                    : data.BuyerOrgType
                                }
                                onChange={(e) => {
                                  let payload = {
                                    fieldName: 'BuyerOrgType',
                                    value: e.target.value,
                                  };

                                  handleInputChange(payload);
                                }}
                              >
                                <option value="Ambulatory Surgery Center">Ambulatory Surgery Center</option>
                                <option value="American Indian or Alaska Native Agency">
                                  American Indian or Alaska Native Agency
                                </option>
                                <option value="County/City Entity">County/City Entity</option>
                                <option value="Dental">Dental</option>
                                <option value="Federally Qualified Health Center">
                                  Federally Qualified Health Center
                                </option>
                                <option value="First Responder (Fire, Police, Ambu)">
                                  First Responder (Fire, Police, Ambu)
                                </option>
                                <option value="Free and Charitable Clinics">Free and Charitable Clinics</option>
                                <option value="Funeral Home/Services">Funeral Home/Services</option>
                                <option value="Home health care/hospice">Home health care/hospice</option>
                                <option value="Homeless Services">Homeless Services</option>
                                <option value="Hospital">Hospital</option>
                                <option value="Military Hospital or Clinic">Military Hospital or Clinic</option>
                                <option value="Other">Other</option>
                                <option value="Outpatient medical practice">Outpatient medical practice</option>
                                <option value="Pharmacy/Durable Equipment Supplier (Storefront)">
                                  Pharmacy/Durable Equipment Supplier (Storefront)
                                </option>
                                <option value="Residential Facility">Residential Facility</option>
                                <option value="Rural Health Clinics">Rural Health Clinics</option>
                                <option value="Social Services Organization">Social Services Organization</option>
                                <option value="Urgent Care">Urgent Care</option>
                                <option value="VA Provider">VA Provider</option>
                                <option value="State Government Entity">State Government Entity</option>
                                <option value="University or College">University or College</option>
                                <option value="School (K-12)">School (K-12)</option>
                              </select>
                            </div>
                            {data.BuyerOrgType === 'Other' ? (
                              <div className="form-group mt-3 ">
                                <label>
                                  <strong>
                                    Other Organization Name
                                    <span className="form-asterik">*</span>
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  value={data.BuyerOtherOrgName}
                                  onChange={(e) => {
                                    let payload = {
                                      fieldName: 'BuyerOtherOrgName',
                                      value: e.target.value,
                                    };

                                    handleInputChange(payload);
                                  }}
                                />
                              </div>
                            ) : null}
                            <div className="form-group mt-3 ">
                              <label>
                                <strong>
                                  Organization Address
                                  <span className="form-asterik">*</span>
                                </strong>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                required
                                value={data.OrganizationAddress}
                                onChange={(e) => {
                                  let payload = {
                                    fieldName: 'OrganizationAddress',
                                    value: e.target.value,
                                  };

                                  handleInputChange(payload);
                                }}
                              />
                            </div>
                            <div className="form-group mt-3 ">
                              <label>
                                <strong>
                                  City
                                  <span className="form-asterik">*</span>
                                </strong>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                required
                                value={data.City}
                                onChange={(e) => {
                                  let payload = {
                                    fieldName: 'City',
                                    value: e.target.value,
                                  };

                                  handleInputChange(payload);
                                }}
                              />
                            </div>
                            <div className="form-group mt-3 ">
                              <label>
                                <strong>
                                  State
                                  <span className="form-asterik">*</span>
                                </strong>
                              </label>
                              <select
                                className="form-control"
                                id="state"
                                required
                                value={data.State}
                                onChange={(e) => {
                                  let payload = {
                                    fieldName: 'State',
                                    value: e.target.value,
                                  };

                                  handleInputChange(payload);
                                }}
                              >
                                <option></option>
                                <option>AL</option>
                                <option>AK</option>
                                <option>AZ</option>
                                <option>AR</option>
                                <option>CA</option>
                                <option>CO</option>
                                <option>CT</option>
                                <option>DE</option>
                                <option>FL</option>
                                <option>GA</option>
                                <option>HI</option>
                                <option>ID</option>
                                <option>IL</option>
                                <option>IN</option>
                                <option>IA</option>
                                <option>KS</option>
                                <option>KY</option>
                                <option>LA</option>
                                <option>ME</option>
                                <option>MD</option>
                                <option>MA</option>
                                <option>MI</option>
                                <option>MN</option>
                                <option>MS</option>
                                <option>MO</option>
                                <option>MT</option>
                                <option>NE</option>
                                <option>NV</option>
                                <option>NH</option>
                                <option>NJ</option>
                                <option>NM</option>
                                <option>NY</option>
                                <option>NC</option>
                                <option>ND</option>
                                <option>OH</option>
                                <option>OK</option>
                                <option>OR</option>
                                <option>PA</option>
                                <option>RI</option>
                                <option>SC</option>
                                <option>SD</option>
                                <option>TN</option>
                                <option>TX</option>
                                <option>UT</option>
                                <option>VT</option>
                                <option>VA</option>
                                <option>WA</option>
                                <option>WV</option>
                                <option>WI</option>
                                <option>WY</option>
                                <option>DC</option>
                                <option>PR</option>
                                <option>GU</option>
                                <option>American Samoa</option>
                                <option>Northern Mariana Islands</option>
                                <option>International</option>
                              </select>
                            </div>
                            <div className="form-group mt-3 ">
                              <label>
                                <strong>
                                  Zip or Postal Code
                                  <span className="form-asterik">*</span>
                                </strong>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                required
                                value={data.ZipCode}
                                onInvalid={(e) => e.target.setCustomValidity('Enter valid zip code')}
                                onInput={(e) => e.target.setCustomValidity('')}
                                maxLength="5"
                                pattern="^[0-9]{5}"
                                onChange={(e) => {
                                  let payload = {
                                    fieldName: 'ZipCode',
                                    value: e.target.value,
                                  };

                                  handleInputChange(payload);
                                }}
                              />
                            </div>
                            <div className="form-group mt-3 ">
                              <label className="mb-1 d-flex">
                                <strong>Tax Exempt Form</strong>
                              </label>
                              {data.TaxExemptForm && data.TaxExemptForm[0] && data.deleteTaxExemptFile === undefined ? (
                                <div className="list-group-item">
                                  <div className="row align-items-center">
                                    <div className="col-10">
                                      {data.TaxExemptForm[0].type && data.TaxExemptForm[0].type.includes('image') ? (
                                        <a
                                          className="avatar"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={data.TaxExemptForm[0].url}
                                        >
                                          <img
                                            src={data.TaxExemptForm[0].url}
                                            alt={data.TaxExemptForm[0].filename}
                                            className="avatar-img rounded"
                                          />
                                        </a>
                                      ) : (
                                        <a
                                          className="avatar"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={data.TaxExemptForm[0].url}
                                        >
                                          <div className="avatar-title rounded bg-white text-secondary">
                                            <span className="fe fe-folder"></span>
                                          </div>
                                        </a>
                                      )}
                                    </div>

                                    <div className="col-2">
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                        onClick={(_e) => {
                                          //when someone delete file we storing deleteTaxExemptFile variable in data
                                          let payload = {
                                            fieldName: 'deleteTaxExemptFile',
                                            value: data.TaxExemptForm[0],
                                          };
                                          handleInputChange(payload);
                                        }}
                                      >
                                        <span aria-hidden="true">×</span>
                                      </button>{' '}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <small>
                                    If applicable, please upload your tax exempt form so we can properly notify
                                    suppliers to not include tax on your offers.
                                  </small>
                                  <input
                                    type="file"
                                    className="form-control"
                                    onChange={(e) => {
                                      //when someone adds file we storing addedTaxExemptFile variable in data
                                      let payload = {
                                        fieldName: 'addedTaxExemptFile',
                                        value: e.target.files[0],
                                      };
                                      handleInputChange(payload);
                                    }}
                                  />
                                </>
                              )}
                            </div>

                            <div className="row mt-4 mb-4">
                              {status && (
                                <div className="col-12">
                                  {/* <button className="btn  btn-primary form-button" type="submit" id="submitBtn">
                                    <strong>Save</strong>
                                  </button> */}
                                  {saveButtonClicked === false ? (
                                    <button type="submit" className="btn btn-block btn-primary">
                                      Save
                                    </button>
                                  ) : (
                                    <button className="btn btn-block btn-primary" type="button" disabled>
                                      Saving...{' '}
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    </button>
                                  )}

                                  <button
                                    className="btn btn-block btn-link text-muted"
                                    onClick={(_e) => {
                                      setEditMode(false);
                                      setOriginalData();
                                    }}
                                  >
                                    <strong>Cancel</strong>
                                  </button>
                                </div>
                              )}
                            </div>
                          </form>
                        ) : (
                          <>
                            <div className="mb-1">
                              <ul className="list-group list-group-flush">
                                <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                                  <div>Contact Name:</div>
                                  <div>{data.BuyerName}</div>
                                </li>
                                <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                                  <div>Email Address:</div>
                                  <div>{data.BuyerEmail}</div>
                                </li>
                                <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                                  <div>Phone Number:</div> <div>{data.BuyerPhone}</div>
                                </li>
                                <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                                  <div>Address:</div> <div>{data.BuyerFullAddress}</div>
                                </li>
                                <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                                  <div>Organization Type(s):</div>
                                  <div>{data.BuyerOrgType}</div>
                                </li>
                                <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                                  <div>Available Credit:</div>
                                  <div>${data.CreditAmount}</div>
                                </li>
                                <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                                  <div>Tax Exempt:</div>
                                  <div>{data['TaxExempt?'] === true ? 'Yes' : 'No'}</div>
                                </li>
                              </ul>
                            </div>
                            <div className="row justify-content-center mt-4 mb-4">
                              <button
                                className="col-8 btn btn-primary my-4"
                                onClick={(e) => {
                                  setEditMode(true);
                                }}
                              >
                                <strong>Update Your Profile</strong>
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                      <p className="text-center">
                        WARNING: <strong>DO NOT</strong> share this website page/url/link with anyone outside YOUR
                        company. This page contains personal identifiable information (PII) about YOU, YOUR company, and
                        YOUR requests/offers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
    },
  ),
);

import React from 'react';
import { connect } from 'react-redux';
import { aggregationSelector, aggregationActions } from './store';
import { state } from '../../store';
import { Modal } from 'react-bootstrap';
require('react-bootstrap/ModalHeader');

export const AddressVerificationModal = connect(
  state.select({
    addressLine1: aggregationSelector((state) => state.addressLine1, ''),
    addressLine2: aggregationSelector((state) => state.addressLine2, ''),
    quantity: aggregationSelector((state) => state.quantity, ''),
    data: aggregationSelector((state) => state.data, []),
    city: aggregationSelector((state) => state.city, ''),
    zipCode: aggregationSelector((state) => state.zipCode, ''),
    state: aggregationSelector((state) => state.state, ''),
    addressErrMessage: aggregationSelector((state) => state.addressErrMessage, null),
    selectedAddress: aggregationSelector((state) => state.selectedAddress, ''),
    addressList: aggregationSelector((state) => state.addressList, []),
  }),

  state.actions(aggregationActions, ['addressVerificationChangeHandler', 'purchaseHandler']),
)(
  class AddressVerificationModal extends React.PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        buttonClicked: false,
      };
    }
    addressFormSubmit = (event) => {
      const { selectedAddress, data, quantity } = this.props;
      event.preventDefault();
      this.setState({ buttonClicked: true });

      let payload = {
        zipcode: selectedAddress.zipcode,
        qty: quantity,
        aggregatedOrderRecordID: data['Record ID'],
        state: selectedAddress.state,
      };
      this.props.purchaseHandler(payload);
    };

    render() {
      const { addressList, addressLine1, addressLine2, city, zipCode, state, addressErrMessage } = this.props;
      return (
        <Modal
          show={this.props.show}
          onHide={(e) => {
            this.setState({ buttonClicked: false });
            this.props.addressVerificationChangeHandler('');
            this.props.onHide();
          }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton className="card-header">
            <Modal.Title id="contained-modal-title-vcenter">
              <h1 className="card-header-title pt-3 text-uppercase">Address Verification</h1>
            </Modal.Title>
          </Modal.Header>
          <div className="modal-content">
            <form id="addressForm" onSubmit={this.addressFormSubmit}>
              <div className="modal-body mx-3 market-content">
                <strong>Original Address: </strong>
                <span>
                  {addressLine1}, {addressLine2 ? `${addressLine2},` : null} {city}, {state}, {zipCode}
                </span>
                <hr />
                {addressErrMessage !== null && (
                  <>
                    <strong>{addressErrMessage}</strong>
                    <hr />
                  </>
                )}

                <strong className="mt-3">Recommended Addresses: </strong>
                <div className="form-group market-agg-radio-list" id="addressRadios">
                  {addressList.map((address, index) => {
                    return (
                      <div className="form-check mt-1" key={index}>
                        <span
                          onClick={(e) => {
                            address['index'] = index;
                            this.props.addressVerificationChangeHandler(address);
                          }}
                        >
                          <input
                            type="radio"
                            className="form-check-input"
                            required
                            name="address"
                            checked={this.props.selectedAddress.index === index}
                            onChange={(e) => {}}
                            value={index}
                          />
                          <label className="form-check-label" style={{ cursor: 'pointer' }}>
                            {address.notVerified === true && (
                              <>
                                <b>{'Use Original Address (not verified by UPS)'}</b>
                                <br />
                              </>
                            )}
                            {address.line1}, {address.line2.trim().length ? ' ' + address.line2 + ',' : ''}{' '}
                            {' ' + address.city}, {address.state}, {address.zipcode}
                          </label>
                        </span>
                      </div>
                    );
                  })}
                </div>
                <hr />
                <p style={{ margin: 0 }}>
                  <strong>NOTES:</strong> (1) Please ensure your address is accurate and NOT a PO Box. (2) Changes in
                  delivery address after shipping has begun incurs a fee of $17 or higher.
                </p>
              </div>

              <div className="modal-footer">
                <button
                  type="submit"
                  id="addressPopupSubmitBtn"
                  className="btn btn-block btn-primary"
                  disabled={this.state.buttonClicked}
                >
                  {this.state.buttonClicked === false ? (
                    `Confirm Address`
                  ) : (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{' '}
                      Processing...
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal>
      );
    }
  },
);

import { putResolve, take, call, spawn } from 'redux-saga/effects';
import { productDetailsPageActions } from './store';
import { api } from '../../app/api';
import { appActions } from '../../app/store';

export function* productDetailsPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(productDetailsPageActions.loadProductDetails().type);
      yield putResolve(productDetailsPageActions.setLoading(true));
      let apiData = yield call(api, `/.netlify/functions/single-product-details?id=${data}`);
      yield putResolve(productDetailsPageActions.setLoading(false));
      if (apiData.error) {
        yield putResolve(appActions.hideNav());
        yield putResolve(appActions.setError(apiData.error));
        return;
      }
      if (apiData.data && apiData.error === null) {
        yield putResolve(appActions.setId(apiData.data['Supplier Record ID']));

        yield putResolve(productDetailsPageActions.loadData(apiData.data));
      }
    } catch (err) {
      console.log('error in productDetailsPageSaga', err);
      yield putResolve(productDetailsPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export default function* productDetailsRootSaga() {
  yield spawn(productDetailsPageSaga);
}

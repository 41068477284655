import React from 'react';
import ReactPlaid from 'react-plaid';
import { connect } from 'react-redux';
import { state } from '../../store';
import { Modal } from 'react-bootstrap';
import { dealPageSelector, dealPageActions } from './store';
require('react-bootstrap/ModalHeader');
export const PurchaseProduct = connect(
  state.select({
    data: dealPageSelector((state) => state.data, []),
    purchaseDelivery: dealPageSelector((state) => state.purchaseDelivery, ''),
    paymentMethod: dealPageSelector((state) => state.paymentMethod, ''),
    iAgreeCheck: dealPageSelector((state) => state.iAgreeCheck, false),
    shippingAddress: dealPageSelector((state) => state.shippingAddress, ''),
    iAgreePopupCheck: dealPageSelector((state) => state.iAgreePopupCheck, false),
  }),
  state.actions(dealPageActions, [
    'offersFieldsChangeHandler',
    'iAgreePopupCheckChangeHandler',
    'purchaseProduct',
    'purchaseProductCC',
    'purchaseProductACHDebit',
    'resetPopup',
  ]),
)(
  class PurchaseProduct extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        buttonClick: false,
        plaidData: null,
        open: false,
      };
    }
    buttonHandler = (event) => {
      event.preventDefault();
      const { data, shippingAddress, iAgreePopupCheck, purchaseDelivery, paymentMethod } = this.props;
      this.setState({ buttonClick: true });
      let payload = {
        TOS: iAgreePopupCheck,
        notes: purchaseDelivery,
        offerId: data.ID,
        offerRecordId: data['Record ID'],
        shippingAddress: shippingAddress,
      };
      payload.offerId = `${payload.offerId}`;
      if (paymentMethod === 'cc') {
        this.props.purchaseProductCC({ ...payload });
      } else if (paymentMethod === 'ach') {
        //this.props.purchaseProductACHDebit({ ...payload });
        this.setState({ open: true });
      } else {
        this.props.purchaseProduct({ ...payload });
      }
    };
    render() {
      const { data, offersFieldsChangeHandler, iAgreePopupCheck, paymentMethod, purchaseDelivery } = this.props;
      return (
        <div className="card">
          <Modal
            show={this.props.show}
            onHide={() => {
              this.setState({ buttonClick: false });
              this.props.resetPopup();
              this.props.onHide();
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <form onSubmit={this.buttonHandler}>
              <Modal.Header closeButton className="card-header ">
                <Modal.Title id="contained-modal-title-vcenter ">
                  <h3 className="card-header-title pt-3">Purchase Confirmation</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="mb-3">
                <div className="container">
                  <h3 className="mt-0 mb-0">{data['Supplier Name']}</h3>
                  {data.Manufacturer}
                  <br />
                  {data['Product Model']}
                  <br />
                  {data['Level of Isolation Gown']}
                  {data['Level of Surgical Mask']}
                  <p className="mt-3 mb-3" style={{ fontSize: '16px' }}>
                    <strong>Quote Price:</strong> ${data['Quote Price']} per unit
                    <br />
                    <strong>Quantity (each/ounce):</strong> {data.Quantity}
                    {/* <br />
                  <strong>Payment Terms:</strong> {data["Payment Terms"]} */}
                    <br />
                    <strong>Delivered within:</strong> {data['Delivery By']}
                    <br />
                    <strong>Additional Information:</strong>
                    {data['Additional Information']}
                  </p>
                  Subtotal: ${data.Subtotal}
                  <br />
                  Taxes: ${data.Taxes}
                  <br />
                  Shipping: ${data['Shipping Cost']}
                  <br />
                  <h5 className="mt-3 mb-4">
                    <mark>TOTAL: ${data['Total Cost']}</mark>
                  </h5>
                  <strong>Purchase/Delivery Instructions: </strong>
                  {purchaseDelivery}
                  {paymentMethod !== 'cc' ? (
                    <div className="form-group mt-3" id="shippingSection">
                      <label>
                        <strong>
                          Shipping Address
                          <span style={{ color: 'red' }}>*</span>
                        </strong>
                      </label>
                      <textarea
                        className="form-control"
                        id="shippingAddress"
                        required
                        rows="3"
                        onChange={(e) => {
                          let payload = {
                            value: e.target.value,
                            fieldLabel: 'shippingAddress',
                          };
                          offersFieldsChangeHandler(payload);
                        }}
                      ></textarea>
                    </div>
                  ) : null}
                  <div className="form-check mt-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="modalTermsAndConditions"
                      required
                      checked={iAgreePopupCheck}
                      onChange={(e) => this.props.iAgreePopupCheckChangeHandler()}
                    />
                    <label className="form-check-label ">
                      I agree to the Marketplace&nbsp;
                      <a href="https://www.projectn95.org/market-terms">terms and conditions</a>
                    </label>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" id="purchaseSubmitBtn" className="btn btn-block btn-primary donate">
                  {this.state.buttonClick === false ? (
                    `Purchase ($${data['Total Cost']})`
                  ) : (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{' '}
                      Processing...
                    </>
                  )}
                </button>
                <ReactPlaid
                  env={process.env.REACT_APP_PLAID_ENVIRONMENT}
                  clientName="Project N95 (powered by LVRG)"
                  product={['auth']}
                  selectAccount={true}
                  apiKey="5a44d0a8072777b7b454eab7605d1e"
                  open={this.state.open}
                  onSuccess={(token, metaData) => {
                    const { data, shippingAddress, iAgreePopupCheck, purchaseDelivery } = this.props;
                    let payload = {
                      TOS: iAgreePopupCheck,
                      notes: purchaseDelivery,
                      offerId: `${data.ID}`,
                      offerRecordId: data['Record ID'],
                      shippingAddress: shippingAddress,
                      public_token: token,
                      account_id: metaData.account_id,
                    };
                    this.props.purchaseProductACHDebit({ ...payload });
                  }}
                  onExit={() => this.setState({ buttonClick: false, open: false })}
                />
              </Modal.Footer>
            </form>
          </Modal>
        </div>
      );
    }
  },
);

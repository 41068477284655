import { putResolve, call, spawn, take } from 'redux-saga/effects';
import { api } from '../../app/api';
import { sellerProfileActions } from './store';
import { appActions } from '../../app/store';

function* sellerProfilePageSaga() {
  while (true) {
    try {
      const { payload: id } = yield take(sellerProfileActions.sellerProfilePageData().type);
      let apiData = yield call(api, `/.netlify/functions/seller-profile?id=${id}`);

      yield putResolve(sellerProfileActions.setLoading(false));

      if (apiData.error) {
        yield putResolve(appActions.hideNav());
        yield putResolve(appActions.setError(apiData.error));
        return;
      }

      if (apiData.data && apiData.error === null) {
        // var sellerDetails = {};
        // for (var key in apiData.data) {
        //   sellerDetails[key.replace(/\s/g, "")] = apiData.data[key];
        // }
        yield putResolve(sellerProfileActions.loadSellerProfileDetails(apiData.data));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(appActions.hideNav());
      yield putResolve(sellerProfileActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}
function* updateSellerProfileSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(sellerProfileActions.updateSellerProfile().type);

      yield putResolve(sellerProfileActions.setLoading(true));

      const result = yield call(api, `/.netlify/functions/seller-update-profile?id=${data.id}`, 'POST', data);

      yield putResolve(sellerProfileActions.setLoading(false));

      if (result.data && result.error === null) {
        yield putResolve(sellerProfileActions.loadSellerProfileDetails(result.data));
        yield putResolve(sellerProfileActions.setEditMode(false));
      }

      if (result && result.error && result.error.length && result.data === null) {
        yield putResolve(appActions.hideNav());
        yield putResolve(appActions.setError(result.error));
      }
    } catch (error) {
      console.log(error);
      yield putResolve(appActions.hideNav());
      yield putResolve(sellerProfileActions.setLoading(false));
    }
  }
}
export default function* sellerProfilePageSagaRootSaga() {
  yield spawn(sellerProfilePageSaga);
  yield spawn(updateSellerProfileSaga);
}

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { editRfqPageActions, editRfqPageSelector } from './store';
import { state } from '../../store';
import { appSelector } from '../../app/store';
import ErrorPage from '../../components-library/error-page';

export const CreateEditRfqPage = withRouter(
  connect(
    state.select({
      data: editRfqPageSelector((state) => state.data, []),
      loading: editRfqPageSelector((state) => state.loading),
      error: appSelector((state) => state.error, ''),
      categoryList: editRfqPageSelector((state) => state.categoryList, []),
      productId: editRfqPageSelector((state) => state.productId, ''),
      specifications: editRfqPageSelector((state) => state.specifications, ''),
      size: editRfqPageSelector((state) => state.size, ''),
      quantity: editRfqPageSelector((state) => state.quantity, ''),
      timing: editRfqPageSelector((state) => state.timing, ''),
      maxPrice: editRfqPageSelector((state) => state.maxPrice, ''),
      notes: editRfqPageSelector((state) => state.notes, ''),
      headcount: editRfqPageSelector((state) => state.headcount, ''),
      daysOfCoverage: editRfqPageSelector((state) => state.daysOfCoverage, ''),
    }),
    state.actions(editRfqPageActions, [
      'loadData',
      'handleInputChange',
      'updateRfq',
      'createRfq',
      'createRfqLoadData',
      'resetState',
    ]),
  )(
    class CreateEditRfqPage extends Component {
      state = {
        buttonClicked: false,
      };
      componentWillMount() {
        this.props.resetState();
        const { query, editMode = true } = this.props;
        let id = query.get('id');

        if (id) {
          if (editMode === true) {
            this.props.loadData(id);
          } else {
            this.props.createRfqLoadData(id);
          }
        } else {
          this.props.history.push('/');
        }
      }

      formSubmitHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ buttonClicked: true });
        const {
          productId,
          specifications,
          size,
          quantity,
          timing,
          maxPrice,
          notes,
          data,
          editMode = true,
          headcount,
          daysOfCoverage,
          query,
        } = this.props;

        if (editMode === true) {
          //update rfq
          let payload = {
            productId,
            specifications,
            size,
            quantity,
            timing,
            maxPrice,
            notes,
            id: data['Record ID'],
            hotlineMERs: data['Hotline MERs'][0],
            daysOfCoverage,
            headcount,
            history: this.props.history,
          };
          this.props.updateRfq(payload);
        } else {
          //creating rfq
          let payload = {
            buyerId: query.get('id'),
            productId,
            specifications,
            size,
            quantity,
            timing,
            maxPrice,
            notes,
            history: this.props.history,
            daysOfCoverage,
            headcount,
          };
          this.props.createRfq(payload);
        }
      };
      breadcrumb = () => {
        const { editMode = true, data } = this.props;
        return (
          <div className="header">
            <div className="container-fluid pt-5">
              {editMode === true && (
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href={'/buyer-rfqs?id=' + data['Buyer Record ID']}>Request For Quotes</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href={'/offer?id=' + data['Record ID']}>Offers</a>
                    </li>
                    <li className="breadcrumb-item active">Edit Request For Quote</li>
                  </ol>
                </nav>
              )}
            </div>
          </div>
        );
      };
      render() {
        const {
          data,
          loading,
          error,
          categoryList,
          productId,
          specifications,
          quantity,
          size,
          timing,
          maxPrice,
          notes,
          editMode = true,
          headcount,
          daysOfCoverage,
        } = this.props;
        //editMode coming from app/index

        //actions
        const { handleInputChange, history } = this.props;

        if (loading) {
          return (
            <>
              {this.breadcrumb()}

              <section className="container page-content pb-6 market-content text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </section>
            </>
          );
        }
        if (error && error.length > 0) {
          return <ErrorPage error={error} />;
        }

        let allCategories = categoryList.map((item) => {
          return { ...item, label: item.name, value: item.id };
        });

        let selectedType = { value: '', label: 'Select One' }; //allCategories ? allCategories[0] : "";

        if (productId && productId.length) {
          selectedType = allCategories.filter((item) => {
            return productId === item.id;
          })[0];
        }

        let quantityText = '';

        if (
          selectedType.name === 'Alcohol-based sanitizer products' ||
          selectedType.name === 'Hard Surface Disinfectants'
        ) {
          quantityText = `(How much do you want? Please submit Amount in Fluid Ounces.(i.e. for 1 gallon please enter 128 in this field) Please put preferred bottle size, if you have one, in the comments below.)`;
        } else if (selectedType.name === 'Wipes') {
          quantityText = `(How much do you want? Please submit the total # of Wipes. (ie. for 10 canisters of 100 wipes please enter 1000 in this field.))`;
        } else if (selectedType.name === 'Other') {
          quantityText = '';
        } else if (
          selectedType.name === 'Sanitizer - Liquid' ||
          selectedType.name === 'Disinfectant - Liquid' ||
          selectedType.name === 'Disinfectant - Spray'
        ) {
          quantityText = '(How much do you want [in ounces]?)';
        } else {
          quantityText = `(How many individual units do you want? [20 = 20 ${selectedType.name}])`;
        }

        return (
          <>
            {this.breadcrumb()}
            <div className="row justify-content-center mb-4">
              <div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
                <h1 className="mb-3 text-uppercase"> request for quote</h1>
                {editMode === true && <p className="mb-5 text-muted text-uppercase"> EDIT mode </p>}
              </div>
            </div>
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-10 col-xl-8">
                  <form className="mb-4" onSubmit={(e) => this.formSubmitHandler(e)}>
                    {/* <!--  types --> */}
                    <div className="form-group">
                      <label>Product Category</label>
                      <span className="form-asterik">*</span>
                      <small className="form-text text-muted">Please pick one based on your needs.</small>
                      <select
                        className="form-control"
                        value={selectedType ? selectedType.value : ''}
                        onChange={(e) => {
                          let payload = {
                            fieldName: 'productId',
                            value: e.target.value,
                          };
                          handleInputChange(payload);
                        }}
                        required
                      >
                        {editMode === false && (
                          <option value="" disabled>
                            Select One
                          </option>
                        )}

                        {allCategories.map((item, index) => {
                          return (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="form-group">
                      <span>
                        <label>
                          Quantity{' '}
                          {productId === '' ? (
                            <label>
                              {' '}
                              (base unit of measure = each / ounces / wipes)<span className="form-asterik">*</span>
                            </label>
                          ) : selectedType.name === 'Alcohol-based sanitizer products' ||
                            selectedType.name === 'Hard Surface Disinfectants' ? (
                            <>
                              {quantityText}
                              <span className="form-asterik">*</span>
                            </>
                          ) : (
                            <>
                              {quantityText}
                              <span className="form-asterik">*</span>
                            </>
                          )}
                        </label>
                      </span>

                      <small className="form-text text-muted">
                        NOTE: The SUPPLIER will also provide a quantity depending on packaging and availability
                      </small>
                      <input
                        type="number"
                        pattern="[0-9]*"
                        min="1"
                        defaultValue={quantity}
                        className="form-control"
                        onChange={(e) => {
                          let payload = {
                            fieldName: 'quantity',
                            value: e.target.value,
                          };
                          handleInputChange(payload);
                        }}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label>Product Specifications</label>
                      <small className="form-text text-muted">
                        Any details about the product, manufacture/model, etc that is worth including and sharing with
                        the SELLERs
                      </small>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={specifications}
                        onChange={(e) => {
                          let payload = {
                            fieldName: 'specifications',
                            value: e.target.value,
                          };
                          handleInputChange(payload);
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Size</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={size}
                        onChange={(e) => {
                          let payload = {
                            fieldName: 'size',
                            value: e.target.value,
                          };
                          handleInputChange(payload);
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Timing (days)</label>
                      <span className="form-asterik">*</span>
                      <small className="form-text text-muted">
                        Needed by in number of days. If set to 0 (ZERO), this means As Soon As Possible (ASAP)
                      </small>
                      <input
                        type="number"
                        defaultValue={timing}
                        pattern="[0-9]*"
                        min="0"
                        className="form-control"
                        onChange={(e) => {
                          let payload = {
                            fieldName: 'timing',
                            value: e.target.value,
                          };
                          handleInputChange(payload);
                        }}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label>Max Price Per Product Unit</label>
                      <span className="form-asterik">*</span>
                      <small className="form-text text-muted">
                        If "Negotiable" set to 0, see projectn95.org/products for average pricing based on Offers we
                        receive on the Marketplace
                      </small>
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        defaultValue={maxPrice}
                        className="form-control"
                        onChange={(e) => {
                          let payload = {
                            fieldName: 'maxPrice',
                            value: e.target.value,
                          };
                          handleInputChange(payload);
                        }}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label>Headcount</label> <span className="form-asterik">*</span>
                      <small className="form-text text-muted">Number of people this purchase will support</small>
                      <input
                        type="number"
                        pattern="[0-9]*"
                        min="0"
                        className="form-control"
                        defaultValue={headcount}
                        onChange={(e) => {
                          let payload = {
                            fieldName: 'headcount',
                            value: e.target.value,
                          };
                          handleInputChange(payload);
                        }}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label>Minimum Days of Coverage</label> <span className="form-asterik">*</span>
                      <small className="form-text text-muted">
                        The duration of time this purchase of PPE would be supporting your staff (conservative estimate)
                      </small>
                      <input
                        type="number"
                        pattern="[0-9]*"
                        min="0"
                        className="form-control"
                        defaultValue={daysOfCoverage}
                        onChange={(e) => {
                          let payload = {
                            fieldName: 'daysOfCoverage',
                            value: e.target.value,
                          };
                          handleInputChange(payload);
                        }}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label>Additional Product Request Notes</label>
                      <small className="form-text text-muted">
                        Please add anything here that may be helpful to assist Project N95 with matching and finding you
                        PPE!{' '}
                      </small>
                      <textarea
                        row={'2'}
                        defaultValue={notes}
                        className="form-control"
                        onChange={(e) => {
                          let payload = {
                            fieldName: 'notes',
                            value: e.target.value,
                          };
                          handleInputChange(payload);
                        }}
                      ></textarea>
                    </div>

                    {/* <!-- Divider --> */}
                    <hr className="mt-5 mb-5" />

                    {/* <!-- Buttons --> */}

                    {this.state.buttonClicked === false ? (
                      <button type="submit" className="btn btn-block btn-primary">
                        {editMode === true ? 'UPDATE' : 'CREATE'}
                      </button>
                    ) : (
                      <button className="btn btn-block btn-primary" type="button" disabled>
                        Saving...{' '}
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </button>
                    )}

                    {editMode === true ? (
                      <button
                        onClick={(e) => history.push(`/offer?id=${data['Record ID']}`)}
                        className="btn btn-block btn-link text-muted"
                      >
                        Cancel
                      </button>
                    ) : null}
                  </form>
                </div>
              </div>
            </div>
          </>
        );
      }
    },
  ),
);

import { putResolve, take, call, spawn, select } from 'redux-saga/effects';
import { aggregationActions, aggregationSelector } from './store';
import { api } from '../../app/api';
import { appActions } from '../../app/store';

export function* aggregationPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(aggregationActions.loadAggData().type);

      let apiData = yield call(api, `/.netlify/functions/agg?id=${data.id}&transact_id=${data.transactId}`);

      yield putResolve(aggregationActions.setLoadingStatus({ status: false }));

      if (apiData.error) {
        yield putResolve(appActions.setError(apiData.error));
        yield putResolve(appActions.hideNav());
        return;
      }
      if (apiData.data) {
        yield putResolve(aggregationActions.loadData(apiData.data));
        yield putResolve(appActions.setId(apiData.data['MER ID']));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(appActions.hideNav());
      yield putResolve(aggregationActions.setLoadingStatus({ status: false }));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export function* purchaseHandlerSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(aggregationActions.purchaseHandler().type);

      let apiData = yield call(
        api,
        `/.netlify/functions/calculate-taxes-and-shipping?zipcode=${data.zipcode}&qty=${data.qty}&agg=${data.aggregatedOrderRecordID}&state=${data.state}`,
      );

      if (apiData.data) {
        yield putResolve(aggregationActions.setPurchaseConfirmationData(apiData.data));
        //closing address verification popup
        yield putResolve(aggregationActions.setAVModalShow(false));

        yield putResolve(aggregationActions.setPCModalShow(true));
      } else if (apiData.error) {
        yield putResolve(aggregationActions.setPCModalShow(false));
        yield putResolve(appActions.setError(apiData.error));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(aggregationActions.setPCModalShow(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export function* purchaseConfirmationHandlerSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(aggregationActions.purchaseConfirmationHandler().type);

      const apiData = yield call(api, `/.netlify/functions/aggregation-buyer`, 'POST', data.payload);

      if (apiData.data) {
        if (apiData.data.type === 'redirect') {
          window.location.href = apiData.data.value;
        }

        if (apiData.data.type === 'session') {
          //  window.location.href = apiData.data.value;
          let stripe = yield select(aggregationSelector((state) => state.stripe));
          stripe
            .redirectToCheckout({
              sessionId: apiData.data.value.id,
            })
            .then(function (result) {
              console.log('result ', result);
              // If `redirectToCheckout` fails due to a browser or network
              // error, display the localized error message to your customer
              // using `result.error.message`.
              console.log('Something here', result.error.message);
            });
        } else {
          yield putResolve(aggregationActions.setPCModalShow(false));
          yield putResolve(appActions.setError(apiData.error));
        }
      } else if (apiData.error) {
        yield putResolve(aggregationActions.setPCModalShow(false));
        yield putResolve(appActions.setError(apiData.error));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(aggregationActions.setPCModalShow(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export function* addressVerificationSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(aggregationActions.addressVerification().type);

      let apiData = yield call(api, `/.netlify/functions/validate-address`, 'POST', data);

      const stateObj = yield select(aggregationSelector((state) => state, null));

      const address = {
        line1: stateObj.addressLine1,
        line2: stateObj.addressLine2,
        city: stateObj.city,
        state: stateObj.state,
        zipcode: stateObj.zipCode,
        notVerified: true,
      };

      if (apiData.data && Array.isArray(apiData.data) && apiData.data.length) {
        yield putResolve(aggregationActions.setAddressList([...apiData.data, address]));
        yield putResolve(aggregationActions.setAddressErrMessage(''));
        yield putResolve(aggregationActions.setAVModalShow(true));
      } else if (apiData.error) {
        yield putResolve(aggregationActions.setAddressList([address]));
        yield putResolve(
          aggregationActions.setAddressErrMessage(
            `Something went wrong with address verification. Please contact support Or Use original address not verified by UPS`,
          ),
        );
        yield putResolve(aggregationActions.setAVModalShow(true));
      } else {
        yield putResolve(aggregationActions.setAddressList([address]));
        yield putResolve(aggregationActions.setAddressErrMessage('No verified address was found.'));
        yield putResolve(aggregationActions.setAVModalShow(true));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(aggregationActions.setPCModalShow(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export default function* aggregationpageRootSaga() {
  yield spawn(aggregationPageSaga);
  yield spawn(purchaseHandlerSaga);
  yield spawn(purchaseConfirmationHandlerSaga);
  yield spawn(addressVerificationSaga);
}

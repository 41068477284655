import { putResolve, call, spawn, take } from 'redux-saga/effects';
import { api } from '../../app/api';
import { appActions } from '../../app/store';
import { groupOrdersPageActions } from './store';
function* groupOrdersPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(groupOrdersPageActions.buyerDetails().type);

      let apiData = yield call(api, `/.netlify/functions/group-orders?id=${data}`);

      yield putResolve(groupOrdersPageActions.setLoading(false));

      if (apiData.error) {
        yield putResolve(appActions.setError(apiData.error));
        yield putResolve(appActions.hideNav());
        return;
      }
      if (apiData.data && apiData.error === null) {
        let groupoOrdersDetails = {};
        for (let key in apiData.data) {
          groupoOrdersDetails[key.replace(/\s/g, '')] = apiData.data[key];
        }

        yield putResolve(groupOrdersPageActions.loadData(groupoOrdersDetails));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(appActions.hideNav());
      yield putResolve(groupOrdersPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}
export function* viewOfferSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(groupOrdersPageActions.viewOffer().type);
      yield putResolve(groupOrdersPageActions.setLoading(true));
      let result = yield call(api, `/.netlify/functions/agg-offer-submission`, 'POST', {
        id: data.id,
        buyerId: data.buyerId,
      });

      yield putResolve(groupOrdersPageActions.setLoading(false));
      if (result.error === null && result.data && result.data.type === 'redirect') {
        if (result.data.value.includes(data.history.location.pathname)) {
          data.history.go(result.data.value);
        } else {
          data.history.push(result.data.value);
        }
      } else if (result.error) {
        yield putResolve(appActions.setError('Something went wrong'));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(groupOrdersPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}
export default function* groupOrdersPageRootSaga() {
  yield spawn(groupOrdersPageSaga);
  yield spawn(viewOfferSaga);
}

import { useState, useEffect } from 'react';

export default function useCheckEmailVerification(user) {
  const [emailVerified, setEmailVerified] = useState(user.emailVerified);

  useEffect(() => {
    if (!emailVerified) {
      const timer = setInterval(async () => {
        await user.reload();

        if (user.emailVerified) {
          await user.getIdToken(true);

          setEmailVerified(user.emailVerified);
        }
      }, 2000);

      return () => clearInterval(timer);
    }
  }, [emailVerified, user]);

  return emailVerified;
}

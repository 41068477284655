import { state } from '../../store';
import { taxes } from '../../components-library/taxes-data';

export const [aggregationActions, aggregationSelector, aggregationState] = state('Aggregation', {
  initial: {
    data: [],
    loading: true,
    formIAgreeCheck: false,
    popupIAgreeCheck: false,
    noOfWorkers: '',
    daysOfCoverage: '',
    phone: '',
    quantity: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    zipCode: '',
    state: '',
    pcModalShow: false,
    puertoRicoModalShow: false,
    purchaseConfirmationData: '',
    stripe: '',
    addressList: [],
    selectedAddress: '',
    addressErrMessage: null,
    isExpired: false,
  },
  on: {
    loadData: (_, data) => {
      return { ..._, data };
    },
    setLoadingStatus: (_, data) => {
      return { ..._, loading: data.status };
    },
    setPurchaseConfirmationData: (_, data) => {
      return { ..._, purchaseConfirmationData: data };
    },
    setAddressList: (_, data) => {
      return { ..._, addressList: data };
    },
    setAddressErrMessage: (_, data) => {
      return { ..._, addressErrMessage: data };
    },
    setExpiredTime: (_, status) => {
      return { ..._, isExpired: status };
    },
    addressVerificationChangeHandler: (_, data) => {
      return { ..._, selectedAddress: data };
    },
    handleInputChange: (_, result) => {
      let newStoreObj = { ..._ };

      const { fieldName, value } = result;

      let error = { ...newStoreObj.error };

      //storing value in variable
      newStoreObj[fieldName] = value;

      if (fieldName === 'zipCode') {
        let zipcode = value;
        let zipcodes = Object.keys(taxes);
        if (!zipcodes.includes(zipcode)) {
          newStoreObj.state = '';
        } else {
          let currentZip = taxes[zipcode];
          newStoreObj.state = currentZip['State'];
        }
      }

      return { ...newStoreObj, error: error };
    },
    formIAgreeCheckChangeHandler: (_) => {
      let newStoreObj = { ..._ };

      newStoreObj.formIAgreeCheck = !newStoreObj.formIAgreeCheck;

      return {
        ...newStoreObj,
      };
    },
    popupIAgreeCheckChangeHandler: (_) => {
      let newStoreObj = { ..._ };

      newStoreObj.popupIAgreeCheck = !newStoreObj.popupIAgreeCheck;

      return {
        ...newStoreObj,
      };
    },
    setPCModalShow: (_, status) => {
      let newStoreObj = { ..._ };

      newStoreObj.pcModalShow = status;

      return {
        ...newStoreObj,
      };
    },
    setPuertoRicoModalShow: (_, status) => {
      let newStoreObj = { ..._ };

      newStoreObj.puertoRicoModalShow = status;

      return {
        ...newStoreObj,
      };
    },
    setAVModalShow: (_, status) => {
      let newStoreObj = { ..._ };

      newStoreObj.avModalShow = status;

      return {
        ...newStoreObj,
      };
    },
    setStripe: (_, data) => {
      let newStoreObj = { ..._, stripe: data };
      return newStoreObj;
    },
  },
  events: ['loadAggData', 'purchaseHandler', 'purchaseConfirmationHandler', 'addressVerification'],
});

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { usersPageActions, usersPageSelector } from './store';
import { appSelector } from '../../app/store';

const UserEditPage = () => {
  const decodedToken = useSelector(appSelector((state) => state.decodedToken));
  const { error, loading, user } = useSelector(usersPageSelector((state) => state));
  const dispatch = useDispatch();
  const params = useParams();
  const [email, setEmail] = useState('');
  const [userType, setUserType] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [buyerId, setBuyerId] = useState('');
  const [sellerId, setSellerId] = useState('');
  const [roles, setRoles] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailVerified, setEmailVerified] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [lastLoggedInAt, setLastLoggedInAt] = useState('');
  const [disabled, setDisabled] = useState('');

  const uid = params.uid;

  useEffect(() => {
    dispatch(usersPageActions.getUser(uid));

    return () => dispatch(usersPageActions.clearUser());
  }, [dispatch, uid]);

  useEffect(() => {
    if (user) {
      setEmail(user.email);

      const displayName = user.displayName || '';
      const nameParts = displayName.split(' ');

      setFirstName(nameParts.shift());
      setLastName(nameParts.join(' '));

      const claims = user.customClaims || {};

      setTenantId(claims.tenantId);
      setBuyerId(claims.buyerId);
      setSellerId(claims.sellerId);
      setUserType(claims.userType);
      setRoles(claims.roles || []);
      setEmailVerified(user.emailVerified);
      setCreatedAt(user.metadata.creationTime || '');
      setLastLoggedInAt(user.metadata.lastSignInTime || '');
      setDisabled(user.disabled);
    }
  }, [user]);

  if (loading) {
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="container-fluid">
          <div className="header-body">
            <div className="row align-items-end">
              <div className="col">
                <h1 className="header-title">Edit User</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Alert variant="danger" show={Boolean(error)}>
              {error}
            </Alert>
            <div style={{ marginBottom: 20 }}>
              <a href="/internal/users">Return to users list</a>
            </div>
            <div className="card">
              <div className="card-body">
                <Form
                  onSubmit={async (e) => {
                    const form = e.currentTarget;

                    e.preventDefault();

                    if (form.checkValidity() === false) {
                      e.stopPropagation();
                    }

                    dispatch(
                      usersPageActions.updateUser({
                        uid,
                        firstName,
                        lastName,
                        userType,
                        buyerId,
                        sellerId,
                        tenantId,
                        roles,
                      }),
                    );
                  }}
                >
                  <Form.Group as={Row} controlId="formEmail">
                    <Form.Label column sm="2">
                      Email Address
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control type="email" placeholder="Enter email" readOnly plaintext value={email} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formVerified">
                    <Form.Label column sm="2">
                      Email verified?
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control readOnly plaintext value={String(emailVerified)} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formDisabled">
                    <Form.Label column sm="2">
                      Disabled?
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control readOnly plaintext value={String(disabled)} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formCreatedAt">
                    <Form.Label column sm="2">
                      Created at
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control readOnly plaintext value={createdAt} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formLastLoggedInAt">
                    <Form.Label column sm="2">
                      Last logged in at
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control readOnly plaintext value={lastLoggedInAt} />
                    </Col>
                  </Form.Group>

                  <Row style={{ marginBottom: 20 }}>
                    <Col sm="2">
                      <Button
                        style={{ width: '100%' }}
                        variant="secondary"
                        onClick={async (e) => {
                          e.preventDefault();

                          dispatch(usersPageActions.sendPasswordResetEmail({ email }));
                        }}
                      >
                        Send password reset email
                      </Button>
                    </Col>
                    {disabled ? (
                      <Col sm="2">
                        <Button
                          style={{ width: '100%' }}
                          variant="secondary"
                          onClick={(e) => {
                            e.preventDefault();

                            dispatch(usersPageActions.enableUser({ uid }));
                          }}
                        >
                          Enable user
                        </Button>
                      </Col>
                    ) : (
                      <Col sm="2">
                        <Button
                          style={{ width: '100%' }}
                          variant="secondary"
                          onClick={(e) => {
                            e.preventDefault();

                            dispatch(usersPageActions.disableUser({ uid }));
                          }}
                        >
                          Disable user
                        </Button>
                      </Col>
                    )}
                    <Col sm="2">
                      <Button
                        style={{ width: '100%' }}
                        variant="secondary"
                        disabled={emailVerified}
                        onClick={(e) => {
                          e.preventDefault();

                          dispatch(usersPageActions.sendVerificationEmail({ uid }));
                        }}
                      >
                        Resend verification email
                      </Button>
                    </Col>
                    <Col sm="2">
                      <Button
                        style={{ width: '100%' }}
                        variant="secondary"
                        disabled={emailVerified}
                        onClick={(e) => {
                          e.preventDefault();

                          dispatch(usersPageActions.setEmailToVerified({ uid }));
                        }}
                      >
                        Set email to verified
                      </Button>
                    </Col>
                  </Row>

                  <Form.Group controlId="formBasicFirstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      placeholder="Enter first name"
                      required
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicLastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      placeholder="Enter last name"
                      required
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicUserType">
                    <Form.Label>User Type</Form.Label>
                    <Form.Control as="select" required onChange={(e) => setUserType(e.target.value)} value={userType}>
                      <option></option>
                      <option>buyer</option>
                      <option>seller</option>
                      {decodedToken.claims.roles && decodedToken.claims.roles.includes('admin') && (
                        <option>internal</option>
                      )}
                    </Form.Control>
                  </Form.Group>

                  {userType && userType !== 'internal' && (
                    <Form.Group controlId="formBasicTenant">
                      <Form.Label>Is this a Project N95 user?</Form.Label>
                      <Form.Check
                        type="radio"
                        label="No"
                        name="formBasicTenant"
                        id="formBasicTenant1"
                        checked={tenantId === 1}
                        onChange={() => setTenantId(1)}
                      />
                      <Form.Check
                        type="radio"
                        label="Yes"
                        name="formBasicTenant"
                        id="formBasicTenant2"
                        checked={tenantId === 2}
                        onChange={() => setTenantId(2)}
                      />
                    </Form.Group>
                  )}

                  {userType && userType === 'buyer' && (
                    <Form.Group controlId="formBasicBuyer">
                      <Form.Label>Buyer ID</Form.Label>
                      <Form.Control
                        placeholder="Enter buyer ID"
                        required
                        onChange={(e) => setBuyerId(e.target.value)}
                        value={buyerId}
                      />
                    </Form.Group>
                  )}

                  {userType && userType === 'seller' && (
                    <Form.Group controlId="formBasicSeller">
                      <Form.Label>Seller ID</Form.Label>
                      <Form.Control
                        placeholder="Enter seller ID"
                        required
                        onChange={(e) => setSellerId(e.target.value)}
                        value={sellerId}
                      />
                    </Form.Group>
                  )}

                  {userType && userType === 'internal' && (
                    <Form.Group controlId="formBasicRole">
                      <Form.Label>Role</Form.Label>
                      <Form.Control as="select" required onChange={(e) => setRoles([e.target.value])} value={roles[0]}>
                        <option></option>
                        <option>support</option>
                        <option>admin</option>
                      </Form.Control>
                    </Form.Group>
                  )}

                  <Button variant="primary" type="submit" style={{ width: '100%' }} disabled={loading}>
                    Submit
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserEditPage;

import React, { useState, useEffect } from 'react';

import _ from 'lodash';
import { useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import firebase from 'firebase';
import Input from 'react-phone-number-input/input';
import { appSelector } from '../../app/store';
import { signupPageActions, signupPageSelector } from './store';
import { AuthPage, AuthBody, AuthExtra } from '../auth-components';
import useCheckEmailVerification from './use-check-email-verification';
import * as associations from './associations';

const SCHOOL = 'School (K-12)';
const COLLEGE = 'University or College';
const CHILDCARE = 'Childcare Facility';
const ASSOCIATION = 'Industry association/Member organization';
const OTHER = 'Other';

const EDUCATION_TYPES = [SCHOOL, COLLEGE, CHILDCARE];

const SignupBuyerPage = () => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [profession, setProfession] = useState();
  const [profile, setProfile] = useState();
  const [organizationType, setOrganizationType] = useState();
  const [otherOrganizationType, setOtherOrganizationType] = useState();
  const [organizationName, setOrganizationName] = useState();
  const [organizationWebsite, setOrganizationWebsite] = useState();
  const [organizationAddress, setOrganizationAddress] = useState();
  const [organizationCity, setOrganizationCity] = useState();
  const [organizationState, setOrganizationState] = useState();
  const [organizationZip, setOrganizationZip] = useState();
  const [howDidYouHear, setHowDidYouHear] = useState();
  const [association, setAssociation] = useState();
  const [howDidYouHearDetails, setHowDidYouHearDetails] = useState();
  const [taxExemptForm, setTaxExemptForm] = useState();
  const [isEducation, setIsEducation] = useState(false);
  const [utmParams, setUtmParams] = useState({});
  const [associationMemberId, setAssociationMemberId] = useState();
  const otherOrgType = Boolean(organizationType && organizationType === 'Other');
  const needDetails = Boolean(howDidYouHear && howDidYouHear === OTHER);
  const needAssociation = Boolean(howDidYouHear && howDidYouHear === ASSOCIATION);
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading } = useSelector(signupPageSelector((state) => state));
  const { decodedToken, user } = useSelector(appSelector((state) => state));

  // Refresh email verification state in case user has clicked the email link already.
  useCheckEmailVerification(user);

  useEffect(() => {
    const utmParamsJson = window.localStorage.getItem('utmParams');

    if (utmParamsJson) {
      setUtmParams(JSON.parse(utmParamsJson));
    }
  }, []);

  // TODO: Remove all this associations landing page stuff. It's all a hack to get
  // people to enter an association name if they were supposed to come from a certain page.
  const [associationLandingPageState, setAssociationLandingPageState] = useState({
    loaded: false,
    exists: false,
  });

  useEffect(() => {
    const associationLandingPageJson = window.localStorage.getItem('associationLandingPage');
    const newAssociationLandingPageState = {
      loaded: true,
      exists: false,
    };

    if (associationLandingPageJson) {
      newAssociationLandingPageState.exists = true;

      const associationLandingPage = JSON.parse(associationLandingPageJson);
      const associationName = _.get(associationLandingPage, 'associations[0]', '');

      if (associationName) {
        setHowDidYouHear(ASSOCIATION);
        setHowDidYouHearDetails(associationName);
        setAssociation(associationName);
      }
    }

    setAssociationLandingPageState(newAssociationLandingPageState);
  }, []);

  if (decodedToken && decodedToken.claims.userType) {
    return <Redirect to="/" />;
  }

  if (!associationLandingPageState.loaded) {
    return <div></div>;
  }

  if (!associationLandingPageState.exists) {
    return <Redirect to="/signup/association" />;
  }
  return (
    <AuthPage error={error} maxWidth={800}>
      <AuthExtra style={{ paddingBottom: 10 }}>
        <span>
          {/* eslint-disable-next-line */}
          <a
            href="#"
            onClick={async () => {
              await firebase.auth().signOut();
              window.location.href = '/login';
            }}
          >
            Return to login page
          </a>
        </span>
      </AuthExtra>
      <AuthBody title="Sign up - continued">
        <p>
          If you or someone you know works for an organization on the frontlines of COVID-19, complete this form to
          register for an account. If you wish to sell PPE,&nbsp;
          <a href="https://airtable.com/shr7P9ZvFuOMuGyHD" alt="Sell PPE">
            click here
          </a>{' '}
          instead.
        </p>
        <p>
          By filling out this form, you agree to allow Project N95 to disclose the information you provide to Project
          N95, consistent with Project N95's mission to connect personal protective equipment (PPE) suppliers to those
          who need it most--the healthcare providers at the frontline.
        </p>
        <Form
          onSubmit={async (e) => {
            const form = e.currentTarget;

            e.preventDefault();

            if (form.checkValidity() === false) {
              e.stopPropagation();
            }

            let taxExemptFormUrl;

            if (taxExemptForm) {
              const storageRef = firebase.storage().ref();
              const fileRef = storageRef.child(`tax-exempt-forms/${user.uid}`);
              const snapshot = await fileRef.put(taxExemptForm);

              taxExemptFormUrl = await snapshot.ref.getDownloadURL();
            }

            dispatch(
              signupPageActions.signupBuyer(
                {
                  phoneNumber,
                  profession,
                  profile,
                  organizationType,
                  otherOrganizationType,
                  isEducation,
                  organizationName,
                  organizationWebsite,
                  organizationAddress,
                  organizationCity,
                  organizationState,
                  organizationZip,
                  howDidYouHear,
                  howDidYouHearDetails,
                  taxExemptFormUrl,
                  utmParams,
                  associationMemberId,
                  association,
                },
                { redirect: (id) => history.push(`/create-rfq?id=${id}`) },
              ),
            );
          }}
        >
          <Form.Group controlId="formBasicPhoneNumber">
            <Form.Label className="required">Phone Number</Form.Label>
            <span className="d-flex">
              <div className="phone-number">+1&nbsp;(US)&nbsp;</div>
              <Input
                type="tel"
                placeholder="Enter phone number"
                required
                country="US"
                maxLength="14"
                pattern=".{14,14}"
                onInvalid={(e) => {
                  e.target.setCustomValidity('Enter valid phone');
                }}
                onInput={(e) => {
                  e.target.setCustomValidity('');
                }}
                className="form-control"
                onChange={(e) => setPhoneNumber(e)}
              />
            </span>
          </Form.Group>

          <Form.Group controlId="formBasicProfession">
            <Form.Label className="required">Title/Profession</Form.Label>
            <Form.Control
              placeholder="I.e. Doctor, Researcher, Department Chair, etc."
              required
              onChange={(e) => setProfession(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicProfile">
            <Form.Label>Profile (LinkedIn, Institution page, etc.)</Form.Label>
            <Form.Control
              placeholder="Please provide proof of association with requesting institution"
              onChange={(e) => setProfile(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicOrganizationType">
            <Form.Label className="required">Type of Organization</Form.Label>
            <Form.Control
              as="select"
              required
              onChange={(e) => {
                const newOrganizationType = e.target.value;

                setOrganizationType(newOrganizationType);

                if (!EDUCATION_TYPES.includes(newOrganizationType)) {
                  setIsEducation(false);
                }
              }}
            >
              <option></option>
              <option>Ambulatory Surgery Center</option>
              <option>County/City Entity</option>
              <option>American Indian or Alaska Native Agency</option>
              <option>{CHILDCARE}</option>
              <option>Dental</option>
              <option>Federally Qualified Health Center</option>
              <option>First Responder (Fire, Police, Ambu)</option>
              <option>Free and Charitable Clinics</option>
              <option>Funeral Home/Services</option>
              <option>Home health care/hospice</option>
              <option>Homeless Services</option>
              <option>Hospital</option>
              <option>Military Hospital or Clinic</option>
              <option>Outpatient medical practice</option>
              <option>Pharmacy/Durable Equipment Supplier (Storefront)</option>
              <option>Residential Facility</option>
              <option>Rural Health Clinics</option>
              <option>Social Services Organization</option>
              <option>State Government Entity</option>
              <option>{COLLEGE}</option>
              <option>Urgent Care</option>
              <option>VA Provider</option>
              <option>{SCHOOL}</option>
              <option>Other</option>
            </Form.Control>
          </Form.Group>
          {otherOrgType && (
            <Form.Group controlId="formOtherOrganizationType">
              <Form.Label className="required">Other Institution</Form.Label>
              <p>Please elaborate on your type of organization</p>
              <Form.Control
                placeholder={'Enter Other Organization Type.'}
                required={otherOrgType}
                onChange={(e) => setOtherOrganizationType(e.target.value)}
              />
            </Form.Group>
          )}
          {organizationType === CHILDCARE && (
            <Form.Group controlId="formOtherIsEducationSchool">
              <Form.Label className="required">
                Will the PPE purchased be used primarily by a nurse or physician?
              </Form.Label>
              <Form.Control as="select" required onChange={(e) => setIsEducation(Boolean(e.target.value))}>
                <option></option>
                <option value={''}>Yes</option>
                <option value={'1'}>No</option>
              </Form.Control>
            </Form.Group>
          )}
          {organizationType === SCHOOL && (
            <Form.Group controlId="formOtherIsEducationSchool">
              <Form.Label className="required">
                Will the PPE purchased be used primarily by a school nurse or school physician?
              </Form.Label>
              <Form.Control as="select" required onChange={(e) => setIsEducation(Boolean(e.target.value))}>
                <option></option>
                <option value={''}>Yes</option>
                <option value={'1'}>No</option>
              </Form.Control>
            </Form.Group>
          )}
          {organizationType === COLLEGE && (
            <Form.Group controlId="formOtherIsEducationCollege">
              <Form.Label className="required">
                Will the PPE purchased be used primarily by a healthcare provider who works for a university or college
                health system or clinic?
              </Form.Label>
              <Form.Control as="select" required onChange={(e) => setIsEducation(Boolean(e.target.value))}>
                <option></option>
                <option value={''}>Yes</option>
                <option value={'1'}>No</option>
              </Form.Control>
            </Form.Group>
          )}
          <Form.Group controlId="formBasicOrganizationName">
            <Form.Label className="required">Name of Organization</Form.Label>
            <Form.Control
              placeholder="Enter organization name"
              required
              onChange={(e) => setOrganizationName(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicOrganizationWebsite">
            <Form.Label>Organization Website</Form.Label>
            <Form.Control
              placeholder="Enter organization website address"
              onChange={(e) => setOrganizationWebsite(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicOrganizationAddress">
            <Form.Label className="required">Organization Street Address</Form.Label>
            <Form.Control
              placeholder="Enter organization street address"
              required
              onChange={(e) => setOrganizationAddress(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicOrganizationCity">
            <Form.Label className="required">Organization City</Form.Label>
            <Form.Control
              placeholder="Enter organization city"
              required
              onChange={(e) => setOrganizationCity(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicOrganizationState">
            <Form.Label className="required">Organization State</Form.Label>
            <Form.Control as="select" required onChange={(e) => setOrganizationState(e.target.value)}>
              <option></option>
              <option>AL</option>
              <option>AK</option>
              <option>AZ</option>
              <option>AR</option>
              <option>CA</option>
              <option>CO</option>
              <option>CT</option>
              <option>DE</option>
              <option>FL</option>
              <option>GA</option>
              <option>HI</option>
              <option>ID</option>
              <option>IL</option>
              <option>IN</option>
              <option>IA</option>
              <option>KS</option>
              <option>KY</option>
              <option>LA</option>
              <option>ME</option>
              <option>MD</option>
              <option>MA</option>
              <option>MI</option>
              <option>MN</option>
              <option>MS</option>
              <option>MO</option>
              <option>MT</option>
              <option>NE</option>
              <option>NV</option>
              <option>NH</option>
              <option>NJ</option>
              <option>NM</option>
              <option>NY</option>
              <option>NC</option>
              <option>ND</option>
              <option>OH</option>
              <option>OK</option>
              <option>OR</option>
              <option>PA</option>
              <option>RI</option>
              <option>SC</option>
              <option>SD</option>
              <option>TN</option>
              <option>TX</option>
              <option>UT</option>
              <option>VT</option>
              <option>VA</option>
              <option>WA</option>
              <option>WV</option>
              <option>WI</option>
              <option>WY</option>
              <option>DC</option>
              <option>PR</option>
              <option>GU</option>
              <option>American Samoa</option>
              <option>Northern Mariana Islands</option>
              <option>International</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formBasicOrganizationZip">
            <Form.Label className="required">Organization Zip or Postal Code</Form.Label>
            <Form.Control
              placeholder="Enter organization postal code"
              required
              onInvalid={(e) => e.target.setCustomValidity('Enter valid zip code')}
              onInput={(e) => e.target.setCustomValidity('')}
              maxLength="5"
              pattern="^[0-9]{5}"
              onChange={(e) => setOrganizationZip(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formBasicTaxExemptForm">
            <Form.File.Label>Tax Exempt Form</Form.File.Label>
            <p>
              If applicable, please upload your tax exempt form so we can properly notify suppliers to not include tax
              on your offers.
            </p>
            <Form.File.Input id="formBasicTaxExemptFormInput" onChange={(e) => setTaxExemptForm(e.target.files[0])} />
          </Form.Group>

          <Form.Group controlId="formBasicHowDidYouHear">
            <Form.Label className="required">How did you hear about us?</Form.Label>
            <Form.Control as="select" required onChange={(e) => setHowDidYouHear(e.target.value)} value={howDidYouHear}>
              <option></option>
              <option>Media (news, social, etc.)</option>
              <option>Friend/colleague</option>
              <option>{ASSOCIATION}</option>
              <option>Government outreach team</option>
              <option>Search enginge</option>
              <option>{OTHER}</option>
            </Form.Control>
          </Form.Group>

          {needDetails && (
            <Form.Group controlId="formBasicHowDidYouHearDetails">
              <Form.Label className="required">Please include details for "Other"</Form.Label>
              <Form.Control
                placeholder={'Elaborate on "other" or include name of organization/association.'}
                required={needDetails}
                onChange={(e) => setHowDidYouHearDetails(e.target.value)}
                value={howDidYouHearDetails}
              />
            </Form.Group>
          )}

          {needAssociation && (
            <>
              <Form.Group controlId="formAssociationName">
                <Form.Label>Which association/organization did you hear about us from?</Form.Label>
                <Form.Control
                  placeholder={'Association name'}
                  onChange={(e) => setHowDidYouHearDetails(e.target.value)}
                  value={howDidYouHearDetails}
                />
              </Form.Group>

              <Form.Group controlId="formAssociationMemberId">
                <Form.Label>Please enter your association member ID if you have one</Form.Label>
                <Form.Control
                  placeholder={'Association member ID'}
                  onChange={(e) => setAssociationMemberId(e.target.value)}
                />
              </Form.Group>
            </>
          )}

          <Button variant="primary" type="submit" style={{ width: '100%' }} disabled={loading}>
            Submit
          </Button>
        </Form>
      </AuthBody>
    </AuthPage>
  );
};

export default SignupBuyerPage;

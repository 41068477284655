import { putResolve, take, call, spawn } from 'redux-saga/effects';
import { sellerOffersPageActions } from './store';
import { api } from '../../app/api';
import { appActions } from '../../app/store';

function* getAllOffers(payload) {
  let data = [];
  let pageToken;

  while (true) {
    const result = yield call(
      api,
      `/.netlify/functions/seller-offers?id=${payload.id}${pageToken ? `&pageToken=${pageToken}` : ''}&status=${
        payload.status
      }`,
    );

    if (result.error) {
      return { error: result.error };
    }

    if (!result.data || !result.data.rfqs || !result.data.rfqs.length) {
      return { data };
    }

    pageToken = result.data.pageToken;
    data = data.concat(result.data.rfqs);
  }
}

export function* sellerOffersPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(sellerOffersPageActions.loadOffersData().type);
      yield putResolve(sellerOffersPageActions.setLoading(true));

      const apiData = yield call(getAllOffers, data);

      yield putResolve(sellerOffersPageActions.setLoading(false));

      if (apiData.error) {
        yield putResolve(appActions.hideNav());
        yield putResolve(appActions.setError(apiData.error));
      } else if (apiData.data) {
        const arr = apiData.data
          ? apiData.data.slice().sort(function (a, b) {
              return new Date(b['Created at']) - new Date(a['Created at']);
            })
          : [];

        yield putResolve(sellerOffersPageActions.loadData({ offers: arr, status: data.status }));
      }
    } catch (err) {
      console.log('error in sellerOffersPageSaga', err);
      yield putResolve(appActions.hideNav());
      yield putResolve(sellerOffersPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export default function* sellerOffersRootSaga() {
  yield spawn(sellerOffersPageSaga);
}

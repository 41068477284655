import { putResolve, call, spawn, take } from 'redux-saga/effects';
import { api } from '../../app/api';
import { buyerDashboardActions } from './store';
import { appActions } from '../../app/store';

function* buyerDashboardPageSaga() {
  while (true) {
    try {
      const { payload: id } = yield take(buyerDashboardActions.loadDashboardData().type);

      let apiData = yield call(api, `/.netlify/functions/buyer-dashboard?id=${id}`);
      console.log('apiData ', apiData);

      if (apiData.error) {
        yield putResolve(appActions.setError(apiData.error));
        yield putResolve(buyerDashboardActions.setLoading(false));
        yield putResolve(appActions.hideNav());
        return;
      }

      if (apiData.data && apiData.error === null) {
        yield putResolve(buyerDashboardActions.setDashboardData(apiData.data));
        yield putResolve(buyerDashboardActions.setLoading(false));
        return;
      }
    } catch (err) {
      console.log(err);
      yield putResolve(appActions.hideNav());
      yield putResolve(buyerDashboardActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export default function* buyerDashboardPageRootSaga() {
  yield spawn(buyerDashboardPageSaga);
}

import { state } from '../../store';
import { isValidEmail } from '../../components-library/helper';
export const [buyerProfileActions, buyerProfileSelector, buyerProfileState] = state('BuyerProfile', {
  initial: {
    data: {},
    qualified: false,
    notVerified: false,
    showNotVerifiedModal: false,
    showNotQualifiedModal: false,
    loading: true,
    originalData: [],
    saveButtonClicked: false,
    editMode: false,
    dataError: {
      BuyerName: '',
      BuyerEmail: '',
      Buyer: '',
      BuyerPhone: '',
      BuyerOrgType: '',
      OrganizationAddress: '',
      City: '',
      State: '',
      ZipCode: '',
    },
  },
  on: {
    loadBuyerProfileDetails: (_, data) => {
      const newStoreObj = {
        ..._,
      };

      newStoreObj.data = { ...data };
      newStoreObj.originalData = { ...data };
      return newStoreObj;
    },
    setOriginalData: (_) => {
      let newStoreObj = { ..._ };

      let data = newStoreObj.originalData;
      return {
        ..._,
        data: data,
      };
    },

    setSaveButtonState: (_, data) => {
      const newStoreObj = { ..._, saveButtonClicked: data };

      return newStoreObj;
    },
    setQualified: (_, data) => {
      const newStoreObj = { ..._, qualified: data };

      return newStoreObj;
    },
    setNotVerified: (_, data) => {
      const newStoreObj = { ..._, notVerified: data };

      return newStoreObj;
    },
    setShowNotVerifiedModal: (_, data) => {
      const newStoreObj = { ..._, showNotVerifiedModal: data };

      return newStoreObj;
    },
    setShowNotQualifiedModal: (_, data) => {
      const newStoreObj = { ..._, showNotQualifiedModal: data };

      return newStoreObj;
    },
    setLoading: (_, data) => {
      let newStoreObj = { ..._, loading: data };
      return newStoreObj;
    },
    setEditMode: (_, state) => {
      let newStoreObj = { ..._, editMode: state };
      return newStoreObj;
    },
    handleInputChange: (_, result) => {
      let newStoreObj = { ..._ };

      const { fieldName, value } = result;

      let data = { ...newStoreObj.data };

      if (fieldName === 'deleteTaxExemptFile') {
        let message = 'Are you sure you want to remove this file?';
        // eslint-disable-next-line no-restricted-globals
        if (confirm(message)) {
          data[fieldName] = value;
        }
      } else {
        data[fieldName] = value;
      }

      if (
        fieldName === 'FirstName' ||
        fieldName === 'LastName' ||
        fieldName === 'Buyer' ||
        fieldName === 'BuyerOrgType' ||
        fieldName === 'BuyerOtherOrgName' ||
        fieldName === 'OrganizationAddress' ||
        fieldName === 'City' ||
        fieldName === 'State'
      ) {
        if (value.length === 0) {
          newStoreObj.dataError[fieldName] = 'This field can not be empty';
        } else {
          newStoreObj.dataError[fieldName] = '';
        }
      }
      //email
      if (fieldName === 'BuyerEmail' && value.length > 0) {
        if (!isValidEmail(value) || value.length === 0) {
          newStoreObj.dataError.BuyerEmail = 'Enter valid email';
        } else {
          newStoreObj.dataError.BuyerEmail = '';
        }
      }

      //zipCode
      if (fieldName === 'ZipCode' && value.length > 0) {
        let noCommas = `${value}`.replace(/,/g, '');
        const ZIP_REGEX = new RegExp('^[0-9]{5}');
        if (ZIP_REGEX.test(noCommas) || value.length === 0) {
          newStoreObj.dataError.ZipCode = '';
        } else {
          newStoreObj.dataError.ZipCode = 'Enter valid zip code';
        }
      }
      return { ...newStoreObj, data: { ...data } };
    },
  },
  events: ['buyerProfilePageData', 'updateBuyerProfile'],
});

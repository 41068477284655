import { state } from '../../store';

export const [groupOrdersPageActions, groupOrdersPageSelector, groupOrdersPageState] = state('groupOrdersPage', {
  initial: {
    data: { open: [], shipping: [], closedAndCompleted: [] },
    loading: true,
  },
  on: {
    loadData: (_, data) => {
      let newStoreObj = { ..._ };
      console.log('store', data);

      let open = data.aggregationData.filter((item) => item.Status === 'Open');
      let shipping = data.aggregationData.filter((item) => item.Status === 'Shipping');

      let closedAndCompleted = data.aggregationData.filter(
        (item) => item.Status === 'Completed' || item.Status === 'Closed',
      );

      newStoreObj.data = { ...data, open, shipping, closedAndCompleted };

      return newStoreObj;
    },
    setLoading: (_, data) => {
      let newStoreObj = { ..._, loading: data };
      return newStoreObj;
    },
    handleHideFields: (_, result) => {
      let newStoreObj = { ..._ };

      let data = newStoreObj.data['open'].map((item) => {
        if (item['Record ID'] === result.id) {
          item.ShowButtonAndTimer = false;
          item.Status = 'Closed';
        }
        return { ...item };
      });

      return {
        ...newStoreObj,
        data: { ...newStoreObj.data, aggregationData: [...data] },
      };
    },
  },
  events: ['buyerDetails', 'viewOffer'],
});

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { state } from '../../store';
import { Carousel, Tabs, Tab } from 'react-bootstrap';

import { groupOrdersPageSelector, groupOrdersPageActions } from './store';
import { StartCountDownTimerWithEndDate } from '../../components-library/countDown';
import { withRouter } from 'react-router-dom';
import { buyerProfileSelector, buyerProfileActions } from '../buyer-profile/store';
import ErrorPage from '../../components-library/error-page';
import { appSelector } from '../../app/store';

export const CommanTab = withRouter(
  connect(
    state.select({
      data: groupOrdersPageSelector((state) => state.data, {
        open: [],
        shipping: [],
        closedAndCompleted: [],
      }),
      filteredData: groupOrdersPageSelector((state) => state.filteredData, []),
      notVerified: buyerProfileSelector((state) => state.notVerified),
      qualified: buyerProfileSelector((state) => state.qualified),
    }),
    state.actions(groupOrdersPageActions, ['viewOffer', 'handleHideFields'], buyerProfileActions, [
      'setShowNotVerifiedModal',
      'setShowNotQualifiedModal',
    ]),
  )(
    class CommanTab extends Component {
      render() {
        const {
          data,
          notVerified,
          qualified,
          currentTab = 'open',
          setShowNotVerifiedModal,
          setShowNotQualifiedModal,
        } = this.props;
        //actions
        return (
          <>
            {data[currentTab].length > 0 ? (
              <>
                {data &&
                  data[currentTab] &&
                  data[currentTab].map((agg) => {
                    return (
                      <div className="card mt-3" key={Math.random()}>
                        <div className="card-body py-2">
                          <div className="row">
                            <div className="col-lg-3">
                              {/* <div className="card-body col-lg-3 col-12 col-md-4 mr-md-0 ml-md-0 mr-sm-auto ml-sm-auto pr-md-0  pl-3 pr-3 "> */}
                              {agg['Product Pictures'] && (
                                <Carousel
                                  className="card-body carousel market-carousel slide px-0 py-0"
                                  indicators={false}
                                >
                                  {agg['Product Pictures'].map((item) => {
                                    return (
                                      <Carousel.Item key={item.id}>
                                        <div
                                          title={item.filename}
                                          style={{
                                            backgroundImage: `url(${item.url})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center',
                                            backgroundSize: 'contain',
                                            cursor: 'pointer',
                                          }}
                                          className="d-block carousel-item-agg  align-items-center"
                                          onClick={(e) => window.open(item.url)}
                                        />
                                      </Carousel.Item>
                                    );
                                  })}
                                </Carousel>
                              )}
                            </div>
                            <div className="col-lg-5">
                              {/* <div className="col-lg-4 col-11 col-md-4 pl-md-0 pr-md-0 mt-3 pl-3 pr-3  "> */}
                              {/* <div className="card-body agg-header pl-md-3 pb-sm-0 xs-pb-zero"> */}
                              <h2 className="mt-4 mb-2 text-uppercase">{agg['Name']}</h2>
                              {agg.ShowButtonAndTimer && agg.ShowButtonAndTimer === true ? (
                                <span id={agg['Record ID']}>
                                  <h3 className="mt-1 mb-4">
                                    CLOSES IN:{' '}
                                    <mark>
                                      <span style={{ lineHeight: '1.3' }} id={agg['Record ID']}>
                                        <StartCountDownTimerWithEndDate
                                          key={`${agg['Record ID']}`}
                                          end_time={new Date(agg['End Date'])}
                                          callBackFun={(e) => {
                                            let payload = {
                                              id: agg['Record ID'],
                                            };
                                            this.props.handleHideFields(payload);
                                          }}
                                        />
                                      </span>
                                    </mark>
                                  </h3>
                                </span>
                              ) : null}

                              <p className="mb-1">
                                <strong>Price: </strong>
                                {agg['Price']} per {agg['UoM']} ({agg['Quantity']} units, ${agg['Base UoM Price']}/
                                {agg['Base UoM']})
                              </p>
                              <p className="mb-1">
                                <strong>Manufacturer/Model:</strong> {agg['Manufacturer']} {agg['Product Model Name']}
                              </p>
                              <p className="mb-1">
                                <strong>Specifications:</strong> {agg['Specifications']}
                              </p>
                              <p className="mb-1">
                                <strong>Estimated Ship by:</strong> {agg['Ship by']}
                              </p>
                              {/* </div> */}
                            </div>
                            <div className="col-lg-4">
                              {/* <div className="card-body pt-3 col-lg-4 col-md-4 col-11 pl-md-0 agg-header pl-3 pl-sm-5 pr-sm-5  pr-md-5 mt-md-3 "> */}
                              {/* <div className="xs-padding market-agg-progress mt-3 pl-md-3 pr-md-3"> */}
                              <div className="progress mt-4 mb-3">
                                <div
                                  className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                                  valuenow={agg['Total Units']}
                                  valuemin="0"
                                  valuemax={agg['MOQ']}
                                  style={{
                                    width: `${agg['ProgressPercent']}%`,
                                  }}
                                />
                              </div>
                              <h1 className="mt-4 mb-1">
                                <strong>{agg['Total Units Formatted']}</strong> <span className="h3">purchased</span>
                              </h1>
                              of {agg['MOQ Formatted']} minimum order quantity
                              <br />
                              <h2 className="mt-4 mb-0">
                                {agg['Order Count']} <span className="h3">orders</span>
                              </h2>
                              <br />
                              {agg.ShowButtonAndTimer && agg.ShowButtonAndTimer === true ? (
                                <button
                                  type="submit"
                                  id={agg['Record ID']}
                                  className="btn pn95-button text-uppercase mt-2 mb-4"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (notVerified) {
                                      setShowNotVerifiedModal(true);
                                    } else if (!qualified) {
                                      setShowNotQualifiedModal(true);
                                    } else {
                                      let payload = {
                                        id: agg['Record ID'],
                                        buyerId: data['BuyerRecordID'],
                                        history: this.props.history,
                                      };
                                      this.props.viewOffer(payload);
                                    }
                                  }}
                                >
                                  View Offer
                                </button>
                              ) : null}
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                        {agg['Show Orders'] ? <hr /> : null}
                        {agg['Show Orders'] ? (
                          <div className="card-body pt-0">
                            <div className="row">
                              <div className="col-md-12">
                                <h2 className="my-2 text-uppercase">
                                  <strong>Your Orders:</strong>
                                </h2>
                                <ul>
                                  {agg['Orders']
                                    ? agg['Orders'].map((order) => {
                                        return order.Receipt ? (
                                          <li key={Math.random()}>
                                            Quantity of <strong>{order.Quantity}</strong> for{' '}
                                            <strong>{order.Total}</strong>, purchased on {order.Date} [
                                            <a target="_blank" rel="noopener noreferrer" href={order.Receipt}>
                                              Receipt URL
                                            </a>
                                            ]
                                          </li>
                                        ) : null;
                                      })
                                    : null}
                                </ul>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
              </>
            ) : (
              <p className="text-center my-5">
                <b>No Group Buying Offer Found.</b>
              </p>
            )}
          </>
        );
      }
    },
  ),
);

export const GroupOrdersPage = withRouter(
  connect(
    state.select({
      data: groupOrdersPageSelector((state) => state.data, []),
      loading: groupOrdersPageSelector((state) => state.loading),
      error: appSelector((state) => state.error, ''),
    }),
    state.actions(groupOrdersPageActions, ['buyerDetails']),
  )(
    class GroupOrdersPage extends Component {
      componentWillMount() {
        const { query } = this.props;
        let id = query.get('id');
        if (id === '') {
          this.props.history.push('/');
        } else {
          this.props.buyerDetails(id);
        }
      }

      render() {
        const { data, loading, error } = this.props;
        //actions

        if (loading) {
          return (
            <>
              <div className="header">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-end">
                      <div className="col">
                        <h1 className="header-title">Group Orders</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section className="container page-content pb-6 market-content text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </section>
            </>
          );
        }
        if (error) {
          return <ErrorPage error={error} />;
        }

        return (
          <div className="container-fluid">
            <div className="header">
              <div className="header-body">
                <div className="row align-items-end">
                  <div className="col titleHeader">
                    <h1 className="header-title">Group Orders</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col"></div>
            </div>
            <Tabs defaultActiveKey="Open" className="nav mb-4 ml-2">
              <Tab eventKey="Open" title={`Open (${data.open.length})`}>
                <CommanTab currentTab="open" />
              </Tab>
              <Tab eventKey="Shipping" title={`Shipping (${data.shipping.length})`}>
                <CommanTab currentTab="shipping" />
              </Tab>
              <Tab eventKey="ClosedAndCompleted" title={`Closed & Completed (${data.closedAndCompleted.length})`}>
                <CommanTab currentTab="closedAndCompleted" />
              </Tab>
            </Tabs>
          </div>
        );
      }
    },
  ),
);

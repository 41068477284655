import { state } from '../../store';

export const [sellerRfqDetailsPageActions, sellerRfqDetailsPageSelector, sellerRfqDetailsPageState] = state(
  'SellerRfqDetailsPage',
  {
    initial: {
      data: [],
      originalData: [],
      loading: true,
    },
    on: {
      loadData: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        newStoreObj.data = { ...data };
        newStoreObj.originalData = { ...data };
        return newStoreObj;
      },
      setLoading: (_, data) => {
        let newStoreObj = { ..._, loading: data };
        return newStoreObj;
      },
    },

    events: ['loadRfqDetailsData', 'viewRfq'],
  },
);

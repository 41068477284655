import { putResolve, call, spawn, take } from 'redux-saga/effects';
import { api } from '../../app/api';
import { rfqPageActions } from './store';
import { appActions } from '../../app/store';

function* rfqPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(rfqPageActions.rfqPageData().type);

      yield putResolve(rfqPageActions.loadRfqDetails([]));

      yield putResolve(rfqPageActions.setLoading(true));
      let apiData = yield call(api, `/.netlify/functions/buyer-rfqs?id=${data}`);

      yield putResolve(rfqPageActions.setLoading(false));

      if (apiData.error) {
        yield putResolve(appActions.setError(apiData.error));
        yield putResolve(appActions.hideNav());
        return;
      }
      let productNames = [];
      if (apiData.data && apiData.error === null) {
        let rfqDetails = {};
        for (let key in apiData.data) {
          rfqDetails[key.replace(/\s/g, '')] = apiData.data[key];
        }

        //separate Out the product type
        apiData.data.rfqs.forEach((rfq) => {
          productNames.push(rfq['Product Type'][0]);
        });

        //filterout the product types which are in api
        //unique product types
        let productNamesArray = productNames.filter((value, index, self) => self.indexOf(value) === index);

        yield putResolve(rfqPageActions.loadRfqDetails(rfqDetails));
        yield putResolve(rfqPageActions.selectProductHandler(productNamesArray));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(appActions.hideNav());
      yield putResolve(rfqPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export default function* buyerRfqPageSagaRootSaga() {
  yield spawn(rfqPageSaga);
}

import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';

import { usersPageSelector, usersPageActions } from './store';
import { state } from '../../store';

const UserListPage = ({ getUsers, clearUsers, users, loading, error, pageToken }) => {
  const [filter, setFilter] = useState('');

  const searchUsers = useCallback(
    (filter) => {
      clearUsers();
      getUsers(filter);
    },
    [getUsers, clearUsers],
  );

  useEffect(() => {
    getUsers();

    return () => clearUsers();
  }, [getUsers, clearUsers]);

  const history = useHistory();

  if (loading) {
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  if (error) {
    return (
      <>
        <div className="header">
          <div className="container-fluid">
            <div className="header-body">
              <div className="row align-items-end">
                <div className="col">
                  <h1 className="header-title">Users</h1>
                </div>
              </div>
            </div>
            {error}
          </div>
        </div>
        <div className="container-fluid"></div>
      </>
    );
  }

  return (
    <>
      <div className="header">
        <div className="container-fluid">
          <div className="header-body">
            <div className="row align-items-end">
              <div className="col">
                <h1 className="header-title">Users</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <Button
                  variant="primary"
                  className="text-uppercase"
                  style={{ maxWidth: 100 }}
                  onClick={() => {
                    history.push('/internal/users/create');
                  }}
                >
                  Add User
                </Button>
                <div style={{ marginLeft: 'auto', display: 'flex' }}>
                  <Button
                    variant="link"
                    onClick={() => {
                      setFilter('');
                      searchUsers('');
                    }}
                  >
                    clear
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => {
                      searchUsers(filter);
                    }}
                  >
                    search
                  </Button>
                  <Form.Control
                    style={{ maxWidth: 300 }}
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        searchUsers(filter);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="table-responsive mb-0">
                <table className="table table-sm table-wrap card-table">
                  <thead>
                    <tr>
                      <th>
                        <span style={{ cursor: 'pointer' }} className="text-muted list-sort">
                          ID
                        </span>
                      </th>
                      <th>
                        <span style={{ cursor: 'pointer' }} className="text-muted list-sort">
                          Name
                        </span>
                      </th>
                      <th>
                        <span style={{ cursor: 'pointer' }} className="text-muted list-sort">
                          Email
                        </span>
                      </th>
                      <th>
                        <span style={{ cursor: 'pointer' }} className="text-muted list-sort">
                          User Type
                        </span>
                      </th>
                      <th>
                        <span style={{ cursor: 'pointer' }} className="text-muted list-sort">
                          Project N95?
                        </span>
                      </th>
                      <th>
                        <span style={{ cursor: 'pointer' }} className="text-muted list-sort">
                          Buyer/Seller ID
                        </span>
                      </th>
                      <th>
                        <span style={{ cursor: 'pointer' }} className="text-muted list-sort">
                          Roles
                        </span>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {users.length ? (
                      users.map((user, i) => {
                        const claims = user.customClaims || {};

                        return (
                          <tr key={i}>
                            <td>{user.uid}</td>
                            <td>{user.displayName}</td>
                            <td>{user.email}</td>
                            <td>{claims.userType}</td>
                            <td>{claims.tenantId === 2 ? 'Yes' : 'No'}</td>
                            <td>
                              {claims.userType === 'buyer'
                                ? claims.buyerId
                                : claims.userType === 'seller'
                                ? claims.sellerId
                                : ''}
                            </td>
                            <td>{(claims.roles || []).join(', ')}</td>
                            <td>
                              <a href={`/internal/users/edit/${user.uid}`}>Edit</a>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <p className="card-text text-muted mt-5 mb-4 text-center">No Users Yet</p>
                    )}
                  </tbody>
                </table>
              </div>
              {pageToken && (
                <Button
                  disabled={loading}
                  onClick={() => {
                    getUsers();
                  }}
                >
                  Load More
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(
  state.select({
    users: usersPageSelector((state) => state.users, []),
    pageToken: usersPageSelector((state) => state.pageToken),
    loading: usersPageSelector((state) => state.loading),
    error: usersPageSelector((state) => state.error),
  }),
  state.actions(usersPageActions, ['getUsers', 'clearUsers']),
)(UserListPage);

import { putResolve, call, spawn, take } from 'redux-saga/effects';
import { api } from '../../app/api';
import { buyerProfileActions } from './store';
import { appActions } from '../../app/store';

function* buyerProfilePageSaga() {
  while (true) {
    try {
      const { payload: id } = yield take(buyerProfileActions.buyerProfilePageData().type);
      let apiData = yield call(api, `/.netlify/functions/buyer-profile?id=${id}`);

      yield putResolve(buyerProfileActions.setLoading(false));

      if (apiData.error) {
        yield putResolve(appActions.setError(apiData.error));
        yield putResolve(appActions.hideNav());
        return;
      }

      if (apiData.data && apiData.error === null) {
        let buyerDetails = {};

        for (let key in apiData.data) {
          buyerDetails[key.replace(/\s/g, '')] = apiData.data[key];
        }

        yield putResolve(buyerProfileActions.loadBuyerProfileDetails(buyerDetails));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(appActions.hideNav());
      yield putResolve(buyerProfileActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export function* updateBuyerProfileSaga() {
  while (true) {
    try {
      const { payload: data, meta } = yield take(buyerProfileActions.updateBuyerProfile().type);

      yield putResolve(buyerProfileActions.setLoading(true));

      const result = yield call(api, `/.netlify/functions/buyer-update-profile?id=${data.id}`, 'POST', data);

      yield putResolve(buyerProfileActions.setLoading(false));

      if (result && result.data) {
        if (result.data && result.error === null) {
          let buyerDetails = {};

          for (let key in result.data) {
            buyerDetails[key.replace(/\s/g, '')] = result.data[key];
          }
          yield putResolve(buyerProfileActions.setSaveButtonState(false));
          yield putResolve(buyerProfileActions.loadBuyerProfileDetails(buyerDetails));
          yield putResolve(buyerProfileActions.setEditMode(false));

          if (meta && meta.callback) {
            meta.callback();
          }
        }
      }

      if (result && result.error && result.error.length && result.data === null) {
        yield putResolve(appActions.setError(result.error));
      }
    } catch (error) {
      console.log(error);
      yield putResolve(buyerProfileActions.setLoading(false));
    }
  }
}
export default function* buyerProfilePageSagaRootSaga() {
  yield spawn(buyerProfilePageSaga);
  yield spawn(updateBuyerProfileSaga);
}

import { state } from '../../store';
export const sortAscDesc = (a, b, typeCheck) => {
  if (typeCheck) {
    if (a < b) {
      return -1;
    }
    if (b > a) {
      return 1;
    }
    return 0;
  } else {
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  }
};

const rfqsFilter = (text) => (item) => {
  const expired = 'expired';
  let fixedTimeline = '';
  if (item['Timeline'] && parseInt(item['Timeline']) > 0) {
    fixedTimeline = `${item['Timeline']} days`;
  } else {
    fixedTimeline = 'ASAP';
  }
  if (item['Product Type'] && item['Product Type'][0].toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['Quantity'] && item['Quantity'].toString().toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['Max Price'] && item['Max Price'].toString().toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['Revenue Opportunity'] && item['Revenue Opportunity'].toString().toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['Zipcode'] && item['Zipcode'][0].toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['RFQ Status'] !== 'RFQExpired' && item['RFQ Status'].toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['Timeline'] && fixedTimeline.toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['RFQ Status'] === 'RFQExpired' && expired.indexOf(text) !== -1) {
    return item;
  }
};

const initialRfqsFilterFields = {
  active: { requestFor: '' },
  unqualified: { requestFor: '' },
  rfqExpired: { requestFor: '' },
};
export const [sellerRfqPageActions, sellerRfqsPageSelector, sellerRfqsageState] = state('SellerRfqPage', {
  initial: {
    data: [],
    filteredRfq: [],
    loading: true,
    sortRfqsType: {
      active: {
        requestFor: { ascending: false },
        quantity: { ascending: false },
        maxPrice: { ascending: false },
        opportunity: { ascending: false },
        status: { ascending: false },
        neededBy: { ascending: false },
      },
      unqualified: {
        requestFor: { ascending: false },
        quantity: { ascending: false },
        maxPrice: { ascending: false },
        opportunity: { ascending: false },
        status: { ascending: false },
        neededBy: { ascending: false },
      },
      rfqExpired: {
        requestFor: { ascending: false },
        quantity: { ascending: false },
        maxPrice: { ascending: false },
        opportunity: { ascending: false },
        status: { ascending: false },
        neededBy: { ascending: false },
      },
    },
    searchText: { active: '', unqualified: '', rfqExpired: '' },
    rfqsFilterFields: { ...initialRfqsFilterFields },
    uniqueRequestFor: [],
  },
  on: {
    loadData: (_, data) => {
      const newStoreObj = {
        ..._,
      };

      newStoreObj.data = data;
      newStoreObj.filteredRfq = data;
      return newStoreObj;
    },
    setLoading: (_, data) => {
      let newStoreObj = { ..._, loading: data };
      return newStoreObj;
    },
    setUniqueRequestFor: (_, data) => {
      let newStoreObj = { ..._, uniqueRequestFor: data };
      return newStoreObj;
    },
    filterChangeHandler: (_, result) => {
      const newStoreObj = {
        ..._,
      };
      const { fieldName, value, currentTab } = result;

      let rfqsFilterFields = { ...newStoreObj.rfqsFilterFields };
      rfqsFilterFields[currentTab][fieldName] = value;

      return {
        ..._,
        rfqsFilterFields: { ...rfqsFilterFields },
      };
    },
    applyFilter: (_, currentTab) => {
      let newStoreObj = {
        ..._,
      };
      let searchText = newStoreObj.searchText[currentTab];
      let originalData = newStoreObj.data[currentTab];

      let currentFilters = newStoreObj.rfqsFilterFields[currentTab];
      let filteredValues = originalData;
      //search
      if (searchText.length) {
        filteredValues = originalData.filter(rfqsFilter(searchText.trim().toLowerCase()));
      }

      //status
      if (currentFilters.status) {
        filteredValues = filteredValues.filter((item) => item['RFQ Status'] === currentFilters.status);
      }
      //requestFor
      if (currentFilters.requestFor) {
        filteredValues = filteredValues.filter((item) => item['Product Type'][0] === currentFilters.requestFor);
      }
      let filteredRfq = { ...newStoreObj.filteredRfq };
      filteredRfq[currentTab] = [...filteredValues];
      return { ...newStoreObj, filteredRfq: { ...filteredRfq } };
    },
    resetFilter: (_, currentTab) => {
      let newStoreObj = { ..._ };
      let originalData = newStoreObj.data;
      let filteredRfq = newStoreObj.filteredRfq;
      filteredRfq[currentTab] = originalData[currentTab];

      let rfqsFilterFields = { ...newStoreObj.rfqsFilterFields };
      rfqsFilterFields[currentTab] = { requestFor: '' };

      return {
        ...newStoreObj,
        filteredRfq: { ...filteredRfq },
        rfqsFilterFields: {
          ...rfqsFilterFields,
        },
      };
    },

    searchRfqs: (_, data) => {
      const { event, value } = data;
      let newStoreObj = { ..._ };
      let searchText = { ...newStoreObj.searchText };

      searchText[value] = event.target.value.toLowerCase();

      return { ...newStoreObj, searchText: { ...searchText } };
    },
    sortRfqs: (_, data) => {
      const { currentTab, type } = data;
      let newStoreObj = {
        ..._,
      };

      let allRfqs = [];

      //request for
      if (type === 'requestFor') {
        newStoreObj.sortRfqsType[currentTab].requestFor.ascending = !newStoreObj.sortRfqsType[currentTab].requestFor
          .ascending;

        allRfqs = newStoreObj.filteredRfq[currentTab].sort((a, b) => {
          let arequestFor = a['Product Type'][0].toLowerCase();
          let brequestFor = b['Product Type'][0].toLowerCase();

          return sortAscDesc(arequestFor, brequestFor, newStoreObj.sortRfqsType[currentTab].requestFor.ascending);
        });
      }
      //quantity
      if (type === 'quantity') {
        newStoreObj.sortRfqsType[currentTab].quantity.ascending = !newStoreObj.sortRfqsType[currentTab].quantity
          .ascending;
        allRfqs = newStoreObj.filteredRfq[currentTab].sort((a, b) => {
          let aquantity = a['Quantity'];
          let bquantity = b['Quantity'];
          return sortAscDesc(aquantity, bquantity, newStoreObj.sortRfqsType[currentTab].quantity.ascending);
        });
      }

      //needed by
      if (type === 'neededBy') {
        newStoreObj.sortRfqsType[currentTab].neededBy.ascending = !newStoreObj.sortRfqsType[currentTab].neededBy
          .ascending;
        allRfqs = newStoreObj.filteredRfq[currentTab].sort((a, b) => {
          let aneededBy = a['Timeline'][0];
          let bneededBy = b['Timeline'][0];
          return sortAscDesc(aneededBy, bneededBy, newStoreObj.sortRfqsType[currentTab].neededBy.ascending);
        });
      }

      //maxPrice
      if (type === 'maxPrice') {
        newStoreObj.sortRfqsType[currentTab].maxPrice.ascending = !newStoreObj.sortRfqsType[currentTab].maxPrice
          .ascending;
        allRfqs = newStoreObj.filteredRfq[currentTab].sort((a, b) => {
          let amaxPrice = parseFloat(a['Max Price'].split('$')[1]);
          let bmaxPrice = parseFloat(b['Max Price'].split('$')[1]);
          return sortAscDesc(amaxPrice, bmaxPrice, newStoreObj.sortRfqsType[currentTab].maxPrice.ascending);
        });
      }

      //opportunity
      if (type === 'opportunity') {
        newStoreObj.sortRfqsType[currentTab].opportunity.ascending = !newStoreObj.sortRfqsType[currentTab].opportunity
          .ascending;
        allRfqs = newStoreObj.filteredRfq[currentTab].sort((a, b) => {
          let aopportunity = parseFloat(a['Revenue Opportunity'].split('$')[1]);
          let bopportunity = parseFloat(b['Revenue Opportunity'].split('$')[1]);
          return sortAscDesc(aopportunity, bopportunity, newStoreObj.sortRfqsType[currentTab].opportunity.ascending);
        });
      }

      //status
      if (type === 'status') {
        newStoreObj.sortRfqsType[currentTab].status.ascending = !newStoreObj.sortRfqsType[currentTab].status.ascending;
        allRfqs = newStoreObj.filteredRfq[currentTab].sort((a, b) => {
          let statusa = a['RFQ Status'] === 'RFQExpired' ? 'expired' : a['RFQ Status'].toLowerCase();
          let statusb = b['RFQ Status'] === 'RFQExpired' ? 'expired' : b['RFQ Status'].toLowerCase();

          return sortAscDesc(statusa, statusb, newStoreObj.sortRfqsType[currentTab].status.ascending);
        });
      }
      let filteredRfq = { ...newStoreObj.filteredRfq };
      filteredRfq[currentTab] = [...allRfqs];
      return {
        ...newStoreObj,
        filteredRfq: { ...filteredRfq },
      };
    },
  },

  events: ['loadRfqsData'],
});

import React, { useState } from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import firebase from 'firebase';
import { useHistory } from 'react-router-dom';

import { AuthPage, AuthBody, AuthExtra } from '../auth-components';
import * as associations from './associations';

const OPTION_NONE_OF_THE_ABOVE = 'None or none of the above';

const SignupAssociationPage = () => {
  const [associationName, setAssociationName] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  return (
    <AuthPage>
      <AuthExtra style={{ paddingBottom: 10 }}>
        <span>
          {/* eslint-disable-next-line */}
          <a
            href="#"
            onClick={async () => {
              await firebase.auth().signOut();
              window.location.href = '/login';
            }}
          >
            Return to login page
          </a>
        </span>
      </AuthExtra>
      <AuthBody header="Are you a member of a medical society?">
        <Form
          onSubmit={async (e) => {
            const form = e.currentTarget;

            e.preventDefault();

            if (form.checkValidity() === false) {
              e.stopPropagation();
            }

            setLoading(true);

            if (associationName === OPTION_NONE_OF_THE_ABOVE) {
              window.localStorage.setItem(
                'associationLandingPage',
                JSON.stringify({
                  associations: [],
                }),
              );
              history.push('/signup/buyer');
            } else {
              window.location.href = associations.redirectLinks[associationName];
            }
          }}
        >
          <Form.Group controlId="formAssociationName">
            <Form.Label className="required">
              If you've received communications from one your medical societies and looking for the group offers, please
              make the appropriate choice below. Otherwise, select <strong>None or none of the above</strong>.
            </Form.Label>
            <Form.Control as="select" required={true} onChange={(e) => setAssociationName(e.target.value)}>
              <option></option>
              {Object.keys(associations.options).map((name, i) => (
                <option key={i}>{associations.options[name]}</option>
              ))}
              <option>{OPTION_NONE_OF_THE_ABOVE}</option>
            </Form.Control>
          </Form.Group>

          <Button variant="primary" type="submit" style={{ width: '100%' }} disabled={loading}>
            Submit
          </Button>
        </Form>
      </AuthBody>
    </AuthPage>
  );
};

export default SignupAssociationPage;

import Select from 'react-select';
import styled, { css } from 'styled-components';

const dropDownStyle = css`
  background: #eaeff2;
  border-radius: 5px;
  border: 0px;
  :hover,
  :active,
  &:focus {
    cursor: text;
    outline: none;
  }
  .css-bgvzuu-indicatorSeparator {
    width: 0px !important;
  }
  &:disabled {
    color: #3e74e1;
    background: #f5f6f7;
    cursor: not-allowed;
  }
  &::placeholder {
    color: #708498;
  }
  padding: 0;
  color: #6e7d90;
  ${(props) =>
    css`
      width: ${props.width || '100%'};
      height: ${props.height || '32px'};
      resize: ${props.resize};
    `}
  ${(props) =>
    props.noresize &&
    css`
      resize: none;
    `}
`;

export const Dropdown = styled(Select)`
  ${dropDownStyle}
`;

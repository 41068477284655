import { putResolve, call, spawn, take, fork } from 'redux-saga/effects';
import { api } from '../../app/api';
import { addProductPageActions } from './store';
import { appActions } from '../../app/store';

function* addProductPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(addProductPageActions.addNewProduct().type);

      yield putResolve(addProductPageActions.setLoading(true));

      let apiData = yield call(api, `/.netlify/functions/add-product?id=${data}`, 'POST', data);

      if (apiData.error) {
        yield putResolve(appActions.hideNav());
        yield putResolve(appActions.setError(apiData.error));
        return;
      }

      if (apiData.data && apiData.error === null) {
        window.location = `seller-products?id=${data.id}`;
      }
    } catch (err) {
      console.log(err);
      yield putResolve(appActions.hideNav());
      yield putResolve(addProductPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}
function* productTypeSaga() {
  try {
    let productTypes = yield call(api, `/.netlify/functions/product-types`);

    yield putResolve(addProductPageActions.setLoading(false));

    if (productTypes.error) {
      yield putResolve(appActions.setError(productTypes.error));
      yield putResolve(appActions.hideNav());
      return;
    }

    if (productTypes.data && productTypes.error === null) {
      yield putResolve(addProductPageActions.loadProductType(productTypes.data));
    }
  } catch (err) {
    console.log(err);
    yield putResolve(appActions.hideNav());
    yield putResolve(addProductPageActions.setLoading(false));
    yield putResolve(appActions.setError('Something went wrong'));
  }
}

export function* loadProductTypesSaga() {
  while (true) {
    try {
      yield putResolve(addProductPageActions.setLoading(true));
      yield take(addProductPageActions.loadProductTypes().type);
      yield fork(productTypeSaga);
      yield putResolve(addProductPageActions.setLoading(false));
    } catch (err) {
      console.log('error in loadProductTypesSaga', err);
      yield putResolve(addProductPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export function* singleProductDetailsPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(addProductPageActions.loadProductDetails().type);

      let apiData = yield call(api, `/.netlify/functions/single-product-details?id=${data}`);
      yield* productTypeSaga();

      yield putResolve(addProductPageActions.setLoading(false));

      if (apiData.error) {
        yield putResolve(appActions.hideNav());
        yield putResolve(appActions.setError(apiData.error));
        return;
      }
      if (apiData.data && apiData.error === null) {
        yield putResolve(appActions.setId(apiData.data['Supplier Record ID']));
        yield putResolve(addProductPageActions.loadProductData(apiData.data));
      }
    } catch (err) {
      console.log('error in productDetailsPageSaga', err);
      yield putResolve(addProductPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}
export function* updateProductSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(addProductPageActions.updateProduct().type);

      let result = yield call(api, `/.netlify/functions/update-product-details`, 'PUT', data);

      if (result.error) {
        yield putResolve(appActions.hideNav());
        yield putResolve(appActions.setError(result.error));
        return;
      }

      if (result.data && result.data.type === 'redirect' && result.error === null) {
        data.history.push(result.data.value);
      }
    } catch (err) {
      console.log('error in updateProductSaga', err);
      yield putResolve(appActions.hideNav());
      yield putResolve(addProductPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export default function* addProductPageRootSaga() {
  yield spawn(addProductPageSaga);
  yield spawn(singleProductDetailsPageSaga);
  yield spawn(updateProductSaga);
  yield spawn(loadProductTypesSaga);
}

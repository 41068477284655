import { putResolve, call, spawn, take } from 'redux-saga/effects';
import { api } from '../../app/api';
import { sellerOfferPageActions } from './store';
import { appActions } from '../../app/store';

function* sellerOfferPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(sellerOfferPageActions.getOfferData().type);

      yield putResolve(sellerOfferPageActions.loadOfferDetails([]));

      yield putResolve(sellerOfferPageActions.setLoading(true));

      let apiData = yield call(api, `/.netlify/functions/seller-offer-details?id=${data.id}&rfqId=${data.rfqId}`);

      yield putResolve(sellerOfferPageActions.setLoading(false));

      if (apiData.error) {
        yield putResolve(appActions.setError(apiData.error));
        yield putResolve(appActions.hideNav());
        return;
      }

      if (apiData.data && apiData.error === null) {
        apiData.data['NoOfOffers'] = parseInt(parseInt(apiData.data['NoOfOffers']) * 2.5);
        yield putResolve(sellerOfferPageActions.loadOfferDetails(apiData.data));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(appActions.hideNav());
      yield putResolve(sellerOfferPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}
function* shippingSubmissionOfferSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(sellerOfferPageActions.shippingSubmissionOffer().type);
      yield putResolve(sellerOfferPageActions.setShippingLoading(true));
      const result = yield call(api, `/.netlify/functions/shipping-submission`, 'POST', data);
      yield putResolve(sellerOfferPageActions.setShippingLoading(false));
      if (result && result.data) {
        yield putResolve(sellerOfferPageActions.setShippingInfo(result.data));
      }
    } catch (error) {
      console.log(error);
      yield putResolve(sellerOfferPageActions.setShippingLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

function* deleteShippingInfoSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(sellerOfferPageActions.deleteShippingInfo().type);
      yield putResolve(sellerOfferPageActions.setRemoveLoading(data));
      const result = yield call(api, `/.netlify/functions/remove-shipping`, 'POST', data);

      if (result && result.data) {
        yield putResolve(sellerOfferPageActions.setShippingInfo(result.data));
      }
    } catch (error) {
      console.log(error);
      yield putResolve(sellerOfferPageActions.setShippingLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export default function* sellerOfferPageSagaRootSaga() {
  yield spawn(sellerOfferPageSaga);
  yield spawn(shippingSubmissionOfferSaga);
  yield spawn(deleteShippingInfoSaga);
}

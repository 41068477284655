import React, { Component, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { Countdown } from '../../components-library/countDown';
import { connect } from 'react-redux';
import { sellerRfqPageActions, sellerRfqsPageSelector } from './store';
import { appSelector } from '../../app/store';
import { state } from '../../store';
import { Tab, Tabs } from 'react-bootstrap';
import ErrorPage from '../../components-library/error-page';
export const CommanTab = withRouter(
  connect(
    state.select({
      filteredRfq: sellerRfqsPageSelector((state) => state.filteredRfq, []),
      sellerId: appSelector((state) => state.paramId, ''),
      data: sellerRfqsPageSelector((state) => state.data, []),
      loading: sellerRfqsPageSelector((state) => state.loading),
      error: appSelector((state) => state.error, ''),
      rfqsFilterFields: sellerRfqsPageSelector((state) => state.rfqsFilterFields),
      uniqueRequestFor: sellerRfqsPageSelector((state) => state.uniqueRequestFor),
    }),
    state.actions(sellerRfqPageActions, [
      'sortRfqs',
      'searchRfqs',
      'filterChangeHandler',
      'applyFilter',
      'resetFilter',
    ]),
  )(
    class CommanTab extends PureComponent {
      state = {
        filterOpen: false,
      };
      resetHandler = (event) => {
        this.setState({ filterOpen: false });
        this.props.resetFilter(this.props.currentTab);
        this.props.applyFilter(this.props.currentTab);
      };
      searchFilteredRfq = (event) => {
        this.props.searchRfqs({ event, value: this.props.currentTab });
        this.props.applyFilter(this.props.currentTab);
      };
      getTimeline = (timeline) => {
        let fixedTimeline = null;
        if (parseInt(timeline) === 1) {
          fixedTimeline = `${timeline} day`;
        } else if (parseInt(timeline) > 1) {
          fixedTimeline = `${timeline} days`;
        } else {
          fixedTimeline = 'ASAP';
        }
        return fixedTimeline;
      };
      render() {
        const { filteredRfq, history, sortRfqs, currentTab = 'active', sellerId } = this.props;

        let filterDDClassName = '';
        let filterDivClassName = '';
        if (this.state.filterOpen) {
          filterDDClassName = 'dropdown show';
          filterDivClassName = 'dropdown-menu dropdown-menu-right dropdown-menu-card show dropdownOpen';
        } else {
          filterDDClassName = 'dropdown';
          filterDivClassName = 'dropdown-menu dropdown-menu-right dropdown-menu-card ';
        }

        return (
          <div>
            <div
              className="card"
              data-list='{"valueNames": ["item-name", "item-title", "item-email", "item-phone", "item-score", "item-company"], "page": 10, "pagination": {"paginationclassName": "list-pagination"}}'
              id="contactsList"
            >
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col">
                    {/* <!-- Form --> */}
                    <form>
                      <div className="input-group input-group-flush">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fe fe-search"></i>
                          </span>
                        </div>
                        <input
                          className="list-search form-control"
                          type="search"
                          placeholder="Search"
                          onChange={this.searchFilteredRfq}
                        />
                      </div>
                    </form>
                  </div>
                  <div className="col-auto mr-n3"></div>

                  <OutsideClickHandler
                    onOutsideClick={(e) => {
                      this.setState({
                        filterOpen: false,
                      });
                    }}
                  >
                    <div className="col-auto">
                      {/* <!-- Dropdown --> */}
                      <div className={filterDDClassName}>
                        {/* <!-- Toggle --> */}
                        <button
                          className="btn btn-sm btn-white"
                          type="button"
                          onClick={(e) => {
                            this.setState({
                              filterOpen: !this.state.filterOpen,
                            });
                          }}
                        >
                          <i className="fe fe-sliders mr-1"></i> Filter{' '}
                          <span className="badge badge-primary ml-1 d-none">0</span>
                        </button>

                        {/* <!-- Menu --> */}
                        <div className={filterDivClassName}>
                          <div className="card-header">
                            {/* <!-- Title --> */}
                            <h4 className="card-header-title">Filters</h4>

                            {/* <!-- Link --> */}
                            <button
                              className="btn btn-sm btn-link text-reset"
                              onClick={(e) => {
                                this.resetHandler();
                              }}
                            >
                              Clear filters
                            </button>
                          </div>
                          <div className="card-body">
                            {/* <!-- List group --> */}
                            <div className="list-group list-group-flush mt-n4 mb-4">
                              <div className="list-group-item">
                                <div className="row">
                                  <div className="col">
                                    {/* <!-- Text --> */}
                                    <small>Request For</small>
                                  </div>
                                  <div className="col-6">
                                    {/* <!-- Select --> */}
                                    <select
                                      className="custom-select custom-select-sm"
                                      onChange={(e) => {
                                        let payload = {
                                          fieldName: 'requestFor',
                                          value: e.target.value,
                                          currentTab: currentTab,
                                        };
                                        this.props.filterChangeHandler(payload);
                                      }}
                                      value={
                                        this.props.rfqsFilterFields[currentTab].requestFor === ''
                                          ? '*'
                                          : this.props.rfqsFilterFields[currentTab].requestFor
                                      }
                                    >
                                      {this.props.rfqsFilterFields[currentTab].requestFor === '' && (
                                        <option value="*" disabled>
                                          Select
                                        </option>
                                      )}
                                      {Array.isArray(this.props.uniqueRequestFor) &&
                                        this.props.uniqueRequestFor.map((item, index) => {
                                          return (
                                            <option key={index} value={item}>
                                              {item}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                                {/* <!-- / .row --> */}
                              </div>
                            </div>

                            {/* <!-- Button --> */}
                            <button
                              className="btn btn-block btn-primary"
                              onClick={(e) => {
                                this.setState({ filterOpen: false });
                                this.props.applyFilter(currentTab);
                              }}
                            >
                              Apply filter
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OutsideClickHandler>
                </div>
                {/* <!-- / .row --> */}
              </div>
              <div className="table-responsive">
                <table className="table table-sm table-hover table-nowrap card-table">
                  <thead>
                    <tr>
                      <th>
                        <span className="list-sort text-muted">Record Id</span>
                      </th>
                      <th>
                        <span
                          className="list-sort text-muted cursorTospan"
                          data-sort="item-name"
                          onClick={() => {
                            sortRfqs({
                              currentTab: currentTab,
                              type: 'requestFor',
                            });
                          }}
                        >
                          REQUEST FOR
                        </span>
                      </th>
                      <th>
                        <span
                          className="list-sort text-muted cursorTospan"
                          data-sort={currentTab === 'active' ? 'item-name' : undefined}
                          onClick={
                            currentTab === 'active'
                              ? () => {
                                  sortRfqs({
                                    currentTab: currentTab,
                                    type: 'status',
                                  });
                                }
                              : undefined
                          }
                        >
                          status
                        </span>
                      </th>
                      <th>
                        <span
                          className="list-sort text-muted cursorTospan"
                          data-sort="item-title"
                          onClick={() => {
                            sortRfqs({
                              currentTab: currentTab,
                              type: 'opportunity',
                            });
                          }}
                        >
                          OPPORTUNITY
                        </span>
                      </th>

                      <th>
                        <span
                          className="list-sort text-muted cursorTospan"
                          data-sort="item-phone"
                          onClick={() => {
                            sortRfqs({
                              currentTab: currentTab,
                              type: 'quantity',
                            });
                          }}
                        >
                          Quantity
                        </span>
                      </th>
                      <th>
                        <span
                          className="list-sort text-muted cursorTospan"
                          data-sort="item-company"
                          onClick={() => {
                            sortRfqs({
                              currentTab: currentTab,
                              type: 'neededBy',
                            });
                          }}
                        >
                          Needed by
                        </span>
                      </th>

                      <th>
                        <span className="list-sort text-muted ">Timer</span>
                      </th>
                    </tr>
                  </thead>
                  {filteredRfq && filteredRfq[currentTab] && filteredRfq[currentTab].length ? (
                    <tbody className="list font-size-base">
                      {filteredRfq[currentTab].map((rfq, index) => {
                        return (
                          <tr
                            className="cursorTospan"
                            key={index}
                            onClick={(e) => {
                              let status =
                                rfq['RFQ Status'] === 'RFQExpired' ? 'expired' : rfq['RFQ Status'].toLowerCase();
                              history.push(
                                `rfq-details?id=${sellerId}&hotlineId=${rfq['Hotline Record ID']}&status=${status}`,
                              );
                            }}
                          >
                            <td>
                              <span className="item-title ">{rfq['Hotline Record ID']}</span>
                            </td>
                            <td>
                              <span className="item-title ">{rfq['Product Type']}</span>
                            </td>
                            <td>
                              <span className="item-title  ">
                                {rfq['RFQ Status'] === 'Active' && (
                                  <>
                                    <span className="text-success">● </span>
                                    Active
                                  </>
                                )}
                                {rfq['RFQ Status'] === 'Quoted' && (
                                  <>
                                    <span className="text-success">● </span>
                                    Quoted ({`${rfq['YourOffers']}/${rfq['NoOfOffers']}`})
                                  </>
                                )}
                                {rfq['RFQ Status'] === 'Unqualified' && (
                                  <>
                                    <span className="text-warning">● </span>
                                    Unqualified
                                  </>
                                )}
                                {rfq['RFQ Status'] === 'RFQExpired' && (
                                  <>
                                    <span className="text-danger">● </span>
                                    Expired
                                  </>
                                )}
                              </span>
                            </td>
                            <td>
                              <span className="item-title ">{rfq['Revenue Opportunity']}</span>
                            </td>

                            {/* <td>
                                  <span className="item-title ">
                                    {rfq["Max Price"]}
                                  </span>
                                </td> */}
                            <td>
                              <span className="item-title ">{rfq['Quantity']}</span>
                            </td>
                            <td>
                              <span className="item-title ">{this.getTimeline(rfq['Timeline'])}</span>
                            </td>

                            <td>
                              <span className="item-title badge mark-span span-font ">
                                {(rfq['RFQ Status'] === 'Active' ||
                                  (rfq['RFQ Status'] === 'Quoted' && rfq['All Quoted'] === false)) && (
                                  <Countdown
                                    start_time={new Date(rfq['Start Time'][0])}
                                    interval={48}
                                    callback={(e) => {
                                      window.location.reload();
                                    }}
                                  />
                                )}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : null}
                </table>
                {filteredRfq && filteredRfq[currentTab] && filteredRfq[currentTab].length === 0 && (
                  <div className="card mb-0">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center justify-content-center">
                        <h6 className="text-uppercase text-center text-muted my-4">No RFQs Found</h6>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }
    },
  ),
);

export const SellerRfqsPage = withRouter(
  connect(
    state.select({
      sellerId: appSelector((state) => state.paramId, ''),
      data: sellerRfqsPageSelector((state) => state.data, []),
      loading: sellerRfqsPageSelector((state) => state.loading),
      error: appSelector((state) => state.error, ''),
      rfqsFilterFields: sellerRfqsPageSelector((state) => state.rfqsFilterFields),
      uniqueRequestFor: sellerRfqsPageSelector((state) => state.uniqueRequestFor),
    }),
    state.actions(sellerRfqPageActions, ['loadRfqsData', 'sortRfqs', 'resetFilter']),
  )(
    class SellerRfqsPage extends Component {
      state = {
        filterOpen: false,
      };
      componentWillMount() {
        const { query } = this.props;
        let id = query.get('id');
        if (id) {
          this.props.loadRfqsData(id);
        } else {
          this.props.history.push('/404');
        }
      }
      render() {
        const { data, loading, error } = this.props;
        if (loading) {
          return (
            <>
              <div className="header">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-end">
                      <div className="col">
                        <h1 className="header-title">Request For Quotes</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section className="container page-content pb-6 market-content text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </section>
            </>
          );
        }
        if (error && error.length > 0) {
          return <ErrorPage error={error} />;
        }
        return (
          <>
            <div className="header">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-end">
                    <div className="col">
                      <h1 className="header-title">Request For Quotes</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row bg-white no-gutters ">
                <div className="col-6 py-4 text-center">
                  <h5 className="text-uppercase text-muted">Won</h5>

                  <h1 className="mb-0">${data.MarketWon}</h1>
                </div>
                <div className="col-6 py-4 text-center border-left">
                  <h5 className="text-uppercase text-muted">Quoted</h5>

                  <h1 className="mb-0">${data.MarketQuoted}</h1>
                </div>
              </div>
              <Tabs defaultActiveKey="Active" className="nav mb-3 ml-2">
                <Tab eventKey="Active" title={`Active (${data && data.active && data.active.length})`}>
                  <CommanTab currentTab="active" />
                </Tab>
                <Tab
                  eventKey="Unqualified"
                  title={`Unqualified (${data && data.unqualified && data.unqualified.length})`}
                >
                  <CommanTab currentTab="unqualified" />
                </Tab>
                <Tab eventKey="Expired" title={`Expired (${data && data.rfqExpired && data.rfqExpired.length})`}>
                  <CommanTab currentTab="rfqExpired" />
                </Tab>
              </Tabs>
            </div>
          </>
        );
      }
    },
  ),
);

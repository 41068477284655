import { putResolve, call, spawn, take } from 'redux-saga/effects';
import { sellerProductPageActions } from './store';
import { appActions } from '../../app/store';
import { api } from '../../app/api';

function* sellerProductPageSaga() {
  while (true) {
    try {
      const { payload: id } = yield take(sellerProductPageActions.sellerProductPageData().type);

      yield putResolve(sellerProductPageActions.setLoading(true));

      let apiData = yield call(api, `/.netlify/functions/seller-products-details?id=${id}`);

      yield putResolve(sellerProductPageActions.setLoading(false));

      if (apiData.error && apiData.data === null) {
        yield putResolve(appActions.hideNav());
        yield putResolve(appActions.setError(apiData.error));
        return;
      }

      if (apiData.data && apiData.error === null) {
        let productList = apiData.data;
        yield putResolve(sellerProductPageActions.loadProductsList(productList));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(appActions.hideNav());
      yield putResolve(sellerProductPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export default function* sellerProductPageSagaRootSaga() {
  yield spawn(sellerProductPageSaga);
}

import React, { useState } from 'react';

import { Link, Redirect, useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import firebase from 'firebase';
import { useSelector } from 'react-redux';

import { appSelector } from '../app/store';
import { AuthPage, AuthBody, AuthExtra, GoogleButton } from './auth-components';

const LoginPage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [email, setEmail] = useState(query.get('email') || '');
  const [password, setPassword] = useState('');
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const user = useSelector(appSelector((state) => state.user));

  if (user) {
    return <Redirect to="/" />;
  }

  return (
    <AuthPage error={error}>
      <AuthBody title="Login">
        <Form
          onSubmit={async (e) => {
            const form = e.currentTarget;

            e.preventDefault();

            if (form.checkValidity() === false) {
              e.stopPropagation();
            }

            try {
              setLoading(true);

              await firebase.auth().signInWithEmailAndPassword(email, password);

              window.location.href = query.get('signInSuccessUrl') || '/';
            } catch (err) {
              setError(err);
            }

            setLoading(false);
          }}
        >
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Button variant="primary" type="submit" style={{ width: '100%' }} disabled={loading}>
            Submit
          </Button>
        </Form>
        <hr />
        <p style={{ textAlign: 'center' }}>or</p>
        <GoogleButton onError={(err) => setError(err.message)} />
      </AuthBody>
      <AuthExtra style={{ paddingTop: 10 }}>
        <span>
          <div>Don't have an account?</div>
          <div>
            <Link to="/signup">Sign up here</Link>
          </div>
        </span>
        <span>
          <Link to="/forgot">Forgot password?</Link>
        </span>
      </AuthExtra>
    </AuthPage>
  );
};

export default LoginPage;

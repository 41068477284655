import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { state } from '../../store';
import RatingComponent from '../../components-library/ratingComponent';
import { formatMoney, getFlagAndCountry } from '../../components-library/helper';
import { Countdown } from '../../components-library/countDown';
import moment from 'moment';
import { offerPageSelector, offerPageActions } from './store';
import { withRouter } from 'react-router-dom';
import { appSelector } from '../../app/store';
import ErrorPage from '../../components-library/error-page';

export const OffersPage = withRouter(
  connect(
    state.select({
      data: offerPageSelector((state) => state.data, []),
      loading: offerPageSelector((state) => state.loading),
      error: appSelector((state) => state.error, ''),
    }),
    state.actions(offerPageActions, ['offersPageData']),
  )(
    class OffersPage extends Component {
      componentWillMount() {
        const { query } = this.props;
        let id = query.get('id');
        if (id === '') {
          this.props.history.push('/');
        } else {
          this.props.offersPageData(id);
        }
      }
      breadcrumb = () => {
        return (
          <div className="header">
            <div className="container-fluid pt-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href={'/buyer-rfqs?id=' + this.props.data['Buyer Record ID']}>Request For Quotes</a>
                  </li>
                  <li className="breadcrumb-item active">Offers</li>
                </ol>
              </nav>
            </div>
          </div>
        );
      };

      render() {
        const { data, loading, error, history } = this.props;
        if (loading) {
          return (
            <>
              {this.breadcrumb()}
              <section className="container page-content pb-6 market-content text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </section>
            </>
          );
        }
        //api error
        if (error.length) {
          return <ErrorPage error={error} />;
        }

        let purchasedRFQS = data && data.winnerOffer && (
          <div key={Math.random()}>
            <div className="card card-body p-5">
              <div className="row pt-2 bg-light">
                <div className="col text-left">
                  <strong>Record ID: </strong> {data['Record ID']}
                </div>
                <div className="col text-right">
                  {data['RFQ Status'] === 'Purchased' && (
                    <>
                      <span className="text-primary">● </span> Purchased
                    </>
                  )}
                  {data['RFQ Status'] === 'Unpaid' && (
                    <>
                      <span className="text-warning">● </span> Unpaid
                    </>
                  )}
                </div>
              </div>

              <div className="row bg-light pt-4">
                <div className="col text-center">
                  <h2 className="mb-2 text-uppercase">{data['Product Type']}</h2>

                  <div>
                    <span className="text-muted mb-6 ">
                      <strong>Total Spend: </strong>
                      <mark>${data.winnerOffer['Winning Total Cost']}</mark>
                    </span>
                    <br />
                    <span className="text-muted mb-6 ">
                      <strong>Date: </strong>
                      {data.winnerOffer['Transaction Date']}
                    </span>
                    <br />
                  </div>
                  <div>
                    {data['Winning Receipt URL'] ? (
                      <a href={`${data.winnerOffer['Winning Receipt URL']}`}>Receipt URL</a>
                    ) : null}
                  </div>
                  <br />
                  <div className="text-body mb-2">
                    {data['Winning Receipt URL'] ? (
                      <>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary text-uppercase"
                          href={`https://airtable.com/shrYoLAfFS5LdJAVK?prefill_RequesterID=${data['Buyer Record ID']}&prefill_ProductID=${data.winnerOffer['Winning Product Record ID']}&prefill_TransactionID=${data.winnerOffer['Winning Offer Transaction Record ID']}`}
                        >
                          Submit Feedback
                        </a>
                      </>
                    ) : (
                      <a
                        className="btn btn-primary text-uppercase"
                        href={data.winnerOffer['Winning Invoice URL']}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Pay Invoice
                      </a>
                    )}
                  </div>
                  <div className="mb-4">
                    {data['Winning Receipt URL'] ? (
                      <span
                        className="link"
                        onClick={() => {
                          this.props.history.push('/create-rfq?id=' + data['Buyer Record ID']);
                        }}
                      >
                        Submit Another RFQ
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <hr />

              {data['RFQ Status'] === 'Purchased' && data.shippingData && data.shippingData.length > 0 && (
                <>
                  <div className="row my-3">
                    <div className="col">
                      <div className="row">
                        <h2 className="col-12 mb-5 text-uppercase">Shipping Information</h2>

                        <div className="col-12">
                          <div className="row mb-3">
                            <div className="col-7 font-weight-bold">Shipping Tracking URL</div>
                            <div className="col-5 font-weight-bold text-right">Delivery Date</div>
                          </div>
                          {data['shippingData'] &&
                            data['shippingData'].map((item, key) => {
                              return (
                                <div className="row" key={key}>
                                  <div className="col-7">
                                    <a href={item['Shipping Link']} target="_blank" rel="noopener noreferrer">
                                      {item['Shipping Link']}
                                    </a>
                                  </div>
                                  <div className="col-5 text-right">
                                    {moment(item['Expected Delivery']).format('YYYY/MM/DD')}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </>
              )}
              <div className="row">
                <div className="col">
                  <div className="row mt-5">
                    <>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Manufacturer/Model:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">
                          {data.winnerOffer['Winning Offer Manufacturer']} /{' '}
                          {data.winnerOffer['Winning Offer Product Model']}
                        </p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Price Per Unit:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">${data.winnerOffer['Winning Offer Price']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Quantity:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data.winnerOffer.Quantity.toLocaleString()}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Delivery:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data.winnerOffer['Winning Offer Delivery']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Shipping:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">${data.winnerOffer['Winning Offer Shipping']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Taxes:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">${data.winnerOffer['Winning Taxes']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Additional Info:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">
                          {data.winnerOffer['Additional Information']
                            ? data.winnerOffer['Additional Information']
                            : '-'}
                        </p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Purchase/Delivery Instructions:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">
                          {data['Winning Delivery Instructions'] ? data['Winning Delivery Instructions'] : '-'}
                        </p>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

        let active = (
          <div className="row mb-5">
            <div className="col-md-6">
              <h1 className="mb-2 pull-webfont-left">Your Offers</h1>
              <p>
                Below is a list of offers from vetted suppliers based on your RFQ, approved by our Marketplace team. You
                have 48 hours to make a purchase before these quotes expire. You can click into each quote to learn more
                about each offer.
              </p>
              {data.offerData && data.offerData.length ? (
                data.offerData.map((offer) => {
                  let flagDetails = getFlagAndCountry(offer['Country of Manufacture']);

                  let rating = offer['Product Ratings CSS'] / 10;

                  return (
                    <div className="card mb-3" key={Math.random()}>
                      <div className="card-header main-bg-color card-header-height">
                        <div className="row">
                          <div className="col-7 mt-1" style={{ minWidth: '207px' }}>
                            <h3 className="card-header-title mt-0 mb-0">
                              {data['Product Type'] + ' '}

                              {flagDetails && (
                                <img
                                  src={flagDetails.flagSrc}
                                  alt={flagDetails.flagAlt}
                                  data-toggle="tooltip"
                                  title={flagDetails.flagTitle}
                                  className="flag-image"
                                />
                              )}
                            </h3>

                            {offer['showRating'] === true ? (
                              <div className="d-flex flex-row">
                                <RatingComponent rating={rating} />
                                <span
                                  className="market-stars"
                                  style={{
                                    padding: 'unset',
                                    paddingLeft: '10px',
                                    paddingTop: '3px',
                                  }}
                                >
                                  {offer['Product Ratings Count Text']}
                                </span>
                              </div>
                            ) : null}
                          </div>
                          <div className="col-5">
                            <a className="btn btn-block btn-primary mt-2" href={`/deal?id=${offer['Record ID']}`}>
                              See Details
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-4">
                            <Carousel className="w-100" indicators={false}>
                              {offer['Product Pictures'] &&
                                offer['Product Pictures'].map((item) => (
                                  <Carousel.Item key={Math.random()}>
                                    <img
                                      key={item}
                                      alt={item.filename}
                                      src={item.url}
                                      className="d-inline-block w-100 h-50"
                                      onClick={(e) => window.open(item.url)}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </Carousel.Item>
                                ))}
                            </Carousel>
                          </div>
                          <div className="col-8">
                            {offer.Badges &&
                              Array.isArray(offer.Badges) &&
                              offer.Badges.map((badge, index) => (
                                <div
                                  key={index}
                                  className={`supply-label ${badge.name} d-inline`}
                                  data-toggle="tooltip"
                                  title={`${badge.title}`}
                                ></div>
                              ))}
                            <p className="mt-1">
                              {offer.Manufacturer} {offer['Product Model']}
                              <br />
                              {offer['Level of Isolation Gown']}
                              {offer['Level of Surgical Mask']}
                            </p>
                            <strong>Quote Price: </strong>
                            {`$${formatMoney(offer['Quote Price'], 2, '.')} per unit`}
                            <br />
                            <strong>Quantity (each/ounce): </strong>
                            {formatMoney(offer.Quantity, 0, '')} <br />
                            <strong>Delivered in: </strong>
                            {offer['Delivery By'] === '0'
                              ? 'ASAP'
                              : offer['Delivery By'] === '1'
                              ? `${offer['Delivery By']} day`
                              : `${offer['Delivery By']} days`}
                            <br />
                            <strong>Additional Information: </strong>
                            {offer['Additional Information']}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <div className="row">
                    <div className="col-12 mt-5">
                      <h3 className="text-center ">No Offers Approved</h3>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-6 pl-5">
              <div className="card mb-0">
                <div className="card-body pt-5">
                  <h3 id={`timer-${data['Record ID']}`}>
                    Offer expires in:&nbsp;
                    <strong>
                      <mark>
                        {data.Type === 'Offer' && data['Offer Start Time'] && (
                          <Countdown
                            start_time={new Date(data['Offer Start Time'])}
                            interval={48}
                            callback={(_e) => {
                              history.push(`buyer-rfqs?id=${data['Buyer Record ID']}`);
                            }}
                          />
                        )}
                        {data.Type === 'RFQ' && data['Call Completed at'] && (
                          <Countdown
                            start_time={new Date(data['Call Completed at'])}
                            interval={48}
                            callback={() => {
                              history.push(`buyer-rfqs?id=${data['Buyer Record ID']}`);
                            }}
                          />
                        )}
                      </mark>
                    </strong>
                  </h3>
                  <strong>Status:</strong> <span className="text-success">● </span>Live <br />
                  <strong>Record ID: </strong>
                  {data['Record ID']}
                  <br />
                  <hr />
                  <h3 className="mt-3 mb-2">Your Request</h3>
                  {data.offerData && data.offerData.length === 0 && (
                    <button
                      className="btn  btn-primary text-uppercase mb-4"
                      onClick={(e) => history.push(`/edit-rfq?id=${data['Record ID']}`)}
                    >
                      Edit RFQ
                    </button>
                  )}
                  <p className="mb-4">
                    <strong>Product: </strong> {data['Product Type']}
                    <br />
                    <strong>Ask Price per Unit: </strong>
                    {data['Max Price Per Product Unit']}
                    <br />
                    <strong>Quantity Needed (each/ounce): </strong>
                    {data['Quantity']}
                    <br />
                    <strong>Needed by: </strong>
                    {data['Timing (days)'] && data['Timing (days)'] === 0
                      ? 'ASAP'
                      : data['Timing (days)'] === 1
                      ? `${data['Timing (days)']} day`
                      : `${data['Timing (days)']} days`}
                    <br />
                    <strong>Product Specifications: </strong>
                    {data['Product Specification']} <br />
                    {data['Size'] && (
                      <>
                        <strong>Size: </strong>
                        {data['Size']} <br />
                      </>
                    )}
                    <strong>Additional Notes: </strong> {data['Public Notes']}
                    <br />
                  </p>
                  <p className="mt-4">
                    <strong>NOTICE:</strong> To mitigate your risk, payment is collected upfront and released based on
                    payment terms. Our team provides support on all dispute resolutions and refunds on orders placed in
                    the Marketplace.
                  </p>
                  <p className="mt-4 product-needs">
                    <strong>Need help with your purchasing decision or have a question about the offers? </strong>
                    Email us at&nbsp;
                    <a href="mailto:marketplace@projectn95.org" target="_blank" rel="noopener noreferrer">
                      marketplace@projectn95.org
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        );

        let noOffers = (
          <div>
            <div className="card card-body p-5">
              <div className="row pt-2 bg-light">
                <div className="col text-left">
                  <strong>Record ID: </strong> {data['Record ID']}
                </div>
                <div className="col text-right">
                  <span className="text-danger">● </span> No Offers
                </div>
              </div>

              <div className="row bg-light pt-4">
                <div className="col text-center">
                  <h2 className="text-uppercase">{data['Product Type']}</h2>
                  <p>
                    <span className="text-muted mb-6">
                      <strong>Target Price: </strong>
                      {data['Total Cost']}
                    </span>
                    <br />
                  </p>
                  <div className="button-margin">
                    <button
                      className="btn btn-primary text-uppercase"
                      onClick={() => {
                        this.props.history.push('/create-rfq?id=' + data['Buyer Record ID']);
                      }}
                    >
                      Resubmit RFQ
                    </button>
                  </div>
                </div>
              </div>
              <hr />

              <div className="row mt-3">
                <div className="col">
                  <h2 className="mb-2 text-uppercase ">Your Request</h2>

                  <div className="row mt-5">
                    <>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Ask Price per Unit:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Max Price Per Product Unit']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Quantity Needed (each/ounce):</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Quantity'] && data['Quantity'].toLocaleString()}</p>
                      </div>

                      <div className="col-12 col-md-6">
                        <strong className="text-body">Needed by:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">
                          {data['Timing (days)'] && data['Timing (days)'] === 0
                            ? 'ASAP'
                            : data['Timing (days)'] === 1
                            ? `${data['Timing (days)']} day`
                            : `${data['Timing (days)']} days`}
                        </p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Product Specifications: </strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">
                          {data['Product Specification'] ? data['Product Specification'] : '-'}
                        </p>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

        let pendingRFQ = (
          <div>
            <div className="card card-body p-5">
              <div className="row pt-2 bg-light">
                <div className="col text-left">
                  <strong>Record ID: </strong>
                  {data['Record ID']}
                </div>
                <div className="col text-right">
                  <span className="text-secondary">● </span> Pending
                </div>
              </div>

              <div className="row bg-light pt-4">
                <div className="col text-center">
                  <h2 className="text-uppercase">{data['Product Type']}</h2>
                  <p>
                    <span className="text-muted mb-6">
                      <strong>Target Price: </strong>
                      {data['Total Cost']}
                    </span>
                    <br />
                    <span className="text-muted mb-6">
                      <strong>RFQ Submitted, Pending Review</strong>
                    </span>
                  </p>
                  <button
                    className="btn btn-primary pr-5 pl-5 mb-5 text-uppercase"
                    onClick={(e) => history.push(`/edit-rfq?id=${data['Record ID']}`)}
                  >
                    Edit RFQ
                  </button>
                </div>
              </div>
              <hr />

              <div className="row mt-3">
                <div className="col">
                  <h2 className="mb-2 text-uppercase ">Your Request</h2>

                  <div className="row mt-5">
                    <>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Ask Price per Unit:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Max Price Per Product Unit']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Quantity Needed (each/ounce):</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Quantity'] && data['Quantity'].toLocaleString()}</p>
                      </div>

                      <div className="col-12 col-md-6">
                        <strong className="text-body">Needed by:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">
                          {data['Timing (days)'] && data['Timing (days)'] === 0
                            ? 'ASAP'
                            : data['Timing (days)'] === 1
                            ? `${data['Timing (days)']} day`
                            : `${data['Timing (days)']} days`}
                        </p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Product Specifications: </strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">
                          {data['Product Specification'] ? data['Product Specification'] : '-'}
                        </p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Size: </strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Size'] ? data['Size'] : '-'}</p>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

        let expiredOffers = (
          <div className="row mb-5">
            <div className="col-md-6">
              <h1 className="mb-2 pull-webfont-left">Your Offers</h1>
              <p>
                Below is a list of offers from vetted suppliers, approved by our Marketplace team. You have 48 hours to
                make a purchase before these quotes expire. You can click into each quote to learn more about each
                offer.
              </p>
              {data.offerData && data.offerData.length ? (
                data.offerData.map((offer) => {
                  let flagDetails = getFlagAndCountry(offer['Country of Manufacture']);

                  let rating = offer['Product Ratings CSS'] / 10;

                  return (
                    <div className="card mb-3" key={Math.random()}>
                      <div className="card-header main-bg-color card-header-height">
                        <div className="row">
                          <div className="col-7" style={{ minWidth: '207px' }}>
                            <h3 className="card-header-title mt-0 mb-0">
                              {data['Product Type'] + ' '}

                              {flagDetails && (
                                <img
                                  src={flagDetails.flagSrc}
                                  alt={flagDetails.flagAlt}
                                  data-toggle="tooltip"
                                  title={flagDetails.flagTitle}
                                  className="flag-image"
                                />
                              )}
                            </h3>

                            {offer['showRating'] === true ? (
                              <div className="d-flex flex-row ">
                                <RatingComponent rating={rating} />
                                <span
                                  className="market-stars"
                                  style={{
                                    padding: 'unset',
                                    paddingLeft: '10px',
                                    paddingTop: '3px',
                                  }}
                                >
                                  {offer['Product Ratings Count Text']}
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-4">
                            <Carousel className="w-100" indicators={false}>
                              {offer['Product Pictures'] &&
                                offer['Product Pictures'].map((item) => (
                                  <Carousel.Item key={Math.random()}>
                                    <img
                                      key={item}
                                      alt={item.filename}
                                      src={item.url}
                                      className="d-inline-block w-100 h-50"
                                      onClick={(e) => window.open(item.url)}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </Carousel.Item>
                                ))}
                            </Carousel>
                          </div>
                          <div className="col-8">
                            <p className="mb-0">
                              {offer.Manufacturer} {offer['Product Model']}
                              <br />
                              {offer['Level of Isolation Gown']}
                              {offer['Level of Surgical Mask']}
                            </p>
                            {offer.Badges &&
                              Array.isArray(offer.Badges) &&
                              offer.Badges.map((badge, index) => (
                                <div
                                  key={index}
                                  className={`supply-label ${badge.name} d-inline`}
                                  data-toggle="tooltip"
                                  title={`${badge.title}`}
                                ></div>
                              ))}
                            <p className="mt-4">
                              <strong>Quote Price: </strong>
                              {`$${formatMoney(offer['Quote Price'], 2, '.')} per unit`}
                              <br />
                              <strong>Quantity (each/ounce): </strong>
                              {formatMoney(offer.Quantity, 0, '')} <br />
                              <strong>Delivered in: </strong>
                              {offer['Delivery By'] === '0'
                                ? 'ASAP'
                                : offer['Delivery By'] === '1'
                                ? `${offer['Delivery By']} day`
                                : `${offer['Delivery By']} days`}
                              <br />
                              <strong>Additional Information: </strong>
                              {offer['Additional Information'] ? offer['Additional Information'] : '-'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <div className="row">
                    <div className="col-12 mt-5">
                      <h3 className="text-center ">No Offers Approved</h3>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-6">
              <div className="card mb-0">
                <div className="card-body pt-5">
                  <strong>Status: </strong>
                  <span className="text-danger">● </span>Expired <br />
                  <strong>Record ID: </strong>
                  {data['Record ID']}
                  <br />
                  <button
                    className="btn btn-primary mt-3 mb-2 md-2 text-uppercase"
                    onClick={() => {
                      this.props.history.push('/create-rfq?id=' + data['Buyer Record ID']);
                    }}
                  >
                    Resubmit RFQ
                  </button>
                  <hr />
                  <h3 className="mt-3 mb-4">Your Request for Quote</h3>
                  <p className="mb-4">
                    <strong>Product: </strong> {data['Product Type']}
                    <br />
                    <strong>Ask Price per Unit: </strong>
                    {data['Max Price Per Product Unit']}
                    <br />
                    <strong>Quantity Needed (each/ounce): </strong>
                    {data['Quantity']}
                    <br />
                    <strong>Needed by: </strong>
                    {data['Timing (days)'] === 0
                      ? 'ASAP'
                      : data['Timing (days)'] === 1
                      ? `${data['Timing (days)']} day`
                      : `${data['Timing (days)']} days`}
                    <br />
                    <strong>Product Specifications: </strong>
                    {data['Product Specification']} <br />
                    <strong>Additional Notes: </strong> {data['Public Notes']}
                  </p>
                  <p className="mt-4">
                    <strong>NOTICE:</strong> To mitigate your risk, payment is collected upfront and released based on
                    payment terms. Our team provides support on all dispute resolutions and refunds on orders placed in
                    the Marketplace.
                  </p>
                  <p className="mt-4 product-needs">
                    <strong>Need help with your purchasing decision or have a question about the offers? </strong>
                    Email us at&nbsp;
                    <a href="mailto:marketplace@projectn95.org" target="_blank" rel="noopener noreferrer">
                      marketplace@projectn95.org
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
        return (
          <>
            {this.breadcrumb()}
            <section className="container page-content pb-6 market-content">
              {data && data['RFQ Status'] === 'Purchased' && purchasedRFQS}
              {data && data['RFQ Status'] === 'Unpaid' && purchasedRFQS}
              {data && data['RFQ Status'] === 'Live' && active}
              {data && data['RFQ Status'] === 'Pending' && pendingRFQ}
              {data && data['RFQ Status'] === 'No Offers' ? noOffers : null}
              {data && data['RFQ Status'] === 'Expired' ? expiredOffers : null}
            </section>
          </>
        );
      }
    },
  ),
);

import { putResolve, call, spawn, take, select } from 'redux-saga/effects';

import { signupPageActions } from './store';
import { api } from '../../app/api';
import { appSelector } from '../../app/store';

function* signupBuyerSaga() {
  while (true) {
    try {
      const { payload, meta } = yield take(signupPageActions.signupBuyer().type);

      yield putResolve(signupPageActions.setError(''));
      yield putResolve(signupPageActions.setLoading(true));

      const apiData = yield call(api, '/.netlify/functions/signup-buyer', 'POST', payload);

      yield putResolve(signupPageActions.setLoading(false));

      if (apiData.error) {
        yield putResolve(signupPageActions.setError(apiData.error));

        window.scrollTo(0, 0);
      } else {
        const { user } = yield select(appSelector((state) => state));

        yield user.getIdToken(true);

        yield call(meta.redirect, apiData.buyer.id);
      }
    } catch (err) {
      console.log(err);
      yield putResolve(signupPageActions.setLoading(false));
      yield putResolve(signupPageActions.setError('Something went wrong. Please try again or contact support.'));
    }
  }
}

export default function* signupRootSaga() {
  yield spawn(signupBuyerSaga);
}

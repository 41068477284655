import React, { useEffect } from 'react';

// css imports
import './assets/css/custom.css';
import './assets/css/theme.min.css';
import './assets/fonts/feather/feather.css';

import _ from 'lodash';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import Intercom from 'react-intercom';
import firebase from 'firebase';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import SwitchWithNotFound from '../components-library/SwitchWithNotFound';
import NotFound from '../components-library/NotFound';

//seller pages
import { SellerRfqsPage } from '../seller/rfq-page/rfq-page';
import { SellerOffersPage } from '../seller/offers-page/offers-page';
import { OfferSubmissionPage } from '../seller/offer-submission-page/offer-submission-page';
import { StripeConnectOauthPage } from '../seller/stripe-connect/stripe-connect-oauth';
import { StripeConnectSuccessPage } from '../seller/stripe-connect/stripe-connect-success';
import { SellerRfqDetailsPage } from '../seller/rfq-details-page/rfq-details-page';
import { SellerSingleRFQPage } from '../seller/single-rfq-page/rfq-page';
import { SellerOfferDetails } from '../seller/offer-details-page/offer-details-page';
import { SellerProfile } from '../seller/seller-profile/seller-profile-page';
import { ProductDetailsPage } from '../seller/product-details-page/product-details-page';
import { SellerDashboardPage } from '../seller/seller-dashboard/seller-dashboard';

//Buyer Pages
import { DealPage } from '../buyer/deal-page/deal-page';
import { AggregationPage } from '../buyer/aggregation-page/aggregation-page';
import { BuyerRfqPage } from '../buyer/rfq-page/rfq-page';
import { OffersPage } from '../buyer/offers-page/offers-page';
import { GroupOrdersPage } from '../buyer/group-orders/group-orders';
import { BuyerProfile } from '../buyer/buyer-profile/buyer-profile';
import { BuyerDashboardPage } from '../buyer/buyer-dashboard/buyer-dashboard';
import { CreateEditRfqPage } from '../buyer/create-edit-rfq-page/create-edit-rfq-page';

// Auth pages
import LoginPage from '../auth/login-page';
import ForgotPasswordPage from '../auth/forgot-password-page';
import SignupUserPage from '../auth/signup/signup-user-page';
import SignupBuyerPage from '../auth/signup/signup-buyer-page';
import SignupVerifyPage from '../auth/signup/signup-verify-page';
import SignupAssociationPage from '../auth/signup/signup-association-page';

import InternalRoutes from '../internal';

// status pages import
import Page500 from '../components-library/500';

// Landing pages
import AssociationLandingPage from '../landing-pages/associations';

import ProtectedRoute from '../components-library/ProtectedRoute';
import { Navbar } from '../components-library/navbar';
import { appActions, appSelector } from './store';
import { buyerProfileActions, buyerProfileSelector } from '../buyer/buyer-profile/store';
import { SellerProductPage } from '../seller/products-page/product-list-page';
import { AddProductPage } from '../seller/add-new-product/add-product';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const App = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const { authLoaded, user, decodedToken } = useSelector(appSelector((state) => state));
  const buyer = useSelector(buyerProfileSelector((state) => state.data));
  const notVerified = useSelector(buyerProfileSelector((state) => state.notVerified));
  const showNotVerifiedModal = useSelector(buyerProfileSelector((state) => state.showNotVerifiedModal));
  const showNotQualifiedModal = useSelector(buyerProfileSelector((state) => state.showNotQualifiedModal));
  const buyerRecordId = buyer['BuyerRecordID'];

  useEffect(() => {
    return firebase.auth().onIdTokenChanged(async (user) => {
      const decodedToken = user ? await user.getIdTokenResult() : null;

      dispatch(appActions.authenticate({ user, decodedToken }));
    });
  }, [dispatch]);

  useEffect(() => {
    if (user && decodedToken) {
      // After we verify the user's email, sometimes the token needs to be refreshed.
      if (user.emailVerified !== decodedToken.claims.email_verified) {
        user.getIdToken(true);
      }

      // Temporary mechanism for getting buyer at top level to know if they
      // have been qualified or not.
      // TODO: Replace this with a more generic mechanism that will work
      // for all account types.
      if (
        decodedToken.claims.email_verified &&
        decodedToken.claims.userType === 'buyer' &&
        decodedToken.claims.buyerId
      ) {
        dispatch(buyerProfileActions.buyerProfilePageData(decodedToken.claims.buyerId));
      }
    }
  }, [user, decodedToken, dispatch]);

  useEffect(() => {
    if (user && decodedToken && decodedToken.claims.userType === 'buyer') {
      dispatch(buyerProfileActions.setQualified(buyer && buyer['Qualified']));
      dispatch(buyerProfileActions.setNotVerified(buyer && buyer['notVerified']));
    } else {
      dispatch(buyerProfileActions.setQualified(true));
    }
  }, [user, decodedToken, buyer, dispatch]);

  useEffect(() => {
    // TODO: Remove everything here. It is only necessary to support the associations campaign and
    // can be removed once that effort is over.
    if (buyerRecordId) {
      try {
        const associationLandingPage = JSON.parse(window.localStorage.getItem('associationLandingPage') || '{}');
        const newAssociations = associationLandingPage.associations;

        if (newAssociations && newAssociations.length) {
          const associations = buyer['Associations'] || [];
          const callback = () => {
            window.localStorage.removeItem('associationLandingPage');
            history.push(`/group-orders?id=${buyerRecordId}`);
          };
          const combinedAssociations = Array.from(new Set(associations.concat(newAssociations)));
          const updateObject = {
            id: buyerRecordId,
            'Lead Status': 'Qualified',
          };

          if (!_.isEqual(associations, combinedAssociations)) {
            updateObject.Associations = combinedAssociations;
          }

          dispatch(
            buyerProfileActions.updateBuyerProfile(updateObject, {
              callback,
            }),
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [buyer, buyerRecordId, dispatch, history]);

  // Wait until we know if the user is logged in or not.
  if (!authLoaded) {
    return <div></div>;
  }

  if (user && decodedToken && user.emailVerified !== decodedToken.claims.email_verified) {
    return <div></div>;
  }

  return (
    <>
      {process.env.NODE_ENV !== 'development' && <Intercom appID="swase5ns" />}
      <Switch>
        <Route
          path="/"
          exact
          component={() => {
            if (!user || !decodedToken) {
              window.location = 'https://projectn95.org';
              return;
            }

            if (decodedToken.claims.userType === 'buyer') {
              return <Redirect to={`/buyer-rfqs?id=${decodedToken.claims.buyerId}`} />;
            }

            if (decodedToken.claims.userType === 'seller') {
              return <Redirect to={`/seller?id=${decodedToken.claims.sellerId}`} />;
            }

            if (decodedToken.claims.userType === 'internal') {
              return <Redirect to="/internal/users" />;
            }

            return <Redirect to="/signup/buyer" />;
          }}
        />

        <Route path="/login">
          <LoginPage />
        </Route>
        <Route
          path="/logout"
          render={() => {
            firebase.auth().signOut();

            window.location.href = '/login';
          }}
        />
        <Route path="/forgot">
          <ForgotPasswordPage />
        </Route>
        <Route path="/signup" exact>
          <SignupUserPage />
        </Route>
        <ProtectedRoute path="/signup/verify" component={SignupVerifyPage} requireVerifiedEmail={false} />
        <Route path="/landing/associations">
          <AssociationLandingPage />
        </Route>
        <ProtectedRoute path="/signup/buyer" component={SignupBuyerPage} requireVerifiedEmail={false} />
        <ProtectedRoute path="/signup/association" component={SignupAssociationPage} requireVerifiedEmail={false} />

        <Route path="/500" component={Page500} />
        <Route path="/404" component={NotFound} />

        <Route>
          <Modal show={showNotVerifiedModal} centered>
            <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
              <p>
                We’ve determined that you are ineligible for our services. We’re focused on supporting healthcare
                providers and specific types of frontline workers at this time. If you believe that we have reached this
                conclusion in error please reach out to us at frontline@projectn95.org
              </p>
              <Button
                style={{ alignSelf: 'center' }}
                onClick={() => {
                  dispatch(buyerProfileActions.setShowNotVerifiedModal(false));
                }}
              >
                Close
              </Button>
            </Modal.Body>
          </Modal>
          <Modal show={showNotQualifiedModal} centered>
            <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
              <p>
                Your account is under additional review. If you need immediate help, please reach out to
                marketplace@projectn95.org
              </p>
              <Button
                style={{ alignSelf: 'center' }}
                onClick={() => {
                  dispatch(buyerProfileActions.setShowNotQualifiedModal(false));
                }}
              >
                Close
              </Button>
            </Modal.Body>
          </Modal>

          <Navbar />
          <div className="main-content">
            <SwitchWithNotFound>
              <Redirect from="/users" to="/internal/users" />
              <Redirect from="/users/create" to="/internal/users/create" />
              <Redirect from="/users/edit/:uid" to="/internal/users/edit/:uid" />

              <ProtectedRoute internalUsersOnly path="/internal" component={InternalRoutes} />

              <Route path="/offer-submission">
                <OfferSubmissionPage query={query} />
              </Route>
              <ProtectedRoute path="/deal" component={DealPage} query={query} />
              <ProtectedRoute path="/buyer-rfqs" component={BuyerRfqPage} query={query} />
              <ProtectedRoute path="/offer" component={OffersPage} query={query} />
              <ProtectedRoute path="/group-orders" component={GroupOrdersPage} query={query} />
              <ProtectedRoute path="/buyer" component={BuyerProfile} query={query} />
              {/* <ProtectedRoute path="/buyer-dashboard" component={BuyerDashboardPage} query={query} /> */}
              <Route path="/seller-dashboard">
                <SellerDashboardPage query={query} />
              </Route>
              <Route path="/stripe-connect-oauth">
                <StripeConnectOauthPage query={query} />
              </Route>
              <Route path="/stripe-connect-success">
                <StripeConnectSuccessPage query={query} />
              </Route>
              <ProtectedRoute path="/agg" component={AggregationPage} query={query} />
              <Route path="/seller-rfqs">
                <SellerRfqsPage query={query} />
              </Route>
              <Route path="/seller-offers">
                <SellerOffersPage query={query} />
              </Route>
              <Route path={`/offer-details`}>
                <SellerOfferDetails query={query} />
              </Route>
              <Route
                path="/edit-offer"
                component={() => {
                  return <SellerSingleRFQPage query={query} editMode={true} />;
                }}
              />
              <Route path="/rfq-details">
                <SellerRfqDetailsPage query={query} />
              </Route>
              <Route path="/rfq">
                <SellerSingleRFQPage query={query} editMode={false} />
              </Route>
              <Route path="/seller">
                <SellerProfile query={query} />
              </Route>
              <Route path="/seller-products">
                <SellerProductPage query={query} />
              </Route>
              <Route path="/add-product">
                <AddProductPage query={query} editMode={false} />
              </Route>
              <Route
                path="/edit-product"
                render={() => {
                  return <AddProductPage query={query} editMode={true} />;
                }}
              />
              <ProtectedRoute path="/edit-rfq" component={CreateEditRfqPage} query={query} />
              <ProtectedRoute
                path="/create-rfq"
                component={() => {
                  if (decodedToken.claims.userType === 'buyer' && notVerified) {
                    return <Redirect to={`/buyer?id=${decodedToken.claims.buyerId}`} />;
                  }

                  return <CreateEditRfqPage query={query} editMode={false} />;
                }}
              />
              <Route path="/product-details">
                <ProductDetailsPage query={query} />
              </Route>
            </SwitchWithNotFound>
          </div>
        </Route>
      </Switch>
    </>
  );
};

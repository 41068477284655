import React from 'react';

import { useRouteMatch, Route } from 'react-router-dom';

import SwitchWithNotFound from '../components-library/SwitchWithNotFound';
import UserRoutes from './users';

const InternalRoutes = () => {
  const match = useRouteMatch();

  return (
    <SwitchWithNotFound>
      <Route path={`${match.path}/users`} component={UserRoutes} />
    </SwitchWithNotFound>
  );
};

export default InternalRoutes;

import React from 'react';

export class Countdown extends React.PureComponent {
  state = {
    hours: undefined,
    minutes: undefined,
    seconds: undefined,
  };

  // setting H , M, S
  setDetails = (countDownDate) => {
    let now = new Date().getTime();
    let distance = countDownDate - now;
    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) + days * 24;
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    this.setState({ hours, minutes, seconds });
  };

  componentWillMount() {
    const { start_time, interval } = this.props;
    let countDownDate = new Date(start_time);
    countDownDate.setHours(countDownDate.getHours() + interval);
    countDownDate = countDownDate.getTime();
    this.setDetails(countDownDate);
    this.intervalTime = setInterval(() => {
      this.setDetails(countDownDate);
    }, 1000);
  }

  componentWillUnmount() {
    if (this.intervalTime) {
      //it stops the  setInterval
      clearInterval(this.intervalTime);
    }
  }

  render() {
    // const { hours, minutes, seconds } = this.state;
    // if (this.props.expirationFlag === true) {
    //   return <span>Expired</span>;
    // }
    const { callback = null } = this.props;
    const { hours = '-', minutes = '-', seconds = '-' } = this.state;

    if (hours < 0 && minutes < 0 && seconds < 0) {
      if (callback !== null) {
        if (this.intervalTime) {
          //it stops the  setInterval
          clearInterval(this.intervalTime);
        }
        this.props.callback();
        return null;
      }
      return <span>Expired</span>;
    }

    let result = `${hours}h  ${minutes}m ${seconds}s`;
    return <span>{result}</span>;
  }
}

export class StartCountDownTimerWithEndDate extends React.PureComponent {
  state = {
    days: undefined,
    hours: undefined,
    minutes: undefined,
    seconds: undefined,
  };
  // setting H , M, S
  setDetails = (countDownDate) => {
    let now = new Date().getTime();
    let distance = countDownDate - now;
    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);
    this.setState({ days, hours, minutes, seconds });
  };
  componentWillMount() {
    const { end_time } = this.props;
    let countDownDate = new Date(end_time);
    countDownDate = countDownDate.getTime();
    this.setDetails(countDownDate);
    this.intervalTime = setInterval(() => {
      this.setDetails(countDownDate);
    }, 1000);
  }
  componentWillUnmount() {
    if (this.intervalTime) {
      //it stops the  setInterval
      clearInterval(this.intervalTime);
    }
  }
  render() {
    const { days = '-', hours = '-', minutes = '-', seconds = '-' } = this.state;
    const { callBackFun = null } = this.props;
    if (hours < 0 && minutes < 0 && seconds < 0) {
      if (callBackFun !== null) {
        if (this.intervalTime) {
          //it stops the  setInterval
          clearInterval(this.intervalTime);
        }
        callBackFun();
      }
      return <span>Expired</span>;
    }
    let result = '';
    if (parseInt(days) > 0) {
      result = `${days}d ${hours}h ${minutes}m ${seconds}s`;
    } else {
      result = `${hours}h ${minutes}m ${seconds}s`;
    }
    return <span>{result}</span>;
  }
}

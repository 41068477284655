import { state } from '../../store';

export const [usersPageActions, usersPageSelector, usersPageState] = state('UsersPage', {
  initial: {
    users: [],
    user: null,
    pageToken: null,
    loading: false,
    error: '',
  },
  on: {
    loadUsers: (_, data) => {
      let newStoreObj = { ..._, users: _.users.concat(data.users), pageToken: data.pageToken };

      return newStoreObj;
    },
    clearUsers: (_) => {
      let newStoreObj = { ..._, users: [] };

      return newStoreObj;
    },
    loadUser: (_, data) => {
      let newStoreObj = { ..._, user: data };

      return newStoreObj;
    },
    clearUser: (_) => {
      let newStoreObj = { ..._, user: null };

      return newStoreObj;
    },
    setLoading: (_, data) => {
      let newStoreObj = { ..._, loading: data };

      return newStoreObj;
    },
    setError: (_, data) => {
      let newStoreObj = { ..._, error: data };

      return newStoreObj;
    },
  },
  events: [
    'getUsers',
    'getUser',
    'createUser',
    'updateUser',
    'sendPasswordResetEmail',
    'sendVerificationEmail',
    'setEmailToVerified',
    'disableUser',
    'enableUser',
  ],
});

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { productDetailsPageActions, productDetailsPageSelector } from './store';
import { state } from '../../store';
import { formatMoney } from '../../components-library/helper';
import DefaultImage from '../../app/assets/img/image-placeholder.jpg';
import { appSelector } from '../../app/store';
import ErrorPage from '../../components-library/error-page';

export const ProductDetailsPage = withRouter(
  connect(
    state.select({
      data: productDetailsPageSelector((state) => state.data, []),
      loading: productDetailsPageSelector((state) => state.loading),
      error: appSelector((state) => state.error, ''),
    }),
    state.actions(productDetailsPageActions, ['loadProductDetails']),
  )(
    class ProductDetailsPage extends Component {
      componentWillMount() {
        const { query } = this.props;
        let id = query.get('id');
        if (id) {
          this.props.loadProductDetails(id);
        } else {
          this.props.history.push('/404');
        }
      }
      breadcrumb = () => {
        return (
          <div className="header pl-4">
            <div className="pt-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href={`/seller-products?id=${this.props.data['Supplier Record ID']}`}>Products</a>
                  </li>
                  <li className="breadcrumb-item active">Details</li>
                </ol>
              </nav>
            </div>
          </div>
        );
      };
      render() {
        const { data, loading, error } = this.props;

        if (loading) {
          return (
            <>
              {this.breadcrumb()}

              <section className="container page-content pb-6 market-content text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </section>
            </>
          );
        }
        if (error && error.length > 0) {
          return <ErrorPage error={error} />;
        }
        return (
          <>
            {this.breadcrumb()}

            <div className="container-fluid">
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary btn-md text-uppercase mb-2"
                  onClick={(e) => {
                    this.props.history.push(`/edit-product?id=${data['id']}`);
                  }}
                >
                  <strong>Edit</strong>
                </button>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6 col-xl-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col">
                          <h6 className="text-uppercase text-muted mb-2">Vetting Status</h6>

                          <span className="h2 mb-0">{data['Airportal Vetting Status']}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* NAME */}
                <div className="col-12 col-lg-6 col-xl-2">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col">
                          <h6 className="text-uppercase text-muted mb-2">Product ID</h6>

                          <span className="h2 mb-0">{data['SupplierProductID']} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-6 col-xl-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col">
                          <h6 className="text-uppercase text-muted mb-2">Product Type</h6>
                          <span className="h2 mb-0">
                            {data['Product Type-Other'] || data['Product Type'] === 'Other'
                              ? data['Product Type Other']
                              : data['Product Type']}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col">
                          <h6 className="text-uppercase text-muted mb-2">Product Out of Stock</h6>
                          <span className="h2 mb-0">-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ROW  2*/}

              <div className="row">
                {/* 1*/}
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col">
                          <h6 className="text-uppercase text-muted mb-2">Manufacturer</h6>

                          <span className="h4 mb-0">
                            {data['Manufacturer'] === 'Other' ? data['Manufacturer - Other'] : data['Manufacturer']}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 2*/}
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col">
                          <h6 className="text-uppercase text-muted mb-2">Product Model</h6>

                          <span className="h4 mb-0">
                            {data['Product Model'] === 'Other' ? data['Product Model - Other'] : data['Product Model']}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*END 2*/}
              </div>
              {/* END ROW  2*/}
              {/* ROW  3*/}

              <div className="row">
                <div className="col-12 ">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-header-title">Product Information</h4>
                    </div>
                    <div className="card-body">
                      {/* 1 */}
                      <div className="row align-items-center mb-4">
                        <div className="col-md-6 col-12 mb-sm-3 mb-md-0 mb-3">
                          <h6 className="text-uppercase text-muted mb-2">Price per unit delivered to the US</h6>

                          <span className="h4 mb-0">
                            ${formatMoney(data['Price per unit delivered to the US'], 2, '.')}
                          </span>
                        </div>
                        <div className="col-md-6 col-12 ">
                          <h6 className="text-uppercase text-muted mb-2">Base Unit of Measure</h6>

                          <span className="h4 text-uppercase mb-0">{data['Base UOM name']} </span>
                        </div>
                      </div>
                      {/* 2 */}
                      <div className="row align-items-center mb-4">
                        <div className="col-md-6 col-12 mb-sm-3 mb-md-0 mb-3">
                          <h6 className="text-uppercase text-muted mb-2">Minimum Order Quantity</h6>

                          <span className="h4 mb-0">{data['Minimum Order Quantity']}</span>
                        </div>
                        <div className="col-md-6 col-12 ">
                          <h6 className="text-uppercase text-muted mb-2">Quantity Currently on Hand</h6>

                          <span className="h4 mb-0">{data['Quantity Currently on Hand']} </span>
                        </div>
                      </div>
                      {/* 3 */}
                      <div className="row align-items-center mb-4">
                        <div className="col-md-6 col-12 mb-sm-3 mb-md-0 mb-3">
                          <h6 className="text-uppercase text-muted mb-2">Lead time in days to deliver to USA</h6>

                          <span className="h4 mb-0">{data['Lead time in days to deliver to USA']}</span>
                        </div>
                        <div className="col-md-6 col-12 ">
                          <h6 className="text-uppercase text-muted mb-2">UPC code</h6>

                          <span className="h4 mb-0">{data['UPC code'] ? data['UPC code'] : '-'}</span>
                        </div>
                      </div>
                      {/* 4 */}
                      <div className="row align-items-center mb-4">
                        <div className="col-12">
                          <h6 className="text-uppercase text-muted mb-2">
                            Supporting Documentation and Certifications
                          </h6>
                          {/* inner image row */}
                          <div className="row">
                            {data['Certification and test results for the product'] ? (
                              data['Certification and test results for the product'].map((item, index) => {
                                return (
                                  <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-6">
                                    {item.type ? (
                                      <div className="card">
                                        {item.type.includes('image') ? (
                                          <a href={item.url} target="_blank" rel="noopener noreferrer">
                                            <img
                                              src={item.url}
                                              alt={item.fileName}
                                              className="card-img-top product-detail-image"
                                            />
                                          </a>
                                        ) : item.type.includes('video') ? (
                                          <video width="100%" height="150px" controls>
                                            <source src={item.url} type="video/mp4" />
                                          </video>
                                        ) : item.type === 'application/pdf' ? (
                                          <a href={item.url} target="_blank" rel="noopener noreferrer">
                                            <img
                                              src={
                                                item.thumbnails.large
                                                  ? item.thumbnails.large.url
                                                  : item.thumbnails.small.url
                                              }
                                              alt={item.fileName}
                                              className="card-img-top product-details-image"
                                            />
                                          </a>
                                        ) : item.type.includes('application') ? (
                                          <a href={item.url} target="_blank" rel="noopener noreferrer">
                                            <i className="fe fe-file-text">{item.filename}</i>
                                          </a>
                                        ) : null}
                                      </div>
                                    ) : (
                                      '-'
                                    )}
                                  </div>
                                );
                              })
                            ) : (
                              <div className="col-12">
                                <span>-</span>
                              </div>
                            )}
                          </div>
                          {/* END inner image row */}
                        </div>
                      </div>
                      {/* end 4 */}
                      {/* row ended */}
                    </div>
                  </div>
                </div>
              </div>
              {/* END ROW  3*/}

              {/* IMAGES ROW 5 Product Pictures */}
              <div className="row">
                <div className="col-12 ">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-header-title">Product Pictures and Videos</h4>
                    </div>
                    <div className="card-body">
                      <div className="row align-items-center mb-4">
                        {data['Product Pictures and Videos'] ? (
                          data['Product Pictures and Videos'].map((item, index) => {
                            return (
                              <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-6">
                                {item.type ? (
                                  <div className="card">
                                    {item.type.includes('image') ? (
                                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                                        <img
                                          src={item.url}
                                          alt={item.fileame}
                                          className="card-img-top product-detail-image"
                                        />
                                      </a>
                                    ) : item.type.includes('video') ? (
                                      <video width="100%" height="150px" controls>
                                        <source src={item.url} type="video/mp4" />
                                      </video>
                                    ) : item.type === 'application/pdf' ? (
                                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                                        <img
                                          src={
                                            item.thumbnails.large
                                              ? item.thumbnails.large.url
                                              : item.thumbnails.small.url
                                          }
                                          alt={item.fileName}
                                          className="card-img-top product-details-image"
                                        />
                                      </a>
                                    ) : item.type.includes('application') ? (
                                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                                        <i className="fe fe-file-text">{item.filename}</i>
                                      </a>
                                    ) : null}
                                  </div>
                                ) : (
                                  '-'
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <div className="col-12">
                            <span>-</span>
                          </div>
                        )}

                        <hr />
                      </div>
                      <div className="row align-items-center mb-4">
                        <div className="col-12">
                          <h4>Photo Publication Permission</h4>
                          {data['Photo Publication Permission'] === true ? (
                            <i className="fe fe-check-square text-success"></i>
                          ) : (
                            <i className="fe fe-square "></i>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*END IMAGES ROW 5 Product Pictures */}

              {/* Manufacturer Information ROW 4 */}
              <div className="row">
                <div className="col-12 ">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-header-title">Manufacturer Information</h4>
                    </div>
                    <div className="card-body">
                      {/* 1 */}
                      <div className="row  mb-4">
                        <div className="col-6">
                          <h6 className="text-uppercase text-muted mb-2">
                            FDA registration information for manufacturing facility
                          </h6>

                          <span className="h4 mb-0">
                            {data['FDA registration information for manufacturing facility']}
                          </span>
                        </div>
                        <div className="col-6">
                          <h6 className="text-uppercase text-muted mb-2">Facility inspection OK</h6>

                          <span className="h4 mb-0">{data['Facility inspection OK']} </span>
                        </div>
                      </div>
                      {/* 2 */}
                      <div className="row align-items-center mb-4">
                        <div className="col-6">
                          <h6 className="text-uppercase text-muted mb-2">Country of Manufacture</h6>

                          <p className="text-uppercase mb-4">
                            {Array.isArray(data['Country of Manufacture']) &&
                              data['Country of Manufacture'].map((item, index) => {
                                return (
                                  <span key={index}>
                                    {' '}
                                    <span className="badge badge-soft-secondary">{item}</span>{' '}
                                  </span>
                                );
                              })}
                          </p>
                        </div>
                      </div>
                      {/* 3 */}
                      <div className="row align-items-center mb-4">
                        <div className="col-12">
                          <h6 className="text-uppercase text-muted mb-2">Manufacturer Documentation</h6>
                          {/* inner image row */}
                          <div className="row">
                            {data['Certification support documentation'] ? (
                              data['Certification support documentation'].map((item, index) => {
                                return (
                                  <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-6">
                                    {item.type ? (
                                      <div className="card">
                                        {item.type.includes('image') ? (
                                          <a href={item.url} target="_blank" rel="noopener noreferrer">
                                            <img
                                              src={item.url}
                                              alt={item.fileName}
                                              className="card-img-top product-detail-image"
                                            />
                                          </a>
                                        ) : item.type.includes('video') ? (
                                          <video width="100%" height="150px" controls>
                                            <source src={item.url} type="video/mp4" />
                                          </video>
                                        ) : item.type === 'application/pdf' ? (
                                          <a href={item.url} target="_blank" rel="noopener noreferrer">
                                            <img
                                              src={item.thumbnails ? item.thumbnails.large.url : DefaultImage}
                                              alt={item.fileName}
                                              className="card-img-top product-details-image"
                                            />
                                          </a>
                                        ) : item.type.includes('application') ? (
                                          <a href={item.url} target="_blank" rel="noopener noreferrer">
                                            <i className="fe fe-file-text">{item.filename}</i>
                                          </a>
                                        ) : null}
                                      </div>
                                    ) : (
                                      '-'
                                    )}
                                  </div>
                                );
                              })
                            ) : (
                              <div className="col-12">
                                <span>-</span>
                              </div>
                            )}
                          </div>
                          {/* END inner image row */}
                        </div>
                      </div>
                      {/* end 3 */}
                    </div>
                  </div>
                </div>
              </div>
              {/*END Manufacturer Information ROW 4 */}
              {/* Request A Review  */}
              <div className="row">
                <div className="col-12 ">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-header-title">Request A Review of Product Submission</h4>
                    </div>
                    <div className="card-body">
                      <div className="row align-items-center mb-2">
                        <div className="col-12">
                          <p className="text-muted">
                            Once you have updated your information, added additional documentation, and provided other
                            details below, please request a review by checking this box.
                          </p>
                        </div>
                      </div>
                      <div className="row align-items-center mb-4">
                        <div className="col-12">
                          <h4>Supplier Requested Review</h4>
                          {data['Supplier Requested Review'] === true ? (
                            <i className="fe fe-check-square text-success"></i>
                          ) : (
                            <i className="fe fe-square "></i>
                          )}
                        </div>
                      </div>
                      <div className="row align-items-center mb-4">
                        <div className="col-12">
                          <h4>Vetting Decision Appeal</h4>
                          <span className="h4 mb-0">-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*END Request A Review  */}
            </div>
          </>
        );
      }
    },
  ),
);

import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { landingPageActions, landingPageSelector } from './store';

const AssociationLandingPage = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(landingPageSelector((state) => state.user));
  const userLoaded = useSelector(landingPageSelector((state) => state.userLoaded));
  const query = new URLSearchParams(location.search);
  const associations = query.getAll('association');
  const email = query.get('email');

  useEffect(() => {
    if (email) {
      dispatch(landingPageActions.getUserByEmail(email));
    } else {
      history.push('/login');
    }
  }, [history, dispatch, email]);

  useEffect(() => {
    if (userLoaded) {
      window.localStorage.setItem(
        'associationLandingPage',
        JSON.stringify({
          associations,
          email,
        }),
      );

      if (!user) {
        history.push(`/signup?email=${email}`);
      } else if (user && user.hasLoggedIn) {
        history.push(`/login?email=${email}`);
      } else if (user && !user.hasLoggedIn) {
        history.push(`/forgot?email=${email}`);
      }
    }
  }, [user, userLoaded, history, email, associations]);

  return <div></div>;
};

export default AssociationLandingPage;

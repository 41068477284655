import React, { useState } from 'react';

import { useHistory, useLocation, Switch, Route, Link, Redirect } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import firebase from 'firebase';
import { useSelector } from 'react-redux';

import { appSelector } from '../app/store';
import { AuthPage, AuthBody, AuthExtra } from './auth-components';

const ForgotPasswordPage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [email, setEmail] = useState(query.get('email') || '');
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const history = useHistory();
  const user = useSelector(appSelector((state) => state.user));

  if (user) {
    return <Redirect to="/" />;
  }

  return (
    <AuthPage error={error}>
      <AuthBody>
        <Switch>
          <Route path="/forgot/sent">
            <h2>Please check your email.</h2>
            {email && <p>We sent an email to {email} which contains a link to reset your password.</p>}
          </Route>
          <Route path="/forgot">
            <Form
              onSubmit={async (e) => {
                setError(undefined);

                const form = e.currentTarget;

                e.preventDefault();

                if (form.checkValidity() === false) {
                  e.stopPropagation();
                }

                try {
                  setLoading(true);

                  await firebase.auth().sendPasswordResetEmail(email, {
                    url: `${window.location.origin}/login`,
                  });

                  history.push('/forgot/sent');
                } catch (err) {
                  setError(err.message);
                  setLoading(false);
                }
              }}
            >
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </Form.Group>

              <Button variant="primary" type="submit" style={{ width: '100%' }} disabled={loading}>
                Submit
              </Button>
            </Form>
          </Route>
        </Switch>
      </AuthBody>
      <AuthExtra style={{ paddingTop: 10 }}>
        <span>
          <div>
            <Link to="/login">Return to login</Link>
          </div>
        </span>
      </AuthExtra>
    </AuthPage>
  );
};

export default ForgotPasswordPage;

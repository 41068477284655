import { state } from '../../store';

const initialState = {
  data: [],
  originalData: [],
  categoryList: [],
  loading: true,
  error: '',
  productId: '',
  specifications: '',
  substitutes: '',
  quantity: '',
  timing: '',
  maxPrice: '',
  notes: '',
  headcount: '',
  daysOfCoverage: '',
  size: '',
};

export const [editRfqPageActions, editRfqPageSelector, editRfqPageState] = state('editRfqPage', {
  initial: {
    data: [],
    originalData: [],
    categoryList: [],
    loading: true,
    productId: '',
    specifications: '',
    substitutes: '',
    quantity: '',
    timing: '',
    maxPrice: '',
    notes: '',
    headcount: '',
    size: '',
    daysOfCoverage: '',
  },
  on: {
    loadRfqData: (_, data) => {
      const newStoreObj = {
        ..._,
      };
      newStoreObj.data = data;
      newStoreObj.originalData = data;

      newStoreObj.specifications = data['Product Specification'];
      newStoreObj.size = data['Size'];
      newStoreObj.quantity = data['Quantity'];
      newStoreObj.timing = data['Timing (days)'];
      newStoreObj.maxPrice = data['Max Price Per Product Unit'].split(' ')[0].split('$')[1];
      newStoreObj.notes = data['Public Notes'];
      newStoreObj.productId = data['Product ID'][0];
      newStoreObj.headcount =
        data['Headcount'] && Array.isArray(data['Headcount']) ? data['Headcount'][0] : data['Headcount'];
      newStoreObj.daysOfCoverage = data['Days of Coverage'][0];

      return newStoreObj;
    },
    setLoading: (_, data) => {
      let newStoreObj = { ..._, loading: data };
      return newStoreObj;
    },
    resetState: (_) => {
      return initialState;
    },
    setProductId: (_, data) => {
      let newStoreObj = { ..._, productId: data };
      return newStoreObj;
    },
    setCategoryList: (_, data) => {
      let newStoreObj = { ..._, categoryList: data };
      return newStoreObj;
    },
    handleInputChange: (_, result) => {
      let newStoreObj = { ..._ };

      const { fieldName, value } = result;

      //storing value in variable
      newStoreObj[fieldName] = value;

      return { ...newStoreObj };
    },
  },

  events: ['loadData', 'updateRfq', 'createRfq', 'createRfqLoadData'],
});

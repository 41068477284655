import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
export const OfferSubmissionPage = withRouter(
  connect(
    null,
    null,
  )(
    class OfferSubmissionPage extends React.PureComponent {
      componentWillMount() {
        const { query } = this.props;
        let id = query.get('id');
        setTimeout(function () {
          window.location = `/seller-offers?id=` + id;
        }, 3000);
      }
      render() {
        return (
          <>
            <div className="header">
              <div className="container">
                <div className="header-body">
                  <div className="row align-items-end">
                    <div className="col">
                      <h1 className="header-title">Thank You!</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className="container page-content pb-6 market-content ">
              <p>Your offer has been submitted and is under active review by our marketplace team.</p>
              <p>
                <strong>Need assistance with your offer? </strong>
                Reach out to{' '}
                <a href="mailto:marketplace@projectn95.org" target="_blank" rel="noopener noreferrer">
                  marketplace@projectn95.org
                </a>
              </p>
            </section>
          </>
        );
      }
    },
  ),
);

import React, { Component } from 'react';
import StarRatings from 'react-star-ratings';

export default class RatingComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rating: this.props.rating,
    };
  }
  render() {
    const { rating } = this.state;
    return (
      <div>
        <StarRatings
          rating={rating}
          starDimension="22px"
          starSpacing="0px"
          starRatedColor="#eac964"
          starEmptyColor="#e2e3e5"
        />
      </div>
    );
  }
}

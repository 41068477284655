import React from 'react';
import { connect } from 'react-redux';
import { aggregationSelector, aggregationActions } from './store';
import { state } from '../../store';
import { Modal } from 'react-bootstrap';
require('react-bootstrap/ModalHeader');

export const PurchaseConfirmationModal = connect(
  state.select({
    data: aggregationSelector((state) => state.data, []),
    purchaseData: aggregationSelector((state) => state.purchaseConfirmationData, ''),
    popupIAgreeCheck: aggregationSelector((state) => state.popupIAgreeCheck, ''),
    noOfWorkers: aggregationSelector((state) => state.noOfWorkers, ''),
    daysOfCoverage: aggregationSelector((state) => state.daysOfCoverage, ''),
    quantity: aggregationSelector((state) => state.quantity, ''),
    phone: aggregationSelector((state) => state.phone, ''),
    selectedAddress: aggregationSelector((state) => state.selectedAddress, ''),
  }),

  state.actions(aggregationActions, [
    'popupIAgreeCheckChangeHandler',
    'purchaseHandler',
    'purchaseConfirmationHandler',
    'addressVerificationChangeHandler',
  ]),
)(
  class PurchaseConfirmationModal extends React.PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        buttonClicked: false,
      };
    }
    purchaseConfirmation = (event) => {
      const { data, noOfWorkers, daysOfCoverage, quantity, popupIAgreeCheck, selectedAddress, phone } = this.props;
      event.preventDefault();
      this.setState({ buttonClicked: true });

      let payload = {
        TOS: popupIAgreeCheck,
        aggregatedOrderRecordID: data['Record ID'],
        city: selectedAddress.city,
        coverage: daysOfCoverage,
        headcount: noOfWorkers,
        line1: selectedAddress.line1,
        line2: selectedAddress.line2,
        qty: quantity,
        state: selectedAddress.state,
        zipcode: selectedAddress.zipcode,
        notVerifiedByUPS: selectedAddress.notVerified,
        contact: phone,
      };
      this.props.purchaseConfirmationHandler({ payload });
    };
    render() {
      const {
        data,
        popupIAgreeCheckChangeHandler,
        popupIAgreeCheck,
        purchaseData,
        noOfWorkers,
        daysOfCoverage,
        quantity,
        phone,
        selectedAddress,
      } = this.props;

      let showManualShippingText = false;
      if (
        selectedAddress.state === 'HI' ||
        selectedAddress.state === 'AK' ||
        selectedAddress.state === 'PR' ||
        selectedAddress.state === 'Hawaii' ||
        selectedAddress.state === 'Alaska' ||
        selectedAddress.state === 'Puerto Rico'
      ) {
        showManualShippingText = true;
      }

      return (
        <Modal
          show={this.props.show}
          onHide={(e) => {
            this.setState({ buttonClicked: false });
            this.props.addressVerificationChangeHandler('');
            this.props.onHide();
          }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton className="card-header">
            <Modal.Title id="contained-modal-title-vcenter">
              <h3 className="card-header-title pt-3">Purchase Confirmation</h3>
            </Modal.Title>
          </Modal.Header>

          <form onSubmit={this.purchaseConfirmation}>
            <div className="modal-body mx-3 market-content">
              {data && data['showCreditAvailable'] && (
                <>
                  <p className="mb-2">
                    <strong>ALERT: </strong>
                    The subtotal below reflects a credit of <strong>${purchaseData.appliedCreditAmout}</strong>. On the
                    checkout page, this will be reflected as a lowered unit price of{' '}
                    <mark>${purchaseData.unitPrice}</mark> instead of the original ${data['Price']}.
                  </p>
                  <hr />
                </>
              )}
              <p className="mt-1 mb-3">
                <strong>Frontline workers protected: </strong>
                <span>{noOfWorkers}</span>
                <br />
                <strong>Days of coverage:</strong> <span>{daysOfCoverage}</span>
                <br />
                <strong>Quantity (per {data['UoM']}): </strong>
                <span>{quantity}</span>
                <br />
                <strong>Price:</strong> ${purchaseData.unitPrice}
                <br />
              </p>
              Subtotal: <span>${purchaseData.subTotal}</span>
              <br />
              Shipping: <span>${purchaseData.totalShipping}</span>
              <br />
              {data['showTaxes'] && (
                <>
                  Taxes: <span>${purchaseData.totalTaxes}</span>
                  <br />
                </>
              )}
              {showManualShippingText && (
                <>
                  <hr />
                  <p className="mb-4 mt-1">
                    <strong>NOTE: </strong>
                    Shipping and taxes are calculated manually for shipments outside of the contiguous US. A customer
                    service representative will contact you separately for the shipping and taxes charges.
                  </p>
                </>
              )}
              <h3 className="mt-3 mb-4">
                <mark>
                  TOTAL: <span>${purchaseData.total}</span>
                </mark>
              </h3>
              <strong>Contact Phone:</strong> <span>{phone}</span>
              <br />
              <strong>Address Line 1:</strong> <span>{selectedAddress.line1}</span>
              <br />
              <strong>Address Line 2:</strong> <span>{selectedAddress.line2}</span>
              <br />
              <strong>City:</strong> <span>{selectedAddress.city}</span>
              <br />
              <strong>State:</strong> <span>{selectedAddress.state}</span>
              <br />
              <strong>Zip Code:</strong> <span>{selectedAddress.zipcode}</span>
              <br />
              <div className="form-group mt-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="modalTermsAndConditions"
                    checked={popupIAgreeCheck}
                    onChange={(e) => popupIAgreeCheckChangeHandler()}
                    required
                  />
                  <label className="form-check-label">
                    I agree to the Marketplace{' '}
                    <a href="https://www.projectn95.org/market-terms">terms and conditions</a>
                  </label>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                id="purchasePopupSubmitBtn"
                className="btn btn-block btn-primary"
                disabled={this.state.buttonClicked === true ? true : false}
              >
                {this.state.buttonClicked === false ? (
                  `  Purchase ($${purchaseData.total})`
                ) : (
                  <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{' '}
                    Processing...
                  </>
                )}
              </button>
            </div>
          </form>
        </Modal>
      );
    }
  },
);

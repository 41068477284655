import { putResolve, take, call, spawn } from 'redux-saga/effects';
import { offerPageActions } from './store';
import { api } from '../../app/api';
import { appActions } from '../../app/store';

export function* OfferspageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(offerPageActions.offersPageData().type);
      yield putResolve(offerPageActions.loadData([]));

      yield putResolve(offerPageActions.setLoading(true));

      let apiData = yield call(api, `/.netlify/functions/offer?id=${data}`);

      yield putResolve(offerPageActions.setLoading(false));

      if (apiData.error) {
        yield putResolve(appActions.setError(apiData.error));
        yield putResolve(appActions.hideNav());
        return;
      }

      if (apiData.data && apiData.error === null) {
        if (Array.isArray(apiData.data.offerData) && apiData.data.offerData.length) {
          apiData.data.offerData = apiData.data.offerData.sort(function (a, b) {
            return parseFloat(a['Total Cost']) - parseFloat(b['Total Cost']);
          });
          apiData.data.offerData = apiData.data.offerData.map((item) => {
            //prodcut rating check

            let ratingCSS = item['Product Ratings CSS'];
            let ratingCount = item['Product Ratings Count'];

            let showRating = true;
            if (
              !(
                Array.isArray(ratingCount) &&
                ratingCount.length &&
                ratingCount.some(function (it) {
                  return it !== 0;
                })
              )
            ) {
              showRating = false;
            }
            if (
              !(
                Array.isArray(ratingCSS) &&
                ratingCSS.length &&
                ratingCSS.some(function (it) {
                  return it !== 0;
                })
              )
            ) {
              showRating = false;
            }
            item.showRating = showRating;

            return item;
          });
        }
        yield putResolve(appActions.setId(apiData.data['Buyer Record ID']));
        yield putResolve(offerPageActions.loadData(apiData.data));
      }

      // yield true;
    } catch (err) {
      console.log(err);
      yield putResolve(appActions.hideNav());
      yield putResolve(offerPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export function* OfferspageRootSaga() {
  yield spawn(OfferspageSaga);
}

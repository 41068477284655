import { state } from '../../store';

export const [stripeConnectOauthPageActions, stripeConnectOauthPageSelector, stripeConnectOauthPageeState] = state(
  'StripeConnectOauthPage',
  {
    initial: {
      loading: true,
    },
    on: {
      setLoading: (_, data) => {
        let newStoreObj = { ..._, loading: data };
        return newStoreObj;
      },
    },
    events: ['stripeConnectOauthPageData'],
  },
);

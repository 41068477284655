import React, { useState, useEffect } from 'react';

import { useHistory, useLocation, Link, Redirect } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import firebase from 'firebase';
import { useSelector } from 'react-redux';

import { AuthPage, AuthBody, AuthExtra, GoogleButton } from '../auth-components';
import { appSelector } from '../../app/store';

const SignupUserPage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [email, setEmail] = useState(query.get('email') || '');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const user = useSelector(appSelector((state) => state.user));
  const utmSource = query.get('utm_source');
  const utmCampaign = query.get('utm_campaign');
  const utmTerm = query.get('utm_term');
  const utmContent = query.get('utm_content');
  const utmMedium = query.get('utm_medium');

  useEffect(() => {
    window.localStorage.setItem(
      'utmParams',
      JSON.stringify({
        utmSource,
        utmCampaign,
        utmTerm,
        utmContent,
        utmMedium,
      }),
    );
  }, [utmSource, utmCampaign, utmTerm, utmContent, utmMedium]);

  if (user) {
    return <Redirect to="/" />;
  }

  return (
    <AuthPage error={error}>
      <AuthExtra style={{ paddingBottom: 10 }}>
        <span>
          Already have an account? <Link to="/login">Login here</Link>
        </span>
      </AuthExtra>
      <AuthBody title="Sign up">
        <Form
          onSubmit={async (e) => {
            const form = e.currentTarget;

            e.preventDefault();

            if (form.checkValidity() === false) {
              e.stopPropagation();
            }

            try {
              setLoading(true);

              const result = await firebase.auth().createUserWithEmailAndPassword(email, password);
              const user = result.user;

              await Promise.all([
                user.updateProfile({ displayName: `${firstName} ${lastName}` }),
                user.sendEmailVerification({
                  url: `${window.location.origin}/login`,
                }),
              ]);

              history.push('/signup/buyer');
            } catch (err) {
              setError(err.message);
              setLoading(false);
            }
          }}
        >
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="required">Work Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="your.name@health.org"
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Form.Group>

          <div className="row">
            <div className="col-md-6">
              <Form.Group controlId="formBasicFirstName">
                <Form.Label className="required">First Name</Form.Label>
                <Form.Control placeholder="Enter first name" required onChange={(e) => setFirstName(e.target.value)} />
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group controlId="formBasicLastName">
                <Form.Label className="required">Last Name</Form.Label>
                <Form.Control placeholder="Enter last name" required onChange={(e) => setLastName(e.target.value)} />
              </Form.Group>
            </div>
          </div>

          <Form.Group controlId="formBasicPassword">
            <Form.Label className="required">Create Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Button variant="primary" type="submit" style={{ width: '100%' }} disabled={loading}>
            Continue
          </Button>
        </Form>
        <hr />
        <p style={{ textAlign: 'center' }}>or</p>
        <GoogleButton onError={(err) => setError(err.message)} />
      </AuthBody>
    </AuthPage>
  );
};

export default SignupUserPage;

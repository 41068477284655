import { state } from '../../store';

export const [dealPageActions, dealPageSelector, dealPageState] = state('DealPage', {
  initial: {
    data: [],
    loading: true,
    showPopup: false,
    showPurchasePopup: false,
    notes: '',
    tos: false,
    fieldError: {
      notes: '',
      tos: '',
    },
    purchaseDelivery: '',
    paymentMethod: 'invoice',
    iAgreeCheck: false,
    shippingAddress: '',
    iAgreePopupCheck: false,
    stripe: null,
    plaid: null,
  },
  on: {
    loadBuyerDetails: (_, data) => {
      let newStoreObj = { ..._, data: data };

      return newStoreObj;
    },
    setLoading: (_, data) => {
      let newStoreObj = { ..._, loading: data };
      return newStoreObj;
    },
    setStripe: (_, data) => {
      let newStoreObj = { ..._, stripe: data };
      return newStoreObj;
    },
    setPlaid: (_, data) => {
      let newStoreObj = { ..._, plaid: data };
      return newStoreObj;
    },
    setShowModal: (_, status) => {
      let newStoreObj = { ..._, showPopup: status };
      return newStoreObj;
    },
    setShowPurchasedPopup: (_, status) => {
      let newStoreObj = { ..._ };
      newStoreObj.showPurchasePopup = status;
      return newStoreObj;
    },
    tosCheckChangeHandler: (_) => {
      let newStoreObj = { ..._ };

      newStoreObj.tos = !newStoreObj.tos;

      return {
        ...newStoreObj,
      };
    },
    inquiryFieldsChangeHandler: (_, data) => {
      let newStoreObj = { ..._ };

      const { fieldLabel, value } = data;
      //specific field update

      if (fieldLabel === 'notes' && value.length === 0) {
        newStoreObj.fieldError[fieldLabel] = 'is-invalid';
      } else {
        newStoreObj.fieldError[fieldLabel] = '';
      }

      if (fieldLabel === 'notes') {
        newStoreObj[fieldLabel] = value;
      }

      return { ...newStoreObj };
    },
    offersFieldsChangeHandler: (_, data) => {
      let newStoreObj = { ..._ };

      const { fieldLabel, value } = data;
      //specific field update

      newStoreObj[fieldLabel] = value;

      return { ...newStoreObj };
    },
    iAgreeCheckChangeHandler: (_) => {
      let newStoreObj = { ..._ };

      newStoreObj.iAgreeCheck = !newStoreObj.iAgreeCheck;

      return {
        ...newStoreObj,
      };
    },
    iAgreePopupCheckChangeHandler: (_) => {
      let newStoreObj = { ..._ };
      newStoreObj.iAgreePopupCheck = !newStoreObj.iAgreePopupCheck;
      return {
        ...newStoreObj,
      };
    },
    resetPopup: (_) => {
      let newStoreObj = { ..._ };
      newStoreObj.shippingAddress = '';
      newStoreObj.iAgreePopupCheck = false;
      return newStoreObj;
    },
    resetInquiryPopup: (_) => {
      let newStoreObj = { ..._ };
      newStoreObj.notes = '';
      newStoreObj.tos = false;
      return newStoreObj;
    },
  },
  events: ['dealPageData', 'submitInquiry', 'purchaseProduct', 'purchaseProductCC', 'purchaseProductACHDebit'],
});

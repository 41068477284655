import { state } from '../../store';
const initialState = {
  productTypesList: [],
  data: [],
  filteredProducts: [],
  loading: true,
  productId: '',
  productOutofStock: '',
  productModel: '',
  productModelOther: '',
  productPictures: [],
  photoPublicationPermission: false,
  pricePerUnit: '',
  baseUnit: '',
  minOrderQuantity: '',
  quantityCurrentlyOnHand: '',
  daysToDeliver: '',
  upcCode: '',
  documentsAndCertification: [],
  manufacturer: '',
  otherManufacturer: '',
  fdaRegistrationInfo: '',
  countryOfManufacture: [],
  manufacturerDocumentation: [],
  supplierRequestedReviewCheck: false,
  vettingDecisionAppeal: '',
  unlinkProductPictures: [],
  unlinkDocumentsAndCertification: [],
  unlinkManufacturerDocumentation: [],
};
export const [addProductPageActions, addProductPageSelector, addProductPageState] = state('AddProductPage', {
  initial: {
    productTypesList: [],
    data: [],
    filteredProducts: [],
    loading: true,
    productId: '',
    otherProductType: '',
    productOutofStock: '',
    productModel: '',
    productModelOther: '',
    productPictures: [],
    photoPublicationPermission: false,
    pricePerUnit: '',
    baseUnit: '',
    minOrderQuantity: '',
    quantityCurrentlyOnHand: '',
    daysToDeliver: '',
    upcCode: '',
    documentsAndCertification: [],
    manufacturer: '',
    otherManufacturer: '',
    fdaRegistrationInfo: '',
    countryOfManufacture: [],
    manufacturerDocumentation: [],
    supplierRequestedReviewCheck: false,
    vettingDecisionAppeal: '',
    unlinkProductPictures: [],
    unlinkDocumentsAndCertification: [],
    unlinkManufacturerDocumentation: [],
  },
  on: {
    loadProductType: (_, data) => {
      let newStoreObj = { ..._, productTypesList: data };
      return newStoreObj;
    },
    loadProductData: (_, data) => {
      let newStoreObj = { ..._ };
      newStoreObj.data = data;

      newStoreObj.productId = data['Product Type ID'][0];
      newStoreObj.otherProductType = data['Product Type Other'];
      newStoreObj.productOutofStock = data['Product Out of Stock'];
      newStoreObj.productModel = data['Actual Product Model'];
      newStoreObj.productModelOther = data['Product Model - Other'];
      newStoreObj.productPictures = data['Product Pictures and Videos'] ? data['Product Pictures and Videos'] : [];
      newStoreObj.photoPublicationPermission = data['Photo Publication Permission'];
      newStoreObj.pricePerUnit = data['Price per unit delivered to the US'];
      newStoreObj.baseUnit = data['Base UOM name'][0];
      newStoreObj.minOrderQuantity = data['Minimum Order Quantity'];
      newStoreObj.quantityCurrentlyOnHand = data['Quantity Currently on Hand'];
      newStoreObj.daysToDeliver = data['Lead time in days to deliver to USA'];
      newStoreObj.upcCode = data['UPC code'];
      newStoreObj.documentsAndCertification = data['Certification and test results for the product']
        ? data['Certification and test results for the product']
        : [];
      newStoreObj.manufacturer = data['Manufacturer'];
      newStoreObj.otherManufacturer = data['Manufacturer - Other'];
      newStoreObj.fdaRegistrationInfo = data['FDA registration information for manufacturing facility'];
      newStoreObj.countryOfManufacture = data['Country of Manufacture'];
      newStoreObj.manufacturerDocumentation = data['Certification support documentation']
        ? data['Certification support documentation']
        : [];

      return newStoreObj;
    },
    setLoading: (_, data) => {
      let newStoreObj = { ..._, loading: data };
      return newStoreObj;
    },
    resetState: (_) => {
      return initialState;
    },
    handleInputChange: (_, result) => {
      let newStoreObj = { ..._ };

      const { fieldName, value } = result;

      if (fieldName === 'countryOfManufacture') {
        let countryNames = value && value.map((item) => item.value);
        newStoreObj[fieldName] = countryNames;
      } else {
        //storing value in variable
        newStoreObj[fieldName] = value;
      }

      return { ...newStoreObj };
    },
    photoPublicationPermissionChangeHandler: (_) => {
      let newStoreObj = { ..._ };

      newStoreObj.photoPublicationPermission = !newStoreObj.photoPublicationPermission;

      return {
        ...newStoreObj,
      };
    },

    uploadFileChangeHandler: (_, data) => {
      let newStoreObj = { ..._ };
      const { selected, key } = data;
      let currentAttachments = newStoreObj[key];

      newStoreObj[key] = [
        ...currentAttachments,
        ...selected.filter((file) => {
          let isFileExist = currentAttachments.some(
            (att) => att.originalname === file.originalname && att.size === file.size && att.mimetype === file.mimetype,
          );
          if (!isFileExist) {
            file.id = Math.random();
            file.newUpload = true;
            return true;
          }
          return false;
        }),
      ];
      return newStoreObj;
    },
    removeProductPicture: (_, result) => {
      let newStoreObj = {
        ..._,
      };
      let message = 'Are you sure you want to remove this file?';
      let newFiles = [...newStoreObj.productPictures];

      let unlinkFiles = [...newStoreObj.unlinkProductPictures];

      const filtered = newFiles.filter((file) => {
        if (file.id === result.id || file.itemImageId === result.id) {
          // eslint-disable-next-line no-restricted-globals
          if (confirm(message)) {
            unlinkFiles.push(file);
          } else {
            return true;
          }
        }
        let returnFlag = file.id ? file.id !== result.id : file.itemImageId !== result.id;
        return returnFlag;
      });
      newStoreObj.unlinkProductPictures = unlinkFiles;
      newStoreObj.productPictures = filtered;
      return newStoreObj;
    },
    removeDocumentsAndCertification: (_, result) => {
      let newStoreObj = {
        ..._,
      };
      let message = 'Are you sure you want to remove this file?';
      let newFiles = [...newStoreObj.documentsAndCertification];

      let unlinkFiles = [...newStoreObj.unlinkDocumentsAndCertification];

      const filtered = newFiles.filter((file) => {
        if (file.id === result.id || file.itemImageId === result.id) {
          // eslint-disable-next-line no-restricted-globals
          if (confirm(message)) {
            unlinkFiles.push(file);
          } else {
            return true;
          }
        }
        let returnFlag = file.id ? file.id !== result.id : file.itemImageId !== result.id;
        return returnFlag;
      });

      newStoreObj.unlinkDocumentsAndCertification = unlinkFiles;
      newStoreObj.documentsAndCertification = filtered;
      return newStoreObj;
    },
    removeManufacturerDocumentation: (_, result) => {
      let newStoreObj = {
        ..._,
      };
      let message = 'Are you sure you want to remove this file?';
      let newFiles = [...newStoreObj.manufacturerDocumentation];

      let unlinkFiles = [...newStoreObj.manufacturerDocumentation];

      const filtered = newFiles.filter((file) => {
        if (file.id === result.id || file.itemImageId === result.id) {
          // eslint-disable-next-line no-restricted-globals
          if (confirm(message)) {
            unlinkFiles.push(file);
          } else {
            return true;
          }
        }
        let returnFlag = file.id ? file.id !== result.id : file.itemImageId !== result.id;
        return returnFlag;
      });
      newStoreObj.unlinkManufacturerDocumentation = unlinkFiles;
      newStoreObj.manufacturerDocumentation = filtered;
      return newStoreObj;
    },
  },
  events: ['addNewProduct', 'updateProduct', 'loadProductDetails', 'loadProductTypes'],
});

import React from 'react';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { state } from '../../store/index';
import { dealPageActions, dealPageSelector } from './store';
import { Countdown } from '../../components-library/countDown';
import RatingComponent from '../../components-library/ratingComponent';
import { InquiryPopup } from './inquiryPopup';
import { getFlagAndCountry } from '../../components-library/helper';
import { PurchaseProduct } from './purchaseProductPopup';
import { appSelector } from '../../app/store';
import ErrorPage from '../../components-library/error-page';

export const DealPage = connect(
  state.select({
    data: dealPageSelector((state) => state.data, []),
    loading: dealPageSelector((state) => state.loading, null),
    error: appSelector((state) => state.error, null),
    showPopup: dealPageSelector((state) => state.showPopup, false),
    notes: dealPageSelector((state) => state.notes, ''),
    tos: dealPageSelector((state) => state.tos, ''),
    fieldError: dealPageSelector((state) => state.fieldError, ''),
    purchaseDelivery: dealPageSelector((state) => state.purchaseDelivery, ''),
    paymentMethod: dealPageSelector((state) => state.paymentMethod, ''),
    iAgreeCheck: dealPageSelector((state) => state.iAgreeCheck, ''),
  }),
  state.actions(dealPageActions, [
    'dealPageData',
    'setShowModal',
    'offersFieldsChangeHandler',
    'iAgreeCheckChangeHandler',
    'setShowPurchasedPopup',
    'setStripe',
    'setPlaid',
  ]),
)(
  class DealPage extends React.PureComponent {
    state = {
      showPurchasedPopup: false,
    };
    componentWillMount() {
      const { query } = this.props;
      // let draft_id = query.get("draft_id");
      let id = query.get('id');
      let transact_id = query.get('transact_id');

      this.props.dealPageData({ id, transact_id });

      let stripElement = document.createElement('script');
      let plaidElement = document.createElement('script');

      stripElement.setAttribute('src', 'https://js.stripe.com/v3/');
      plaidElement.setAttribute('src', 'https://cdn.plaid.com/link/v2/stable/link-initialize.js');

      document.head.appendChild(stripElement);
      document.head.appendChild(plaidElement);

      plaidElement.onload = () => {
        console.log(`plaid loaded!`, window.Plaid);
      };

      stripElement.onload = () => {
        let base_url = window.location.origin;
        const stripe_pk_test_key = `pk_test_lEB9lUuCtWLZqEz2tc5NjHef00coK4jX91`;
        const stripe_pk_live_key = `pk_live_Hh9wXjxOfGl47hbUrWHl7bc900LeLjyaYs`;
        let stripe = window.Stripe(base_url.includes('projectn95.lvrg.ai') ? stripe_pk_live_key : stripe_pk_test_key);

        this.props.setStripe(stripe);
      };
    }

    purchaseProductbuttonHandler = (event) => {
      event.preventDefault();
      this.setState({ showPurchasedPopup: true });
    };
    breadcrumb = () => {
      return (
        <div className="header">
          <div className="container-fluid pt-5">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href={'/buyer-rfqs?id=' + this.props.data['Buyer Record ID']}>Request For Quotes</a>
                </li>
                <li className="breadcrumb-item">
                  <a href={`/offer?id=${this.props.data['Hotline ID']}`}>Offers</a>
                </li>
                <li className="breadcrumb-item active">Offer Details</li>
              </ol>
            </nav>
          </div>
        </div>
      );
    };
    render() {
      const {
        data,
        loading,
        error,
        showPopup,
        setShowModal,
        notes,
        tos,
        fieldError,
        offersFieldsChangeHandler,
        iAgreeCheck,
      } = this.props;
      if (loading) {
        return (
          <>
            {this.breadcrumb()}
            <section className="container page-content pb-6 market-content text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </section>
          </>
        );
      }
      if ((Array.isArray(data['Expired?']) && data['Expired?'][0] === 1) || (error && error.length > 0)) {
        return <ErrorPage error={error} />;
      }

      let productPictures = data['Product Pictures'];

      let flagDetails = getFlagAndCountry(data['Country of Manufacture']);

      let rating = data['Product Ratings CSS'] / 10;

      let showACHText = false;

      if (data.showPurchaseButton === false) {
        let paymentType = data['transactionData']['Transaction Type'];
        let status = data['transactionData']['Status'];
        if (paymentType === 'ACH' && status !== 'failed' && status !== 'success') {
          showACHText = true;
        }
      }

      return (
        <>
          {this.breadcrumb()}

          <div className="container">
            <div className="row mb-5 justify-content-center">
              <div className="col-md-6">
                <h3 className="my-0">
                  <strong>Your offer for:</strong>
                </h3>
                <h1 className="mt-0 mb-1 pull-webfont-left">
                  {data['Product Type']}
                  {flagDetails && (
                    <span data-toggle="tooltip" className="pl-1" title={flagDetails.flagTitle}>
                      <img
                        src={flagDetails.flagSrc}
                        alt={flagDetails.flagAlt}
                        data-toggle="tooltip"
                        title={flagDetails.flagTitle}
                        className="flag-image"
                      />
                    </span>
                  )}
                </h1>
                <p className="mb-1">
                  {data.Manufacturer}
                  <br />
                  {data['Product Model']}
                  <br />
                  {data['Level of Isolation Gown']}
                  {data['Level of Surgical Mask']}
                </p>
                {data['showRating'] === true ? (
                  <div className="d-flex flex-row " style={{ height: '20px' }}>
                    <RatingComponent rating={rating} />
                    <span
                      className="market-stars"
                      style={{
                        padding: 'unset',
                        paddingLeft: '10px',
                        paddingTop: '3px',
                      }}
                    >
                      {data['Product Ratings Count Text']}
                    </span>
                  </div>
                ) : null}
                <h5 className="mt-3 mb-0"> </h5>
                <Row>
                  <div className="col-md-12 ">
                    {data.Badges &&
                      Array.isArray(data.Badges) &&
                      data.Badges.map((badge, idx) => (
                        <div
                          key={idx}
                          className={`supply-label ${badge.name} d-inline`}
                          data-toggle="tooltip"
                          title={`${badge.title}`}
                        ></div>
                      ))}
                    <br />
                    <p className="mt-3">
                      <strong>Price per unit: </strong> ${data['Quote Price']}
                      <br />
                      <strong>Quantity (each/ounce): </strong> {data.Quantity}
                      <br />
                      <strong>Delivered within: </strong> {data['Delivery By']}
                      <br />
                      {/* <strong>Payment Terms: </strong> {data["Payment Terms"]}
                    <br /> */}
                      <strong>Additional Information: </strong> {data['Additional Information']}
                    </p>
                  </div>
                  <div className=" col-md-12  mt-3 acp-content">
                    <ul className="n95 mt-2">
                      {productPictures &&
                        productPictures.map((img, index) => {
                          return (
                            <span key={index}>
                              <li>
                                <img
                                  className="rounded cursorTospan"
                                  src={img.url}
                                  alt={img.filename}
                                  onClick={(e) => window.open(img.url)}
                                />
                              </li>
                            </span>
                          );
                        })}
                    </ul>
                  </div>
                </Row>
                <p className="mt-4">
                  <strong>Dispute Resolution:</strong> Refunds will be issued in full (less shipping) in case of product
                  defect, failed delivery or delay of more than 5 business days over original delivery estimate. No
                  refunds will be required if request is made more than 7 business days following Buyer receipt of the
                  goods, or for open or used products.
                </p>
                <p className="mt-4">
                  <strong>Need additional information before purchasing?&nbsp;</strong>
                  Submit your&nbsp;
                  <span onClick={() => setShowModal(true)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                    inquiry or question here
                  </span>
                  .
                </p>
                {showPopup && (
                  <InquiryPopup
                    show={showPopup}
                    onHide={() => this.props.setShowModal(false)}
                    notes={notes}
                    tos={tos}
                    fieldError={fieldError}
                  />
                )}
              </div>
              <div className="col-md-6 card market-agg-card">
                <div className="info-box md-5 mt-5 mt-md-0 card-body">
                  {data['showTimer'] && (
                    <>
                      <h3>
                        Offer expires in:{' '}
                        <span className="d-md-none">
                          <br />
                          <br />
                        </span>
                        <strong>
                          <mark>
                            {data && data['All Offers Start Time'] && data['showTimer'] === true ? (
                              <Countdown start_time={new Date(data['All Offers Start Time'])} interval={48} />
                            ) : (
                              <span>- h - m - s</span>
                            )}
                          </mark>
                        </strong>
                      </h3>
                      <strong>Record ID: </strong> <span>{data['Record ID']}</span>
                      <hr />
                    </>
                  )}

                  {data.showTotal === true ? (
                    <>
                      <strong>Subtotal: </strong>${data.Subtotal}
                      <br />
                      <strong>Taxes: </strong>${data.Taxes}
                      <br />
                      <strong>Shipping: </strong>${data['Shipping Cost']}
                      <br />
                      <h5 className="my-4">
                        <mark>TOTAL: ${data['Total Cost']}</mark>
                      </h5>
                    </>
                  ) : null}

                  {data.showPurchaseButton === true ? (
                    <>
                      {data.todaysChargeText && <h5 className="my-4">{data.todaysChargeText}</h5>}
                      <form onSubmit={this.purchaseProductbuttonHandler}>
                        <div className="form-group">
                          <label>Purchase/Delivery Instructions:</label>
                          <textarea
                            className="form-control"
                            id="notes"
                            rows="3"
                            onChange={(e) => {
                              let payload = {
                                value: e.target.value,
                                fieldLabel: 'purchaseDelivery',
                              };

                              offersFieldsChangeHandler(payload);
                            }}
                          ></textarea>
                        </div>
                        <div className="form-group">
                          <label>
                            <strong>Payment Method:</strong>
                          </label>
                          <select
                            className="form-control"
                            id="paymentMethod"
                            required
                            defaultValue="invoice"
                            onChange={(e) => {
                              let payload = {
                                value: e.target.value,
                                fieldLabel: 'paymentMethod',
                              };

                              offersFieldsChangeHandler(payload);
                            }}
                          >
                            <option value="invoice">Wire Transfer</option>
                            <option value="invoice">ACH Payment (Credit)</option>
                            <option value="cc">Credit Card</option>
                            {data.showAchDebitOption === false ? null : (
                              <option value="ach">ACH Payment (Debit)</option>
                            )}
                          </select>
                        </div>
                        <div className="form-group">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="termsAndConditions"
                              required
                              checked={iAgreeCheck}
                              onChange={(e) => this.props.iAgreeCheckChangeHandler()}
                            />
                            <label className="form-check-label">
                              I agree to the{' '}
                              <a href="https://www.projectn95.org/market-terms">Marketplace terms and conditions</a>
                            </label>
                          </div>
                        </div>

                        <button id="purchaseBtn" type="submit" className="btn btn-block btn-primary">
                          Purchase Product
                        </button>
                      </form>
                      <p className="mt-4">
                        <strong>NOTICE:</strong> To mitigate your risk, payment is collected upfront and released based
                        on payment terms. Our team provides support on all dispute resolutions and refunds on orders
                        placed in the Marketplace.
                      </p>
                    </>
                  ) : (
                    data.transactionData && (
                      <>
                        <h5 className="my-4">
                          <mark>Total amount charged: ${data.transactionData.Amount}</mark>
                        </h5>
                        <h5 className="mt-0 mb-4">Payment Type: {data.transactionData.PaymentMethod}</h5>
                        <h5 className="mt-0 mb-4">Transaction Status: {data.transactionData.Status}</h5>
                        {data.transactionData['Invoice URL'] && (
                          <h5 className="mt-0 mb-4">
                            Invoice URL:{' '}
                            <a
                              href={data.transactionData['Invoice URL']}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: 'underline' }}
                            >
                              Click here to pay
                            </a>
                          </h5>
                        )}

                        {showACHText && (
                          <p>
                            <strong>
                              ACH payments may take 5-7 business days to complete. We will notify you and the seller
                              once the transaction has cleared.
                            </strong>
                          </p>
                        )}
                        <h5>Purchase/Delivery Instructions: {data.transactionData['Purchase Instructions']}</h5>
                        {data.showRetryLink && (
                          <>
                            <div className="form-group">
                              <label htmlFor="notes">Purchase/Delivery Instructions:</label>
                              <textarea
                                className="form-control"
                                id="notes"
                                rows="3"
                                onChange={(e) => {
                                  let payload = {
                                    value: e.target.value,
                                    fieldLabel: 'purchaseDelivery',
                                  };

                                  offersFieldsChangeHandler(payload);
                                }}
                              ></textarea>
                            </div>
                            <div className="form-group">
                              <label htmlFor="paymentMethod">
                                <strong>Payment Method:</strong>
                              </label>
                              <select
                                className="form-control"
                                id="paymentMethod"
                                onChange={(e) => {
                                  let payload = {
                                    value: e.target.value,
                                    fieldLabel: 'paymentMethod',
                                  };

                                  offersFieldsChangeHandler(payload);
                                }}
                                required
                              >
                                <option value="invoice" selected>
                                  Wire Transfer
                                </option>
                                <option value="invoice">ACH Payment (Credit)</option>
                                <option value="cc">Credit Card</option>
                                {data.showAchDebitOption && <option value="ach">ACH Payment (Debit)</option>}
                              </select>
                            </div>

                            <div className="form-group"></div>
                            <button
                              id="purchaseBtn"
                              type="submit"
                              className="btn btn-block btn-primary"
                              onClick={() => this.setState({ showPurchasedPopup: true })}
                            >
                              Retry Payment
                            </button>
                          </>
                        )}
                        <p className="mt-4 product-needs">We can accept ACH, wire, and credit card payment methods.</p>
                      </>
                    )
                  )}
                  <PurchaseProduct
                    show={this.state.showPurchasedPopup}
                    onHide={() => this.setState({ showPurchasedPopup: false })}
                  />
                  <p className="product-needs">
                    <strong>Need help with this transaction or have a question about the offer?</strong> Email us at{' '}
                    <a href="mailto:marketplace@projectn95.org" target="_blank" rel="noopener noreferrer">
                      marketplace@projectn95.org
                    </a>
                    .
                  </p>
                  <p className="mt-4 ">
                    <strong>WARNING: DO NOT</strong> share this website page/url/link with anyone outside YOUR company.
                    This page contains personal identifiable information (PII) about YOU, YOUR company, and YOUR
                    competitive offers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  },
);

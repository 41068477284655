import { state } from '../../store';
export const sortAscDesc = (a, b, typeCheck) => {
  if (typeCheck) {
    if (a < b) {
      return -1;
    }
    if (b > a) {
      return 1;
    }
    return 0;
  } else {
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  }
};

const offersFilter = (text) => (item) => {
  const won = 'won';
  const refunded = 'refunded';
  const completed = 'quoted';

  if (item['Product Type'] && item['Product Type'][0].toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['Quoted Quantity'] && item['Quoted Quantity'].toString().toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['Quoted Taxes'] && item['Quoted Taxes'].toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['Quoted Shipping Cost'] && item['Quoted Shipping Cost'].toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['Quoted Delivery By'] && item['Quoted Delivery By'].toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['RFQ Offer Status'] === 'Lost' && item['RFQ Offer Status'].toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['RFQ Offer Status'] === 'Missed' && item['RFQ Offer Status'].toLowerCase().indexOf(text) !== -1) {
    return item;
  } else if (item['RFQ Offer Status'] === 'Completed' && completed.indexOf(text) !== -1) {
    return item;
  } else if (item['RFQ Offer Status'] === 'Won' && won.indexOf(text) !== -1) {
    return item;
  } else if (item['RFQ Offer Status'] === 'Refunded' && refunded.indexOf(text) !== -1) {
    return item;
  }
};

const initialOffersFilterFields = {
  won: { product: '' },
  quoted: { product: '' },
  lost: { product: '' },
};
export const [sellerOffersPageActions, sellerOffersPageSelector, sellerOffersPageState] = state('SellerOffersPage', {
  initial: {
    filteredOffer: { won: [], quoted: [], lost: [] },
    originalData: { won: [], quoted: [], lost: [] },
    loading: true,
    sortoffersType: {
      won: {
        product: { ascending: false },
        status: { ascending: false },
        quantity: { ascending: false },
        taxes: { ascending: false },
        shipping: { ascending: false },
        deliveredBy: { ascending: false },
      },
      quoted: {
        product: { ascending: false },
        status: { ascending: false },
        quantity: { ascending: false },
        taxes: { ascending: false },
        shipping: { ascending: false },
        deliveredBy: { ascending: false },
      },
      lost: {
        product: { ascending: false },
        status: { ascending: false },
        quantity: { ascending: false },
        taxes: { ascending: false },
        shipping: { ascending: false },
        deliveredBy: { ascending: false },
      },
    },
    offersFilterFields: { ...initialOffersFilterFields },
    uniqueProducts: [],
    searchText: { won: '', quoted: '', lost: '' },
  },
  on: {
    loadData: (_, data) => {
      const newStoreObj = {
        ..._,
      };

      if (data.status === 'Won') {
        let wonWithShippingUrl = data.offers.filter(function (item) {
          return item['Winning Offer Shipping Information'];
        });

        let wonWithShippingUrlUnpaid = wonWithShippingUrl.filter(function (item) {
          return !item['Paid Out?'] || (item['Paid Out?'] && item['Paid Out?'][0] !== true);
        });

        let wonWithShippingUrlPaid = wonWithShippingUrl.filter(function (item) {
          return item['Paid Out?'] && item['Paid Out?'][0] === true;
        });

        let wonWithoutShippingUrl = data.offers.filter(function (item) {
          return !item['Winning Offer Shipping Information'];
        });

        newStoreObj.filteredOffer[data.status.toLowerCase()] = [
          ...wonWithoutShippingUrl,
          ...wonWithShippingUrlUnpaid,
          ...wonWithShippingUrlPaid,
        ];
        newStoreObj.originalData[data.status.toLowerCase()] = [
          ...wonWithoutShippingUrl,
          ...wonWithShippingUrlUnpaid,
          ...wonWithShippingUrlPaid,
        ];
      } else {
        newStoreObj.filteredOffer[data.status.toLowerCase()] = data.offers;
        newStoreObj.originalData[data.status.toLowerCase()] = data.offers;
      }
      //reseting sort
      newStoreObj.sortoffersType[data.status.toLowerCase()] = {
        product: { ascending: false },
        status: { ascending: false },
        quantity: { ascending: false },
        taxes: { ascending: false },
        shipping: { ascending: false },
        deliveredBy: { ascending: false },
      };
      //reseting search
      newStoreObj.searchText[data.status.toLowerCase()] = '';

      //unique products
      let requestForArray = [];
      data.offers.forEach((offer) => {
        if (offer['Product Type']) {
          requestForArray.push(offer['Product Type'][0]);
        }
      });

      let uniqueProducts = requestForArray.filter((v, i, a) => a.indexOf(v) === i);

      newStoreObj.uniqueProducts = uniqueProducts;
      return newStoreObj;
    },
    setLoading: (_, data) => {
      let newStoreObj = { ..._, loading: data };
      return newStoreObj;
    },

    setUniqueProducts: (_, data) => {
      let newStoreObj = { ..._, uniqueProducts: data };
      return newStoreObj;
    },
    filterChangeHandler: (_, result) => {
      const newStoreObj = {
        ..._,
      };
      const { fieldName, value, currentTab } = result;
      let offersFilterFields = { ...newStoreObj.offersFilterFields };

      offersFilterFields[currentTab][fieldName] = value;
      return {
        ..._,
        offersFilterFields: { ...offersFilterFields },
      };
    },
    applyFilter: (_, currentTab) => {
      let newStoreObj = {
        ..._,
      };
      let searchText = newStoreObj.searchText[currentTab];
      let originalData = newStoreObj.originalData[currentTab];

      let currentFilters = newStoreObj.offersFilterFields[currentTab];
      let filteredValues = originalData;

      //search
      if (searchText.length) {
        filteredValues = originalData.filter(offersFilter(searchText.trim().toLowerCase()));
      }

      //requestFor
      if (currentFilters.product) {
        filteredValues = filteredValues.filter((item) => item['Product Type'][0] === currentFilters.product);
      }
      let filteredData = { ...newStoreObj.filteredOffer };
      filteredData[currentTab] = [...filteredValues];
      return { ...newStoreObj, filteredOffer: { ...filteredData } };
    },
    resetFilter: (_, currentTab) => {
      let newStoreObj = { ..._ };
      let originalData = newStoreObj.originalData;
      let filteredOffer = newStoreObj.filteredOffer;

      filteredOffer[currentTab] = originalData[currentTab];

      let offersFilterFields = { ...newStoreObj.offersFilterFields };
      offersFilterFields[currentTab] = { product: '' };

      return {
        ...newStoreObj,
        filteredOffer: { ...filteredOffer },
        offersFilterFields,
      };
    },
    searchoffers: (_, data) => {
      let newStoreObj = { ..._ };
      let searchText = { ...newStoreObj.searchText };

      const { event, value } = data;

      searchText[value] = event.target.value.toLowerCase();

      return {
        ..._,
        searchText: { ...searchText },
      };
    },
    sortOffers: (_, srcAndType) => {
      let newStoreObj = {
        ..._,
      };
      const { currentTab, type } = srcAndType;

      let data = [];
      if (type === 'product') {
        newStoreObj.sortoffersType[currentTab].product.ascending = !newStoreObj.sortoffersType[currentTab].product
          .ascending;
        data = newStoreObj.filteredOffer[currentTab].sort((a, b) => {
          let aproduct = a['Product Type'][0];
          let bproduct = b['Product Type'][0];
          return sortAscDesc(
            aproduct.toLowerCase(),
            bproduct.toLowerCase(),
            newStoreObj.sortoffersType[currentTab].product.ascending,
          );
        });
      }
      if (type === 'quantity') {
        newStoreObj.sortoffersType[currentTab].quantity.ascending = !newStoreObj.sortoffersType[currentTab].quantity
          .ascending;
        data = newStoreObj.filteredOffer[currentTab].sort((a, b) => {
          let aquantity = parseInt(a['Quoted Quantity']);
          let bquantity = parseInt(b['Quoted Quantity']);
          return sortAscDesc(aquantity, bquantity, newStoreObj.sortoffersType[currentTab].quantity.ascending);
        });
      }

      if (type === 'taxes') {
        newStoreObj.sortoffersType[currentTab].taxes.ascending = !newStoreObj.sortoffersType[currentTab].taxes
          .ascending;
        data = newStoreObj.filteredOffer[currentTab].sort((a, b) => {
          let ataxes = parseFloat(a['Quoted Taxes'].split('$')[1]);
          let btaxes = parseFloat(b['Quoted Taxes'].split('$')[1]);
          return sortAscDesc(ataxes, btaxes, newStoreObj.sortoffersType[currentTab].taxes.ascending);
        });
      }
      if (type === 'shipping') {
        newStoreObj.sortoffersType[currentTab].shipping.ascending = !newStoreObj.sortoffersType[currentTab].shipping
          .ascending;
        data = newStoreObj.filteredOffer[currentTab].sort((a, b) => {
          let ashipping = parseFloat(a['Quoted Shipping Cost'].split('$')[1]);
          let bshipping = parseFloat(b['Quoted Shipping Cost'].split('$')[1]);
          return sortAscDesc(ashipping, bshipping, newStoreObj.sortoffersType[currentTab].shipping.ascending);
        });
      }

      if (type === 'deliveredBy') {
        newStoreObj.sortoffersType[currentTab].deliveredBy.ascending = !newStoreObj.sortoffersType[currentTab]
          .deliveredBy.ascending;
        data = newStoreObj.filteredOffer[currentTab].sort((a, b) => {
          let adeliveredBy = parseInt(a['Quoted Delivery By'].split('days')[0]);
          let bdeliveredBy = parseInt(b['Quoted Delivery By'].split('days')[0]);
          return sortAscDesc(adeliveredBy, bdeliveredBy, newStoreObj.sortoffersType[currentTab].deliveredBy.ascending);
        });
      }

      if (type === 'status') {
        newStoreObj.sortoffersType[currentTab].status.ascending = !newStoreObj.sortoffersType[currentTab].status
          .ascending;
        data = newStoreObj.filteredOffer[currentTab].sort((a, b) => {
          let statusa =
            a['RFQ Offer Status'] === 'Lost' || a['RFQ Offer Status'] === 'Missed'
              ? a['RFQ Offer Status'].toLowerCase()
              : '';

          statusa = a['RFQ Offer Status'] === 'Quoted' ? 'quoted' : statusa;
          statusa = a['RFQ Offer Status'] === 'Won' || a['RFQ Offer Status'] === 'Refunded' ? 'won' : statusa;

          let statusb = b['RFQ Offer Status'] === 'Lost' ? b['RFQ Offer Status'].toLowerCase() : '';

          statusb = b['RFQ Offer Status'] === 'Quoted' ? 'quoted' : statusb;
          statusb = b['RFQ Offer Status'] === 'Won' || b['RFQ Offer Status'] === 'Refunded' ? 'won' : statusb;

          return sortAscDesc(statusa, statusb, newStoreObj.sortoffersType[currentTab].status.ascending);
        });
      }

      let filteredData = { ...newStoreObj.filteredOffer };
      filteredData[currentTab] = [...data];
      return {
        ...newStoreObj,
        filteredOffer: { ...filteredData },
      };
    },
  },

  events: ['loadOffersData'],
});

import React from 'react';

import { useRouteMatch, Route } from 'react-router-dom';

import SwitchWithNotFound from '../../components-library/SwitchWithNotFound';
import UserListPage from './list';
import UserCreatePage from './create';
import UserEditPage from './edit';

const InternalRoutes = () => {
  const match = useRouteMatch();

  return (
    <SwitchWithNotFound>
      <Route path={`${match.path}`} exact component={UserListPage} />
      <Route path={`${match.path}/create`} component={UserCreatePage} />
      <Route path={`${match.path}/edit/:uid`} component={UserEditPage} />
    </SwitchWithNotFound>
  );
};

export default InternalRoutes;

import { state } from '../../store';

export const [productDetailsPageActions, productDetailsPageSelector, productDetailsPageState] = state(
  'ProductDetailsPage',
  {
    initial: {
      data: [],
      loading: true,
    },
    on: {
      loadData: (_, data) => {
        const newStoreObj = {
          ..._,
        };
        newStoreObj.data = data;
        return newStoreObj;
      },
      setLoading: (_, data) => {
        let newStoreObj = { ..._, loading: data };
        return newStoreObj;
      },
    },

    events: ['loadProductDetails'],
  },
);

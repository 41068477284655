import { spawn } from 'redux-saga/effects';
//seller saga

import SellerSingleRFQpageRootSaga from '../seller/single-rfq-page/saga';
import StripeConnectOauthPageRootSaga from '../seller/stripe-connect/saga';
import sellerRfqsRootSaga from '../seller/rfq-page/saga';
import usersRootSaga from '../internal/users/saga';
import signupRootSaga from '../auth/signup/saga';
import sellerOffersRootSaga from '../seller/offers-page/saga';
import sellerOfferPageSagaRootSaga from '../seller/offer-details-page/saga';
import sellerProfilePageSagaRootSaga from '../seller/seller-profile/saga';
import sellerDashboardPageRootSaga from '../seller/seller-dashboard/saga';

//buyer saga
import dealPageRootSaga from '../buyer/deal-page/saga';
import aggregationpageRootSaga from '../buyer/aggregation-page/saga';
import buyerRfqPageSagaRootSaga from '../buyer/rfq-page/saga';
import buyerProfilePageSagaRootSaga from '../buyer/buyer-profile/saga';
import sellerRfqDetailsRootSaga from '../seller/rfq-details-page/saga';
import { OfferspageRootSaga } from '../buyer/offers-page/saga';
import groupOrdersPageRootSaga from '../buyer/group-orders/saga';
import sellerProductPageSagaRootSaga from '../seller/products-page/saga';
import addProductPageRootSaga from '../seller/add-new-product/saga';
import createEditRfqRootSaga from '../buyer/create-edit-rfq-page/saga';
import productDetailsRootSaga from '../seller/product-details-page/saga';
import landingPageRootSaga from '../landing-pages/saga';
import buyerDashboardPageRootSaga from '../buyer/buyer-dashboard/saga';

export default function* rootSaga() {
  yield spawn(SellerSingleRFQpageRootSaga);
  yield spawn(dealPageRootSaga);
  yield spawn(StripeConnectOauthPageRootSaga);
  yield spawn(buyerRfqPageSagaRootSaga);
  yield spawn(aggregationpageRootSaga);
  yield spawn(sellerRfqsRootSaga);
  yield spawn(sellerOffersRootSaga);
  yield spawn(sellerOfferPageSagaRootSaga);
  yield spawn(sellerProfilePageSagaRootSaga);
  yield spawn(buyerProfilePageSagaRootSaga);
  yield spawn(sellerRfqDetailsRootSaga);
  yield spawn(OfferspageRootSaga);
  yield spawn(groupOrdersPageRootSaga);
  yield spawn(usersRootSaga);
  yield spawn(signupRootSaga);
  yield spawn(sellerProductPageSagaRootSaga);
  yield spawn(addProductPageRootSaga);
  yield spawn(createEditRfqRootSaga);
  yield spawn(productDetailsRootSaga);
  yield spawn(landingPageRootSaga);
  yield spawn(buyerDashboardPageRootSaga);
  yield spawn(sellerDashboardPageRootSaga);
}

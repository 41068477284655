import { putResolve, take, call, spawn } from 'redux-saga/effects';
import { rfqActions } from './store';
import { api } from '../../app/api';
import { appActions } from '../../app/store';

export function* RFQpageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(rfqActions.loadRfqData().type);
      let apiData = yield call(api, `/.netlify/functions/rfq?id=${data.id}&draft_offer=${data.draft_offer}`);

      yield putResolve(rfqActions.setLoadingStatus(false));

      if (apiData.error) {
        yield putResolve(appActions.setError(apiData.error));
        yield putResolve(appActions.hideNav());
        return;
      }
      if (apiData.data && apiData.error === null) {
        //ratings check
        let ratingCSS = apiData.data['Product Ratings CSS'] ? apiData.data['Product Ratings CSS'] : 0;
        let ratingCount = apiData.data['Product Ratings Count'] ? apiData.data['Product Ratings Count'] : 0;
        let showRating = true;
        if (!ratingCount || ratingCount === 0) {
          showRating = false;
        }
        if (!ratingCSS || ratingCSS === 0) {
          showRating = false;
        }
        apiData.data.showRating = showRating;
        yield putResolve(appActions.setId(apiData.data['Supplier Record ID']));
        yield putResolve(rfqActions.load(apiData.data));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(appActions.hideNav());
      yield putResolve(rfqActions.setLoadingStatus(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}
export function* loadOfferDataSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(rfqActions.loadOfferData().type);

      let apiData = yield call(api, `/.netlify/functions/seller-offer-details?id=${data.id}&rfqId=${data.rfqId}`);
      yield putResolve(rfqActions.setLoadingStatus(false));

      if (apiData.error) {
        yield putResolve(appActions.setError(apiData.error));
        yield putResolve(appActions.hideNav());
        return;
      }

      if (apiData.data['RFQ Status'] !== 'Completed') {
        yield putResolve(
          appActions.setError('You can not edit ' + apiData.data['RFQ Status'].toLowerCase() + ' offer'),
        );
        yield putResolve(appActions.hideNav());
        return;
      }

      if (apiData.data && apiData.error === null && apiData.data['RFQ Status'] === 'Completed') {
        //ratings check
        let ratingCSS = apiData.data['Product Ratings CSS'] ? apiData.data['Product Ratings CSS'] : 0;
        let ratingCount = apiData.data['Product Ratings Count'] ? apiData.data['Product Ratings Count'] : 0;
        let showRating = true;
        if (!ratingCount || ratingCount === 0) {
          showRating = false;
        }
        if (!ratingCSS || ratingCSS === 0) {
          showRating = false;
        }
        apiData.data.showRating = showRating;

        yield putResolve(rfqActions.load(apiData.data));

        yield putResolve(appActions.setId(apiData.data['Supplier Record ID']));

        yield putResolve(rfqActions.setData(apiData.data));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(appActions.hideNav());
      yield putResolve(rfqActions.setLoadingStatus(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}
export function* offerUpdateSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(rfqActions.offerUpdate().type);
      let result = yield call(api, `/.netlify/functions/offer-update`, 'PUT', data);
      if (result && result.data && result.error === null) {
        data.history.push(`offer-details?id=${result.data['Supplier Record ID']}&rfqId=${result.data['RFQ ID']}`);
      } else {
        yield putResolve(appActions.setError(result.error));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(rfqActions.setLoadingStatus(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}
export function* OfferSubmitSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(rfqActions.offerSubmit().type);
      let result = yield call(api, `/.netlify/functions/offer-submission`, 'POST', data);

      if (result && result.data && result.error === null) {
        data.history.push(`/offer-submission?id=${data.supplierRecordID}`);
      } else {
        yield putResolve(rfqActions.setModalShow(false));

        yield putResolve(appActions.setError(result.error));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(rfqActions.setLoadingStatus(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}
export function* OfferDraftSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(rfqActions.offerDraft().type);
      let result = yield call(api, `/.netlify/functions/offer-draft`, 'POST', data);

      if (result && result.data && result.data.type === 'redirect') {
        window.location = result.data.value;
      }
      if (result && result.error && result.error.length && result.data === null) {
        yield putResolve(appActions.setError(result.error));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(rfqActions.setLoadingStatus(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export function* getSubTotalTaxesSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(rfqActions.getSubTotalTaxes().type);
      yield putResolve(rfqActions.setTaxSpinner(true));
      let result = yield call(
        api,
        `/.netlify/functions/calculate-tax-seller-rfq?buyer_id=${data.buyerId}&subtotal=${data.subtotal}`,
      );

      console.log('result ', result);
      if (result && result.data) {
        yield putResolve(rfqActions.setTaxes(result.data.value));
        yield putResolve(rfqActions.setTaxSpinner(false));
        yield putResolve(rfqActions.showTaxDisclaimer());
      }
      if (result && result.error && result.error.length && result.data === null) {
        yield putResolve(rfqActions.setTaxSpinner(false));
        console.log('Error in tax api', result.error);
      }
    } catch (err) {
      console.log(err);
      yield putResolve(rfqActions.setTaxSpinner(false));
    }
  }
}
export default function* SellerSingleRFQpageRootSaga() {
  yield spawn(RFQpageSaga);
  yield spawn(OfferSubmitSaga);
  yield spawn(OfferDraftSaga);
  yield spawn(loadOfferDataSaga);
  yield spawn(offerUpdateSaga);
  yield spawn(getSubTotalTaxesSaga);
}

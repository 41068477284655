import { putResolve, take, call, spawn } from 'redux-saga/effects';
import { editRfqPageActions } from './store';
import { appActions } from '../../app/store';
import { api } from '../../app/api';

export function* editRfqPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(editRfqPageActions.loadData().type);
      yield putResolve(editRfqPageActions.setLoading(true));

      let apiData = yield call(api, `/.netlify/functions/offer?id=${data}`);

      if (apiData.error) {
        yield putResolve(editRfqPageActions.setLoading(false));
        yield putResolve(appActions.hideNav());
        yield putResolve(appActions.setError(apiData.error));
        return;
      }

      if (apiData.data) {
        let productTypes = yield call(
          api,
          `/.netlify/functions/get-buyer-product-types?id=${apiData.data['Buyer Record ID']}`,
        );

        yield putResolve(editRfqPageActions.setLoading(false));

        if (apiData.error === null && productTypes.data) {
          yield putResolve(editRfqPageActions.setCategoryList(productTypes.data));
          yield putResolve(editRfqPageActions.loadRfqData(apiData.data));
          yield putResolve(appActions.setId(apiData.data['Buyer Record ID']));
        }
      }
    } catch (err) {
      console.log('error in editRfqPageSaga', err);
      yield putResolve(appActions.hideNav());
      yield putResolve(editRfqPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}
export function* createRfqLoadDataPageSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(editRfqPageActions.createRfqLoadData().type);
      yield putResolve(editRfqPageActions.setLoading(true));

      let result = yield call(api, `/.netlify/functions/get-buyer-product-types?id=${data}`);

      yield putResolve(editRfqPageActions.setLoading(false));

      if (result.error) {
        yield putResolve(appActions.hideNav());
        yield putResolve(appActions.setError(result.error));
        return;
      }

      if (result.data && result.error === null) {
        yield putResolve(editRfqPageActions.setCategoryList(result.data));
        // yield putResolve(appActions.setId(result.data["Buyer Record ID"]));
      }
    } catch (err) {
      console.log('error in createRfqLoadDataPageSaga', err);
      yield putResolve(appActions.hideNav());
      yield putResolve(editRfqPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export function* createRfqSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(editRfqPageActions.createRfq().type);

      let result = yield call(api, `/.netlify/functions/create-rfq`, 'POST', data);

      if (result.error) {
        yield putResolve(appActions.hideNav());
        yield putResolve(appActions.setError(result.error));
        return;
      }

      if (result.data && result.error === null) {
        data.history.push(`/buyer-rfqs?id=${result.data['Buyer Record ID']}`);
      }
    } catch (err) {
      console.log('error in createRfqSaga', err);
      yield putResolve(appActions.hideNav());
      yield putResolve(editRfqPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}
export function* updateRfqSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(editRfqPageActions.updateRfq().type);

      let result = yield call(api, `/.netlify/functions/update-rfq`, 'PUT', data);

      if (result.error) {
        yield putResolve(appActions.hideNav());
        yield putResolve(appActions.setError(result.error));
        return;
      }

      if (result.data && result.data.type === 'redirect' && result.error === null) {
        data.history.push(result.data.value);
      }
    } catch (err) {
      console.log('error in updateRfqSaga', err);
      yield putResolve(appActions.hideNav());
      yield putResolve(editRfqPageActions.setLoading(false));
      yield putResolve(appActions.setError('Something went wrong'));
    }
  }
}

export default function* createEditRfqRootSaga() {
  yield spawn(editRfqPageSaga);
  yield spawn(updateRfqSaga);
  yield spawn(createRfqSaga);
  yield spawn(createRfqLoadDataPageSaga);
}

import React, { Component } from 'react';

export default class ErrorPage extends Component {
  render() {
    const { error, callUsAt = false } = this.props;
    return (
      <div className="container pt-5">
        <div className="row justify-content-center pt-5">
          <div className="col-12 col-md-5 col-xl-4 my-5 pt-5">
            <div className="text-center pt-5">
              <h1 className="display-4 mb-3">Whoops!</h1>
              <p>{error}</p>
              <p className="text-muted mb-4">
                Need help with something? Reach out to{' '}
                <a href="mailto:marketplace@projectn95.org" target="_blank" rel="noopener noreferrer">
                  marketplace@projectn95.org
                </a>
                .
                {callUsAt === true && (
                  <>
                    <br /> or call us at <a href="te:202-849-9858">202-849-9858</a>.
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const options = {
  AMERICAN_ACADEMY_OF_FAMILY_PHYSICIANS: 'American Academy of Family Physicians',
  AMERICAN_ACADEMY_OF_NEUROLOGY: 'American Academy of Neurology',
  AMERICA_ACADEMY_OF_OPHTHALMOLOGY: 'American Academy of Ophthalmology',
  AMERICAN_COLLEGE_OF_ALLERGY: 'American College of Allergy, Asthma and Immunology',
  AMERICAN_ACADEMY_OF_PHYSICAL_MEDICINE: 'American Academy of Physical Medicine and Rehabilitation',
  AMERICAN_COLLEGE_OF_EMERGENCY_PHYSICIANS: 'American College of Emergency Physicians',
  AMERICAN_COLLEG_OF_RADIOLOGY: 'American College of Radiology',
  AMERICAN_COLLEGE_OF_PHYSICIANS: 'American College of Physicians',
  AMERICAN_MEDICAL_WOMENS_ASSOCIATION: 'American Medical Women’s Association',
  AMERICAN_PSYCHIATRIC_ASSOCIATION: 'American Psychiatric Association',
  AMERICAN_SOCIETY_OF_ANESTHESIOLOGISTS: 'American Society of Anesthesiologists',
  AMERICAN_COLLEGE_OF_GASTROENTEROLOGY: 'American College of Gastroenterology',
  AMERICAN_ACADEMY_OF_OTOLARYNGIC_ALLERGY: 'American Academy of Otolaryngic Allergy',
  AMERICAN_SOCIETY_OF_CLINICAL_ONCOLOGY: 'American Society of Clinical Oncology',
  AMERICAN_SOCIETY_OF_RADIATION_ONCOLOGY: 'American Society of Radiation Oncology',
  AMERICAN_SOCIETY_FOR_CLINICAL_PATHOLOGY: 'American Society for Clinical Pathology',
  AMERICAN_SOCIETY_FOR_GASTROINTESTINAL_ENDOSCOPY: 'American Society for Gastrointestinal Endoscopy',
};

export const redirectLinks = {
  [options.AMERICAN_ACADEMY_OF_FAMILY_PHYSICIANS]:
    'https://app.aafp.org/sf/s/login/?ec=302&inst=3l&startURL=%2Fsf%2Fidp%2Flogin%3Fapp%3D0sp1N0000008XOm%26RelayState%3Dhttps%253A%252F%252Fwww.aafp.org%252Fcgi-bin%252Flg.pl%253Fredirect%253Dhttps%253A%252F%252Fwww.aafp.org%252Fhome.html%26binding%3DHttpPost%26inresponseto%3D_ccaea6fc479df71484e55e2801366e74',
  [options.AMERICAN_ACADEMY_OF_NEUROLOGY]: 'https://www.aan.com/',
  [options.AMERICA_ACADEMY_OF_OPHTHALMOLOGY]: 'https://secure.aao.org/aao/Login?returnUrl=https%3a%2f%2fwww.aao.org%2f',
  [options.AMERICAN_COLLEGE_OF_ALLERGY]: 'https://imis.acaai.org/IdP/Login.aspx?ReturnUrl=%2fIdP%2fSSO%2fPost.aspx',
  [options.AMERICAN_ACADEMY_OF_PHYSICAL_MEDICINE]:
    'https://members.aapmr.org/AAPMR/iCore/Contacts/Sign_In.aspx?ReturnURL=/AAPMR/AAPMR/SignInRedirect.aspx',
  [options.AMERICAN_COLLEGE_OF_EMERGENCY_PHYSICIANS]:
    'https://signin.acep.org/idp/Account/Login?ReturnUrl=%2fidp%2fSAML%2fSSOService%3fSAMLRequest%3dfZJfT8IwFMW%252FytL3rbMM1AaWIMRI4p%252FFoQ%252B%252BmNLdQZOtnb0d6Le3Gyj4IA9tmptze373tGMUddXwaes2%252Bhk%252BWkAXLOYT8s5GbBDLeBgOrsVVmABLwtVQXoeJFMPRwG%252BlHJLgFSwqoyeERTEJFogtLDQ6oZ0vxSwO46uQJUvGOEt4zKLk8vKNBHPvorRwfefGuQY5pajWWulISGgiY9dUFQ3Npw%252F3NM%252BfcrBbJYEEt8ZK6GEnpBQVQmeaCUS1hd9KZo0z0lQ3ShdKryektZobgQq5FjUgd5J3N3MPzVd7EfK75TILs6d8SYIpItiObmY0tjXYg%252F%252FL8%252F2Rd7fbHWFFoyjuRUgRTbfkoRl%252F4D%252FrSiPvAz%252FP1BwGIOm4U%252FM%252BV3vSf75d%252FOCTtJOdgvaHWmixhoIfpvJxbVUBdkxPzPbODX%252F0ty%252FmmamU%252FOrSr4X73%252FwiuugrqgjLXspbjQ1IVSoofKxVZXYzC8L5t3K29ZHQdO%252F69wem3w%253D%253D%26RelayState%3d%252F',
  [options.AMERICAN_COLLEG_OF_RADIOLOGY]: 'https://www.acr.org/Login-Page?returnUrl=https%3A%2F%2Fwww.acr.org%2F',
  [options.AMERICAN_COLLEGE_OF_PHYSICIANS]: 'https://www.acponline.org/',
  [options.AMERICAN_MEDICAL_WOMENS_ASSOCIATION]:
    'https://www.amwa-doc.org/login/?redirect_to=https%3A%2F%2Fwww.amwa-doc.org%2Fwp-admin%2F&reauth=1',
  [options.AMERICAN_PSYCHIATRIC_ASSOCIATION]:
    'https://my.psychiatry.org/APA_login?startURL=%2Fidp%2Flogin%3Fapp%3D0sp360000004FM3%26RelayState%3DLw%253D%253D%26binding%3DHttpPost',
  [options.AMERICAN_SOCIETY_OF_ANESTHESIOLOGISTS]:
    'https://auth.asahq.org/saml/Account/Login?returnUrl=%2Fsaml%2FSAML%2FSSOService%3Finitiator%3Dhttps%253A%252F%252Fwww.asahq.org&brand=ASAHQ',
  [options.AMERICAN_COLLEGE_OF_GASTROENTEROLOGY]: 'https://gi.org/',
  [options.AMERICAN_SOCIETY_FOR_GASTROINTESTINAL_ENDOSCOPY]: '',
  [options.AMERICAN_ACADEMY_OF_OTOLARYNGIC_ALLERGY]: 'https://aaoa.cloud-cme.com/default.aspx',
  [options.AMERICAN_SOCIETY_OF_CLINICAL_ONCOLOGY]: 'https://www.asco.org/user/login?destination=front',
  [options.AMERICAN_SOCIETY_OF_RADIATION_ONCOLOGY]: 'https://www.astro.org/',
  [options.AMERICAN_SOCIETY_FOR_CLINICAL_PATHOLOGY]:
    'https://sso.ascp.org/account/signin?ReturnUrl=%2fissue%2fwsfed%3fwtrealm%3dhttp%253A%252F%252Fwww.ascp.org%252Frouter%26wa%3dwsignin1.0%26wreply%3dhttps%253A%252F%252Fwww.ascp.org%252Fapps%252Frouter%252Fsignin-wsfed%26wctx%3dCfDJ8ABQBbYBtXZAhyr_lXbMz8_jSh1hQiVICWRSE5UmJe1Q2EsgwWRVy_59ulEqca6lfUPCCLEKetm_eIbVmDVoVMdrr5ob1xe1I-jOEIe8tQetkTiMaG7MvRrCjesNSk93mTHYXa8JZF56DYEJJThHr1SfUu8p6X1kolm9aTcjvNlRPmFinuwZRGOyeXFhKJV9_Au-icSvObZSj9feayZQph5k-N7pOeI_wyFtN3YMLaC13QGlopS6zSQQxAyO83Tj4Q&wtrealm=http%3A%2F%2Fwww.ascp.org%2Frouter&wa=wsignin1.0&wreply=https%3A%2F%2Fwww.ascp.org%2Fapps%2Frouter%2Fsignin-wsfed&wctx=CfDJ8ABQBbYBtXZAhyr_lXbMz8_jSh1hQiVICWRSE5UmJe1Q2EsgwWRVy_59ulEqca6lfUPCCLEKetm_eIbVmDVoVMdrr5ob1xe1I-jOEIe8tQetkTiMaG7MvRrCjesNSk93mTHYXa8JZF56DYEJJThHr1SfUu8p6X1kolm9aTcjvNlRPmFinuwZRGOyeXFhKJV9_Au-icSvObZSj9feayZQph5k-N7pOeI_wyFtN3YMLaC13QGlopS6zSQQxAyO83Tj4Q',
  [options.AMERICAN_SOCIETY_FOR_GASTROINTESTINAL_ENDOSCOPY]: 'https://www.asge.org/account/login?ReturnUrl=/',
};

import React from 'react';

import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import firebase from 'firebase';

import logoSrc from '../app/assets/img/p95-lvrg-logo.png';

import googleLogoSrc from '../app/assets/img/google-logo.svg';

export const GoogleButton = ({ onError }) => {
  return (
    <Button
      variant="outline-secondary"
      style={{ width: '100%' }}
      onClick={async () => {
        try {
          await firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider());
        } catch (err) {
          onError(err);
        }
      }}
    >
      <img src={googleLogoSrc} alt="Google" style={{ marginRight: 18 }} />
      <span>Continue with Google</span>
    </Button>
  );
};

export const AuthExtra = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
  padding: 0 10px;
`;

export const AuthBody = ({ children, header }) => (
  <Card style={{ width: '100%', marginBottom: 0 }}>
    {header && <Card.Header as="h2">{header}</Card.Header>}
    <Card.Body>{children}</Card.Body>
  </Card>
);

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: auto;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

const ERROR_MESSAGES = {
  'auth/wrong-password': 'Incorrect password entered.',
};

export const AuthPage = ({ children, maxWidth = 500, error = '' }) => {
  let errorMessage = '';

  if (typeof error === 'string') {
    errorMessage = error;
  } else if (error.code) {
    errorMessage = ERROR_MESSAGES[error.code] || error.message;
  } else {
    errorMessage = error.message;
  }

  return (
    <Container
      style={{ height: '100%', minHeight: 'inherit', display: 'flex', flexDirection: 'column', padding: '24px 12px' }}
    >
      <AuthContainer style={{ maxWidth }}>
        <img
          src={logoSrc}
          className="navbar-brand-img mx-auto"
          style={{ maxWidth: 250, maxHeight: '100%', marginBottom: 24 }}
          alt="Project N95 (powered by LVRG)"
        />
        <Alert variant="danger" show={Boolean(errorMessage)}>
          <p>{errorMessage}</p>
          Need help? Contact support at frontline@projectn95.org.
        </Alert>
        {children}
      </AuthContainer>
    </Container>
  );
};

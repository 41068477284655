import React, { Component } from 'react';
import moment from 'moment';
import { state } from '../../store';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { sellerOfferPageActions, sellerOfferPageSelector } from './store';
import { appSelector } from '../../app/store';
import ErrorPage from '../../components-library/error-page';

export const SellerOfferDetails = withRouter(
  connect(
    state.select({
      data: sellerOfferPageSelector((state) => state.data, null),
      loading: sellerOfferPageSelector((state) => state.loading, null),
      error: appSelector((state) => state.error, ''),
      shippingLoading: sellerOfferPageSelector((state) => state.shippingLoading, false),
    }),
    state.actions(sellerOfferPageActions, ['getOfferData', 'shippingSubmissionOffer', 'deleteShippingInfo']),
  )(
    class SellerOfferDetails extends Component {
      componentWillMount() {
        const { query } = this.props;
        let id = query.get('id');
        let rfqId = query.get('rfqId');
        if (id && rfqId) {
          this.props.getOfferData({ id, rfqId });
        } else {
          this.props.history.push('/404');
        }
      }
      breadcrumb = () => {
        return (
          <div className="header">
            <div className="pt-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href={`/seller-offers?id=${this.props.data['Supplier Record ID']}`}>Offers</a>
                  </li>
                  <li className="breadcrumb-item active">Details</li>
                </ol>
              </nav>
            </div>
          </div>
        );
      };

      submitHandler = (event) => {
        const { data } = this.props;
        event.preventDefault();

        let payload = {
          shippingLink: event.target[0].value,
          buyerActionId: data['Buyer Actions'][0],
          sellerId: data['Supplier Record ID'][0],
          expectedDeliveryDate: event.target[1].value,
          rfqId: data['Record ID'],
        };

        this.props.shippingSubmissionOffer(payload);
      };

      render() {
        const { data, loading, error, shippingLoading } = this.props;
        let showEditButton = data['Offer Approved'] ? (data['Offer Approved'][0] === true ? false : true) : false;

        if (loading) {
          return (
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-10 col-xl-8">
                  {this.breadcrumb()}
                  <section className="container page-content pb-6 market-content text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          );
        }
        if (error && error.length > 0) {
          return <ErrorPage error={error} />;
        }
        let getTimeline = null;
        if (data['Timeline'] && data['Timeline'].length) {
          let timeline = data['Timeline'];
          if (parseInt(timeline) > 0) {
            getTimeline = `${timeline} days`;
          } else {
            getTimeline = 'ASAP';
          }
        }

        //offer with status completed
        let quotedOffer = data && (
          <div className="card card-body p-5">
            <div className="row pt-2 bg-light">
              <div className="col text-left">
                <strong>Record ID: </strong>
                {data['Hotline Record ID']}
              </div>
              <div className="col text-right">
                <span className="text-warning">●</span> Quoted
              </div>
            </div>

            <div className="row bg-light pt-4 pb-4">
              <div className="col text-center">
                <h2 className="mb-2"> {data['Product Type']}</h2>
                <strong className="text-uppercase">
                  YOUR QUOTE: <span>{data['Quoted Price']}</span>
                </strong>

                <h3 className="text-muted mt-2 mb-3">
                  <span>
                    {data['Manufacturer']} / {data['Product Model']}
                  </span>
                </h3>
                {showEditButton && (
                  <button
                    className="btn btn-primary "
                    onClick={(e) => {
                      this.props.history.push(
                        `/edit-offer?id=${data['Supplier Record ID']}&rfqId=${data['Record ID']}`,
                      );
                    }}
                  >
                    Edit Offer
                  </button>
                )}
              </div>
            </div>
            <hr />

            <div className="card mt-3">
              <div className="card-body">
                <div className="row justify-content-between">
                  <div className="col-12 text-center mb-4 ">
                    <span className="h4 text-uppercase">
                      Other Offers&nbsp;
                      {/* <span className="h2 mb-0">
                        {data["Min Quote from Offers"]}
                      </span> */}
                    </span>
                  </div>
                  <div className="col-12">
                    {/* 1 row starts */}
                    <div className="row no-gutters ">
                      <div className="col-6 py-4 text-center">
                        <h6 className="text-uppercase text-muted">Lowest Offer</h6>

                        <h2 className="mb-0">{data['Min Quote from Offers']}</h2>
                      </div>
                      <div className="col-6 py-4 text-center border-left">
                        <h6 className="text-uppercase text-muted"># of quotes</h6>

                        <h2 className="mb-0">{data['NoOfOffers']}</h2>
                      </div>
                    </div>
                    {/* 1st row ends */}
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div className="row mt-5">
              <div className="col-12 col-md-6">
                <strong className="text-body">Total:</strong>
              </div>
              <div className="col-12 col-md-6 text-md-right">
                <p className="text-body">{data['Quoted Total']}</p>
              </div>
              <div className="col-12 col-md-6">
                <strong className="text-body">Quantity (each/ounce/wipe):</strong>
              </div>
              <div className="col-12 col-md-6 text-md-right">
                <p className="text-body">{data['Quoted Quantity']}</p>
              </div>
              <div className="col-12 col-md-6">
                <strong className="text-body">Product Specifications:</strong>
              </div>
              <div className="col-12 col-md-6 text-md-right">
                <p className="text-body">{data['Product Spec'] ? data['Product Spec'] : '-'}</p>
              </div>
              <div className="col-12 col-md-6">
                <strong className="text-body">Taxes:</strong>
              </div>
              <div className="col-12 col-md-6 text-md-right">
                <p className="text-body">{data['Quoted Taxes']}</p>
              </div>
              <div className="col-12 col-md-6">
                <strong className="text-body">Shipping:</strong>
              </div>
              <div className="col-12 col-md-6 text-md-right">
                <p className="text-body">{data['Quoted Shipping Cost']}</p>
              </div>
              <div className="col-12 col-md-6">
                <strong className="text-body">Delivery by:</strong>
              </div>
              <div className="col-12 col-md-6 text-md-right">
                <p className="text-body">{data['Quoted Delivery By']}</p>
              </div>
              <div className="col-12 col-md-6">
                <strong className="text-body">Additional Notes:</strong>
              </div>
              <div className="col-12 col-md-6 text-md-right">
                <p className="text-body">{data['Quoted Notes'] ? data['Quoted Notes'] : '-'}</p>
              </div>
            </div>
          </div>
        );

        let d = new Date();

        let minDeliveryDate =
          d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2);
        //offer with rfq status >>>Won
        let wonOffer = data && (
          <div key={Math.random()}>
            <div className="card card-body p-5">
              <div className="row pt-2 bg-light">
                <div className="col text-left">
                  <strong>Record ID: </strong>
                  {data['Hotline Record ID']}
                </div>
                <div className="col text-right">
                  <span className="text-success">●</span> Won
                </div>
              </div>

              <div className="row bg-light pt-4">
                <div className="col text-center">
                  <h2 className="mb-2 text-uppercase">{data['Product Type']}</h2>
                  <span>
                    <strong className="text-uppercase">PAYOUT: </strong>
                    {data.Payout}
                  </span>

                  <p>
                    <span className="text-muted mb-3 ">
                      {data['Winning Receipt URL'] ? (
                        <>
                          <a href={`${data['Winning Receipt URL']}`}>Receipt URL</a>
                          <br />
                          <strong>Paid Out: </strong>
                          {data['Paid Out?']}
                        </>
                      ) : (
                        <>
                          <strong>Status: </strong> Invoice Sent
                        </>
                      )}
                    </span>
                  </p>

                  <div>
                    <p className="text-muted mb-5 h3">
                      <span>
                        {data['Manufacturer']}
                        <br />
                        {data['Product Model']}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <hr />

              {data['Winning Receipt URL'] ? (
                <>
                  <div className="row my-3">
                    <div className="col">
                      <div className="row">
                        <h2 className="col-12 mb-5 text-uppercase">Shipping Information</h2>

                        <div className="col-12">
                          <div className="row mb-3">
                            <div className="col-5 font-weight-bold">Shipping Tracking URL</div>
                            <div className="col-5 font-weight-bold">Expected Delivery Date</div>
                            <div className="col-2"></div>
                          </div>
                          {data['Shipping Information'] &&
                            data['Shipping Information'].map((item, key) => {
                              return (
                                <div className="row" key={key}>
                                  <div className="col-5">
                                    <a href={item['Shipping Link']} target="_blank" rel="noopener noreferrer">
                                      {item['Shipping Link']}
                                    </a>
                                  </div>
                                  <div className="col-5">{moment(item['Expected Delivery']).format('YYYY/MM/DD')}</div>
                                  <div className="col-2 text-right">
                                    {item.loading === true ? (
                                      <button className="close close-sm ml-3 mt-2" disabled={true}>
                                        <span
                                          className="spinner-border spinner-border-sm align-self-center"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                      </button>
                                    ) : (
                                      <button
                                        className="close close-sm ml-3 mt-2"
                                        onClick={() => {
                                          this.props.deleteShippingInfo({
                                            id: item['Record ID'],
                                            offerId: item['Offer Record ID'][0],
                                          });
                                        }}
                                      >
                                        <i className="fe fe-x"></i>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          <form onSubmit={this.submitHandler} className="row">
                            <div className="col-md-5 mt-sm-3 mt-3 col-12 col-sm-12">
                              <input
                                type="url"
                                className="form-control"
                                name="shippingLink"
                                id="shippingLink"
                                placeholder="https://example.com"
                                required
                              />
                            </div>
                            <div className="col-md-5  mt-sm-3 mt-3 col-12 col-sm-12">
                              <input
                                type="date"
                                className="form-control"
                                name="expectedDeliveryDate"
                                id="expectedDeliveryDate"
                                min={minDeliveryDate}
                                required
                              />
                            </div>
                            <div className="col-md-2 col-12 mt-sm-3 mt-3 col-sm-12 text-center text-md-right text-sm-center">
                              {shippingLoading === true ? (
                                <button className="btn btn-link kanban-add-link pr-0" disabled={true}>
                                  <span
                                    className="spinner-border spinner-border-sm align-self-center"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                </button>
                              ) : (
                                <button className="btn btn-link kanban-add-link pr-0">Add</button>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </>
              ) : null}

              <div className="row mt-5">
                <div className="col">
                  <h2 className="mb-2 text-uppercase">Buyer Information</h2>
                  <div className="row mt-5">
                    {data['Winning Receipt URL'] ? (
                      <>
                        <div className="col-12 col-md-6">
                          <strong className="text-body">Name:</strong>
                        </div>
                        <div className="col-12 col-md-6 text-md-right">
                          <p className="text-body">{data['Buyer Name']}</p>
                        </div>
                        <div className="col-12 col-md-6">
                          <strong className="text-body">Organization:</strong>
                        </div>
                        <div className="col-12 col-md-6 text-md-right">
                          <p className="text-body">{data['Buyer Org']}</p>
                        </div>
                        <div className="col-12 col-md-6">
                          <strong className="text-body">Shipping Address:</strong>
                        </div>
                        <div className="col-12 col-md-6 text-md-right">
                          <p className="text-body">{data['Shipping Address']}</p>
                        </div>
                        <div className="col-12 col-md-6">
                          <strong className="text-body">Delivery Instructions:</strong>
                        </div>
                        <div className="col-12 col-md-6 text-md-right">
                          <p className="text-body">{data['Winning Delivery Instructions']}</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-12 col-md-6">
                          <strong className="text-body">Organization Type:</strong>
                        </div>
                        <div className="col-12 col-md-6 text-md-right">
                          <p className="text-body">{data['Org Type']}</p>
                        </div>
                        <div className="col-12 col-md-6">
                          <strong className="text-body">Ship-to Zipcode:</strong>
                        </div>
                        <div className="col-12 col-md-6 text-md-right">
                          <p className="text-body">{data['Zipcode']}</p>
                        </div>
                        <div className="col-12 col-md-6">
                          <strong className="text-body">Product Specifications:</strong>
                        </div>
                        <div className="col-12 col-md-6 text-md-right">
                          <p className="text-body">{data['Product Spec'] ? data['Product Spec'] : '-'}</p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <hr />
              <div className="row mt-5">
                <div className="col">
                  <h2 className="mb-2 text-uppercase">Transaction Details</h2>

                  <div className="row mt-5">
                    <>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Date: </strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{moment(data['Transaction Date']).format('YYYY-MM-DD hh:mm A')}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Quantity (each/ounce/wipe):</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Quoted Quantity']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Unit Price:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Quoted Price']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Taxes:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Quoted Taxes']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Shipping:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Quoted Shipping Cost']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Delivery by:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Quoted Delivery By']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Additional Notes:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Quoted Notes'] ? data['Quoted Notes'] : '-'}</p>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

        //offer with rfq status lost >>
        let lostOffer = data && (
          <div>
            <div className="card card-body p-5">
              <div className="row pt-2 bg-light">
                <div className="col text-left">
                  <strong>Record ID: </strong>
                  {data['Hotline Record ID']}
                </div>
                <div className="col text-right">
                  <span className="text-danger">●</span> Lost
                </div>
              </div>

              <div className="row bg-light pt-4">
                <div className="col text-center">
                  <h2 className="mb-2 text-uppercase">{data['Product Type']}</h2>

                  <div>
                    <p className="text-muted mb-6">
                      <strong>TOTAL QUOTED:</strong>
                      <span>{data['Quoted Total']}</span>
                    </p>
                  </div>
                </div>
              </div>
              <hr />

              <div className="row mt-5">
                <div className="col">
                  <h2 className="mb-2 text-uppercase">Buyer Request</h2>
                  <div className="row mt-5">
                    <>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Opportunity:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Revenue Opportunity']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Max Price per Unit:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Max Price']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Quantity:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Quantity'] && data['Quantity'].toLocaleString()}</p>
                      </div>

                      <div className="col-12 col-md-6">
                        <strong className="text-body">Needed by:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{getTimeline}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Ship-to Zipcode:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Zipcode']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Organization Type:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Org Type']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Product Specifications:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Product Spec'] ? data['Product Spec'] : '-'}</p>
                      </div>
                    </>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row mt-5">
                <div className="col">
                  <h2 className="mb-2 text-uppercase">Your Offer</h2>
                  <div className="row mt-5">
                    <>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Unit Price:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Quoted Price']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Quantity (each/ounce/wipe):</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Quoted Quantity']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Unit Price:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Quoted Price']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Taxes:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Quoted Taxes']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Shipping:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Quoted Shipping Cost']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Delivery by:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Quoted Delivery By']}</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <strong className="text-body">Additional Notes:</strong>
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="text-body">{data['Quoted Notes'] ? data['Quoted Notes'] : '-'}</p>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        return (
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-8">
                {this.breadcrumb()}
                {data && data['RFQ Status'] && data['RFQ Status'] === 'Completed' ? quotedOffer : null}
                {data && data['RFQ Status'] && data['RFQ Status'] === 'Won' ? wonOffer : null}

                {data && data['RFQ Status'] && data['RFQ Status'] === 'Lost' ? lostOffer : null}
              </div>
            </div>
          </div>
        );
      }
    },
  ),
);
